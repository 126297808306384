import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import BasicContainer from "../../components/BasicContainer";
import { ButtonA, ButtonB } from "../../components/Button";
import { Black, Gray300, Green600 } from "../../config/color";
import { getWindowSize, thousandComma } from "../../utils";

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1439px) {
    min-width: 320px;
    width: calc(100% - 40px);
    padding: 0 20px;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 72px;
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  color: ${Black};
  white-space: pre-wrap;
  line-height: 51px;
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 28.8px;
  white-space: pre-wrap;
  word-break: keep-all;
`;

const RowDiv = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  width: 1328px;
  margin: 0 auto;

  @media screen and (max-width: 1439px) {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
`;

const ColumnDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 1328px;
  margin: 0 auto;

  @media screen and (max-width: 1439px) {
    width: 100%;
  }
`;

const InfoBox = styled.div`
  display: flex;
  height: 484px;
  flex-direction: column;
  flex: 1;
  @media screen and (max-width: 1439px) {
    width: 100%;
  }
`;

const Image = styled.img`
  width: 756px;
  height: 484px;
  border-radius: 10px;
  margin-right: 28px;

  @media screen and (max-width: 1439px) {
    width: 100%;
    height: 320px;
    margin-bottom: 24px;
  }
`;

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;

  @media screen and (max-width: 1439px) {
    width: 100%;
  }
`;

const InfoLabel = styled.div`
  width: 56px;
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  margin-right: 9px;
`;

const ShopDetail = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const { productId } = state;

  const [product, setProduct] = useState({
    _id: productId,
    pageCategory: "럭셔리",
    name: "원명",
    namedBy: "이대건",
    originCountry: "한국",
    price: 2000000,
    explain: `2019 함평 대한민국난대제전 특별상 2015년 함평대한민국 난명품대제전(4위) 도지상수상
한국난명품전국대회 우수상
한국 최고 수준의 황화로 금 년 눈부신 두각을 나타낸 명품종입니다,
원판+100%진성 극황색의 2예품으로 금년 대구시전
동양란 협회국전
함평 대통령배 메이저 대회에서 꽃이 1송이 임에도 기라성 같은 황화를 이기고 황화부문 all 석권을 한 기록적인 품종입니다.`,
  });

  const addCart = () => {
    console.log(Object.keys(product));
  };

  const buy = () => {
    navigate("/shop/order");
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <BasicContainer metaTitle="관유정-진흥원">
      <Content>
        <RowDiv style={{ marginTop: windowSize.innerWidth <= 1439 ? 24 : 66 }}>
          <Image src="/assets/tmpImg/shop01.png" />
          <InfoBox>
            <TitleLabel>{product.pageCategory}</TitleLabel>
            <TitleText>{product.name}</TitleText>
            <InfoRow style={{ marginTop: 16 }}>
              <InfoLabel>상품코드</InfoLabel>
              <Text>{productId}</Text>
            </InfoRow>
            <InfoRow>
              <InfoLabel>명명자</InfoLabel>
              <Text>{product.namedBy}</Text>
            </InfoRow>
            <InfoRow>
              <InfoLabel>원산지</InfoLabel>
              <Text>{product.originCountry}</Text>
            </InfoRow>
            <InfoRow>
              <InfoLabel>판매가</InfoLabel>
              <Text
                style={{ color: Green600, fontWeight: "700" }}
              >{`${thousandComma(product.price)}원`}</Text>
            </InfoRow>
            <InfoRow
              style={{
                marginTop: 8,
                marginBottom: 2.5,
                paddingTop: 16,
                borderTop: `1px solid ${Gray300}`,
              }}
            >
              <InfoLabel>계좌</InfoLabel>
              <Text>농협 011-5555-5555-08 예금주 이대건</Text>
            </InfoRow>
            <InfoRow>
              <InfoLabel style={{ minWidth: 56 }}></InfoLabel>
              <Text
                style={{ color: Green600, fontWeight: "700", fontSize: 16 }}
              >
                3일 이내에 입금이 이루어지지 않으면 주문이 취소됩니다
              </Text>
            </InfoRow>
            <InfoRow
              style={{ marginTop: windowSize.innerWidth <= 1439 ? 32 : "auto" }}
            >
              <ButtonA
                text="장바구니"
                viewStyle={{ width: 208, marginRight: 16 }}
                onClickEvent={addCart}
              />
              <ButtonB onClickEvent={buy} text="바로 구매하기" />
            </InfoRow>
          </InfoBox>
        </RowDiv>
        <ColumnDiv style={{ marginTop: 85, marginBottom: 68 }}>
          <Text>{product.explain}</Text>
        </ColumnDiv>
      </Content>
    </BasicContainer>
  );
};

export default ShopDetail;

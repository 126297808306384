import React from "react";
import styled from "styled-components";
import { Black, Gray500 } from "../config/color";

const Container = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

const Image = styled.img`
  width: 320px;
  height: 320px;
  border-radius: 10px;
  margin-bottom: 16px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 2px;
  color: ${Black};
`;

const Content = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: ${Gray500};
`;

const ImageListItem = (props) => {
  const { uri, title, content } = props;
  return (
    <Container>
      <Image src={uri} />
      <Title>{title}</Title>
      <Content>{content}</Content>
    </Container>
  );
};

export default ImageListItem;

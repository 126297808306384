import React, { useRef } from "react";
import styled from "styled-components";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import { Black, White } from "../config/color";
import { ButtonA } from "./Button";

const slideImages = [
  {
    url: "../assets/image/main_tmp.png",
    title: "대한민국 난초명장\n이대발 관유정",
    detail: "100만 도시농부 육성을 위하여",
    buttonText: "이대발과 관유정 자세히보기",
  },
  {
    url: "../assets/image/main_tmp.png",
    caption: "Slide 2",
  },
];

const Container = styled.div`
  width: 100%;
  min-width: 1440px;
  height: 438px;
  display: flex;

  @media screen and (max-width: 1439px) {
    min-width: 360px;
  }
`;

const SliderBox = styled.div`
  width: 100%;
  min-width: 1440px;
  height: 438px;

  @media screen and (max-width: 1439px) {
    min-width: 360px;
  }
`;

const SliderImage = styled.div`
  width: 100%;
  min-width: 1440px;
  height: 438px;

  @media screen and (max-width: 1439px) {
    min-width: 360px;
  }
`;

const SliderContent = styled.div`
  padding: 110px 124px;
`;

const Title = styled.div`
  font-size: 34px;
  font-weight: 600;
  line-height: 150%;
  color: ${White};
  white-space: pre-line;
`;

const Detail = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 180%;
  color: ${White};
`;

const MainImageSlider = (props) => {
  const properties = useRef({
    duration: 5000,
    autoplay: true,
    transitionDuration: 500,
    arrows: false,
    infinite: true,
    easing: "ease",
  });

  return (
    <Container>
      <SliderBox className="slide-container">
        <Slide {...properties.current}>
          {slideImages.map((slideImage, index) => (
            <div className="each-slide" key={index}>
              <SliderImage
                style={{ backgroundImage: `url(${slideImage.url})` }}
              >
                <SliderContent>
                  <Title>{slideImage.title}</Title>
                  <Detail>{slideImage.detail}</Detail>
                  {slideImage.buttonText ? (
                    <ButtonA
                      text={slideImage.buttonText}
                      viewStyle={{ backgroundColor: White, marginTop: 24 }}
                      textStyle={{ color: Black }}
                    />
                  ) : null}
                </SliderContent>
              </SliderImage>
            </div>
          ))}
        </Slide>
      </SliderBox>
    </Container>
  );
};

export default MainImageSlider;

import React from "react";
import styled from "styled-components";

const CommingSoonImage = require("../../assets/image/comming_soon.jpg");

const Container = styled.div`
  background-image: url(${CommingSoonImage});
  background-size: cover;
  flex: 1;
`;

const ContainerCover = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 60px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 28px;
`;

const Description = styled.div`
  font-size: 20px;
  color: #fff;
  margin-bottom: 16px;
`;

const CommingSoon = (props) => {
  return (
    <Container>
      <ContainerCover>
        <Title style={{ marginBottom: 6 }}>Dr. Lee Dae Bal</Title>
        <Title style={{ fontSize: 48 }}>COMMING SOON</Title>
        <Description>이대발 홈페이지를 새롭게 단장중입니다.</Description>
        <Description>많은 성원 부탁드립니다.</Description>
      </ContainerCover>
    </Container>
  );
};

export default CommingSoon;

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import BasicContainer from "../../../components/BasicContainer";
import Table2 from "../../../components/Table2";
import Table2Mobile from "../../../components/Table2Mobile";
import {
  Black,
  Gray100,
  Gray300,
  Green100,
  Green600,
} from "../../../config/color";
import { getWindowSize } from "../../../utils";

const RegisterImg = require("../../../assets/icon/Register44.png");

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1439px) {
    min-width: 360px;
    width: calc(100% - 40px);
    padding: 0 20px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 1094px;

  @media screen and (max-width: 1439px) {
    width: 100%;
  }
`;

const Title = styled.div`
  margin-top: 53px;
  font-family: "Nanum Myeongjo", serif;
  font-weight: 600;
  font-size: 26px;
  line-height: 36.4px;
  color: ${Black};
  padding-bottom: 24px;
  width: 100%;
`;

const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 1095px;
  margin-top: 56px;
  padding-top: 28px;
  border-top: 1px solid ${Gray300};

  @media screen and (max-width: 1439px) {
    width: 100%;
  }
`;

const FormDivTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Icon = styled.img`
  width: 44px;
  height: 44px;
`;

const FormDivTitleText = styled.div`
  font-family: "Nanum Myeongjo", serif;
  font-weight: 600;
  font-size: 26px;
  line-height: 140%;
  color: ${Black};
`;

const FullWidth = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InfoBox = styled.div`
  width: calc(100% - 48px);
  display: flex;
  padding: 24px;
  background-color: ${Gray100};
  border-radius: 10px;
  margin-top: 24px;
  flex-direction: column;
`;

const InfoLine = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  align-items: center;
`;

const InfoLabel = styled.div`
  width: 90px;
  margin-right: 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Black};
`;

const InfoValue = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Black};
`;

const AccountBox = styled.div`
  width: calc(100% - 48px);
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: ${Green100};
  margin-top: 56px;
  margin-bottom: 208px;
`;

const AccountText = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 28.8px;
  color: ${Black};
  margin-bottom: 4px;
`;

const AccountInfo = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const HeaderList = ["상품명", "총수량", "판매가", "소개", "포인트", "배송비"];

const cellWidth = [288, 171, 196, 196, 122, 122];

const OrderDetail = (props) => {
  const { state } = useLocation();

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const [dataList, setDataList] = useState([
    {
      _id: "001",
      image: "/assets/tmpImg/tmpImg1.png",
      name: "원명",
      count: 1,
      price: 2000000,
      point: 0,
      deliveryFee: "선불",
    },
    {
      _id: "002",
      image: "/assets/tmpImg/tmpImg1.png",
      name: "원명",
      count: 2,
      price: 2000000,
      point: 0,
      deliveryFee: "선불",
    },
    {
      _id: "003",
      image: "/assets/tmpImg/tmpImg1.png",
      name: "원명",
      count: 1,
      price: 2000000,
      point: 0,
      deliveryFee: "선불",
    },
  ]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <BasicContainer metaTitle="관유정-마이페이지 주문내역">
      <Content>
        <Container>
          <Title>주문 내역 상세</Title>

          {windowSize.innerWidth <= 1439 ? (
            <Table2Mobile
              viewStyle={{ marginTop: 38 }}
              headerList={HeaderList}
              cellWidth={cellWidth}
              dataList={dataList}
            />
          ) : (
            <Table2
              viewStyle={{ width: 1094 }}
              headerList={HeaderList}
              cellWidth={cellWidth}
              dataList={dataList}
            />
          )}
          <FormDiv>
            <FormDivTitle>
              <Icon src={RegisterImg} />
              <FormDivTitleText>주문 정보</FormDivTitleText>
            </FormDivTitle>
          </FormDiv>
          <InfoBox>
            <InfoLine>
              <InfoLabel>이름</InfoLabel>
              <InfoValue>홍길동</InfoValue>
            </InfoLine>
            <InfoLine>
              <InfoLabel>연락처</InfoLabel>
              <InfoValue>010-1234-1234</InfoValue>
            </InfoLine>
            <InfoLine>
              <InfoLabel>주소지</InfoLabel>
              <InfoValue>대구광역시 달구벌대로 1834</InfoValue>
            </InfoLine>
            <InfoLine>
              <InfoLabel>이메일</InfoLabel>
              <InfoValue>abcd123@naver.com</InfoValue>
            </InfoLine>
            <InfoLine style={{ marginBottom: 0 }}>
              <InfoLabel>희망배송일</InfoLabel>
              <InfoValue>2022-05-30</InfoValue>
            </InfoLine>
          </InfoBox>
          <FormDiv>
            <FormDivTitle>
              <Icon src={RegisterImg} />
              <FormDivTitleText>배송지 정보</FormDivTitleText>
            </FormDivTitle>
          </FormDiv>
          <InfoBox>
            <InfoLine>
              <InfoLabel>배송지명</InfoLabel>
              <InfoValue>우리집</InfoValue>
            </InfoLine>
            <InfoLine>
              <InfoLabel>이름</InfoLabel>
              <InfoValue>홍길동</InfoValue>
            </InfoLine>
            <InfoLine>
              <InfoLabel>연락처</InfoLabel>
              <InfoValue>010-1234-1234</InfoValue>
            </InfoLine>
            <InfoLine style={{ marginBottom: 0 }}>
              <InfoLabel>주소지</InfoLabel>
              <InfoValue>대구광역시 달구벌대로 1834</InfoValue>
            </InfoLine>
          </InfoBox>
          <FormDiv>
            <FormDivTitle>
              <Icon src={RegisterImg} />
              <FormDivTitleText>결제 정보</FormDivTitleText>
            </FormDivTitle>
          </FormDiv>
          <InfoBox>
            <InfoLine>
              <InfoLabel>결제금액</InfoLabel>
              <InfoValue>6,000,000원</InfoValue>
            </InfoLine>
            <InfoLine>
              <InfoLabel>배송비</InfoLabel>
              <InfoValue>0원</InfoValue>
            </InfoLine>
            <InfoLine>
              <InfoLabel>포인트</InfoLabel>
              <InfoValue>+0point</InfoValue>
            </InfoLine>
            <InfoLine>
              <InfoLabel>결제방법</InfoLabel>
              <InfoValue>무통장입금</InfoValue>
            </InfoLine>
            <InfoLine>
              <InfoLabel>상태</InfoLabel>
              <InfoValue>미결제</InfoValue>
            </InfoLine>
            <InfoLine style={{ marginBottom: 0 }}>
              <InfoLabel>결제일</InfoLabel>
              <InfoValue>-</InfoValue>
            </InfoLine>
          </InfoBox>
          <AccountBox>
            <AccountText>농협 011-5555-5555-08 예금주 이대건</AccountText>
            <AccountInfo>
              3일 이내에 입금이 이루어지지 않으면 주문이 취소됩니다.
            </AccountInfo>
          </AccountBox>
        </Container>
      </Content>
    </BasicContainer>
  );
};

export default OrderDetail;

import Home from "./pages/Home";
import RouteMain from "./routes";

function App() {
  return <RouteMain />;
  // return <>Hello</>;
  // return <Home />;
}

export default App;

import React from "react";
import styled from "styled-components";
import { Black, Gray100, Gray200, Gray400, Gray500 } from "../config/color";
import { thousandComma } from "../utils";

const EmptyImg = require("../assets/icon/Close29.png");

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ItemHalf = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  padding: 14px 0;
  align-items: center;
  border-top: 1px solid ${Gray200};
  width: 100%;
`;

const ItemImgBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const ItemImg = styled.img`
  width: 81px;
  height: 81px;
`;

const ItemName = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 32.4px;
  display: flex;
  align-items: center;
`;

const ItemText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Gray500};
`;

const EmptyBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${Gray100};
  border-bottom: 1px solid ${Gray200};
  height: 142px;
  width: 100%;
`;

const EmptyBoxIcon = styled.img`
  width: 29px;
  height: 29px;
`;

const EmptyBoxText = styled.div`
  margin-top: 12px;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: ${Gray400};
`;

const TotalBox = styled.div`
  background-color: ${Gray100};
  padding: 16px 26px 15px 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-size: 16px;
  font-weight: 700;
  line-height: 28.8px;
  color: ${Gray500};
  border-top: 1px solid ${Gray200};
  border-bottom: 1px solid ${Gray200};
  margin-top: 2px;
`;

const TotalResultText = styled.div`
  margin-left: 16px;
  font-size: 20px;
  font-weight: 700;
  line-height: 36px;
  color: ${Black};
`;

const TotalPriceList = styled.div`
  margin-left: 0;
  margin-right: auto;
  display: flex;
  flex-direction: column;
`;

const PriceList = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${Gray400};
`;

const PriceLabel = styled.div`
  width: 60px;
  margin-right: 24px;
  text-align: left;
`;

const Price = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${Black};
`;

const TotalPrice = styled.div`
  margin-left: auto;
  margin-right: 0;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Table2Mobile = (props) => {
  const { dataList, viewStyle } = props;

  const calcProduct = () => {
    let result = 0;
    for (let i in dataList) {
      result += dataList[i].count * dataList[i].price;
    }
    return thousandComma(result) + "원";
  };

  const calcTotal = () => {
    let result = 0;
    let selectedCount = 0;
    for (let i in dataList) {
      result += dataList[i].count * dataList[i].price;
      selectedCount++;
    }
    return thousandComma(result) + "원 / " + selectedCount + "개";
  };

  return (
    <Container style={viewStyle}>
      {dataList.length > 0 ? (
        dataList.map((item, idx) => {
          return (
            <Item key={item._id}>
              <ItemHalf style={{ alignItems: "flex-start" }}>
                <ItemName>{item.name}</ItemName>
                <ItemText>포인트 {item.point}p</ItemText>
                <ItemText>배송비 {item.deliveryFee}</ItemText>
                <ItemText
                  style={{ fontWeight: "700", fontSize: 18, lineHiehgt: 32 }}
                >
                  {thousandComma(item.price * item.count)}원
                </ItemText>
              </ItemHalf>
              <ItemHalf
                style={{
                  alignItems: "flex-end",
                  justifyContent: "flex-start",
                  height: "100%",
                }}
              >
                <ItemImg src={item.image} />
              </ItemHalf>
            </Item>
          );
        })
      ) : (
        <EmptyBox>
          <EmptyBoxIcon src={EmptyImg} />
          <EmptyBoxText>내용이 존재하지 않습니다</EmptyBoxText>
        </EmptyBox>
      )}
      <TotalBox>
        <TotalPriceList>
          <PriceList style={{ marginBottom: 12 }}>
            <PriceLabel>주문금액</PriceLabel>
            <Price>{calcProduct()}</Price>
          </PriceList>
          <PriceList style={{ marginBottom: 12 }}>
            <PriceLabel>배송비</PriceLabel>
            <Price>0원</Price>
          </PriceList>
          <PriceList>
            <PriceLabel>포인트</PriceLabel>
            <Price>0점</Price>
          </PriceList>
          <TotalPrice>
            총금액
            <TotalResultText>{calcTotal()}</TotalResultText>
          </TotalPrice>
        </TotalPriceList>
      </TotalBox>
    </Container>
  );
};

export default Table2Mobile;

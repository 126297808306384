import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Tmp from "../pages/Tmp";
import Home from "../pages/Home";
import Company from "../pages/Company";
import Shop from "../pages/Shop";
import Farm from "../pages/Farm";
import Education from "../pages/Education";
import Technical from "../pages/Technical";
import Medical from "../pages/Medical";
import Signin from "../pages/User/Signin";
import Signup from "../pages/User/Signup";
import Agreement from "../pages/User/Agreement/index.js";
import SignupComplete from "../pages/User/SignupComplete";
import Mypage from "../pages/User/Mypage";
import OrderDetail from "../pages/User/Mypage/OrderDetail";
import Greeting from "../pages/Company/Greeting/index.js";
import Master from "../pages/Company/Master";
import Precursor from "../pages/Company/Precursor";
import GYJ from "../pages/Company/GYJ";
import Agency from "../pages/Company/Agency";
import BookAndPaper from "../pages/Company/BookAndPaper";
import Board from "../pages/Company/Board";
import Writing from "../pages/Company/Board/Writing";
import BoardDetail from "../pages/Company/Board/Detail";
import History from "../pages/Company/History";
import OrchidHistory from "../pages/Company/OrchidHistory";
import Luxary from "../pages/Shop/Luxary";
import Collection from "../pages/Shop/Collection";
import Auction from "../pages/Shop/Auction";
import Beginner from "../pages/Shop/Beginner";
import Tool from "../pages/Shop/Tool";
import ShopDetail from "../pages/Shop/Detail";
import Order from "../pages/Shop/Order";
import OrderComplete from "../pages/Shop/Complete";
import SuccessRoadMap from "../pages/Farm/SuccessRoadMap/index.js";
import HalfToHalf from "../pages/Farm/SuccessRoadMap/HalfToHalf/index.js";
import CooperativeFarm from "../pages/Farm/CooperativeFarm";
import Guarantee from "../pages/Farm/Guarantee";
import Voucher from "../pages/Farm/Voucher";
import ConsignmentCare from "../pages/Farm/Consignment";
import SpeciesCenter from "../pages/Farm/SpeciesCenter";
import HealthExaminationCenter from "../pages/Medical/HealthExaminationCenter";
import RegularCheckup from "../pages/Medical/RegularCheckup";
import ScalingRepotting from "../pages/Medical/ScalingRepotting.js";
import CareProcess from "../pages/Medical/CareProcess";
import InpatientTreatment from "../pages/Medical/InpatientTreatment";
import Alumni from "../pages/Education/Alumni";
import AlumniDetail from "../pages/Education/Alumni/Detail";
import AlumniOrchidCultureAgency from "../pages/Education/Alumni/OrchidCultureAgency";
import AlumniOneToOne from "../pages/Education/Alumni/OneToOne";
import SuccessCase from "../pages/Education/SuccessCase";
import CertificateInfo from "../pages/Education/CertificateInfo";
import SecondGrade from "../pages/Education/SecondGrade";
import FirstGrade from "../pages/Education/FirstGrade";
import VoucherFranchise from "../pages/Education/VoucherFranchise";
import RecoveryFarm from "../pages/Education/RecoveryFarm";
import OutsideLecture from "../pages/Education/OutsideLecture";
import OutsideLectureDetail from "../pages/Education/OutsideLecture/Detail";
import QualityGrade from "../pages/Technical/QualityGrade";
import UpgradeGrade from "../pages/Technical/UpgradeGrade";
import AnnualCareGuideline from "../pages/Technical/AnnualCareGuideline";
import IoTSystem from "../pages/Technical/IoTSystem";
import ManagementTable from "../pages/Technical/ManagementTable";
import ControlTable from "../pages/Technical/ControlTable";
import ControlTableProfessional from "../pages/Technical/ControlTableProfessional";
import Book from "../pages/Technical/Book";
import YoutubePage from "../pages/Technical/Youtube";
import CommingSoon from "../pages/Etc/CommingSoon";

const RouteMain = () => {
  return (
    <Router basename="/">
      <Routes>
        {/* Basic */}
        <Route path="/" exact element={<CommingSoon />} />
        <Route path="/main" exact element={<Home />} />
        <Route path="/company" element={<Company />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/farm" element={<Farm />} />
        <Route path="/education" element={<Education />} />
        <Route path="/technical" element={<Technical />} />
        <Route path="/medical" element={<Medical />} />
        {/* Company */}
        <Route path="/company/greeting" element={<Greeting />} />
        <Route path="/company/master" element={<Master />} />
        <Route path="/company/precursor" element={<Precursor />} />
        <Route path="/company/gyj" element={<GYJ />} />
        <Route path="/company/agency" element={<Agency />} />
        <Route path="/company/bookandpaper" element={<BookAndPaper />} />
        <Route path="/company/board" element={<Board />} />
        <Route path="/company/board/writing" element={<Writing />} />
        <Route path="/company/board/detail" element={<BoardDetail />} />
        <Route path="/company/history" element={<History />} />
        <Route path="/company/orchidhistory" element={<OrchidHistory />} />
        {/* Shop */}
        <Route path="/shop/luxary" element={<Luxary />} />
        <Route path="/shop/collection" element={<Collection />} />
        <Route path="/shop/auction" element={<Auction />} />
        <Route path="/shop/beginner" element={<Beginner />} />
        <Route path="/shop/tool" element={<Tool />} />
        <Route path="/shop/detail" element={<ShopDetail />} />
        <Route path="/shop/order" element={<Order />} />
        <Route path="/shop/order/complete" element={<OrderComplete />} />
        {/* Farm */}
        <Route path="/farm/successroadmap" element={<SuccessRoadMap />} />
        <Route
          path="/farm/successroadmap/halftohalf"
          element={<HalfToHalf />}
        />
        <Route path="/farm/cooperativefarm" element={<CooperativeFarm />} />
        <Route path="/farm/guarantee" element={<Guarantee />} />
        <Route path="/farm/voucher" element={<Voucher />} />
        <Route path="/farm/consignmentcare" element={<ConsignmentCare />} />
        <Route path="/farm/speciescenter" element={<SpeciesCenter />} />
        {/* Education */}
        <Route path="/education/alumni" element={<Alumni />} />
        <Route
          path="/education/alumni/1"
          element={<AlumniOrchidCultureAgency />}
        />
        <Route path="/education/alumni/2" element={<AlumniOneToOne />} />
        <Route path="/education/alumni/detail" element={<AlumniDetail />} />
        <Route path="/education/successcase" element={<SuccessCase />} />
        <Route
          path="/education/certificateinfo"
          element={<CertificateInfo />}
        />
        <Route path="/education/secondgrade" element={<SecondGrade />} />
        <Route path="/education/firstgrade" element={<FirstGrade />} />
        <Route
          path="/education/voucherfranchise"
          element={<VoucherFranchise />}
        />
        <Route path="/education/recoveryfarm" element={<RecoveryFarm />} />
        <Route path="/education/outsidelecture" element={<OutsideLecture />} />
        <Route
          path="/education/outsidelecture/detail"
          element={<OutsideLectureDetail />}
        />
        {/* Technical */}
        <Route path="/technical/qualitygrade" element={<QualityGrade />} />
        <Route path="/technical/upgradegrade" element={<UpgradeGrade />} />
        <Route
          path="/technical/annualcareguideline"
          element={<AnnualCareGuideline />}
        />
        <Route path="/technical/iotsystem" element={<IoTSystem />} />
        <Route
          path="/technical/managementtable"
          element={<ManagementTable />}
        />
        <Route path="/technical/controltable" element={<ControlTable />} />
        <Route
          path="/technical/controltable/professional"
          element={<ControlTableProfessional />}
        />
        <Route path="/technical/book" element={<Book />} />
        <Route path="/technical/youtube" element={<YoutubePage />} />
        {/* Medical */}
        <Route
          path="/medical/healthexaminationcenter"
          element={<HealthExaminationCenter />}
        />
        <Route path="/medical/regularcheckup" element={<RegularCheckup />} />
        <Route
          path="/medical/scalingrepotting"
          element={<ScalingRepotting />}
        />
        <Route path="/medical/careprocess" element={<CareProcess />} />
        <Route
          path="/medical/inpatienttreatment"
          element={<InpatientTreatment />}
        />
        {/* User */}
        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/signup/complete" element={<SignupComplete />} />
        <Route path="/agreement" element={<Agreement />} />
        <Route path="/mypage" element={<Mypage />} />
        <Route path="/mypage/orderdetail" element={<OrderDetail />} />
        {/* COMPONETN TEMP */}
        <Route path="/tmp" element={<Tmp />} />
      </Routes>
    </Router>
  );
};

export default RouteMain;

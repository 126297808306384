import React from "react";
import styled from "styled-components";
import { Black, Gray200, Gray500, Green100, Green600 } from "../config/color";

const Container = styled.div`
  width: calc(320px - 2px);
  display: flex;
  flex-direction: column;
  border: 1px solid ${Gray200};
  border-radius: 10px;
  &:hover {
    cursor: pointer;
  }
`;

const InfoBox = styled.div`
  width: calc(100% - 48px);
  height: clac(182px - 44px);
  padding: 22px 24px;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 42.5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
`;

const Tag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Green100};
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  width: 40px;
  height: 26px;
  color: ${Green600};
  margin-left: 10px;
`;

const Image = styled.img`
  width: 320px;
  height: 256px;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

const ItemTitle = styled.div`
  font-family: "Nanum Myeongjo";
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: ${Black};
`;

const Content = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 25.2px;
  color: ${Gray500};
  margin-top: 4px;
  white-space: pre-wrap;
`;

const SuccessCaseListItem = (props) => {
  const { thumbnail, graduation, title, content, studentName, onClickEvent } =
    props;
  return (
    <Container onClick={onClickEvent}>
      <InfoBox>
        <ItemTitle>{title}</ItemTitle>
        <Content>{content}</Content>
        <Row>
          {studentName}
          <Tag>{graduation}기</Tag>
        </Row>
      </InfoBox>
      <Image src={thumbnail} />
    </Container>
  );
};

export default SuccessCaseListItem;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BasicContainer from "../../../components/BasicContainer";
import PageTop from "../../../components/PageTop";

import {
  Black,
  Gray100,
  Gray300,
  Gray500,
  Green600,
  White,
} from "../../../config/color";
import { getWindowSize } from "../../../utils";
import MedicalTable from "./MedicalTable";

const PhoneIcon = require("../../../assets/icon/Phone32.png");
const AccountIcon = require("../../../assets/icon/Account32.png");

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1439px) {
    min-width: 320px;
    width: 100%;
  }
`;

const RowDiv = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 1328px;
  margin: 0 auto;

  @media screen and (max-width: 1439px) {
    flex-direction: column;
    width: calc(100% - 40px);
    padding: 0 20px;
    align-items: flex-start;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 72px;
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  color: ${Black};
  white-space: pre-wrap;
  line-height: 51px;

  @media screen and (max-width: 1439px) {
    font-size: 26px;
    line-height: 36px;
  }
`;

const Image = styled.img`
  width: 464px;
  height: 362px;
  border-radius: 10px;

  @media screen and (max-width: 1439px) {
    width: 100%;
    height: auto;
    margin-bottom: 32px;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 16px 0;
  border-top: 1px solid ${Gray300};

  @media screen and (max-width: 1439px) {
    margin: 0;
  }
`;

const ListLabel = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Green600};
`;

const ListTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Black};
  margin-bottom: 16px;
`;

const ListContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  white-space: pre-wrap;
`;

const PointBox = styled.div`
  width: 100%;
  padding: 48px 0;
  margin-top: 56px;
`;

const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 56px;
  align-items: center;
`;

const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Icon = styled.img`
  width: 32px;
  height: 32px;
`;

const IconCover = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${White};
  border-radius: 28px;
  width: 56px;
  height: 56px;
  margin-right: 16px;
`;

const Text = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Gray500};
`;

const MobileTableRow = styled.div`
  @media screen and (max-width: 1439px) {
    width: 100%;
    overflow-x: auto;
  }
`;

const HealthExaminationCenter = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <BasicContainer metaTitle="관유정-건강검진센터">
      <Content>
        <PageTop
          label="의료지원"
          title="건강검진센터"
          ImageUrl="/assets/image/MedicalHeaderImg.png"
        />
        <RowDiv>
          <TitleBox
            style={{ marginTop: windowSize.innerWidth <= 1439 ? 32 : 56 }}
          >
            <TitleLabel>의료지원</TitleLabel>
            <TitleText>{`건강검진센터에서
난의 상태를 체크해보세요`}</TitleText>
          </TitleBox>
        </RowDiv>
        <RowDiv style={{ marginTop: windowSize.innerWidth <= 1439 ? 32 : 42 }}>
          <Image src="/assets/tmpImg/health01.png" />
          <List style={{ marginLeft: windowSize.innerWidth <= 1439 ? 0 : 24 }}>
            <ListLabel>01</ListLabel>
            <ListTitle>건강검진센터 설립 취지</ListTitle>
            <ListContent>
              {`제가 20여 년을 한국 춘란과 함께해 온 날들을 돌아보면 병이 왔거나 오려 할 때 그리고 문제가 생겼을 때 자문할 곳이 마땅치 않았고, 개운한 답을 주는 곳을 만나지 못해 이리저리로 헤매다 만학을 통해 공부를 했습니다. 한국 춘란을 체계적으로 전공한 병리학자나 기관이 턱없이 부족한 현실에 처해 있어 급할 때 어디 한 곳 물어볼 곳이 없을 지경입니다.저는 긴 세월 춘란 생산 농장을 해오며 쓸 만한 현미경 하나 없지만 나름대로 작은 결론에 도달하게 되었고, 그 결론에 힘입어 학사학위 논문을 병충해의 현황으로 집필하였습니다.아끼는 애장 난이 병에 걸렸을 때 그 안타까움은 누구보다 제가 잘 압니다.언젠가부터 저의 치료와 처방에 의해 몇 몇 난들이 새 생명을 찾아갔습니다.아직 우리나라는 5대 병충해에 취약합니다.그래서 저는 『이대발난클리닉』 센터를 만들게 되었습니다.내원해 치료를 받거나 소견 및 처방을 받으면 약소한 금액을 받고 있으며, 몇 몇 핵심 기술과 매뉴얼은 난아카데미에서 시행하는 한국춘란 전문가 과정을 통해 교육하고 있습니다.`}
            </ListContent>
          </List>
        </RowDiv>
        <RowDiv style={{ borderTop: `1px solid ${Gray300}`, marginTop: 56 }}>
          <List style={{ borderTop: 0, marginLeft: 0 }}>
            <ListLabel>02</ListLabel>
            <ListTitle>원장님 특진 치료 및 상담료</ListTitle>
          </List>
        </RowDiv>
        <RowDiv style={{ justifyContent: "flex-start" }}>
          <MobileTableRow>
            <MedicalTable />
          </MobileTableRow>
        </RowDiv>
        <PointBox style={{ backgroundColor: Gray100, marginBottom: 104 }}>
          <RowDiv style={{ marginTop: 0 }}>
            <List style={{ borderTop: 0, marginLeft: 0 }}>
              <ListLabel>03</ListLabel>
              <ListTitle>치료 및 상담 예약</ListTitle>
              <ListContent>
                <ContentRow>
                  <IconCover>
                    <Icon src={PhoneIcon} />
                  </IconCover>
                  <ContentColumn>
                    <Text>대한민국난문화진흥원</Text>
                    <Text style={{ color: Green600 }}>053-766-5935</Text>
                  </ContentColumn>
                </ContentRow>
                <ContentRow style={{ marginTop: 24 }}>
                  <IconCover>
                    <Icon src={AccountIcon} />
                  </IconCover>
                  <ContentColumn>
                    <Text>대구은행</Text>
                    <Text>026-08-223767 이대건</Text>
                  </ContentColumn>
                </ContentRow>
              </ListContent>
            </List>
          </RowDiv>
        </PointBox>
      </Content>
    </BasicContainer>
  );
};

export default HealthExaminationCenter;

import React, { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import BasicContainer from "../../../components/BasicContainer";
import PageTop from "../../../components/PageTop";
import {
  Black,
  Gray100,
  Gray300,
  Gray400,
  Gray500,
  Green600,
} from "../../../config/color";
import { getWindowSize } from "../../../utils";

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1439px) {
    min-width: 360px;
    width: 100%;
  }
`;

const RowDiv = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 1328px;
  margin: 0 auto;

  @media screen and (max-width: 1439px) {
    flex-direction: column;
    width: calc(100% - 40px);
    padding: 0 20px;
    align-items: flex-start;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 72px;

  @media screen and (max-width: 1439px) {
    margin-top: 32px;
  }
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  color: ${Black};
  white-space: pre-wrap;
  line-height: 51px;

  @media screen and (max-width: 1439px) {
    font-size: 26px;
    line-height: 36px;
    word-break: keep-all;
  }
`;

const Image = styled.img`
  width: 616px;
  height: 338px;
  border-radius: 10px;
  margin-right: 8px;
  object-fit: cover;

  @media screen and (max-width: 1439px) {
    width: 100%;
  }
`;

const InfoContainer = styled.div`
  flex: 1;
  margin-left: 24px;
  border-top: 1px solid ${Gray300};
  display: flex;
  flex-direction: column;
  padding-top: 16px;

  @media screen and (max-width: 1439px) {
    width: 100%;
    margin-left: 0;
    margin-top: 32px;
  }
`;

const InfoTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Black};
`;

const InfoBox = styled.div`
  background-color: ${Gray100};
  height: calc(270px - 40px);
  width: calc(100% - 48px);
  padding: 18px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: 700;
  line-height: 28.8px;
  color: ${Gray500};
  border-radius: 10px;

  @media screen and (max-width: 1439px) {
    height: auto;
  }
`;

const InfoList = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${Gray400};
`;

const InfoLabel = styled.div`
  width: 60px;
  margin-right: 24px;
  text-align: left;
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
`;

const Info = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  white-space: pre-wrap;
`;

const CooperativeFarm = (props) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <BasicContainer metaTitle="관유정-협력농가모집">
      <Content>
        <PageTop
          label="영농지원"
          title="관유정 협력 농가 모집"
          ImageUrl="/assets/image/FarmHeaderImg.png"
        />
        <RowDiv>
          <TitleBox>
            <TitleLabel>영농지원</TitleLabel>
            <TitleText>
              {windowSize.innerWidth <= 1439
                ? `관유정과 함께할
협력농가를 모집합니다`
                : `관유정과 함께할 협력농가를 모집합니다`}
            </TitleText>
          </TitleBox>
        </RowDiv>
        <RowDiv
          style={{
            marginTop: windowSize.innerWidth <= 1439 ? 32 : 56,
            marginBottom: 130,
          }}
        >
          <Image src="/assets/tmpImg/gyj01.png" />
          <InfoContainer>
            <InfoTitle>협력농가 문의</InfoTitle>
            <InfoBox style={{ marginTop: 16 }}>
              <InfoList style={{ marginBottom: 12 }}>
                <InfoLabel>업무시간</InfoLabel>
                <Info>
                  평일 09:00 ~ 21:00{" "}
                  {windowSize.innerWidth <= 1439 ? <br /> : "/"} 겨울
                  09:00~20:00 {windowSize.innerWidth <= 1439 ? <br /> : "/"}{" "}
                  배송관련문의 010-2456-6000
                </Info>
              </InfoList>
              <InfoList style={{ marginBottom: 12 }}>
                <InfoLabel>주소</InfoLabel>
                <Info>
                  대구광역시 수성구 청호로 72
                  {windowSize.innerWidth <= 1439 ? <br /> : ""} 관유정 이대발
                  원장 010-3505-5577
                </Info>
              </InfoList>
              <InfoList style={{ marginBottom: 12 }}>
                <InfoLabel>이메일</InfoLabel>
                <Info>nanacademy@hanmail.net</Info>
              </InfoList>
              <InfoList>
                <InfoLabel>연락처</InfoLabel>
                <Info>{`대표 연락처 053-766-5935
이대발 원장 010-3503-5577
배송관련문의 010-2456-6000
팩스번호 053-781-5935`}</Info>
              </InfoList>
            </InfoBox>
          </InfoContainer>
        </RowDiv>
      </Content>
    </BasicContainer>
  );
};

export default CooperativeFarm;

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import BasicContainer from "../../components/BasicContainer";
import Table2 from "../../components/Table2";
import {
  Black,
  Gray100,
  Gray300,
  Gray400,
  Gray500,
  Green100,
  Green600,
} from "../../config/color";

import {
  ADDRESS_BOX,
  TF_A_EXPAND,
  TF_B_EXPAND,
} from "../../components/TextField";
import { getWindowSize, thousandComma } from "../../utils";
import { ButtonA, ButtonB } from "../../components/Button";
import { CheckBox, CheckBoxExpand } from "../../components/Checkbox";
import { useEffect } from "react";
import Table2Mobile from "../../components/Table2Mobile";

const RegisterImg = require("../../assets/icon/Register44.png");

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1439px) {
    min-width: 360px;
    width: calc(100% - 40px);
    padding: 0 20px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 1094px;

  @media screen and (max-width: 1439px) {
    width: 100%;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 1094px;
  margin: 0 auto;
  margin-top: 56px;
  text-align: center;

  @media screen and (max-width: 1439px) {
    width: 100%;
    margin-top: 32px;
  }
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
  margin-bottom: 8px;
`;

const Title = styled.div`
  font-family: "Nanum Myeongjo", serif;
  font-weight: 600;
  font-size: 34px;
  line-height: 36.4px;
  color: ${Black};
  padding-bottom: 37px;
  width: 100%;
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 524.5px;

  @media screen and (max-width: 1439px) {
    width: 100%;
  }
`;

const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 1095px;
  margin-top: 56px;
  padding-top: 28px;
  border-top: 1px solid ${Gray300};

  @media screen and (max-width: 1439px) {
    width: 100%;
  }
`;

const FormDivTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Icon = styled.img`
  width: 44px;
  height: 44px;
`;

const FormDivTitleText = styled.div`
  font-family: "Nanum Myeongjo", serif;
  font-weight: 600;
  font-size: 26px;
  line-height: 140%;
  color: ${Black};
`;

const AccountBox = styled.div`
  width: calc(100% - 48px);
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: ${Green100};
  margin-top: 56px;
`;

const AccountText = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 28.8px;
  color: ${Black};
  margin-bottom: 4px;
`;

const AccountInfo = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TotalBox = styled.div`
  background-color: ${Gray100};
  height: 100px;
  padding: 12px 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-size: 16px;
  font-weight: 700;
  line-height: 28.8px;
  color: ${Gray500};

  @media screen and (max-width: 1439px) {
    margin-top: 2px;
    padding: 16px 26px 15px 26px;
    height: auto;
  }
`;

const TotalResultText = styled.div`
  margin-left: 16px;
  font-size: 20px;
  font-weight: 700;
  line-height: 36px;
  color: ${Black};
`;

const TotalPriceList = styled.div`
  margin-left: 0;
  margin-right: auto;
  display: flex;
  flex-direction: column;
`;

const PriceList = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${Gray400};
`;

const PriceLabel = styled.div`
  width: 60px;
  margin-right: 24px;
  text-align: left;
`;

const Price = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${Black};
`;

const TotalPrice = styled.div`
  margin-left: auto;
  margin-right: 0;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1439px) {
    align-items: center;
    margin-top: 20px;
  }
`;

const FullWidth = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 56px;
  margin-bottom: 113px;
`;

const HeaderList = ["상품명", "총수량", "판매가", "소개", "포인트", "배송비"];

const cellWidth = [288, 171, 196, 196, 122, 122];

const Order = (props) => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [windowSize, setWindowSize] = useState(getWindowSize());

  // 주문 입력
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [addressDetail, setAddressDetail] = useState("");
  const [email, setEmail] = useState("");
  const [wishDate, setWishDate] = useState("");

  // 배송지 입력
  const [typeOfShippingAddress, setTypeOfShippingAddress] = useState("");
  const [shippingName, setShippingName] = useState("");
  const [recipientName, setRecipientName] = useState("");
  const [recipientPhone, setRecipientPhone] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [shippingAddressDetail, setShippingAddressDetail] = useState("");

  // 결제 방법
  const [typeOfPayment, setTypeOfPayment] = useState("");
  const [depositorName, setDepositorName] = useState("");

  // 주문 품목
  const [dataList, setDataList] = useState([
    {
      _id: "001",
      image: "/assets/tmpImg/tmpImg1.png",
      name: "원명",
      count: 1,
      price: 2000000,
      point: 0,
      deliveryFee: "선불",
    },
    {
      _id: "002",
      image: "/assets/tmpImg/tmpImg1.png",
      name: "원명",
      count: 2,
      price: 2000000,
      point: 0,
      deliveryFee: "선불",
    },
    {
      _id: "003",
      image: "/assets/tmpImg/tmpImg1.png",
      name: "원명",
      count: 1,
      price: 2000000,
      point: 0,
      deliveryFee: "선불",
    },
  ]);

  const selectShippingSame = (param) => {
    if (param === "주문자와 동일" && typeOfShippingAddress === param) {
      return true;
    } else {
      return false;
    }
  };

  const setSelectShippingSame = (bool) => {
    if (bool) {
      setTypeOfShippingAddress("주문자와 동일");
    } else {
      setTypeOfShippingAddress("");
    }
  };

  const selectShippingNew = (param) => {
    if (param === "신규배송지" && typeOfShippingAddress === param) {
      return true;
    } else {
      return false;
    }
  };

  const setSelectShippingNew = (bool) => {
    if (bool) {
      setTypeOfShippingAddress("신규배송지");
    } else {
      setTypeOfShippingAddress("");
    }
  };

  const selectPaymentDeposit = (param) => {
    if (param === "무통장입금" && typeOfPayment === param) {
      return true;
    } else {
      return false;
    }
  };

  const setSelectPaymentDeposit = (bool) => {
    if (bool) {
      setTypeOfPayment("무통장입금");
    } else {
      setTypeOfPayment("");
    }
  };

  const calcProduct = () => {
    let result = 0;
    for (let i in dataList) {
      result += dataList[i].count * dataList[i].price;
    }
    return thousandComma(result) + "원";
  };

  const calcTotal = () => {
    let result = 0;
    let selectedCount = 0;
    for (let i in dataList) {
      result += dataList[i].count * dataList[i].price;
      selectedCount++;
    }
    return thousandComma(result) + "원 / " + selectedCount + "개";
  };

  const orderComplete = () => {
    navigate("/shop/order/complete", {
      state: { orderPrice: calcProduct(), total: calcTotal() },
    });
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <BasicContainer metaTitle="관유정-마이페이지 주문내역">
      <Content>
        <Container>
          <TitleBox>
            <TitleLabel>결제하기</TitleLabel>
            <Title>상품 결제하기</Title>
          </TitleBox>
          {windowSize.innerWidth <= 1439 ? (
            <Table2Mobile dataList={dataList} />
          ) : (
            <Table2
              viewStyle={{ width: 1094 }}
              headerList={HeaderList}
              cellWidth={cellWidth}
              dataList={dataList}
            />
          )}
          <FormDiv>
            <FormDivTitle>
              <Icon src={RegisterImg} />
              <FormDivTitleText>주문 입력</FormDivTitleText>
            </FormDivTitle>
          </FormDiv>
          <TF_B_EXPAND
            val={name}
            setVal={setName}
            required={false}
            label="이름"
            viewStyle={{
              width: windowSize.innerWidth <= 767 ? "calc(100%)" : 490,
              marginTop: 24,
            }}
            inputStyle={{
              width: windowSize.innerWidth <= 767 ? "calc(100% - 32px)" : 490,
            }}
            placeholder="이름을 입력해주세요"
            inputType="text"
          />
          <TF_B_EXPAND
            val={phone}
            setVal={setPhone}
            required={false}
            label="연락처"
            viewStyle={{
              width: windowSize.innerWidth <= 767 ? "calc(100%)" : 490,
              marginTop: 24,
            }}
            inputStyle={{
              width: windowSize.innerWidth <= 767 ? "calc(100% - 32px)" : 490,
            }}
            placeholder="연락처 입력"
            inputType="text"
          />
          <ADDRESS_BOX
            val={address}
            setVal={setAddress}
            placeholder="주소지 선택"
            inputStyle={{
              width: windowSize.innerWidth <= 767 ? "calc(100% - 30px)" : 440,
            }}
            inputTYpe="text"
            label="주소지"
            viewStyle={{
              marginTop: 24,
            }}
            inputContainerStyle={{
              backgroundColor: Gray100,
              paddingLeft: 16,
              paddingRight: 16,
              width: windowSize.innerWidth <= 767 ? "calc(100% - 32px)" : 492.5,
            }}
          />
          <TF_A_EXPAND
            val={addressDetail}
            setVal={setAddressDetail}
            placeholder="상세주소 입력"
            inputStyle={{
              width: windowSize.innerWidth <= 767 ? "calc(100%)" : 490,
            }}
            viewStyle={{
              width: windowSize.innerWidth <= 767 ? "calc(100% - 32px)" : 490,
              marginTop: 16,
            }}
          />
          <TF_B_EXPAND
            val={email}
            setVal={setEmail}
            required={false}
            label="이메일"
            viewStyle={{
              width: windowSize.innerWidth <= 767 ? "calc(100%)" : 490,
              marginTop: 24,
            }}
            inputStyle={{
              width: windowSize.innerWidth <= 767 ? "calc(100% - 32px)" : 490,
            }}
            placeholder="이메일 입력"
            inputType="text"
          />
          <TF_B_EXPAND
            val={wishDate}
            setVal={setWishDate}
            required={false}
            label="희망배송일"
            viewStyle={{
              width: windowSize.innerWidth <= 767 ? "calc(100%)" : 490,
              marginTop: 24,
            }}
            inputStyle={{
              width: windowSize.innerWidth <= 767 ? "calc(100% - 32px)" : 490,
            }}
            placeholder="예) 2022-05-20"
            inputType="text"
          />
          <FormDiv>
            <FormDivTitle>
              <Icon src={RegisterImg} />
              <FormDivTitleText>배송지 입력</FormDivTitleText>
            </FormDivTitle>
          </FormDiv>
          <InputRow style={{ marginTop: 28 }}>
            <CheckBox
              isChecked={selectShippingSame("주문자와 동일")}
              setIsChecked={setSelectShippingSame}
              text="주문자와 동일"
              viewStyle={{ width: 180 }}
            />
            <CheckBox
              isChecked={selectShippingNew("신규배송지")}
              setIsChecked={setSelectShippingNew}
              text="신규배송지"
              viewStyle={{ width: 180, marginLeft: 8 }}
            />
            {windowSize.innerWidth <= 767 ? null : (
              <ButtonA
                text="배송지목록"
                onClickEvent={() => {
                  console.log("비어있음");
                }}
                viewStyle={{ width: 114, marginLeft: 8 }}
              />
            )}
          </InputRow>
          {windowSize.innerWidth <= 767 ? (
            <ButtonA
              text="배송지목록"
              onClickEvent={() => {
                console.log("비어있음");
              }}
              viewStyle={{ width: 114, marginTop: 16 }}
            />
          ) : null}
          <TF_B_EXPAND
            val={shippingName}
            setVal={setShippingName}
            required={false}
            label="배송지명"
            viewStyle={{
              width: windowSize.innerWidth <= 767 ? "calc(100%)" : 490,
              marginTop: 24,
            }}
            inputStyle={{
              width: windowSize.innerWidth <= 767 ? "calc(100% - 32px)" : 490,
            }}
            placeholder="배송지명 입력"
            inputType="text"
          />
          <TF_B_EXPAND
            val={recipientName}
            setVal={setRecipientName}
            required={false}
            label="이름"
            viewStyle={{
              width: windowSize.innerWidth <= 767 ? "calc(100%)" : 490,
              marginTop: 24,
            }}
            inputStyle={{
              width: windowSize.innerWidth <= 767 ? "calc(100% - 32px)" : 490,
            }}
            placeholder="수령인 이름 입력"
            inputType="text"
          />
          <TF_B_EXPAND
            val={recipientPhone}
            setVal={setRecipientPhone}
            required={false}
            label="연락처"
            viewStyle={{
              width: windowSize.innerWidth <= 767 ? "calc(100%)" : 490,
              marginTop: 24,
            }}
            inputStyle={{
              width: windowSize.innerWidth <= 767 ? "calc(100% - 32px)" : 490,
            }}
            placeholder="연락처 입력"
            inputType="text"
          />
          <ADDRESS_BOX
            val={shippingAddress}
            setVal={setShippingAddress}
            placeholder="주소지 선택"
            inputStyle={{
              width: windowSize.innerWidth <= 767 ? "calc(100% - 30px)" : 440,
            }}
            inputTYpe="text"
            label="주소지"
            viewStyle={{
              marginTop: 24,
            }}
            inputContainerStyle={{
              backgroundColor: Gray100,
              paddingLeft: 16,
              paddingRight: 16,
              width: windowSize.innerWidth <= 767 ? "calc(100% - 32px)" : 492.5,
            }}
          />
          <TF_A_EXPAND
            val={shippingAddressDetail}
            setVal={setShippingAddressDetail}
            placeholder="상세주소 입력"
            inputStyle={{
              width: windowSize.innerWidth <= 767 ? "calc(100%)" : 490,
            }}
            viewStyle={{
              width: windowSize.innerWidth <= 767 ? "calc(100% - 32px)" : 490,
              marginTop: 16,
            }}
          />
          <FormDiv>
            <FormDivTitle>
              <Icon src={RegisterImg} />
              <FormDivTitleText>결제 정보</FormDivTitleText>
            </FormDivTitle>
          </FormDiv>
          <TotalBox style={{ marginTop: 20 }}>
            <TotalPriceList>
              <PriceList style={{ marginBottom: 12 }}>
                <PriceLabel>주문금액</PriceLabel>
                <Price>{calcProduct()}</Price>
              </PriceList>
              <PriceList style={{ marginBottom: 12 }}>
                <PriceLabel>배송비</PriceLabel>
                <Price>0원</Price>
              </PriceList>
              <PriceList>
                <PriceLabel>포인트</PriceLabel>
                <Price>0점</Price>
              </PriceList>
              {windowSize.innerWidth <= 1439 ? (
                <TotalPrice>
                  총금액
                  <TotalResultText>{calcTotal()}</TotalResultText>
                </TotalPrice>
              ) : null}
            </TotalPriceList>
            {windowSize.innerWidth <= 1439 ? null : (
              <TotalPrice>
                총금액
                <TotalResultText>{calcTotal()}</TotalResultText>
              </TotalPrice>
            )}
          </TotalBox>
          <FormDiv>
            <FormDivTitle>
              <Icon src={RegisterImg} />
              <FormDivTitleText>결제 방법</FormDivTitleText>
            </FormDivTitle>
          </FormDiv>
          <CheckBoxExpand
            isChecked={selectPaymentDeposit("무통장입금")}
            setIsChecked={setSelectPaymentDeposit}
            text="무통장입금"
            viewStyle={{
              marginTop: 20,
              width: windowSize.innerWidth <= 767 ? "calc(100% - 32px)" : 490,
            }}
          />
          <TF_B_EXPAND
            val={depositorName}
            setVal={setDepositorName}
            required={false}
            label="입금자명 입력"
            viewStyle={{
              width: windowSize.innerWidth <= 767 ? "calc(100%)" : 490,
              marginTop: 24,
            }}
            inputStyle={{
              width: windowSize.innerWidth <= 767 ? "calc(100% - 32px)" : 490,
            }}
            placeholder="예) 2022-05-20"
            inputType="text"
          />
          <AccountBox>
            <AccountText>농협 011-5555-5555-08 예금주 이대건</AccountText>
            <AccountInfo>
              3일 이내에 입금이 이루어지지 않으면 주문이 취소됩니다.
            </AccountInfo>
          </AccountBox>
          <FullWidth>
            <ButtonB
              onClickEvent={orderComplete}
              viewStyle={{ width: 262, height: 50 }}
              text="주문하기"
            />
          </FullWidth>
        </Container>
      </Content>
    </BasicContainer>
  );
};

export default Order;

import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Gray200, Gray500, Green100, Green600, White } from "../config/color";

const EmptyCheck = require("../assets/icon/EmptyCheck20.png");
const Check = require("../assets/icon/Check20.png");
const CheckSq = require("../assets/icon/CheckBoxSq20.png");
const EmptyCheckSq = require("../assets/icon/EmptyCheckBoxSq20.png");

const DefaultCheckBox = styled.div`
  width: 214px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  border-radius: 5px;
  border: 1px solid ${Gray200};
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  &:hover {
    cursor: pointer;
  }
`;

const ExpandCheckBox = styled.div`
  width: 300px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  &:hover {
    cursor: pointer;
  }
`;

const CheckImg = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  &:hover {
    cursor: pointer;
  }
`;

const CheckSqImg = styled.img`
  width: 20px;
  height: 20px;
  &:hover {
    cursor: pointer;
  }
`;

const Text = styled.div`
  font-size: 16px;
  flex: 1;
  display: flex;
  align-items: center;
`;

const LinkText = styled(Link)`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-left: auto;
  color: ${Gray500};
`;

export const CheckBox = (props) => {
  const { isChecked, setIsChecked, text, viewStyle, textStyle } = props;
  return (
    <DefaultCheckBox
      style={{
        borderColor: isChecked ? Green600 : Gray200,
        backgroundColor: isChecked ? Green100 : White,
        ...viewStyle,
      }}
      onClick={() => setIsChecked(!isChecked)}
    >
      <CheckImg src={isChecked ? Check : EmptyCheck} />
      <Text
        style={{
          color: isChecked ? Green600 : Gray500,
          fontWeight: isChecked ? 700 : 400,
          ...textStyle,
        }}
      >
        {text}
      </Text>
    </DefaultCheckBox>
  );
};

export const CheckBoxExpand = (props) => {
  const { isChecked, setIsChecked, text, viewStyle, textStyle } = props;
  return (
    <ExpandCheckBox
      style={{
        borderColor: isChecked ? Green600 : Gray200,
        backgroundColor: isChecked ? Green100 : White,
        ...viewStyle,
      }}
      onClick={() => setIsChecked(!isChecked)}
    >
      <CheckImg src={isChecked ? Check : EmptyCheck} />
      <Text
        style={{
          color: isChecked ? Green600 : Gray500,
          fontWeight: isChecked ? 700 : 400,
          ...textStyle,
        }}
      >
        {text}
      </Text>
    </ExpandCheckBox>
  );
};

export const CheckBoxWithLink = (props) => {
  const { isChecked, setIsChecked, text, viewStyle, textStyle, linkText } =
    props;
  return (
    <ExpandCheckBox
      style={{
        borderColor: isChecked ? Green600 : Gray200,
        backgroundColor: isChecked ? Green100 : White,
        ...viewStyle,
      }}
      onClick={() => setIsChecked(!isChecked)}
    >
      <CheckImg src={isChecked ? Check : EmptyCheck} />
      <Text
        style={{
          color: isChecked ? Green600 : Gray500,
          fontWeight: isChecked ? 700 : 400,
          ...textStyle,
        }}
      >
        {text}
      </Text>
      <LinkText>{linkText}</LinkText>
    </ExpandCheckBox>
  );
};

export const OnlyCheckBox = (props) => {
  const {
    id,
    toggleAllSelected,
    toggleSelected,
    allSelected,
    selected,
    isAllSelect = false,
    viewStyle,
  } = props;
  return (
    <CheckSqImg
      onClick={() => {
        return isAllSelect ? toggleAllSelected() : toggleSelected(id);
      }}
      src={
        isAllSelect
          ? allSelected
            ? CheckSq
            : EmptyCheckSq
          : selected[id]
          ? CheckSq
          : EmptyCheckSq
      }
      style={viewStyle}
    />
  );
};

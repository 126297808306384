import React, { useState } from "react";
import styled from "styled-components";
import { Helmet, HelmetProvider } from "react-helmet-async";

import Navigation from "../../components/Navigation";
import {
  ButtonA,
  ButtonAExpand,
  ButtonB,
  ButtonBExpand,
  ButtonC,
  ButtonCExpand,
  ButtonD,
  ButtonDExpand,
  ButtonE,
  ButtonEExpand,
  ButtonF,
  ButtonFExpand,
} from "../../components/Button";
import {
  SEARCH_BOX,
  SEARCH_BOX_EXPAND,
  TF_A,
  TF_A_EXPAND,
  TF_B,
  TF_B_EXPAND,
} from "../../components/TextField";
import { TAB } from "../../components/Tab";
import {
  DROPDOWN_A,
  DROPDOWN_A_EXPAND,
  DROPDOWN_B,
  DROPDOWN_B_EXPAND,
  DROPDOWN_MOBILE,
} from "../../components/Dropdown";
import { CheckBox, CheckBoxExpand } from "../../components/Checkbox";
import { Popup, Toast } from "../../components/Popup";
import Header from "../../components/Header";

const ogtagImage = require("../../assets/image/ogtag.png");

const Container = styled.div`
  flex: 1;
`;

const tabList = [
  { name: "Tab" },
  { name: "Tab" },
  { name: "Tab" },
  { name: "Tab" },
];

const dropdownList = ["a", "b", "c", "d", "e"];

const Tmp = () => {
  const [val, setVal] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [dropdownSelected, setDropdownSelected] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openToast, setOpenToast] = useState(false);

  const popupOpen = () => {
    setOpenPopup(true);
  };

  const popupButtonEvent = () => {
    setOpenPopup(false);
  };

  const toastOpen = () => {
    setOpenToast(true);
  };

  return (
    <Container>
      <HelmetProvider>
        <Helmet>
          <title>관유정</title>
          <meta property="og:type" content="website" />
          <meta property="og:image" content={ogtagImage} />
        </Helmet>
      </HelmetProvider>
      <Header />
      <Navigation />
      <ButtonA text="Button" onClickEvent={popupOpen} />
      <ButtonAExpand text="Button" onClickEvent={toastOpen} />
      <ButtonB text="Button" />
      <ButtonBExpand text="Button" />
      <ButtonC text="Button" />
      <ButtonCExpand text="Button" />
      <ButtonD text="Text" />
      <ButtonDExpand text="Text" />
      <ButtonE text="Text" />
      <ButtonEExpand text="Text" />
      <ButtonF text="Text" />
      <ButtonFExpand text="Text" />
      <TF_A
        placeholder="Text"
        val={val}
        setVal={setVal}
        required={true}
        errorMessage="Error message"
      />
      <TF_A_EXPAND
        placeholder="Text"
        val={val}
        setVal={setVal}
        required={true}
        errorMessage="Error message"
      />
      <TF_B
        placeholder="Text"
        val={val}
        setVal={setVal}
        required={true}
        errorMessage="Error message"
        label="Label"
      />
      <TF_B_EXPAND
        placeholder="Text"
        val={val}
        setVal={setVal}
        required={true}
        errorMessage="Error message"
        label="Label"
      />
      <SEARCH_BOX placeHolder="Text" val={val} setVal={setVal} />
      <SEARCH_BOX_EXPAND placeHolder="Text" val={val} setVal={setVal} />
      <TAB
        tabList={tabList}
        tabStyle={{
          paddingTop: 9,
          paddingBottom: 9,
          paddingLeft: 90,
          paddingRight: 90,
        }}
        activeTabIdx={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      <DROPDOWN_A
        dropdownSelected={dropdownSelected}
        setDropdownSelected={setDropdownSelected}
        list={dropdownList}
        defaultText="개인정보 수집 및 이용동의"
      />
      <DROPDOWN_A_EXPAND
        dropdownSelected={dropdownSelected}
        setDropdownSelected={setDropdownSelected}
        list={dropdownList}
        defaultText="개인정보 수집 및 이용동의"
      />
      <DROPDOWN_B
        dropdownSelected={dropdownSelected}
        setDropdownSelected={setDropdownSelected}
        list={dropdownList}
        defaultText="Text"
      />
      <DROPDOWN_B_EXPAND
        dropdownSelected={dropdownSelected}
        setDropdownSelected={setDropdownSelected}
        list={dropdownList}
        defaultText="Text"
      />
      <DROPDOWN_MOBILE
        dropdownSelected={dropdownSelected}
        setDropdownSelected={setDropdownSelected}
        list={dropdownList}
        defaultText="Text"
      />
      <CheckBox isChecked={isChecked} setIsChecked={setIsChecked} text="Text" />
      <CheckBoxExpand
        isChecked={isChecked}
        setIsChecked={setIsChecked}
        text="Text"
      />
      {openPopup ? (
        <Popup
          title="정말 삭제하시겠습니까?"
          content={`삭제된 관련 데이터는 복구가 어렵습니다.\n기기를 삭제하시겠습니까?`}
          buttonText="삭제"
          buttonEvent={popupButtonEvent}
          closeEvent={popupButtonEvent}
        />
      ) : null}
      {openToast ? (
        <Toast
          text="정보가 변경되었습니다"
          openToast={openToast}
          setOpenToast={setOpenToast}
          timer={3000}
        />
      ) : null}
    </Container>
  );
};

export default Tmp;

import React from "react";
import styled from "styled-components";
import { Black, White } from "../config/color";
import { TEXTAREA } from "./TextField";

const Container = styled.div`
  width: calc(100% - 112px);
  background-color: ${White};
  padding: 56px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1439px) {
    width: 100%;
    padding: 0;
    margin-top: 23px;
  }
`;

const CommentTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 32.4px;
  color: ${Black};
  margin-bottom: 8px;
`;

const Comment = (props) => {
  const { viewStyle, comment, setComment, inputStyle } = props;
  return (
    <Container style={viewStyle}>
      <CommentTitle>댓글</CommentTitle>
      <TEXTAREA
        val={comment}
        setVal={setComment}
        placeholder="댓글 입력"
        inputStyle={{ width: "100%", ...inputStyle }}
      />
    </Container>
  );
};

export default Comment;

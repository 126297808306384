// Color
export const Green = "#67884D";
export const Green900 = "#173302";
export const Green800 = "#2b4b12";
export const Green700 = "#496d2d";
export const Green600 = "#67884d";
export const Green500 = "#85a070";
export const Green400 = "#a4b894";
export const Green300 = "#c2cfb8";
export const Green200 = "#e0e7db";
export const Green100 = "#f0f3ed";

export const Gray600 = "#292c2e";
export const Gray500 = "#54585a";
export const Gray400 = "#949494";
export const Gray300 = "#c3c3c3";
export const Gray200 = "#dbdbdb";
export const Gray100 = "#f5f6f8";

export const Red700 = "#da291c";

export const Black = "#000";
export const White = "#fff";

export const TextColorBlack = Black;
export const TextColorGray600 = Gray600;
export const TextColorGray500 = Gray500;
export const TextColorGray400 = Gray400;
export const TextColorGray300 = Gray300;

export const BackgroundColorGray100 = Gray100;
export const BackgroundColorWhite = White;

export const BorderColorGray300 = Gray300;
export const BorderColorGray200 = Gray200;

export const PositiveGreen600 = Green600;
export const PositiveGreen100 = Green100;

export const NagativeRed600 = "#dd6540";
export const NagativeRed100 = "#fcf0ec";

export const PrimaryTextBlack = Black;
export const PrimaryTextGray600 = Gray600;
export const PrimaryTextGray500 = Gray500;

export const DisabledGray300 = Gray300;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Black, Gray500 } from "../../../config/color";
import { getWindowSize } from "../../../utils";

const Certificate = require("../../../assets/icon/Certificate64Gray.png");
const House = require("../../../assets/icon/House64Gray.png");
const Package = require("../../../assets/icon/Package64Gray.png");
const Operation = require("../../../assets/icon/Operation64Gray.png");
const Mentoring = require("../../../assets/icon/Mentoring64Gray.png");

const Container = styled.div`
  width: 561px;
  margin: 0 auto;
  margin-top: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  @media screen and (max-width: 1439px) {
    flex-direction: column;
    width: 257px;
    margin-top: 0;
  }
`;

const Box = styled.div`
  width: 104px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BoxRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 257px;
  margin-top: 32px;
`;

const BoxStep = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  text-align: center;
  color: ${Gray500};
  margin-bottom: 8px;

  @media screen and (max-width: 1439px) {
    height: 29px;
  }
`;

const Circle = styled.div`
  width: 104px;
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 52px;
  background-color: ${Gray500};
`;

const CircleImg = styled.img`
  width: 64px;
  height: 64px;
`;

const DashLine = styled.div`
  width: 16px;
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1439px) {
    width: 48px;
  }
`;

const DashLineImg = styled.div`
  border-top: 2px dashed ${Gray500};
  width: 16px;

  @media screen and (max-width: 1439px) {
    width: 48px;
  }
`;

const BoxTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  margin-top: 8px;
  letter-spacing: -1.5px;
`;

const VoucherGraph2 = (props) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <Container>
      {windowSize.innerWidth <= 1439 ? (
        <BoxRow>
          <Box>
            <BoxStep>STEP 1</BoxStep>
            <Circle>
              <CircleImg src={Certificate} />
            </Circle>
            <BoxTitle>1급 자격 취득</BoxTitle>
          </Box>
          <Box style={{ width: 135 }}>
            <BoxStep></BoxStep>
            <DashLine>
              <DashLineImg />
            </DashLine>
            <BoxTitle></BoxTitle>
          </Box>
          <Box style={{ width: 105 }}>
            <BoxStep>STEP 2</BoxStep>
            <Circle>
              <CircleImg src={House} />
            </Circle>
            <BoxTitle>난실 검증</BoxTitle>
          </Box>
        </BoxRow>
      ) : (
        <>
          <Box>
            <BoxStep>STEP 1</BoxStep>
            <Circle>
              <CircleImg src={Certificate} />
            </Circle>
            <BoxTitle>1급 자격 취득</BoxTitle>
          </Box>
          <Box style={{ width: 135 }}>
            <BoxStep></BoxStep>
            <DashLine>
              <DashLineImg />
            </DashLine>
            <BoxTitle></BoxTitle>
          </Box>
          <Box style={{ width: 105 }}>
            <BoxStep>STEP 2</BoxStep>
            <Circle>
              <CircleImg src={House} />
            </Circle>
            <BoxTitle>난실 검증</BoxTitle>
          </Box>
        </>
      )}
      {windowSize.innerWidth <= 1439 ? null : (
        <Box style={{ width: 135 }}>
          <BoxStep></BoxStep>
          <DashLine>
            <DashLineImg />
          </DashLine>
          <BoxTitle></BoxTitle>
        </Box>
      )}
      {windowSize.innerWidth <= 1439 ? (
        <BoxRow>
          <Box>
            <BoxStep>STEP 3</BoxStep>
            <Circle>
              <CircleImg src={Package} />
            </Circle>
            <BoxTitle>가맹점 패키지 이전</BoxTitle>
          </Box>
          <Box style={{ width: 135 }}>
            <BoxStep></BoxStep>
            <DashLine>
              <DashLineImg />
            </DashLine>
            <BoxTitle></BoxTitle>
          </Box>
          <Box style={{ width: 105 }}>
            <BoxStep>STEP 4</BoxStep>
            <Circle>
              <CircleImg src={Operation} />
            </Circle>
            <BoxTitle>독립자경운영</BoxTitle>
          </Box>
        </BoxRow>
      ) : (
        <>
          <Box>
            <BoxStep>STEP 3</BoxStep>
            <Circle>
              <CircleImg src={Package} />
            </Circle>
            <BoxTitle>가맹점 패키지 이전</BoxTitle>
          </Box>
          <Box style={{ width: 135 }}>
            <BoxStep></BoxStep>
            <DashLine>
              <DashLineImg />
            </DashLine>
            <BoxTitle></BoxTitle>
          </Box>
          <Box style={{ width: 105 }}>
            <BoxStep>STEP 4</BoxStep>
            <Circle>
              <CircleImg src={Operation} />
            </Circle>
            <BoxTitle>독립자경운영</BoxTitle>
          </Box>
        </>
      )}
      {windowSize.innerWidth <= 1439 ? null : (
        <Box style={{ width: 135 }}>
          <BoxStep></BoxStep>
          <DashLine>
            <DashLineImg />
          </DashLine>
          <BoxTitle></BoxTitle>
        </Box>
      )}
      <Box
        style={{
          width: 105,
          marginTop: windowSize.innerWidth <= 1439 ? 32 : 0,
        }}
      >
        <BoxStep>STEP 5</BoxStep>
        <Circle>
          <CircleImg src={Mentoring} />
        </Circle>
        <BoxTitle>1년간 멘토링</BoxTitle>
      </Box>
    </Container>
  );
};

export default VoucherGraph2;

import React from "react";
import styled from "styled-components";
import { Black, Gray100, Gray200, Gray400, Gray500 } from "../config/color";
import { thousandComma } from "../utils";

const EmptyImg = require("../assets/icon/Close29.png");

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ListHeader = styled.div`
  height: 45px;
  border-top: 1px solid ${Gray200};
  border-bottom: 1px solid ${Gray200};
  display: flex;
  flex-direction: row;
`;

const ListHeaderCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  padding-right: 0;
  height: 60px;
  align-items: center;
  border-bottom: 1px solid ${Gray200};
`;

const ItemImgBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const ItemImg = styled.img`
  width: 60px;
  height: 60px;
`;

const ItemName = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 32.4px;
  display: flex;
  align-items: center;
  height: 60px;
  margin-left: 16px;
`;

const ItemText = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  text-align: center;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px;
  color: ${Gray500};
`;

const CellBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const EmptyBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${Gray100};
  border-bottom: 1px solid ${Gray200};
  height: 142px;
  width: 100%;
`;

const EmptyBoxIcon = styled.img`
  width: 29px;
  height: 29px;
`;

const EmptyBoxText = styled.div`
  margin-top: 12px;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: ${Gray400};
`;

const TotalBox = styled.div`
  background-color: ${Gray100};
  height: 100px;
  padding: 12px 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-size: 16px;
  font-weight: 700;
  line-height: 28.8px;
  color: ${Gray500};
`;

const TotalResultText = styled.div`
  margin-left: 16px;
  font-size: 20px;
  font-weight: 700;
  line-height: 36px;
  color: ${Black};
`;

const TotalPriceList = styled.div`
  margin-left: 0;
  margin-right: auto;
  display: flex;
  flex-direction: column;
`;

const PriceList = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${Gray400};
`;

const PriceLabel = styled.div`
  width: 60px;
  margin-right: 24px;
  text-align: left;
`;

const Price = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${Black};
`;

const TotalPrice = styled.div`
  margin-left: auto;
  margin-right: 0;
  display: flex;
  flex-direction: row;
`;

const Table2 = (props) => {
  const { headerList, dataList, cellWidth, viewStyle } = props;

  const calcProduct = () => {
    let result = 0;
    for (let i in dataList) {
      result += dataList[i].count * dataList[i].price;
    }
    return thousandComma(result) + "원";
  };

  const calcTotal = () => {
    let result = 0;
    let selectedCount = 0;
    for (let i in dataList) {
      result += dataList[i].count * dataList[i].price;
      selectedCount++;
    }
    return thousandComma(result) + "원 / " + selectedCount + "개";
  };

  return (
    <Container style={viewStyle}>
      <ListHeader>
        {headerList.map((item, idx) => {
          return (
            <ListHeaderCell
              key={`table_${idx}`}
              style={{ width: cellWidth[idx] }}
            >
              {item}
            </ListHeaderCell>
          );
        })}
      </ListHeader>
      {dataList.length > 0 ? (
        dataList.map((item, idx) => {
          return (
            <Item key={item._id}>
              <ItemImgBox style={{ width: cellWidth[0] - 16 }}>
                <ItemImg src={item.image} />
                <ItemName>{item.name}</ItemName>
              </ItemImgBox>
              <CellBox style={{ width: cellWidth[1] }}>
                <ItemText>{item.count}개</ItemText>
              </CellBox>
              <CellBox style={{ width: cellWidth[2] }}>
                <ItemText>{thousandComma(item.price)}</ItemText>
              </CellBox>
              <CellBox style={{ width: cellWidth[3] }}>
                <ItemText>{thousandComma(item.price * item.count)}</ItemText>
              </CellBox>
              <CellBox style={{ width: cellWidth[4] }}>
                <ItemText>{item.point}</ItemText>
              </CellBox>
              <CellBox style={{ width: cellWidth[5] }}>
                <ItemText>{item.deliveryFee}</ItemText>
              </CellBox>
            </Item>
          );
        })
      ) : (
        <EmptyBox>
          <EmptyBoxIcon src={EmptyImg} />
          <EmptyBoxText>내용이 존재하지 않습니다</EmptyBoxText>
        </EmptyBox>
      )}
      <TotalBox>
        <TotalPriceList>
          <PriceList style={{ marginBottom: 12 }}>
            <PriceLabel>주문금액</PriceLabel>
            <Price>{calcProduct()}</Price>
          </PriceList>
          <PriceList style={{ marginBottom: 12 }}>
            <PriceLabel>배송비</PriceLabel>
            <Price>0원</Price>
          </PriceList>
          <PriceList>
            <PriceLabel>포인트</PriceLabel>
            <Price>0점</Price>
          </PriceList>
        </TotalPriceList>
        <TotalPrice>
          총금액
          <TotalResultText>{calcTotal()}</TotalResultText>
        </TotalPrice>
      </TotalBox>
    </Container>
  );
};

export default Table2;

import React from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";

const ogtagImage = require("../assets/image/ogtag.png");

const HelmetInfo = (props) => {
  const { title } = props;
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{title}</title>
          <meta property="og:type" content="website" />
          <meta property="og:image" content={ogtagImage} />
        </Helmet>
      </HelmetProvider>
    </>
  );
};

export default HelmetInfo;

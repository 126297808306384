import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BasicContainer from "../../../components/BasicContainer";
import PageTop from "../../../components/PageTop";

import {
  Black,
  Gray300,
  Gray500,
  Green100,
  Green600,
  White,
} from "../../../config/color";
import { getWindowSize } from "../../../utils";
import RegularCheckupGraph from "./Graph";

const ThirtyIcon = require("../../../assets/icon/Thirty42.png");
const ChartIcon = require("../../../assets/icon/Chart42.png");
const ValanceIcon = require("../../../assets/icon/Valance42.png");
const SpotIcon = require("../../../assets/icon/Spot42.png");
const SeedIcon = require("../../../assets/icon/Seed42.png");
const PlantIcon = require("../../../assets/icon/Plant42.png");
const GrowPlantIcon = require("../../../assets/icon/GrowPlant.png");

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1439px) {
    min-width: 320px;
    width: 100%;
  }
`;

const RowDiv = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 1328px;
  margin: 0 auto;

  @media screen and (max-width: 1439px) {
    flex-direction: column;
    width: calc(100% - 40px);
    padding: 0 20px;
    align-items: flex-start;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 72px;
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  color: ${Black};
  white-space: pre-wrap;
  line-height: 51px;

  @media screen and (max-width: 1439px) {
    font-size: 26px;
    line-height: 36px;
  }
`;

const Image = styled.img`
  width: 464px;
  height: 362px;
  border-radius: 10px;
`;

const List = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 16px 0;
  border-top: 1px solid ${Gray300};
`;

const ListLabel = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Green600};
`;

const ListTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Black};
  margin-bottom: 16px;
`;

const ListContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  white-space: pre-wrap;
`;

const PointBox = styled.div`
  width: 100%;
  padding: 48px 0;
  margin-top: 56px;
`;

const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 56px;
  align-items: center;
`;

const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Icon = styled.img`
  width: 32px;
  height: 32px;
`;

const IconCover = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${White};
  border-radius: 28px;
  width: 56px;
  height: 56px;
  margin-right: 16px;
`;

const BigIcon = styled.img`
  width: 301px;
  height: 208px;
  position: absolute;
  right: 56px;
  bottom: 76px;
`;

const Text = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Gray500};
`;

const KindListUl = styled.ul`
  padding-left: 32px;
  margin: 0;
`;

const KindListLi = styled.li`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
`;

const RegularCheckup = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <BasicContainer metaTitle="관유정-정기 건강 검진">
      <Content>
        <PageTop
          label="의료지원"
          title="정기 건강 검진"
          ImageUrl="/assets/image/MedicalHeaderImg.png"
        />
        <RowDiv>
          <TitleBox
            style={{ marginTop: windowSize.innerWidth <= 1439 ? 32 : 56 }}
          >
            <TitleLabel>의료지원</TitleLabel>
            <TitleText>{`명품 또는 소중한 난,
정기 건강 검진`}</TitleText>
          </TitleBox>
        </RowDiv>
        <RowDiv style={{ marginTop: 42 }}>
          <List>
            <ListLabel>01</ListLabel>
            <ListTitle>정기 검진의 필요성</ListTitle>
            <ListContent>
              <KindListUl>
                <KindListLi>
                  원예성이 있는 하이옵션의 품종은 내병성이 낮고 작은
                  스트레스에도 민감하게 반응해 이상반응을 일으키는 경우가 많다.
                </KindListLi>
                <KindListLi>
                  시시각각 변하는 이상기후로 인해 하이옵션의 난초들이 몸살을
                  앓는 경우가 많으므로 정기검진의 필요성이 갈수록 증가되고 있다.
                </KindListLi>
                <KindListLi>
                  병이 생긴 후 내원하면 아픈 난초가 건강하게 회복되는데 많은
                  시간이 걸린다. 하지만 정기검진으로 미리미리 점검하면 병에
                  걸리지 않고 건강한 1등급 난초로 자랄 수 있게 된다.
                </KindListLi>
              </KindListUl>
            </ListContent>
          </List>
        </RowDiv>
        <RowDiv style={{ borderTop: `1px solid ${Gray300}`, marginTop: 56 }}>
          <List
            style={{
              borderTop: 0,
              marginLeft: 0,
              width: windowSize.innerWidth <= 1439 ? "100%" : 448,
            }}
          >
            <ListLabel>02</ListLabel>
            <ListTitle>정기 검진의 종류 및 주기</ListTitle>
            <ListContent>
              <KindListUl>
                <KindListLi>
                  1차 : 신아가 표토 위로 나오기 전 약 2~3월 중순
                </KindListLi>
                <KindListLi>
                  2차 : 신아가 표토 위로 나와 30~40% 성장 시 약 4~6월
                </KindListLi>
                <KindListLi>
                  3차 : 신아가 자라서 70~80% 성장 시 약 7-8월
                </KindListLi>
                <KindListLi>
                  전년도 가을 신아가 표토 위로 나와 30~40% 성장 시에도 정기검진
                  필요
                </KindListLi>
              </KindListUl>
            </ListContent>
          </List>
          <RegularCheckupGraph />
        </RowDiv>
        <PointBox
          style={{
            backgroundColor: Green100,
            position: "relative",
          }}
        >
          <RowDiv style={{ marginTop: 0 }}>
            <List style={{ borderTop: 0, marginLeft: 0 }}>
              <ListLabel>03</ListLabel>
              <ListTitle>정기 검진 프로세스</ListTitle>
              <ListContent>
                <ContentRow>
                  <IconCover>
                    <Icon src={ThirtyIcon} />
                  </IconCover>
                  <ContentColumn>
                    <Text style={{ color: Green600 }}>Step 1</Text>
                    <Text>내원</Text>
                  </ContentColumn>
                </ContentRow>
                <ContentRow style={{ marginTop: 24 }}>
                  <IconCover>
                    <Icon src={ChartIcon} />
                  </IconCover>
                  <ContentColumn>
                    <Text style={{ color: Green600 }}>Step 2</Text>
                    <Text>잎 성장세 분석과 뿌리 염증반응 검사</Text>
                  </ContentColumn>
                </ContentRow>
                <ContentRow style={{ marginTop: 24 }}>
                  <IconCover>
                    <Icon src={ValanceIcon} />
                  </IconCover>
                  <ContentColumn>
                    <Text style={{ color: Green600 }}>Step 3</Text>
                    <Text>T/R율 밸런스 검사</Text>
                  </ContentColumn>
                </ContentRow>
                <ContentRow style={{ marginTop: 24 }}>
                  <IconCover>
                    <Icon src={SpotIcon} />
                  </IconCover>
                  <ContentColumn>
                    <Text style={{ color: Green600 }}>Step 4</Text>
                    <Text>액아, 신아 위치와 개수 및 건강 검사</Text>
                  </ContentColumn>
                </ContentRow>
                <ContentRow style={{ marginTop: 24 }}>
                  <IconCover>
                    <Icon src={SeedIcon} />
                  </IconCover>
                  <ContentColumn>
                    <Text style={{ color: Green600 }}>Step 5</Text>
                    <Text>신근 성장판 검사</Text>
                  </ContentColumn>
                </ContentRow>
                <ContentRow style={{ marginTop: 24 }}>
                  <IconCover>
                    <Icon src={PlantIcon} />
                  </IconCover>
                  <ContentColumn>
                    <Text style={{ color: Green600 }}>Step 6</Text>
                    <Text>벌브 과비만 검사</Text>
                  </ContentColumn>
                </ContentRow>
                <ContentRow style={{ marginTop: 24 }}>
                  <IconCover>
                    <Icon src={ChartIcon} />
                  </IconCover>
                  <ContentColumn>
                    <Text style={{ color: Green600 }}>Step 7</Text>
                    <Text>옷자람과 축 당치 검사</Text>
                  </ContentColumn>
                </ContentRow>
              </ListContent>
            </List>
          </RowDiv>
          <BigIcon
            src={GrowPlantIcon}
            style={windowSize.innerWidth <= 1439 ? { opacity: 0.3 } : null}
          />
        </PointBox>
        <PointBox
          style={{
            backgroundColor: Green600,
            position: "relative",
            marginTop: 0,
            paddingTop: 0,
            marginBottom: 104,
          }}
        >
          <RowDiv style={{ borderTop: 0, marginTop: 56 }}>
            <List
              style={{
                borderTop: 0,
                marginLeft: 0,
                width: windowSize.innerWidth <= 1439 ? "100%" : 448,
              }}
            >
              <ListLabel style={{ color: White }}>02</ListLabel>
              <ListTitle style={{ color: White }}>정기 검진 비용</ListTitle>
              <ListContent
                style={{
                  fontFamily: "Nanum Myeongjo",
                  fontWeight: "600",
                  fontSize: windowSize.innerWidth <= 1439 ? 24 : 26,
                  color: White,
                }}
              >
                건당 100,000원
              </ListContent>
            </List>
          </RowDiv>
        </PointBox>
      </Content>
    </BasicContainer>
  );
};

export default RegularCheckup;

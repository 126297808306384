import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Black, Green600 } from "../../../config/color";
import { getWindowSize } from "../../../utils";

const Certificate = require("../../../assets/icon/Certificate64.png");
const VoucherJoin = require("../../../assets/icon/VoucherJoin64.png");
const Contract = require("../../../assets/icon/Contract64.png");

const Container = styled.div`
  width: 586px;
  margin: 0 auto;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  @media screen and (max-width: 767px) {
    width: 100%;
    flex-direction: column;
  }
`;

const Box = styled.div`
  width: 104px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
  }
`;

const BoxStep = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  text-align: center;
  color: ${Green600};
  margin-bottom: 8px;
`;

const Circle = styled.div`
  width: 104px;
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 52px;
  background-color: ${Green600};
`;

const CircleImg = styled.img`
  width: 64px;
  height: 64px;
`;

const DashLine = styled.div`
  width: 135px;
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DashLineImg = styled.div`
  border-top: 2px dashed ${Green600};
  width: 135px;
`;

const BoxTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  margin-top: 8px;
`;

const GuaranteeGraph = (props) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Container>
      <Box>
        <BoxStep>STEP 1</BoxStep>
        <Circle>
          <CircleImg src={Certificate} />
        </Circle>
        <BoxTitle>2급 자격 취득</BoxTitle>
      </Box>
      {windowSize.innerWidth <= 767 ? null : (
        <Box style={{ width: 135 }}>
          <BoxStep></BoxStep>
          <DashLine>
            <DashLineImg />
          </DashLine>
          <BoxTitle></BoxTitle>
        </Box>
      )}
      <Box style={{ width: 105 }}>
        <BoxStep>STEP 2</BoxStep>
        <Circle>
          <CircleImg src={VoucherJoin} />
        </Circle>
        <BoxTitle>안심 바우처 가입</BoxTitle>
      </Box>
      {windowSize.innerWidth <= 767 ? null : (
        <Box style={{ width: 135 }}>
          <BoxStep></BoxStep>
          <DashLine>
            <DashLineImg />
          </DashLine>
          <BoxTitle></BoxTitle>
        </Box>
      )}
      <Box>
        <BoxStep>STEP 3</BoxStep>
        <Circle>
          <CircleImg src={Contract} />
        </Circle>
        <BoxTitle>계약서 작성</BoxTitle>
      </Box>
    </Container>
  );
};

export default GuaranteeGraph;

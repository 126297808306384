import React, { useEffect, useState } from "react";
import styled from "styled-components";

import BasicContainer from "../../../../components/BasicContainer";
import PageTop from "../../../../components/PageTop";

import { Black, Green600 } from "../../../../config/color";
import { TAB } from "../../../../components/Tab";
import { SEARCH_BOX } from "../../../../components/TextField";
import { DROPDOWN_A } from "../../../../components/Dropdown";
import { useNavigate } from "react-router-dom";
import EduListItem from "../../../../components/EduListItem";
import Pagination from "../../../../components/Pagination";

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const RowDiv = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 1328px;
  margin: 0 auto;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 72px;
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  color: ${Black};
  white-space: pre-wrap;
  line-height: 51px;
`;

const ListGrid = styled.div`
  display: grid;
  width: 1328px;
  margin: 32px 112px;
  gap: 16px;
  row-gap: 56px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 435px);
`;

const AlumniOneToOne = () => {
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(2);
  const [selectType, setSelectType] = useState("");
  const [searchText, setSearchText] = useState("");

  const [list, setList] = useState([
    {
      _id: "001",
      graduation: "1",
      thumbnail: "/assets/tmpImg/lecturetmpImg.png",
    },
    {
      _id: "002",
      graduation: "2",
      thumbnail: "/assets/tmpImg/lecturetmpImg.png",
    },
    {
      _id: "003",
      graduation: "3",
      thumbnail: "/assets/tmpImg/lecturetmpImg.png",
    },
    {
      _id: "004",
      graduation: "4",
      thumbnail: "/assets/tmpImg/lecturetmpImg.png",
    },
    {
      _id: "005",
      graduation: "5",
      thumbnail: "/assets/tmpImg/lecturetmpImg.png",
    },
    {
      _id: "006",
      graduation: "6",
      thumbnail: "/assets/tmpImg/lecturetmpImg.png",
    },
    {
      _id: "007",
      graduation: "7",
      thumbnail: "/assets/tmpImg/lecturetmpImg.png",
    },
    {
      _id: "008",
      graduation: "8",
      thumbnail: "/assets/tmpImg/lecturetmpImg.png",
    },
  ]);

  const onSearch = () => {
    console.log(searchText);
  };

  const onClickAlumni = (_id, graduation) => {
    navigate("/education/alumni/detail", { state: { _id, graduation } });
  };

  useEffect(() => {
    if (selectedTab === 0) {
      navigate("/education/alumni");
    } else if (selectedTab === 1) {
      navigate("/education/alumni/1");
    }
  }, [selectedTab]);

  return (
    <BasicContainer metaTitle="관유정-총동창회">
      <Content>
        <PageTop
          label="교육지원"
          title="총동창회"
          ImageUrl="/assets/image/EduHeaderImg.png"
        />
        <TAB
          tabList={[
            { name: "카톨릭대학교" },
            { name: "난문화진흥원" },
            { name: "1:1" },
          ]}
          viewStyle={{ marginTop: 56, marginBottom: 32 }}
          tabStyle={{
            width: 152,
            paddingTop: 0,
            paddingBottom: 9,
            paddingLeft: 26,
            paddingRight: 26,
          }}
          activeTabIdx={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <RowDiv>
          <TitleBox style={{ marginTop: 0 }}>
            <TitleLabel>교육지원</TitleLabel>
            <RowDiv>
              <TitleText>1:1</TitleText>
              <DROPDOWN_A
                list={["제목", "기수"]}
                defaultText="검색분류"
                dropdownSelected={selectType}
                setDropdownSelected={setSelectType}
                viewStyle={{ width: 133, marginLeft: "auto" }}
                optionStyle={{ width: 133 }}
              />
              <SEARCH_BOX
                viewStyle={{ marginLeft: 16 }}
                val={searchText}
                setVal={setSearchText}
                onClick={onSearch}
                placeholder="검색하기"
              />
            </RowDiv>
          </TitleBox>
        </RowDiv>
        <ListGrid>
          {list.map((item, idx) => {
            return (
              <EduListItem
                key={item._id}
                thumbnail={item.thumbnail}
                graduation={item.graduation}
                onClickEvent={() => {
                  onClickAlumni(item._id, item.graduation);
                }}
              />
            );
          })}
        </ListGrid>
        <Pagination viewStyle={{ marginTop: 56, marginBottom: 174 }} />
      </Content>
    </BasicContainer>
  );
};

export default AlumniOneToOne;

import React from "react";
import styled from "styled-components";
import {
  Gray100,
  Gray200,
  Gray500,
  Gray600,
  Green200,
  Green600,
  Green800,
  White,
} from "../config/color";

const Next20 = require("../assets/icon/Next20.png");

const DefaultButtonBox = styled.button`
  width: 320px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 0;
  &:hover {
    cursor: pointer;
  }
`;

const SmallButtonBox = styled.button`
  width: 240px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 0;
  &:hover {
    cursor: pointer;
  }
`;

const ExpandButtonBox = styled.button`
  width: 380px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 0;
  &:hover {
    cursor: pointer;
  }
`;

const AlignLeft = styled.div`
  margin-left: 16px;
  margin-right: auto;
`;

const AlignRight = styled.div`
  margin-left: auto;
  margin-right: 16px;
`;

const WhiteText = styled.div`
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  text-align: center;
  color: ${White};
`;

const GrayText = styled.div`
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  text-align: center;
  color: ${Gray500};
`;

const WhiteText400 = styled.div`
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  color: ${White};
`;

const GrayText400 = styled.div`
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  color: ${Gray500};
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
`;

const AButton = styled(DefaultButtonBox)`
  background-color: ${Gray600};
  &:active {
    background-color: ${Gray500};
  }
  &:disabled {
    background-color: ${Gray200};
  }
`;

const AButtonExpand = styled(ExpandButtonBox)`
  background-color: ${Gray600};
  &:active {
    background-color: ${Gray500};
  }
  &:disabled {
    background-color: ${Gray200};
  }
`;

const BButton = styled(DefaultButtonBox)`
  background-color: ${Green600};
  &:active {
    background-color: ${Green800};
  }
  &:disabled {
    background-color: ${Gray200};
  }
`;

const BButtonExpand = styled(ExpandButtonBox)`
  background-color: ${Green600};
  &:active {
    background-color: ${Green800};
  }
  &:disabled {
    background-color: ${Gray200};
  }
`;

const CButton = styled(DefaultButtonBox)`
  background-color: ${Gray100};
  &:active {
    background-color: ${Green200};
  }
  &:disabled {
    background-color: ${Gray200};
  }
`;

const CButtonExpand = styled(ExpandButtonBox)`
  background-color: ${Gray100};
  &:active {
    background-color: ${Green200};
  }
  &:disabled {
    background-color: ${Gray200};
  }
`;

const DButton = styled(SmallButtonBox)`
  background-color: ${Gray100};
  &:active {
    background-color: ${Green200};
  }
  &:disabled {
    background-color: ${Gray200};
  }
`;

const DButtonExpand = styled(ExpandButtonBox)`
  background-color: ${Gray100};
  &:active {
    background-color: ${Green200};
  }
  &:disabled {
    background-color: ${Gray200};
  }
`;

const EButton = styled(SmallButtonBox)`
  background-color: ${White};
  &:active {
    background-color: ${Green200};
  }
  &:disabled {
    background-color: ${Gray200};
  }
`;

const EButtonExpand = styled(ExpandButtonBox)`
  background-color: ${White};
  &:active {
    background-color: ${Green200};
  }
  &:disabled {
    background-color: ${Gray200};
  }
`;

const FButton = styled(DefaultButtonBox)`
  background-color: ${Gray100};
  &:active {
    background-color: ${Green200};
  }
  &:disabled {
    background-color: ${Gray200};
  }
`;

const FButtonExpand = styled(ExpandButtonBox)`
  background-color: ${Gray100};
  &:active {
    background-color: ${Green200};
  }
  &:disabled {
    background-color: ${Gray200};
  }
`;

export const ButtonA = (props) => {
  const { disabled, text, onClickEvent, viewStyle, textStyle } = props;
  return (
    <AButton style={viewStyle} disabled={disabled} onClick={onClickEvent}>
      <WhiteText style={textStyle}>{text}</WhiteText>
    </AButton>
  );
};

export const ButtonAExpand = (props) => {
  const { disabled, text, onClickEvent, viewStyle, textStyle } = props;
  return (
    <AButtonExpand style={viewStyle} disabled={disabled} onClick={onClickEvent}>
      <WhiteText style={textStyle}>{text}</WhiteText>
    </AButtonExpand>
  );
};

export const ButtonB = (props) => {
  const { disabled, text, onClickEvent, viewStyle, textStyle } = props;
  return (
    <BButton style={viewStyle} disabled={disabled} onClick={onClickEvent}>
      <WhiteText style={textStyle}>{text}</WhiteText>
    </BButton>
  );
};

export const ButtonBExpand = (props) => {
  const { disabled, text, onClickEvent, viewStyle, textStyle } = props;
  return (
    <BButtonExpand style={viewStyle} disabled={disabled} onClick={onClickEvent}>
      <WhiteText style={textStyle}>{text}</WhiteText>
    </BButtonExpand>
  );
};

export const ButtonC = (props) => {
  const { disabled, text, onClickEvent, viewStyle, textStyle } = props;
  return (
    <CButton style={viewStyle} disabled={disabled} onClick={onClickEvent}>
      {disabled ? (
        <WhiteText style={textStyle}>{text}</WhiteText>
      ) : (
        <GrayText>{text}</GrayText>
      )}
    </CButton>
  );
};

export const ButtonCExpand = (props) => {
  const { disabled, text, onClickEvent, viewStyle, textStyle } = props;
  return (
    <CButtonExpand style={viewStyle} disabled={disabled} onClick={onClickEvent}>
      {disabled ? (
        <WhiteText style={textStyle}>{text}</WhiteText>
      ) : (
        <GrayText>{text}</GrayText>
      )}
    </CButtonExpand>
  );
};

export const ButtonD = (props) => {
  const { disabled, text, onClickEvent, viewStyle, textStyle } = props;
  return (
    <DButton style={viewStyle} disabled={disabled} onClick={onClickEvent}>
      <AlignLeft>
        {disabled ? (
          <WhiteText style={textStyle}>{text}</WhiteText>
        ) : (
          <GrayText>{text}</GrayText>
        )}
      </AlignLeft>
      <AlignRight>
        <Icon src={Next20} />
      </AlignRight>
    </DButton>
  );
};

export const ButtonDExpand = (props) => {
  const { disabled, text, onClickEvent, viewStyle, textStyle } = props;
  return (
    <DButtonExpand style={viewStyle} disabled={disabled} onClick={onClickEvent}>
      <AlignLeft>
        {disabled ? (
          <WhiteText style={textStyle}>{text}</WhiteText>
        ) : (
          <GrayText>{text}</GrayText>
        )}
      </AlignLeft>
      <AlignRight>
        <Icon src={Next20} />
      </AlignRight>
    </DButtonExpand>
  );
};

export const ButtonE = (props) => {
  const { disabled, text, onClickEvent, viewStyle, textStyle } = props;
  return (
    <EButton style={viewStyle} disabled={disabled} onClick={onClickEvent}>
      <AlignLeft>
        {disabled ? (
          <WhiteText style={textStyle}>{text}</WhiteText>
        ) : (
          <GrayText>{text}</GrayText>
        )}
      </AlignLeft>
      <AlignRight>
        <Icon src={Next20} />
      </AlignRight>
    </EButton>
  );
};

export const ButtonEExpand = (props) => {
  const { disabled, text, onClickEvent, viewStyle, textStyle } = props;
  return (
    <EButtonExpand style={viewStyle} disabled={disabled} onClick={onClickEvent}>
      <AlignLeft>
        {disabled ? (
          <WhiteText style={textStyle}>{text}</WhiteText>
        ) : (
          <GrayText>{text}</GrayText>
        )}
      </AlignLeft>
      <AlignRight>
        <Icon src={Next20} />
      </AlignRight>
    </EButtonExpand>
  );
};

export const ButtonF = (props) => {
  const { disabled, text, onClickEvent, viewStyle, textStyle } = props;
  return (
    <FButton style={viewStyle} disabled={disabled} onClick={onClickEvent}>
      <AlignLeft>
        {disabled ? (
          <WhiteText400 style={textStyle}>Message</WhiteText400>
        ) : (
          <GrayText400 style={textStyle}>Message</GrayText400>
        )}
      </AlignLeft>
      <AlignRight>
        <GrayText>{text}</GrayText>
      </AlignRight>
    </FButton>
  );
};

export const ButtonFExpand = (props) => {
  const { disabled, text, onClickEvent, viewStyle, textStyle } = props;
  return (
    <FButtonExpand style={viewStyle} disabled={disabled} onClick={onClickEvent}>
      <AlignLeft>
        {disabled ? (
          <WhiteText400 style={textStyle}>Message</WhiteText400>
        ) : (
          <GrayText400 style={textStyle}>Message</GrayText400>
        )}
      </AlignLeft>
      <AlignRight>
        <GrayText>{text}</GrayText>
      </AlignRight>
    </FButtonExpand>
  );
};

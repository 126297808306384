import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BasicContainer from "../../../components/BasicContainer";
import PageTop from "../../../components/PageTop";

import MyInfo from "./MyInfo";
import Cart from "./Cart";
import OrderList from "./OrderList";
import { useLocation } from "react-router-dom";

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1439px) {
    min-width: 360px;
    width: 100%;
  }
`;

const Mypage = (props) => {
  const { state } = useLocation();
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (state.tab === "내 정보") {
      setSelectedTab(0);
    } else if (state.tab === "장바구니") {
      setSelectedTab(1);
    } else if (state.tab === "주문내역") {
      setSelectedTab(2);
    }
  }, []);

  return (
    <BasicContainer metaTitle="관유정-내 정보">
      <Content>
        <PageTop
          label="이대발난초"
          title="마이페이지"
          ImageUrl="/assets/image/main_tmp.png"
        />
        {selectedTab === 0 ? (
          <MyInfo selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        ) : selectedTab === 1 ? (
          <Cart selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        ) : (
          <OrderList
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        )}
      </Content>
    </BasicContainer>
  );
};

export default Mypage;

import React from "react";
import styled from "styled-components";

const Container = styled.div`
  flex: 1;
`;

const Farm = () => {
  return <Container></Container>;
};

export default Farm;

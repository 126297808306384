import React from "react";
import styled from "styled-components";
import {
  Gray200,
  Gray500,
  Green100,
  Green600,
  White,
} from "../../../config/color";

const Container = styled.div`
  width: 1328px;
  height: 100px;
  margin: 56px auto;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1439px) {
    margin-bottom: 0;
  }
`;

const Label = styled.div`
  width: 174px;
  background-color: ${Green600};
  border: 1px solid ${Gray200};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  color: ${White};
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  flex: 1;
  background-color: ${Green100};
  border: 1px solid ${Gray200};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Gray500};
`;

const CellBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`;

const Cell = styled.div`
  flex: 1;
  border: 1px solid ${Gray200};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Gray500};
`;

const SpeciesCenterTable = (props) => {
  return (
    <Container>
      <Label>관유정 바우처 제도</Label>
      <Content>
        <Title>종 보존센터 오더 돌봄 프로그램</Title>
        <CellBox>
          <Cell>농가/난초 등록</Cell>
          <Cell>계약서 작성</Cell>
          <Cell>돌봄선택</Cell>
          <Cell>작황 정보 누적 DB</Cell>
          <Cell>매년 정산</Cell>
          <Cell>1월 재시작</Cell>
        </CellBox>
      </Content>
    </Container>
  );
};

export default SpeciesCenterTable;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Black, Green600 } from "../../../config/color";
import { getWindowSize } from "../../../utils";

const Shield = require("../../../assets/icon/Shield64.png");
const Shake = require("../../../assets/icon/Shake64.png");
const Truck = require("../../../assets/icon/Truck64.png");

const Container = styled.div`
  width: 586px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  @media screen and (max-width: 767px) {
    width: 100%;
    flex-direction: column;
    margin-top: 0;
  }
`;

const Box = styled.div`
  width: 104px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BoxStep = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  text-align: center;
  color: ${Green600};
  margin-bottom: 8px;
`;

const Circle = styled.div`
  width: 104px;
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 52px;
  background-color: ${Green600};
`;

const CircleImg = styled.img`
  width: 64px;
  height: 64px;
`;

const DashLine = styled.div`
  width: 135px;
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DashLineImg = styled.div`
  border-top: 2px dashed ${Green600};
  width: 135px;
`;

const BoxTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  margin-top: 8px;
`;

const ConsignmentGraph = (props) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Container>
      <Box>
        <BoxStep>STEP 1</BoxStep>
        <Circle>
          <CircleImg src={Shield} />
        </Circle>
        <BoxTitle>안심바우처</BoxTitle>
      </Box>
      {windowSize.innerWidth <= 767 ? null : (
        <Box style={{ width: 135 }}>
          <BoxStep></BoxStep>
          <DashLine>
            <DashLineImg />
          </DashLine>
          <BoxTitle></BoxTitle>
        </Box>
      )}
      <Box
        style={{ width: 105, marginTop: windowSize.innerWidth <= 767 ? 24 : 0 }}
      >
        <BoxStep>STEP 2</BoxStep>
        <Circle>
          <CircleImg src={Shake} />
        </Circle>
        <BoxTitle>협력업체 계약</BoxTitle>
      </Box>
      {windowSize.innerWidth <= 767 ? null : (
        <Box style={{ width: 135 }}>
          <BoxStep></BoxStep>
          <DashLine>
            <DashLineImg />
          </DashLine>
          <BoxTitle></BoxTitle>
        </Box>
      )}
      <Box style={{ marginTop: windowSize.innerWidth <= 767 ? 24 : 0 }}>
        <BoxStep>STEP 3</BoxStep>
        <Circle>
          <CircleImg src={Truck} />
        </Circle>
        <BoxTitle>위탁</BoxTitle>
      </Box>
    </Container>
  );
};

export default ConsignmentGraph;

import React, { useState } from "react";
import styled from "styled-components";
import ImageListItem from "../../../components/ImageListItem";
import { Black, Green600 } from "../../../config/color";
import { toStringByFormatting } from "../../../utils";

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 112px);
  padding: 0 56px;
  margin-top: 74px;

  @media screen and (max-width: 1439px) {
    padding: 0;
    width: 100%;
    margin-top: 40px;
  }
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  color: ${Black};
  white-space: pre-wrap;

  @media screen and (max-width: 1439px) {
    font-size: 26px;
    line-height: 29px;
  }
`;

const ListGrid = styled.div`
  display: grid;
  width: 1328px;
  margin: 32px 56px;
  gap: 16px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 439px);

  @media screen and (max-width: 767px) {
    margin: 24px 0;
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 395px);
    row-gap: 28px;
  }

  @media screen and (min-width: 768px) and (max-width: 1439px) {
    margin: 24px 0;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 395px);
    row-gap: 28px;
  }
`;

const Certificate = () => {
  const [list, setList] = useState([
    {
      uri: "/assets/tmpImg/gyj04.png",
      title: "진품 식별을 위한 프라이머 (열쇄)발명",
      createdAt: new Date(),
    },
    {
      uri: "/assets/tmpImg/gyj04.png",
      title: "진품 식별을 위한 프라이머 (열쇄)발명",
      createdAt: new Date(),
    },
    {
      uri: "/assets/tmpImg/gyj04.png",
      title: "진품 식별을 위한 프라이머 (열쇄)발명",
      createdAt: new Date(),
    },
    {
      uri: "/assets/tmpImg/gyj04.png",
      title: "진품 식별을 위한 프라이머 (열쇄)발명",
      createdAt: new Date(),
    },
    {
      uri: "/assets/tmpImg/gyj04.png",
      title: "진품 식별을 위한 프라이머 (열쇄)발명",
      createdAt: new Date(),
    },
    {
      uri: "/assets/tmpImg/gyj04.png",
      title: "진품 식별을 위한 프라이머 (열쇄)발명",
      createdAt: new Date(),
    },
    {
      uri: "/assets/tmpImg/gyj04.png",
      title: "진품 식별을 위한 프라이머 (열쇄)발명",
      createdAt: new Date(),
    },
    {
      uri: "/assets/tmpImg/gyj04.png",
      title: "진품 식별을 위한 프라이머 (열쇄)발명",
      createdAt: new Date(),
    },
  ]);
  return (
    <>
      <TitleBox>
        <TitleLabel>관유정</TitleLabel>
        <TitleText>특허와 자격증</TitleText>
      </TitleBox>
      <ListGrid>
        {list.map((item, idx) => {
          return (
            <ImageListItem
              key={`list_${idx}`}
              uri={item.uri}
              title={item.title}
              content={toStringByFormatting(item.createdAt)}
            />
          );
        })}
      </ListGrid>
    </>
  );
};

export default Certificate;

import React from "react";
import styled from "styled-components";

import { Gray300, Gray400, Green, TextColorBlack } from "../config/color";

const TabBox = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${Gray300};
`;

const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:hover {
    cursor: pointer;
  }
`;

const ActiveTab = styled.div`
  position: absolute;
  bottom: 0;
  border-bottom: 5px solid ${Green};
  width: 100%;
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  color: ${Gray400};

  @media screen and (max-width: 1439px) {
    font-size: 14px;
    letter-spacing: -1px;
  }
`;

const ActiveText = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  color: ${TextColorBlack};

  @media screen and (max-width: 1439px) {
    font-size: 14px;
    letter-spacing: -1px;
  }
`;

export const TAB = (props) => {
  const { tabList, tabStyle, activeTabIdx, setSelectedTab, viewStyle } = props;
  return (
    <TabBox style={viewStyle}>
      {tabList.map((item, idx) => {
        return (
          <Tab
            style={tabStyle}
            key={`tab_${idx}`}
            onClick={() => {
              setSelectedTab(idx);
            }}
          >
            {activeTabIdx === idx ? (
              <>
                <ActiveText>{item.name}</ActiveText>
                <ActiveTab />
              </>
            ) : (
              <Text>{item.name}</Text>
            )}
          </Tab>
        );
      })}
    </TabBox>
  );
};

import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Gray100, Gray300, Gray500 } from "../config/color";

const LogoImg = require("../assets/image/header_logo.png");
const LocationIcon = require("../assets/icon/Location16.png");
const PhoneIcon = require("../assets/icon/Phone16.png");
const LetterIcon = require("../assets/icon/Letter16.png");

const Container = styled.div`
  width: 100%;
  min-width: 1440px;

  @media screen and (max-width: 1439px) {
    display: none;
  }
`;

const LittleMenu = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${Gray100};
  padding: 14px 58px;
  border-top: 1px solid ${Gray300};
`;

const LittleMenuLink = styled(Link)`
  font-weight: 400;
  font-size: 13px;
  line-height: 23px;
  color: ${Gray500};
  padding: 0 16px;
  border-right: 1px solid ${Gray300};
  text-decoration: none;
`;

const CompanyBox = styled.div`
  background-color: ${Gray100};
  padding: 21px 56px;
  border-top: 1px solid ${Gray300};
`;

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const Logo = styled.img`
  width: 106px;
  height: 60px;
  margin-right: 8px;
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: ${Gray500};
  white-space: pre-wrap;
`;

const Icon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 5px;
`;

const Footer = (props) => {
  return (
    <Container>
      <LittleMenu>
        <LittleMenuLink to="/" style={{ paddingLeft: 0 }}>
          이대발소개
        </LittleMenuLink>
        <LittleMenuLink to="/">정보이용약관</LittleMenuLink>
        <LittleMenuLink to="/">개인정보취급방침</LittleMenuLink>
        <LittleMenuLink to="/">컨텐츠저작권보호정책</LittleMenuLink>
        <LittleMenuLink to="/">이메일주소무단수집거부</LittleMenuLink>
        <LittleMenuLink to="/" style={{ paddingRight: 0, borderRight: 0 }}>
          광고/제휴문의
        </LittleMenuLink>
      </LittleMenu>
      <CompanyBox>
        <RowDiv style={{ marginBottom: 16 }}>
          <Logo src={LogoImg} />
          <ColumnDiv>
            <Text>
              이대발박사 난 연구소 | 사업자등록번호 132-81-01004 |
              통신판매업신고 제 02306호{"\n"}건강기능식품판매업신고 제 366호 |
              개인정보관리 책임 및 청소년보호 책임자 이대건
            </Text>
          </ColumnDiv>
        </RowDiv>
        <RowDiv>
          <Icon src={LocationIcon} />
          <Text>대구광역시 수성구 청호로 72 (지산동 145)</Text>
        </RowDiv>
        <RowDiv style={{ marginBottom: 3 }}>
          <Icon src={PhoneIcon} />
          <Text>
            FAX 053-781-5935 | 이대발 원장 010-3503-5577 | 고객만족센터
            053-766-5935 (평일 09:00 ~ 18:00)
          </Text>
        </RowDiv>
        <RowDiv style={{ marginBottom: 16 }}>
          <Icon src={LetterIcon} />
          <Text>nanacademy@hanmail.net</Text>
        </RowDiv>
        <Text>Copyright(c) 2015 nanacademy Co.Ltd. All Rights Reserved.</Text>
      </CompanyBox>
    </Container>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BasicContainer from "../../../components/BasicContainer";
import { TAB } from "../../../components/Tab";
import { Black, Green100, Green600 } from "../../../config/color";

import ImageListItem from "../../../components/ImageListItem";
import { getWindowSize, toStringByFormatting } from "../../../utils";
import Pagination from "../../../components/Pagination";

const Medal = require("../../../assets/icon/Medal190x266.png");

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1439px) {
    min-width: 360px;
    width: 100%;
    padding: 0;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(1440px - 112px); //calc(100% - 112px);
  padding: 0 56px;

  @media screen and (max-width: 1439px) {
    width: calc(100% - 40px);
    padding: 0 20px;
  }
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  color: ${Black};

  @media screen and (max-width: 1439px) {
    width: 100%;
    font-size: 26px;
  }
`;

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media screen and (max-width: 1439px) {
    flex-direction: column;
  }
`;

const MobileRowDiv = styled.div`
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0;
  }

  @media screen and (min-width: 768px) and (max-width: 1439px) {
    display: flex;
    flex-direction: row;
    width: 578px;
    padding: 0;
    margin: 0 auto;
  }
`;

const Introduce = styled.div`
  flex: 1;
  height: 203px;
  display: flex;
  padding: 122px 56px;
  background-color: ${Green100};
  position: relative;

  @media screen and (max-width: 1439px) {
    width: calc(100% - 40px);
    padding: 54px 20px;
    padding-bottom: 249px;
  }
`;

const IntroduceTextBox = styled.div`
  z-index: 999;

  @media screen and (max-width: 1439px) {
  }
`;

const ImageBox = styled.div`
  width: 669px;
  height: 447px;
  display: flex;

  @media screen and (max-width: 1439px) {
    width: calc(100% + 40px);
    margin-left: -40px;
    height: auto;
  }
`;

const Icon01 = styled.img`
  position: absolute;
  top: 97px;
  right: 53px;
  z-index: 99;
  width: 190px;
  height: 266px;

  @media screen and (max-width: 1439px) {
    width: 125px;
    height: 175px;
    top: auto;
    right: auto;
    bottom: 42px;
  }
`;

const Image01 = styled.img`
  width: 100%;
  height: 100%;

  @media screen and (max-width: 1439px) {
    width: 100%;
    height: auto;
  }
`;

const Image02 = styled.img`
  width: 246px;
  height: 348px;
  margin-right: 16px;
  margin-left: 56px;

  @media screen and (max-width: 1439px) {
    margin: 0;
    margin-right: 6px;
    width: calc(100% / 2 - 6px);
    height: auto;
  }
`;

const Image03 = styled.img`
  width: 261px;
  height: 348px;
  margin-right: 64px;

  @media screen and (max-width: 1439px) {
    margin: 0;
    margin-left: 6px;
    width: calc(100% / 2 - 6px);
    height: auto;
  }
`;

const TextDiv = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1439px) {
    padding: 40px 20px 250px 20px;
  }
`;

const BigText = styled.div`
  font-family: "Nanum Myeongjo", serif;
  white-space: pre-wrap;
  font-size: 30px;
  font-weight: 600;
  line-height: 42px;
  margin-bottom: 16px;

  @media screen and (max-width: 1439px) {
    font-size: 24px;
    line-height: 34px;
  }
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 28.8px;
  white-space: pre-wrap;
`;

const ListGrid = styled.div`
  display: grid;
  width: 1328px;
  gap: 16px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 439px);
  justify-content: center;
  align-content: center;

  @media screen and (max-width: 767px) {
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 395px);
    row-gap: 28px;
  }

  @media screen and (min-width: 768px) and (max-width: 1439px) {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 395px);
    row-gap: 28px;
  }
`;

const Master = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const [windowSize, setWindowSize] = useState(getWindowSize());

  // 이대건상
  const [list, setList] = useState([
    {
      uri: "/assets/tmpImg/master04.png",
      title: "함평대제전 2022년",
      createdAt: new Date(),
    },
    {
      uri: "/assets/tmpImg/master04.png",
      title: "함평대제전 2021년",
      createdAt: new Date(),
    },
    {
      uri: "/assets/tmpImg/master04.png",
      title: "함평대제전 2020년",
      createdAt: new Date(),
    },
    {
      uri: "/assets/tmpImg/master04.png",
      title: "함평대제전 2019년",
      createdAt: new Date(),
    },
    {
      uri: "/assets/tmpImg/master04.png",
      title: "함평대제전 2018년",
      createdAt: new Date(),
    },
    {
      uri: "/assets/tmpImg/master04.png",
      title: "함평대제전 2017년",
      createdAt: new Date(),
    },
  ]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <BasicContainer metaTitle="관유정-대한민국 명장">
      <Content>
        <TAB
          tabList={[{ name: "대한민국난초명장" }, { name: "명장 이대건상" }]}
          viewStyle={{ marginTop: 32, marginBottom: 42 }}
          tabStyle={{
            paddingTop: 0,
            paddingBottom: 9,
            paddingLeft: 26,
            paddingRight: 26,
          }}
          activeTabIdx={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        {selectedTab === 0 ? (
          <>
            <TitleBox>
              <TitleLabel>대한민국 명장</TitleLabel>
              <TitleText>대한민국난초명장</TitleText>
            </TitleBox>
            <RowDiv style={{ marginTop: 32, marginBottom: 81 }}>
              <Introduce>
                <Icon01
                  src={Medal}
                  style={{
                    right:
                      windowSize.innerWidth <= 1439
                        ? (windowSize.innerWidth - 125 - 20) / 2
                        : 53,
                  }}
                />
                <IntroduceTextBox>
                  <BigText>대한민국난초명장, 이대발춘란</BigText>
                  <Text>
                    {`본 홈페이지를 방문해 주셔서 감사합니다. 아울러 본 코너를 찾아 주셔서 반갑습니다.
함평군에서 개최되는`}
                    <b>{`함평 난 대제전은 우리나라에서 상훈이 가장 높은 대통령상`}</b>
                    {`입니다.
한국춘란으로 초대 대한민국농업명장에 선정되어 한국농업 내 난계의 위상 제고와
이로 인해 저변확대의 공로가 반영되어 제정된 상으로 모든 면에서 일천한 저에게 과분한 상입니다.
마지막으로 `}
                    <b>{`대한민국농업(난초)명장상을 수상하신 분들을 기리고자`}</b>
                    {` 본 코너를 만들게 되었습니다.`}
                  </Text>
                </IntroduceTextBox>
              </Introduce>
              <ImageBox>
                <Image01 src="/assets/tmpImg/master01.png" />
              </ImageBox>
            </RowDiv>
            <RowDiv>
              <MobileRowDiv>
                <Image02 src="/assets/tmpImg/master02.png" />
                <Image03 src="/assets/tmpImg/master03.png" />
              </MobileRowDiv>
              <TextDiv>
                <BigText>대한민국 명장이란?</BigText>
                <Text>
                  {windowSize.innerWidth <= 1439
                    ? `산업현장에서 최고의 숙련된 기술을 보유한 기술자로, 숙련기술의 발전과 지위 향상에 크게 공헌한 사람 중에서 「숙련기술장려법」에 따라 선정된 사람을 말한다. 명장이란 칭호는 여러 곳에서 사용하지만
대한민국명장은 대통령이 뽑는 것으로 38개 분야 92개의 직종이 있다. 
직종마다 최고 수준의 기술자가 겨루어 매년 4~10여 분이 선정되는데, 2012년 9월 저는 대한민국 한국 춘란으로 농업 직종 처음으로 선정되었고 2013년 국민스타 명장에 선정되어 대한민국 홍보대사(고용노동부)를 역임하며 한국춘란을 더 크게 세상에 알리게 되었다.`
                    : `산업현장에서 최고의 숙련된 기술을 보유한 기술자로, 숙련기술의 발전과 지위 향상에 크게 공헌한 사람
중에서 「숙련기술장려법」에 따라 선정된 사람을 말한다. 명장이란 칭호는 여러 곳에서 사용하지만
대한민국명장은 대통령이 뽑는 것으로 38개 분야 92개의 직종이 있다. 
직종마다 최고 수준의 기술자가 겨루어 매년 4~10여 분이 선정되는데,
2012년 9월 저는 대한민국 한국 춘란으로 농업 직종 처음으로 선정되었고
2013년 국민스타 명장에 선정되어 대한민국 홍보대사(고용노동부)를 역임하며
한국춘란을 더 크게 세상에 알리게 되었다.`}
                </Text>
              </TextDiv>
            </RowDiv>
          </>
        ) : (
          <>
            <TitleBox
              style={{ marginBottom: windowSize.innerWidth <= 1439 ? 32 : 80 }}
            >
              <TitleLabel>대한민국 명장</TitleLabel>
              <TitleText>명장 이대건상</TitleText>
              <Text style={{ marginTop: 16, lineHeight: "29px" }}>
                <b>{`대한민국 농업명장 이대건상`}</b>
                {`은 함평군에서 개최되는 함평 난 대제전은 우리나라에서 상훈이 가장 높은 대통령상입니다.
한국춘란으로 초대 대한민국농업명장에 선정되어 한국농업 내 난계의 위상 제고와 이로 인해 저변확대의 공로가 반영되어 제정된 상입니다.`}
              </Text>
            </TitleBox>
            <ListGrid>
              {list.map((item, idx) => {
                return (
                  <ImageListItem
                    key={`list_${idx}`}
                    uri={item.uri}
                    title={item.title}
                    content={toStringByFormatting(item.createdAt)}
                  />
                );
              })}
            </ListGrid>
            {list.length > 0 ? (
              <Pagination viewStyle={{ marginBottom: 42 }} />
            ) : null}
          </>
        )}
      </Content>
    </BasicContainer>
  );
};

export default Master;

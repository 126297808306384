import React, { useState } from "react";
import styled from "styled-components";
import BasicContainer from "../../../components/BasicContainer";
import PageTop from "../../../components/PageTop";
import {
  Black,
  Gray200,
  Gray300,
  Gray500,
  Green100,
  Green300,
  Green600,
} from "../../../config/color";

import { useNavigate } from "react-router-dom";

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const RowDiv = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  width: 1328px;
  margin: 0 auto;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 72px;
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  color: ${Black};
  white-space: pre-wrap;
  line-height: 51px;
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 28.8px;
  white-space: pre-wrap;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 1328px;
  margin: 0 auto;
  margin-top: 40px;
  padding: 16px 0;
  border-top: 1px solid ${Gray300};
`;

const ListLabel = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Green600};
`;

const ListTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Black};
  margin-bottom: 16px;
`;

const ListContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  white-space: pre-wrap;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 1328px;
  margin: 0 auto;
`;

const TableText = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Gray500};
`;

const TableCell = styled(TableText)`
  height: calc(50px - 2px);
  border: 1px solid ${Gray200};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const TableColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableLabel = styled(TableCell)`
  width: calc(265px - 2px);
  background-color: ${Green100};
`;

const TableRowCategory = styled(TableText)`
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: ${Green300};
  width: calc(100% - 2px);
  height: calc(50px - 2px);
  border: 1px solid ${Gray200};
`;

const IoTSystem = (props) => {
  const navigate = useNavigate();

  return (
    <BasicContainer metaTitle="관유정-IoT 시스템">
      <Content>
        <PageTop
          label="기술지원"
          title="IoT 시스템"
          ImageUrl="/assets/image/TechnicalHeaderImg.png"
        />
        <RowDiv>
          <TitleBox>
            <TitleLabel>영농지원</TitleLabel>
            <TitleText>IoT 시스템</TitleText>
          </TitleBox>
        </RowDiv>
      </Content>
    </BasicContainer>
  );
};

export default IoTSystem;

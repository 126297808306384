import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { OnlyCheckBox } from "../../../components/Checkbox";
import {
  Gray200,
  Gray500,
  Green100,
  Green600,
  White,
} from "../../../config/color";
import { thousandComma } from "../../../utils";

const MinusIcon = require("../../../assets/icon/Minus26.png");
const PlusIcon = require("../../../assets/icon/Plus26.png");

const Table = styled.div`
  width: 1328px
  margin: 0 auto;
  margin-bottom: 112px;
  position: relative;
  min-width: 1328px;

  @media screen and (max-width: 1439px) {
    margin: 0;
    margin-bottom: 0;
  }
`;

const TableHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TableRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const TableColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableCell = styled.div`
  border: 1px solid ${Gray200};
  height: calc(50px - 2px);
  display: flex;
`;

const TableHeaderCell = styled(TableCell)`
  font-weight: 700;
  font-size: 16px;
  color: ${Gray500};
  background-color: ${White};
  align-items: center;
  justify-content: center;
  border: 0;
`;

const TableLabel = styled.div`
  border: 1px solid ${Gray200};
  background-color: ${Green600};
  width: calc(200px - 2px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${White};
`;

const TableContentCell = styled(TableCell)`
  font-weight: 700;
  font-size: 16px;
  color: ${Gray500};
  background-color: ${White};
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  border: 1px solid ${Gray200};
  height: calc(50px - 2px);
`;

const Icon = styled.img`
  width: 26px;
  height: 26px;
  &:hover {
    cursor: pointer;
  }
`;

const ToolTable = (props) => {
  const {
    list,
    selectedItem,
    setSelectedItem,
    selectedCnt,
    setSelectedCnt,
    typeofdata,
  } = props;

  const [category, setCategory] = useState({});

  const toggleSelected = (id) => {
    setSelectedItem({ ...selectedItem, [id]: !selectedItem[id] });

    if (selectedItem[id]) {
      setSelectedCnt({ ...selectedCnt, [id]: 0 });
    } else {
      setSelectedCnt({ ...selectedCnt, [id]: 1 });
    }
  };

  const addCnt = (id) => {
    if (id in selectedCnt) {
      setSelectedCnt({ ...selectedCnt, [id]: selectedCnt[id] + 1 });
    } else {
      setSelectedCnt({ ...selectedCnt, [id]: 1 });
    }
  };

  const removeCnt = (id) => {
    if (id in selectedCnt) {
      if (selectedCnt[id] > 0) {
        setSelectedCnt({ ...selectedCnt, [id]: selectedCnt[id] - 1 });
      }
    }
  };

  useEffect(() => {
    if (list.length > 0) {
      let categoryObj = {};
      for (let i = 0; i < list.length; i++) {
        const category_ = list[i].category;
        if (category_ in categoryObj) {
          categoryObj[category_]++;
        } else {
          categoryObj[category_] = 1;
        }
      }
      setCategory(categoryObj);
    }
  }, [list]);

  return (
    <Table>
      <TableHeader>
        <TableHeaderCell style={{ width: 200 }}>카테고리</TableHeaderCell>
        <TableHeaderCell style={{ width: 255 }}>항목</TableHeaderCell>
        <TableHeaderCell style={{ width: 202 }}>
          {typeofdata !== "도서" ? "용량" : "구매 대상"}
        </TableHeaderCell>
        <TableHeaderCell style={{ width: 200 }}>수량</TableHeaderCell>
        <TableHeaderCell style={{ width: 122 }}>재고량</TableHeaderCell>
        <TableHeaderCell style={{ width: 160 }}>단가</TableHeaderCell>
        <TableHeaderCell style={{ width: 190 }}>총액</TableHeaderCell>
      </TableHeader>
      {Object.keys(category).map((label, idx) => {
        return (
          <TableColumn key={`${typeofdata}_${idx}`}>
            <TableRow style={{ height: 50 * category[label] }}>
              <TableLabel
                key={`label_${idx}`}
                style={{ height: "calc(100% - 2px)" }}
              >
                {label}
              </TableLabel>
              <TableColumn style={{ height: "100%", flex: 1 }}>
                {list.map((item) => {
                  return label === item.category ? (
                    <TableRow key={`tool_${item._id}`} style={{ flex: 1 }}>
                      <TableContentCell
                        style={{
                          width: 255 - 32 - 2,
                          backgroundColor: selectedItem[item._id]
                            ? Green100
                            : White,
                        }}
                      >
                        <OnlyCheckBox
                          id={item._id}
                          selected={selectedItem}
                          toggleSelected={toggleSelected}
                          viewStyle={{ marginRight: 8 }}
                        />
                        {item.name}
                      </TableContentCell>
                      <TableContentCell
                        style={{
                          width: 202 - 32 - 2,
                          backgroundColor: selectedItem[item._id]
                            ? Green100
                            : White,
                        }}
                      >
                        {item.explain}
                      </TableContentCell>
                      <TableContentCell
                        style={{
                          width: 200 - 32 - 2,
                          justifyContent: "center",
                          backgroundColor: selectedItem[item._id]
                            ? Green100
                            : White,
                        }}
                      >
                        <Icon
                          src={MinusIcon}
                          style={{ marginRight: "auto" }}
                          onClick={() => {
                            removeCnt(item._id);
                          }}
                        />
                        {`${
                          item._id in selectedCnt ? selectedCnt[item._id] : 0
                        }개`}
                        <Icon
                          src={PlusIcon}
                          style={{ marginLeft: "auto" }}
                          onClick={() => {
                            addCnt(item._id);
                          }}
                        />
                      </TableContentCell>
                      <TableContentCell
                        style={{
                          width: 122 - 32 - 2,
                          justifyContent: "center",
                          backgroundColor: selectedItem[item._id]
                            ? Green100
                            : White,
                        }}
                      >
                        {item.quantity}
                      </TableContentCell>
                      <TableContentCell
                        style={{
                          width: 160 - 32 - 2,
                          justifyContent: "center",
                          backgroundColor: selectedItem[item._id]
                            ? Green100
                            : White,
                        }}
                      >
                        {`${thousandComma(item.price)}원`}
                      </TableContentCell>
                      <TableContentCell
                        style={{
                          width: 190 - 32 - 2,
                          justifyContent: "center",
                          backgroundColor: selectedItem[item._id]
                            ? Green100
                            : White,
                        }}
                      >
                        {thousandComma(
                          item._id in selectedCnt
                            ? selectedCnt[item._id] === 0
                              ? "-"
                              : selectedCnt[item._id] * item.price + "원"
                            : "-"
                        )}
                      </TableContentCell>
                    </TableRow>
                  ) : null;
                })}
              </TableColumn>
            </TableRow>
          </TableColumn>
        );
      })}
      {/* <TableColumn>
          {Object.keys(category).map((label, idx) => {
            return (
              <TableColumn>
                {list.map((item) => {
                  return label === item.category ? (
                    <TableRow
                      key={`tool_${item._id}`}
                      style={{ height: 50 - 2 }}
                    >
                      <TableContentCell style={{ width: 255 - 2 }}>
                        <OnlyCheckBox
                          id={item._id}
                          selected={selectedItem}
                          toggleSelected={toggleSelected}
                          viewStyle={{ marginRight: 8 }}
                        />
                        {item.name}
                      </TableContentCell>
                    </TableRow>
                  ) : null;
                })}
              </TableColumn>
            );
          })}
        </TableColumn> */}
    </Table>
  );
};

export default ToolTable;

import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Gray400, Green600, White } from "../config/color";

const Container = styled.div`
  min-height: 463px;
  min-width: 100%;
  background-color: ${White};
  padding-top: 23px;
  z-index: 9999;
`;

const InnerContainer = styled.div`
  width: 1176px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
`;

const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 48px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 25px;
  color: ${Green600};
  margin-bottom: 16px;
`;

const MenuLink = styled(Link)`
  text-decoration: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 25px;
  color: ${Gray400};
  margin-bottom: 16px;
`;

const NavMenu = (props) => {
  const { viewStyle, hideMenu } = props;
  return (
    <Container style={viewStyle} onMouseLeave={hideMenu}>
      <InnerContainer>
        <ColumnBox>
          <Title>이대발과 관유정</Title>
          <MenuLink to="/company/greeting">인사말</MenuLink>
          <MenuLink to="/company/master">대한민국명장</MenuLink>
          <MenuLink to="/company/precursor">선구자 이대발</MenuLink>
          <MenuLink to="/company/gyj">관유정</MenuLink>
          <MenuLink to="/company/agency">진흥원</MenuLink>
          <MenuLink to="/company/bookandpaper">저서와 논문</MenuLink>
          <MenuLink to="/company/board">게시판</MenuLink>
          <MenuLink to="/company/history">연혁</MenuLink>
          <MenuLink to="/company/orchidhistory">이력제</MenuLink>
        </ColumnBox>
        <ColumnBox>
          <Title>종자지원</Title>
          <MenuLink to="/shop/luxary">럭셔리</MenuLink>
          <MenuLink to="/shop/collection">컬렉션</MenuLink>
          <MenuLink to="/shop/auction">옥션</MenuLink>
          <MenuLink to="/shop/beginner">입문코너</MenuLink>
          <MenuLink to="/shop/tool">자재 및 도구</MenuLink>
        </ColumnBox>
        <ColumnBox>
          <Title>영농지원</Title>
          <MenuLink to="/farm/successroadmap">성공로드맵</MenuLink>
          <MenuLink to="/farm/cooperativefarm">관유정 협력농가 모집</MenuLink>
          <MenuLink to="/farm/guarantee">원금보장 OEM</MenuLink>
          <MenuLink to="/farm/voucher">안심바우처</MenuLink>
          <MenuLink to="/farm/consignmentcare">위탁오더 대리돌봄</MenuLink>
          <MenuLink to="/farm/speciescenter">종 보존센터</MenuLink>
        </ColumnBox>
        <ColumnBox>
          <Title>교육지원</Title>
          <MenuLink to="/education/alumni">총동창회</MenuLink>
          <MenuLink to="/education/successcase">교육생 사례</MenuLink>
          <MenuLink to="/education/certificateinfo">자격증 과정 개요</MenuLink>
          <MenuLink to="/education/secondgrade">2급 자격 과정</MenuLink>
          <MenuLink to="/education/firstgrade">1급 자격 과정</MenuLink>
          <MenuLink to="/education/voucherfranchise">
            바우처 시스템 프랜차이즈
          </MenuLink>
          <MenuLink to="/education/recoveryfarm">개인 회생농</MenuLink>
          <MenuLink to="/education/outsidelecture">외부 강의</MenuLink>
        </ColumnBox>
        <ColumnBox>
          <Title>기술지원</Title>
          <MenuLink to="/technical/qualitygrade">품질 등급 기준</MenuLink>
          <MenuLink to="/technical/upgradegrade">한 등급 높이는 기술</MenuLink>
          <MenuLink to="/technical/annualcareguideline">
            연간 관리 지침
          </MenuLink>
          <MenuLink to="/technical/iotsystem">IoT 시스템</MenuLink>
          <MenuLink to="/technical/managementtable">관행 관리표 7집</MenuLink>
          <MenuLink to="/technical/controltable">관행 방제표 7집</MenuLink>
          <MenuLink to="/technical/controltable/professional">
            관행 방제표 전문가용
          </MenuLink>
          <MenuLink to="/technical/book">책 소개 가이드북</MenuLink>
          <MenuLink to="/technical/youtube">유튜브 리스트</MenuLink>
        </ColumnBox>
        <ColumnBox>
          <Title>의료지원</Title>
          <MenuLink to="/medical/healthexaminationcenter">
            건강검진센터
          </MenuLink>
          <MenuLink to="/medical/regularcheckup">정기건강검진</MenuLink>
          <MenuLink to="/medical/scalingrepotting">
            스케일링 및 분갈이 검진
          </MenuLink>
          <MenuLink to="/medical/careprocess">진료 프로세스</MenuLink>
          <MenuLink to="/medical/inpatienttreatment">입원 치료</MenuLink>
          {/* <MenuLink to="/medical/">DNA 검사</MenuLink> */}
        </ColumnBox>
      </InnerContainer>
    </Container>
  );
};

export default NavMenu;

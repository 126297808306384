import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ButtonB, ButtonC } from "../../../components/Button";
import { TAB } from "../../../components/Tab";
import Table from "../../../components/Table";
import TableMobile from "../../../components/TableMobile";
import { Black, Green600 } from "../../../config/color";
import { getWindowSize } from "../../../utils";

const Form = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 1180px;

  @media screen and (max-width: 1439px) {
    width: 100%;
  }
`;

const FullWidth = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PageTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const Title = styled.div`
  font-family: "Nanum Myeongjo", serif;
  font-weight: 600;
  font-size: 34px;
  line-height: 51px;
  color: ${Black};
  margin-top: 8px;

  @media screen and (max-width: 1439px) {
    font-size: 26px;
    line-height: 36px;
  }
`;

const ButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  width: 100%;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    width: calc(100% - 40px);
    padding: 0 20px;
  }

  @media screen and (min-width: 768px) and (max-width: 1439px) {
    width: calc(100% - 40px);
    padding: 0 20px;
  }
`;

const ButtonBoxLeft = styled.div`
  margin-right: auto;
  margin-left: 0;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

const ButtonBoxRight = styled.div`
  margin-right: 0;
  margin-left: auto;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

const HeaderList = [
  "상품명",
  "총수량",
  "판매가",
  "소개",
  "포인트",
  "배송비",
  "checkbox",
];

const cellWidth = [288, 171, 196, 196, 122, 122, 85];

const Cart = (props) => {
  const { selectedTab, setSelectedTab } = props;

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const [allSelected, setAllSelected] = useState(false);
  const [selected, setSelected] = useState({});

  const [dataList, setDataList] = useState([
    {
      _id: "001",
      image: "/assets/tmpImg/tmpImg1.png",
      name: "원명",
      count: 1,
      price: 2000000,
      point: 0,
      deliveryFee: "선불",
    },
    {
      _id: "002",
      image: "/assets/tmpImg/tmpImg1.png",
      name: "원명",
      count: 2,
      price: 2000000,
      point: 0,
      deliveryFee: "선불",
    },
    {
      _id: "003",
      image: "/assets/tmpImg/tmpImg1.png",
      name: "원명",
      count: 1,
      price: 2000000,
      point: 0,
      deliveryFee: "선불",
    },
  ]);

  const toggleAllSelected = () => {
    let newSelected = {};
    for (let i in dataList) {
      newSelected[dataList[i]._id] = !allSelected;
    }
    setSelected(newSelected);
    setAllSelected(!allSelected);
  };

  const toggleSelected = (id) => {
    setSelected({ ...selected, [id]: !selected[id] });
    setAllSelected(false);
  };

  const minusCount = (id) => {
    for (let i in dataList) {
      if (dataList[i]._id === id) {
        const tmpDataList = [...dataList];
        tmpDataList[i].count -= 1;
        setDataList(tmpDataList);
        break;
      }
    }
  };

  const addCount = (id) => {
    for (let i in dataList) {
      if (dataList[i]._id === id) {
        const tmpDataList = [...dataList];
        tmpDataList[i].count += 1;
        setDataList(tmpDataList);
        break;
      }
    }
  };

  const removeAll = () => {
    setDataList([]);
    setSelected({});
    setAllSelected(false);
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Form>
      <FullWidth>
        <TAB
          tabList={[
            { name: "내 정보" },
            { name: "장바구니" },
            { name: "주문 내역" },
          ]}
          viewStyle={{
            marginTop: windowSize.innerWidth <= 1439 ? 28 : 32,
            marginBottom: windowSize.innerWidth <= 1439 ? 32 : 42,
          }}
          tabStyle={{
            width:
              windowSize.innerWidth <= 1439
                ? windowSize.innerWidth / 3 - 18
                : "auto",
            paddingTop: 0,
            paddingBottom: 9,
            paddingLeft: windowSize.innerWidth <= 1439 ? 8 : 90,
            paddingRight: windowSize.innerWidth <= 1439 ? 8 : 90,
          }}
          activeTabIdx={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <PageTitle>마이페이지</PageTitle>
        <Title>장바구니</Title>

        {windowSize.innerWidth <= 1439 ? (
          <TableMobile
            viewStyle={{ marginTop: 38 }}
            headerList={HeaderList}
            cellWidth={cellWidth}
            toggleAllSelected={toggleAllSelected}
            toggleSelected={toggleSelected}
            selected={selected}
            allSelected={allSelected}
            dataList={dataList}
            minusCount={minusCount}
            addCount={addCount}
          />
        ) : (
          <Table
            viewStyle={{ width: 1180, marginTop: 38 }}
            headerList={HeaderList}
            cellWidth={cellWidth}
            toggleAllSelected={toggleAllSelected}
            toggleSelected={toggleSelected}
            selected={selected}
            allSelected={allSelected}
            dataList={dataList}
            minusCount={minusCount}
            addCount={addCount}
          ></Table>
        )}
        <ButtonBox>
          <ButtonBoxLeft>
            <ButtonC
              viewStyle={{
                width:
                  windowSize.innerWidth <= 767
                    ? (windowSize.innerWidth - 20) / 2 - 13
                    : 160,
                height: 50,
                marginRight: 13,
              }}
              text="쇼핑 계속하기"
            />
            <ButtonC
              viewStyle={{
                width:
                  windowSize.innerWidth <= 767
                    ? (windowSize.innerWidth - 20) / 2 - 13
                    : 160,
                height: 50,
              }}
              text="선택 삭제"
              onClickEvent={() => removeAll()}
            />
          </ButtonBoxLeft>
          <ButtonBoxRight>
            <ButtonB
              viewStyle={{
                width: windowSize.innerWidth <= 767 ? "100%" : 262,
                height: 50,
                marginTop: windowSize.innerWidth <= 767 ? 16 : 0,
                marginBottom: windowSize.innerWidth <= 767 ? 100 : 0,
              }}
              text="주문하기"
            />
          </ButtonBoxRight>
        </ButtonBox>
      </FullWidth>
    </Form>
  );
};

export default Cart;

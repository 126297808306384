import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import BasicContainer from "../../../components/BasicContainer";
import { DROPDOWN_A } from "../../../components/Dropdown";
import Pagination from "../../../components/Pagination";
import SalesListItem from "../../../components/SalesListItem";
import { TAB } from "../../../components/Tab";
import { SEARCH_BOX } from "../../../components/TextField";
import { Black, Green600 } from "../../../config/color";
import {
  getWindowSize,
  thousandComma,
  toStringByFormatting,
} from "../../../utils";

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1328px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1439px) {
    min-width: 320px;
    width: calc(100% - 40px);
    padding: 0 20px;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  /* width: calc(100% - 112px); //calc(100% - 112px); */
  margin-top: 72px;

  @media screen and (max-width: 1439px) {
    margin-top: 32px;
  }
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  color: ${Black};
  white-space: pre-wrap;
  line-height: 51px;

  @media screen and (max-width: 1439px) {
    font-size: 26px;
    line-height: 36px;
  }
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 28.8px;
  white-space: pre-wrap;
`;

const RowDiv = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  width: 1328px;
  margin: 0 auto;
  /* width: calc(100% - 112px);
  padding: 0 56px; */

  @media screen and (max-width: 1439px) {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
`;

const SearchBoxRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;
  width: 100%;
  position: relative;
`;

const ListGrid = styled.div`
  display: grid;
  width: 1328px;
  margin: 32px 112px;
  gap: 16px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 480px);

  @media screen and (max-width: 767px) {
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 456px);
    row-gap: 28px;
    margin: 0 auto;
  }

  @media screen and (min-width: 768px) and (max-width: 1439px) {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 456px);
    row-gap: 28px;
    margin: 0 auto;
  }
`;

const Auction = () => {
  const navigate = useNavigate();

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const [selectedTab, setSelectedTab] = useState(2);
  const [searchFilter, setSearchFilter] = useState(["이름", "가격"]);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [searchText, setSearchText] = useState("");

  const [list, setList] = useState([
    {
      _id: "001",
      uri: "/assets/tmpImg/orchidsale.png",
      name: "홍금보",
      price: 1500000,
      hit: true,
      population: true,
      createdAt: new Date(),
    },
    {
      _id: "002",
      uri: "/assets/tmpImg/orchidsale.png",
      name: "홍금보",
      price: 1500000,
      hit: true,
      population: true,
      createdAt: new Date(),
    },
    {
      _id: "003",
      uri: "/assets/tmpImg/orchidsale.png",
      name: "홍금보",
      price: 1500000,
      hit: true,
      population: true,
      createdAt: new Date(),
    },
    {
      _id: "004",
      uri: "/assets/tmpImg/orchidsale.png",
      name: "홍금보",
      price: 1500000,
      hit: true,
      population: true,
      createdAt: new Date(),
    },
    {
      _id: "005",
      uri: "/assets/tmpImg/orchidsale.png",
      name: "홍금보",
      price: 1500000,
      hit: true,
      population: true,
      createdAt: new Date(),
    },
    {
      _id: "006",
      uri: "/assets/tmpImg/orchidsale.png",
      name: "홍금보",
      price: 1500000,
      hit: true,
      population: true,
      createdAt: new Date(),
    },
    {
      _id: "007",
      uri: "/assets/tmpImg/orchidsale.png",
      name: "홍금보",
      price: 1500000,
      hit: true,
      population: true,
      createdAt: new Date(),
    },
    {
      _id: "008",
      uri: "/assets/tmpImg/orchidsale.png",
      name: "홍금보",
      price: 1500000,
      hit: true,
      population: true,
      createdAt: new Date(),
    },
  ]);

  const onSearch = () => {
    console.log(searchText);
  };

  useEffect(() => {
    if (selectedTab === 0) {
      navigate("/shop/luxary");
    } else if (selectedTab === 1) {
      navigate("/shop/collection");
    } else if (selectedTab === 3) {
      navigate("/shop/beginner");
    } else if (selectedTab === 4) {
      navigate("/shop/tool");
    }
  }, [selectedTab]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <BasicContainer metaTitle="관유정-옥션">
      <Content>
        <TAB
          tabList={[
            { name: "럭셔리" },
            { name: "컬렉션" },
            { name: "옥션" },
            { name: "입문코너" },
            { name: "자재 및 도구" },
          ]}
          viewStyle={{
            marginTop: windowSize.innerWidth <= 1439 ? 28 : 32,
            marginBottom: windowSize.innerWidth <= 1439 ? 0 : 42,
          }}
          tabStyle={{
            width:
              windowSize.innerWidth <= 1439
                ? windowSize.innerWidth / 5 - 18
                : 96,
            paddingTop: 0,
            paddingBottom: 9,
            paddingLeft: windowSize.innerWidth <= 1439 ? 8 : 26,
            paddingRight: windowSize.innerWidth <= 1439 ? 8 : 26,
          }}
          activeTabIdx={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        {windowSize.innerWidth <= 1439 ? (
          <RowDiv>
            <TitleBox>
              <TitleLabel>종자지원</TitleLabel>
              <TitleText>옥션</TitleText>
              <Text style={{ marginBottom: 24 }}>
                알뜰용으로 추천하는 경매 코너입니다
              </Text>
            </TitleBox>
            <SearchBoxRow>
              <DROPDOWN_A
                list={searchFilter}
                defaultText="상세필터"
                dropdownSelected={selectedFilter}
                setDropdownSelected={setSelectedFilter}
                viewStyle={{
                  width: 150,
                  marginRight: 16,
                  height: 18,
                }}
                optionStyle={{ width: 164 - 32 }}
              />
              <SEARCH_BOX
                placeholder="검색하기"
                val={searchText}
                setVal={setSearchText}
                onClick={onSearch}
                viewStyle={{ width: "calc(100% - 161px)" }}
                seachBoxStyle={{ width: "calc(100% - 81px)" }}
              />
            </SearchBoxRow>
          </RowDiv>
        ) : (
          <RowDiv>
            <TitleBox>
              <TitleLabel>종자지원</TitleLabel>
              <TitleText>옥션</TitleText>
            </TitleBox>
            <DROPDOWN_A
              list={searchFilter}
              defaultText="상세필터"
              dropdownSelected={selectedFilter}
              setDropdownSelected={setSelectedFilter}
              viewStyle={{
                width: 150,
                marginRight: 16,
                height: 18,
              }}
              optionStyle={{ width: 164 - 32 }}
            />
            <SEARCH_BOX
              placeholder="검색하기"
              val={searchText}
              setVal={setSearchText}
              onClick={onSearch}
            />
          </RowDiv>
        )}
        {windowSize.innerWidth <= 1439 ? null : (
          <RowDiv style={{ marginTop: 2, marginBottom: 56 }}>
            <Text>알뜰용으로 추천하는 경매 코너입니다</Text>
          </RowDiv>
        )}
        <ListGrid>
          {list.map((item, idx) => {
            return (
              <SalesListItem
                key={item._id}
                uri={item.uri}
                name={item.name}
                hit={item.hit}
                population={item.population}
                createdAt={toStringByFormatting(item.createdAt)}
                price={`${thousandComma(item.price)}원`}
                isAuction={true}
              />
            );
          })}
        </ListGrid>
        <Pagination viewStyle={{ marginTop: 56, marginBottom: 174 }} />
      </Content>
    </BasicContainer>
  );
};

export default Auction;

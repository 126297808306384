import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Pagination from "../../../components/Pagination";
import { TAB } from "../../../components/Tab";
import {
  Black,
  Gray100,
  Gray200,
  Gray400,
  Gray500,
  Green600,
} from "../../../config/color";
import {
  getWindowSize,
  thousandComma,
  toStringByFormatting,
} from "../../../utils";

const Form = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 1180px;

  @media screen and (max-width: 1439px) {
    width: 100%;
  }
`;

const FullWidth = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PageTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const Title = styled.div`
  font-family: "Nanum Myeongjo", serif;
  font-weight: 600;
  font-size: 34px;
  line-height: 51px;
  color: ${Black};
  margin-top: 8px;

  @media screen and (max-width: 1439px) {
    font-size: 26px;
    line-height: 36px;
  }
`;

const ListHederBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 1180px;
  align-items: center;
  margin-top: 38px;
  margin-bottom: 12px;
`;

const ListHeader = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${Black};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const List = styled.div`
  width: 1148px;
  height: 60px;
  border: 1px solid ${Gray200};
  display: flex;
  flex-direction: row;
  padding: 16px;
  border-radius: 5px;
  margin-bottom: 16px;
`;

const ListCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ItemImgBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const ItemImg = styled.img`
  width: 60px;
  height: 60px;
`;

const ItemName = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 32.4px;
  display: flex;
  align-items: center;
  height: 60px;
  margin-left: 16px;
`;

const StatusWait = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 25.2px;
  text-align: center;
  color: ${Black};
`;

const StatusComplete = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 25.2px;
  text-align: center;
  color: ${Green600};
`;

const StatusCancel = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 25.2px;
  text-align: center;
  color: ${Gray400};
`;

const GoDetailButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Gray100};
  border-radius: 5px;
  padding: 6px 14px;
  font-weight: 700;
  font-size: 13px;
  line-height: 19.5px;
  &:hover {
    cursor: pointer;
  }
`;

const MobileListBox = styled.div`
  width: calc(100% - 72px);
  border: 1px solid ${Gray200};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  margin: 0 20px;
  margin-bottom: 20px;
`;

const MobileListBoxColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const OrderList = (props) => {
  const navigate = useNavigate();
  const { selectedTab, setSelectedTab } = props;

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const [dataList, setDataList] = useState([
    {
      _id: "001",
      image: "/assets/tmpImg/tmpImg1.png",
      name: "원명",
      amountOfPayment: 2000000,
      status: "미입금",
      createdAt: new Date(),
    },
    {
      _id: "002",
      image: "/assets/tmpImg/tmpImg1.png",
      name: "원명",
      amountOfPayment: 2000000,
      status: "입금완료",
      createdAt: new Date(),
    },
    {
      _id: "003",
      image: "/assets/tmpImg/tmpImg1.png",
      name: "원명",
      amountOfPayment: 2000000,
      status: "결제취소",
      createdAt: new Date(),
    },
  ]);

  const goOrderDetail = (id) => {
    navigate("/mypage/orderdetail", { state: id });
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Form>
      <FullWidth>
        <TAB
          tabList={[
            { name: "내 정보" },
            { name: "장바구니" },
            { name: "주문 내역" },
          ]}
          viewStyle={{
            marginTop: windowSize.innerWidth <= 1439 ? 28 : 32,
            marginBottom: windowSize.innerWidth <= 1439 ? 32 : 32,
          }}
          tabStyle={{
            width:
              windowSize.innerWidth <= 1439
                ? windowSize.innerWidth / 3 - 18
                : "auto",
            paddingTop: 0,
            paddingBottom: 9,
            paddingLeft: windowSize.innerWidth <= 1439 ? 8 : 90,
            paddingRight: windowSize.innerWidth <= 1439 ? 8 : 90,
          }}
          activeTabIdx={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <PageTitle>마이페이지</PageTitle>
        <Title
          style={windowSize.innerWidth <= 1439 ? { marginBottom: 38 } : null}
        >
          주문 내역
        </Title>
        {windowSize.innerWidth <= 1439 ? null : (
          <ListHederBox>
            <ListHeader
              style={{
                width: 521,
                marginLeft: 125,
                justifyContent: "flex-start",
              }}
            >
              상품영
            </ListHeader>
            <ListHeader style={{ width: 144 }}>결제일</ListHeader>
            <ListHeader style={{ width: 145 }}>결제금액</ListHeader>
            <ListHeader style={{ width: 144, marginRight: 0 }}>상태</ListHeader>
          </ListHederBox>
        )}
        {dataList.map((item, idx) => {
          return windowSize.innerWidth <= 1439 ? (
            <MobileListBox>
              <MobileListBoxColumn style={{ alignItems: "flex-start" }}>
                <ListCell
                  style={{
                    justifyContent: "flex-start",
                  }}
                >
                  <ItemImgBox>
                    <ItemName
                      style={{
                        textAlign: "left",
                        height: "auto",
                        marginLeft: 0,
                      }}
                    >
                      {item.name}
                    </ItemName>
                  </ItemImgBox>
                </ListCell>
                <ListCell
                  style={{
                    fontWeight: 700,
                    fontSize: 16,
                    color: Gray500,
                    marginTop: 8,
                  }}
                >
                  {thousandComma(item.amountOfPayment)}
                </ListCell>
                <ListCell
                  style={{
                    marginTop: 8,
                  }}
                >
                  {item.status === "미입금" ? (
                    <StatusWait>미입금</StatusWait>
                  ) : null}
                  {item.status === "입금완료" ? (
                    <StatusComplete>입금완료</StatusComplete>
                  ) : null}
                  {item.status === "결제취소" ? (
                    <StatusCancel>결제취소</StatusCancel>
                  ) : null}
                </ListCell>
                <ListCell
                  style={{
                    fontWeight: 400,
                    fontSize: 14,
                    marginTop: 8,
                  }}
                >
                  {toStringByFormatting(new Date(item.createdAt))}
                </ListCell>
              </MobileListBoxColumn>
              <MobileListBoxColumn
                style={{ marginLeft: "auto", alignItems: "flex-end" }}
              >
                <ItemImg src={item.image} />
                <ListCell
                  style={{
                    marginTop: "auto",
                    marginBottom: 0,
                  }}
                >
                  <GoDetailButton
                    onClick={() => {
                      goOrderDetail(item._id);
                    }}
                  >
                    상세보기
                  </GoDetailButton>
                </ListCell>
              </MobileListBoxColumn>
            </MobileListBox>
          ) : (
            <List key={item._id}>
              <ListCell
                style={{
                  width: 630,
                  marginLeft: 0,
                  justifyContent: "flex-start",
                }}
              >
                <ItemImgBox>
                  <ItemImg src={item.image} />
                  <ItemName>{item.name}</ItemName>
                </ItemImgBox>
              </ListCell>
              <ListCell
                style={{
                  width: 144,
                  fontWeight: 400,
                  fontSize: 14,
                }}
              >
                {toStringByFormatting(new Date(item.createdAt))}
              </ListCell>
              <ListCell
                style={{
                  width: 144,
                  fontWeight: 700,
                  fontSize: 16,
                  color: Gray500,
                }}
              >
                {thousandComma(item.amountOfPayment)}
              </ListCell>
              <ListCell style={{ width: 144 }}>
                {item.status === "미입금" ? (
                  <StatusWait>미입금</StatusWait>
                ) : null}
                {item.status === "입금완료" ? (
                  <StatusComplete>입금완료</StatusComplete>
                ) : null}
                {item.status === "결제취소" ? (
                  <StatusCancel>결제취소</StatusCancel>
                ) : null}
              </ListCell>
              <ListCell>
                <GoDetailButton
                  onClick={() => {
                    goOrderDetail(item._id);
                  }}
                >
                  상세보기
                </GoDetailButton>
              </ListCell>
            </List>
          );
        })}
        {dataList.length > 0 ? <Pagination /> : null}
      </FullWidth>
    </Form>
  );
};

export default OrderList;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Black, Green600 } from "../../../config/color";
import { getWindowSize } from "../../../utils";

const CheckList = require("../../../assets/icon/CheckList104.png");
const Light = require("../../../assets/icon/Light104.png");
const LeafSpot = require("../../../assets/icon/LeafSpot104.png");
const LeafEnd = require("../../../assets/icon/LeafEnd104.png");

const Container = styled.div`
  width: 586px;
  margin: 0 auto;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  @media screen and (max-width: 767px) {
    width: 100%;
    flex-direction: column;
  }

  @media screen and (min-width: 768px) and (max-width: 1439px) {
    width: 100%;
  }
`;

const MobileContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 767px) {
    width: 240px;
    margin: 0 auto;
  }
`;

const Box = styled.div`
  width: 105px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BoxStep = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  text-align: center;
  color: ${Green600};
  margin-bottom: 8px;
`;

const Circle = styled.div`
  width: 104px;
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 52px;
  background-color: ${Green600};
`;

const CircleImg = styled.img`
  width: 104px;
  height: 104px;
`;

const DashLine = styled.div`
  width: 135px;
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DashLineImg = styled.div`
  border-top: 2px dashed ${Green600};
  width: 135px;
`;

const BoxTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  margin-top: 8px;
`;

const ScalingRepottingGraph01 = (props) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Container>
      <MobileContainer
        style={windowSize.innerWidth <= 767 ? { marginBottom: 32 } : null}
      >
        <Box>
          <CircleImg src={CheckList} />
          <BoxTitle>웃자람 체크</BoxTitle>
        </Box>
        <Box style={{ width: windowSize.innerWidth <= 767 ? 32 : 80 }}>
          <BoxStep></BoxStep>
          <DashLine></DashLine>
          <BoxTitle></BoxTitle>
        </Box>
        <Box style={{ width: 105 }}>
          <CircleImg src={Light} />
          <BoxTitle>광저해 체크</BoxTitle>
        </Box>
      </MobileContainer>
      {windowSize.innerWidth <= 767 ? null : (
        <Box style={{ width: 80 }}>
          <BoxStep></BoxStep>
          <DashLine></DashLine>
          <BoxTitle></BoxTitle>
        </Box>
      )}
      <MobileContainer>
        <Box>
          <CircleImg src={LeafSpot} />
          <BoxTitle>잎 반점 체크</BoxTitle>
        </Box>
        <Box style={{ width: windowSize.innerWidth <= 767 ? 32 : 80 }}>
          <BoxStep></BoxStep>
          <DashLine></DashLine>
          <BoxTitle></BoxTitle>
        </Box>
        <Box>
          <CircleImg src={LeafEnd} />
          <BoxTitle style={{ letterSpacing: -1 }}>잎 끝 마름 체크</BoxTitle>
        </Box>
      </MobileContainer>
    </Container>
  );
};

export default ScalingRepottingGraph01;

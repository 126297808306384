import React from "react";
import styled from "styled-components";

const Container = styled.div`
  flex: 1;
`;

const Company = () => {
  return <Container></Container>;
};

export default Company;

import React from "react";
import styled from "styled-components";
import { Gray600, White } from "../config/color";
import { useUserStore } from "../store";

import { useNavigate } from "react-router-dom";

const CartImg = require("../assets/icon/Cart24.png");

const Container = styled.div`
  width: 100%;
  min-width: 1440px;
  height: 48px;
  display: flex;
  justify-content: center;
  background-color: ${Gray600};

  @media screen and (max-width: 1439px) {
    display: none;
  }
`;

const InnerContainer = styled.div`
  width: 100%;
  min-width: 1440px;
  height: 48px;
  display: flex;
  padding: 0 56px;
  align-items: center;
  flex-direction: row;
`;

const LeftLinkBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 0;
  margin-right: auto;
`;

const RightLinkBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  margin-right: 0;
`;

const PageLink = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: ${White};
  margin-right: 40px;
  &:hover {
    cursor: pointer;
  }
`;

const SpecialButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${White};
  padding: 4px 8px;
  height: 24px;
  width: 86px;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
  }
`;

const ButtonImg = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 4px;
`;

const ButtonText = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  color: #242627;
`;

const Header = (props) => {
  const { isLogin } = useUserStore((state) => state);
  const navigate = useNavigate();

  const goMyPage = () => {
    navigate("/mypage");
  };

  return (
    <Container>
      <InnerContainer>
        <LeftLinkBox>
          <PageLink>내 난 시세보기</PageLink>
          <PageLink>내 난 직접팔기</PageLink>
          <PageLink>바우처 시스템</PageLink>
          <PageLink>종보존 오더돌봄</PageLink>
          <PageLink>난초 품질 이력제</PageLink>
          <PageLink>난초 건강증진센터</PageLink>
        </LeftLinkBox>
        <RightLinkBox>
          {isLogin ? (
            <>
              <PageLink>주문조회</PageLink>
              <PageLink onClick={goMyPage}>마이페이지</PageLink>
              <PageLink>고객센터</PageLink>
            </>
          ) : null}
          <SpecialButton>
            <ButtonImg src={CartImg} />
            <ButtonText>장바구니</ButtonText>
          </SpecialButton>
        </RightLinkBox>
      </InnerContainer>
    </Container>
  );
};

export default Header;

import React from "react";
import styled from "styled-components";

import {
  Black,
  Gray200,
  Gray300,
  Gray600,
  Green100,
  Green600,
  White,
} from "../../../config/color";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 1328px;
  margin-top: 56px;
  margin-bottom: 112px;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
`;

const Card = styled.div`
  width: 656px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${Gray200};
  border-radius: 10px;
`;

const InfoBox = styled.div`
  padding: 24px;
`;

const Tag = styled.div`
  width: 100px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Green600};
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: ${White};
  margin-bottom: 12px;
`;

const Title = styled.div`
  font-family: "Nanum Myeongjo";
  font-size: 26px;
  font-weight: 600;
  line-height: 36px;
  color: ${Black};
  margin-bottom: 24px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 8px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 8px;
`;

const Label = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  width: 28px;
  margin-right: 8px;
`;

const Content = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  white-space: pre-wrap;
  flex: 1;
`;

const Image = styled.img`
  width: 100%;
  height: 230px;
  object-fit: cover;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

const Box = styled.div`
  width: calc(100% - 32px);
  padding: 16px;
  border-radius: 10px;
  background-color: ${Green100};
`;

const BoxTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: ${Green600};
`;

const BoxSubtitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  margin-top: 2px;
  margin-bottom: 2px;
`;

const BoxContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  white-space: pre-wrap;
`;

const SecondGradeCard = (props) => {
  return (
    <Container>
      <InnerContainer>
        <Card>
          <InfoBox>
            <Tag>2급 자격 과정</Tag>
            <Title>평일반</Title>
            <Row>
              <Label>장소</Label>
              <Content>대한민국난문화진흥원</Content>
            </Row>
            <Row>
              <Label>정원</Label>
              <Content>5명 이상</Content>
            </Row>
            <Row>
              <Label>개강</Label>
              <Content>매월 첫 주 월~수 / 2박3일</Content>
            </Row>
            <Row>
              <Label>일정</Label>
              <Content>오전 10시 ~ 오후 5시 하루 3과목씩 실시</Content>
            </Row>
            <Row>
              <Label>특전</Label>
              <Content>
                한국 춘란 관리사 2급 자격증 발급 (대한민국난문화진흥원 발급)
              </Content>
            </Row>
            <Row>
              <Label>비용</Label>
              <Content>300만원 (숙식제공) 2~3명 동반 시 총 100만원 DC</Content>
            </Row>
          </InfoBox>
          <Image src="/assets/tmpImg/lecturetmpImg.png" />
        </Card>
        <Card style={{ marginLeft: 16 }}>
          <InfoBox>
            <Tag>2급 자격 과정</Tag>
            <Title>주말반</Title>
            <Row>
              <Label>장소</Label>
              <Content>대구카톨릭대학 평생교육원</Content>
            </Row>
            <Row>
              <Label>정원</Label>
              <Content>25명</Content>
            </Row>
            <Row>
              <Label>개강</Label>
              <Content>매년 3, 6, 9, 12월 첫 주 토요일</Content>
            </Row>
            <Row>
              <Label>일정</Label>
              <Content>오후 1:30 ~ 오후 4:00 시 하루 1과목 씩</Content>
            </Row>
            <Row>
              <Label>특전</Label>
              <Content>
                한국 춘란 관리사 2급 자격증 발급 (대한민국난문화진흥원 발급)
              </Content>
            </Row>
            <Row>
              <Label>비용</Label>
              <Content>200만원 (현장 실습비 + 멘토링 비 포함)</Content>
            </Row>
          </InfoBox>
          <Image src="/assets/tmpImg/lecturetmpImg.png" />
        </Card>
      </InnerContainer>
      <InnerContainer>
        <Card>
          <InfoBox>
            <Tag>2급 자격 과정</Tag>
            <Title>신바람 이동 캠퍼스</Title>
            <Row>
              <Label>장소</Label>
              <Content>교육 이수 지역</Content>
            </Row>
            <Row>
              <Label>정원</Label>
              <Content>3~5인</Content>
            </Row>
            <Row>
              <Label>기간</Label>
              <Content>협의 2박 3일 또는 3회 하루 3과목</Content>
            </Row>
            <Row>
              <Label>특전</Label>
              <Content>한국 춘란 관리사 2급 자격증 발급</Content>
            </Row>
            <Row>
              <Label>비용</Label>
              <Content>
                3인 각 300만원 / 5인 1000만원 (추가시 인당 200만원)
              </Content>
            </Row>
            <Box style={{ marginTop: 40 }}>
              <BoxTitle>DAY 1</BoxTitle>
              <BoxSubtitle>어떤 옵션을 갖춘 품종을 선택해야하나</BoxSubtitle>
              <BoxContent>{`1. 난초 부업의 정체성
2. 돌연변이와 예
3. 옵션과 랭킹결정 `}</BoxContent>
            </Box>
            <Box style={{ marginTop: 16 }}>
              <BoxTitle>DAY 2</BoxTitle>
              <BoxSubtitle>경영설계와 영농설계를 어떻게 해야하나</BoxSubtitle>
              <BoxContent>{`4. 대회와 경기
5. 경영설계 영농설계
6. 재배생리 `}</BoxContent>
            </Box>
            <Box style={{ marginTop: 16 }}>
              <BoxTitle>DAY 3</BoxTitle>
              <BoxSubtitle>
                1등급을 만들려면 품질관리를 어떻게 해야하나
              </BoxSubtitle>
              <BoxContent>{`7. 1등급 만들기
8. 품질 관리 및 건강증진기술
9. 분갈이 및 종합검진
ㅡ수료식`}</BoxContent>
            </Box>
          </InfoBox>
        </Card>
        <Card style={{ marginLeft: 16 }}>
          <InfoBox>
            <Tag>2급 자격 과정</Tag>
            <Title>취미&콜렉션 과정</Title>
            <Row>
              <Label>장소</Label>
              <Content>난문화진흥원(대구. 수성구. 지산동. 145)</Content>
            </Row>
            <Row>
              <Label>정원</Label>
              <Content>1~3인</Content>
            </Row>
            <Row>
              <Label>기간</Label>
              <Content>
                협의 1박 2일 하루 4과목씩 총 8과목 / 오전9 ~ 오후 6시
              </Content>
            </Row>
            <Row>
              <Label>특전</Label>
              <Content>한국 춘란 관리사 2급 자격증 발급</Content>
            </Row>
            <Row>
              <Label>비용</Label>
              <Content>300만(숙식 제공)</Content>
            </Row>
            <Column
              style={{
                marginTop: 16,
                paddingTop: 16,
                borderTop: `1px solid ${Gray300}`,
              }}
            >
              <BoxSubtitle>취미반 2급을 받으면 좋은 이유</BoxSubtitle>
              <BoxContent>{`ㅡ최신 고급 콜랙션 체계 / 작품을 잘 만드는 체계 확립
ㅡ경기에서 우승 능력 확립
ㅡ난을 늘 건강하게 기르는 기술 확립
ㅡ꽃을 잘 피우는 기술 `}</BoxContent>
            </Column>
            <Box style={{ marginTop: 29 }}>
              <BoxTitle>DAY 1</BoxTitle>
              <BoxSubtitle>최신 콜랙션 세계</BoxSubtitle>
              <BoxContent>{`1강. 변이와 예, 25개 종목의 미학
2강. 옵션과 의인화와 미학
3강. 콜랙션 체계와 테크닉(갖추는 기술)
4강. 광합성과 튼튼히 기르기 `}</BoxContent>
            </Box>
            <Box style={{ marginTop: 16 }}>
              <BoxTitle>DAY 2</BoxTitle>
              <BoxSubtitle>최신 작품 세계</BoxSubtitle>
              <BoxContent>{`5강. 대회와 경기 실 격과 에티켓
6강. 작품 프로세스:기획, 설계, 시공, 장식, 디피
7강. 우승 전략과 꽃 피우기
8강. 무 감점과 채점 기준표`}</BoxContent>
            </Box>
          </InfoBox>
        </Card>
      </InnerContainer>
    </Container>
  );
};

export default SecondGradeCard;

import React, { useState } from "react";
import styled from "styled-components";
import BasicContainer from "../../../components/BasicContainer";
import PageTop from "../../../components/PageTop";
import { Gray100, Gray500, White } from "../../../config/color";

import { useNavigate } from "react-router-dom";

import { TF_A } from "../../../components/TextField";
import { ButtonA } from "../../../components/Button";

const DocumentIcon = require("../../../assets/icon/Document64.png");

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1439px) {
    min-width: 360px;
    width: 100%;
  }
`;

const RowDiv = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  width: 1328px;
  margin: 0 auto;
`;

const Box = styled.div`
  width: 1328px;
  height: 407px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${Gray100};
  margin-top: 50px;
  margin-bottom: 91px;
  border-radius: 10px;

  @media screen and (max-width: 1439px) {
    width: 100%;
    background-color: ${White};
  }
`;

const Icon = styled.img`
  width: 64px;
  height: 64pxx;
  margin-top: 35px;
  margin-bottom: 8px;
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: ${Gray500};
  text-align: center;
  white-space: pre-wrap;
  margin-bottom: 16px;
`;

const ImportantText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: ${Gray500};
  margin-top: 16px;
  margin-bottom: 32px;
`;

const ControlTable = (props) => {
  const navigate = useNavigate();

  const [contact, setContact] = useState("");

  return (
    <BasicContainer metaTitle="관유정-관행 방제표 7집">
      <Content>
        <PageTop
          label="기술지원"
          title="관행 방제표 7집"
          ImageUrl="/assets/image/TechnicalHeaderImg.png"
        />
        <Box>
          <Icon src={DocumentIcon} />
          <Text>{`관행 방제표 7집이 필요하신 분은
고객센터로 문의해주시면
남겨주신 연락처로 전달드리도록 하겠습니다`}</Text>
          <TF_A
            placeholder="전화번호 또는 이메일 주소 기재"
            val={contact}
            setVal={setContact}
            inputType="text"
            viewStyle={{
              width: 320,
            }}
            inputStyle={{ width: 320 - 32 }}
          />
          <ButtonA
            text="연락처 전달하기"
            viewStyle={{ marginTop: 16 }}
            onClickEvent={() => {
              alert("확인후 배송해 드리겠습니다.");
            }}
          />
          <ImportantText>*배송비는 착불입니다</ImportantText>
        </Box>
      </Content>
    </BasicContainer>
  );
};

export default ControlTable;

import React from "react";
import styled from "styled-components";
import {
  Gray200,
  Gray500,
  Green100,
  Green600,
  White,
} from "../../../config/color";

const Container = styled.div`
  width: 1328px;
  margin: 56px auto;
  margin-top: 0;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1439px) {
    margin-bottom: 0;
    margin-top: 56px;
  }
`;

const Header = styled.div`
  width: 100%;
  height: 48px;
  background-color: ${Green600};
  border: 1px solid ${Gray200};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  color: ${White};
  padding: 15px 0;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  flex: 1;
  background-color: ${Green100};
  border: 1px solid ${Gray200};
  display: flex;
  flex-direction: row;
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Gray500};
`;

const CellBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`;

const Cell = styled.div`
  flex: 1;
  border: 1px solid ${Gray200};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  color: ${Gray500};
  height: 48px;
`;

const TempManual = (props) => {
  return (
    <Container>
      <Header>위탁 오더 돌봄 - 온도 주기 위 주간 / 아래 야간</Header>
      <Content>
        <Title>
          <Cell>1월</Cell>
          <Cell>2월</Cell>
          <Cell>3월</Cell>
          <Cell>4월</Cell>
          <Cell>5월</Cell>
          <Cell>6월</Cell>
          <Cell>7월</Cell>
          <Cell>8월</Cell>
          <Cell>9월</Cell>
          <Cell>10월</Cell>
          <Cell>11월</Cell>
          <Cell>12월</Cell>
        </Title>
        <CellBox>
          <Cell>20-22</Cell>
          <Cell>20-22</Cell>
          <Cell>20-24</Cell>
          <Cell>24-26</Cell>
          <Cell>24-26</Cell>
          <Cell>26-28</Cell>
          <Cell>28-32</Cell>
          <Cell>28-32</Cell>
          <Cell>25-28</Cell>
          <Cell>22-25</Cell>
          <Cell>20-22</Cell>
          <Cell>20-22</Cell>
        </CellBox>
        <CellBox>
          <Cell>6-7</Cell>
          <Cell>7-8</Cell>
          <Cell>8-10</Cell>
          <Cell>12-15</Cell>
          <Cell>15-18</Cell>
          <Cell>18-20</Cell>
          <Cell>20-23</Cell>
          <Cell>20-23</Cell>
          <Cell>17-20</Cell>
          <Cell>13-17</Cell>
          <Cell>10-13</Cell>
          <Cell>8-10</Cell>
        </CellBox>
        <CellBox>
          <Cell>7집 관리표 참고</Cell>
        </CellBox>
      </Content>
    </Container>
  );
};

export default TempManual;

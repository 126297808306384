import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useUserStore } from "../store";
import { useState } from "react";
import NavMenu from "./NavMenu";

import {
  BackgroundColorWhite,
  Gray100,
  Gray500,
  TextColorGray500,
} from "../config/color";

import { getWindowSize } from "../utils";
import NavigationMobile from "./NavigationMobile";

const LogoImg = require("../assets/image/header_logo.png");
const MenuIcon = require("../assets/icon/MenuBar30.png");

const Container = styled.div`
  height: 72px;
  width: 1440px;
  background-color: ${BackgroundColorWhite};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media screen and (max-width: 1439px) {
    width: 100%;
    min-width: 360px;
    height: 59px;
  }
`;

const InnerContainer = styled.div`
  width: 1440px;
  height: 72px;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 56px;

  @media screen and (max-width: 1439px) {
    width: calc(100% - 38px);
    height: 59px;
    padding: 0 19px;
  }
`;

const Logo = styled.img`
  width: 106px;
  height: 60px;

  @media screen and (max-width: 1439px) {
    width: 89px;
    height: 50px;
  }
`;

const MenuList = styled.div`
  margin-left: 26px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;

  @media screen and (max-width: 1439px) {
    display: none;
  }
`;

const MenuBarImg = styled.img`
  display: none;
  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 1439px) {
    display: flex;
    width: 30px;
    height: 30px;
    margin-left: auto;
  }
`;

const UserStateBox = styled.div`
  margin-left: auto;
  margin-right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  @media screen and (max-width: 1439px) {
    display: none;
  }
`;

const MenuLink = styled(Link)`
  text-decoration: none;
  margin-right: 32px;
  &:hover {
    cursor: pointer;
  }
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  text-align: center;
  color: ${TextColorGray500};
`;

const LanguageButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 32px;
  border-radius: 50px;
  background-color: ${Gray100};
  color: ${Gray500};
  font-size: 14px;
  font-weight: 700;
  &:hover {
    cursor: pointer;
  }
`;

const Navigation = (props) => {
  const { isLogin } = useUserStore((state) => state);

  const [windowSize, setWindowSize] = useState(getWindowSize());
  // PC
  const [menuHover, setMenuHover] = useState(false);

  const showMenu = () => {
    setMenuHover(true);
  };

  const hideMenu = () => {
    setMenuHover(false);
  };

  // Tablet & Mobile
  const [navigationMobileVisible, setNavigationMobileVisible] = useState(false);

  const showNavMobile = () => {
    setNavigationMobileVisible(true);
  };

  const hideNavMobile = () => {
    setNavigationMobileVisible(false);
  };

  // Window Resize Hook
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Container>
      <InnerContainer>
        <Link to="/">
          <Logo src={LogoImg} />
        </Link>
        <MenuList>
          <MenuLink onMouseEnter={showMenu}>
            <Text>이대발과 관유정</Text>
          </MenuLink>
          <MenuLink onMouseEnter={showMenu}>
            <Text>종자지원</Text>
          </MenuLink>
          <MenuLink onMouseEnter={showMenu}>
            <Text>영농지원</Text>
          </MenuLink>
          <MenuLink onMouseEnter={showMenu}>
            <Text>교육지원</Text>
          </MenuLink>
          <MenuLink onMouseEnter={showMenu}>
            <Text>기술지원</Text>
          </MenuLink>
          <MenuLink onMouseEnter={showMenu}>
            <Text>의료지원</Text>
          </MenuLink>
        </MenuList>
        <UserStateBox>
          {!isLogin ? (
            <>
              <MenuLink to="/signin">
                <Text>로그인</Text>
              </MenuLink>
              <MenuLink to="/signup">
                <Text>회원가입</Text>
              </MenuLink>
            </>
          ) : (
            <MenuLink to="/">
              <Text>로그아웃</Text>
            </MenuLink>
          )}
          <LanguageButton>ENG</LanguageButton>
        </UserStateBox>
        <MenuBarImg src={MenuIcon} onClick={showNavMobile} />
      </InnerContainer>
      {menuHover ? (
        <NavMenu
          viewStyle={{ position: "absolute", top: 72 }}
          hideMenu={hideMenu}
        />
      ) : null}
      {windowSize.innerWidth <= 1439 && navigationMobileVisible ? (
        <NavigationMobile hideNavMobile={hideNavMobile} />
      ) : null}
    </Container>
  );
};

export default Navigation;

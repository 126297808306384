import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import BasicContainer from "../../../components/BasicContainer";
import { ButtonA } from "../../../components/Button";
import { SEARCH_BOX } from "../../../components/TextField";
import {
  Black,
  Gray100,
  Gray200,
  Gray400,
  Green600,
} from "../../../config/color";

import { getWindowSize, toStringByFormatting } from "../../../utils";
import Pagination from "../../../components/Pagination";
import { useEffect } from "react";

const EmptyIcon = require("../../../assets/icon/Close29.png");
const CommentIcon = require("../../../assets/icon/Comment24.png");
const ViewIcon = require("../../../assets/icon/View24.png");

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1439px) {
    min-width: 360px;
    width: calc(100% - 40px);
    padding: 0 20px;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 112px); //calc(100% - 112px);
  padding: 0 56px;

  @media screen and (max-width: 1439px) {
    width: 100%;
    padding: 0;
  }
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  color: ${Black};
`;

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media screen and (max-width: 1439px) {
    flex-direction: column;
  }
`;

const EmptyBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${Gray100};
  height: 142px;
  margin: 0 56px;
  width: calc(100% - 112px);

  @media screen and (max-width: 1439px) {
    width: 100%;
    margin: 0;
  }
`;

const EmptyBoxIcon = styled.img`
  width: 29px;
  height: 29px;
`;

const EmptyBoxText = styled.div`
  margin-top: 12px;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: ${Gray400};
`;

const ListBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 56px;
  width: calc(100% - 56px);

  @media screen and (max-width: 1439px) {
    width: 100%;
    margin: 0;
  }
`;

const List = styled.div`
  flex: 1;
  height: 32px;
  border: 1px solid ${Gray200};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  border-radius: 8px;
  margin-bottom: 16px;
  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 1439px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Number = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Gray400};
  margin-right: 16px;
  margin-left: 0;
`;

const ListTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 32.4px;
  color: ${Black};
  margin-right: auto;
`;

const ListIconBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 4px;
`;

const CountText = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 25.2px;
  margin-right: 16px;
`;

const DateText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 25.2px;
`;

const Board = () => {
  const navigate = useNavigate();

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [searchText, setSearchText] = useState("");
  const [list, setList] = useState([
    {
      _id: "003",
      title: "관유정과 이대발 난초",
      commentCnt: 13,
      viewCnt: 13,
      createdAt: new Date(),
    },
    {
      _id: "002",
      title: "관유정과 이대발 난초",
      commentCnt: 13,
      viewCnt: 13,
      createdAt: new Date(),
    },
    {
      _id: "001",
      title: "관유정과 이대발 난초",
      commentCnt: 13,
      viewCnt: 13,
      createdAt: new Date(),
    },
  ]);

  const onSearch = () => {
    console.log(searchText);
  };

  const onMoveWriting = () => {
    navigate("/company/board/writing", { state: "id값" });
  };

  const onMoveDetail = (id) => {
    navigate("/company/board/detail", { state: { _id: id } });
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <BasicContainer metaTitle="관유정-자유게시판">
      <Content>
        <RowDiv style={{ marginBottom: 32 }}>
          <TitleBox style={{ marginTop: 72 }}>
            <TitleLabel>자유게시판</TitleLabel>
            <RowDiv
              style={{
                flexDirection: windowSize.innerWidth <= 767 ? "column" : "row",
              }}
            >
              <TitleText>자유게시판</TitleText>
              <SEARCH_BOX
                viewStyle={{
                  marginLeft: windowSize.innerWidth <= 767 ? 0 : "auto",
                  marginTop: windowSize.innerWidth <= 767 ? 32 : 0,
                  width: windowSize.innerWidth <= 767 ? "100%" : 352,
                }}
                seachBoxStyle={{
                  width:
                    windowSize.innerWidth <= 767 ? "calc(100% - 60px)" : 320,
                }}
                val={searchText}
                setVal={setSearchText}
                onClick={onSearch}
                placeholder="검색하기"
              />
              <ButtonA
                viewStyle={{
                  width: 134,
                  marginLeft: 16,
                  position:
                    windowSize.innerWidth <= 767 ? "absolute" : "relative",
                  right: windowSize.innerWidth <= 767 ? 20 : "auto",
                }}
                text="글 작성"
                onClickEvent={onMoveWriting}
              />
            </RowDiv>
          </TitleBox>
        </RowDiv>
        {list.length <= 0 ? (
          <EmptyBox>
            <EmptyBoxIcon src={EmptyIcon} />
            <EmptyBoxText>내용이 존재하지 않습니다</EmptyBoxText>
          </EmptyBox>
        ) : (
          <ListBox>
            {list.map((item, idx) => {
              return (
                <List
                  key={item._id}
                  onClick={() => {
                    onMoveDetail(item._id);
                  }}
                >
                  <Number>{list.length - idx}</Number>
                  <ListTitle>{item.title}</ListTitle>
                  <ListIconBox>
                    <Icon src={CommentIcon} />
                    <CountText>{item.commentCnt}</CountText>
                    <Icon src={ViewIcon} />
                    <CountText>{item.viewCnt}</CountText>
                    <DateText>{toStringByFormatting(item.createdAt)}</DateText>
                  </ListIconBox>
                </List>
              );
            })}
            <Pagination viewStyle={{ marginTop: 40, marginBottom: 83 }} />
          </ListBox>
        )}
      </Content>
    </BasicContainer>
  );
};

export default Board;

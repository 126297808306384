import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BasicContainer from "../../../components/BasicContainer";
import PageTop from "../../../components/PageTop";
import {
  Black,
  Gray100,
  Gray300,
  Gray400,
  Gray500,
  Green600,
} from "../../../config/color";
import { getWindowSize } from "../../../utils";

import GuaranteeGraph from "./Graph";

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1439px) {
    min-width: 360px;
    width: 100%;
  }
`;

const RowDiv = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  width: 1328px;
  margin: 0 auto;

  @media screen and (max-width: 1439px) {
    flex-direction: column;
    width: calc(100% - 40px);
    padding: 0 20px;
    align-items: flex-start;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 72px;

  @media screen and (max-width: 1439px) {
    margin-top: 32px;
  }
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  color: ${Black};
  white-space: pre-wrap;
  line-height: 51px;

  @media screen and (max-width: 1439px) {
    font-size: 26px;
    line-height: 36px;
    word-break: keep-all;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 1328px;
  margin: 0 auto;
  margin-top: 40px;
  padding: 16px 0;
  border-top: 1px solid ${Gray300};

  @media screen and (max-width: 1439px) {
    width: calc(100% - 40px);
    padding: 16px 0;
    margin: 32px 20px;
  }
`;

const ListLabel = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Green600};
`;

const ListTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Black};
  margin-bottom: 16px;
`;

const ListContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  white-space: pre-wrap;
`;

const InfoBox = styled.div`
  background-color: ${Gray100};
  width: calc(100% - 48px);
  padding: 18px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: 700;
  line-height: 28.8px;
  color: ${Gray500};
  border-radius: 10px;
  margin-bottom: 40px;
`;

const InfoList = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${Gray400};
`;

const InfoLabel = styled.div`
  width: 60px;
  margin-right: 24px;
  text-align: left;
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
`;

const Info = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  white-space: pre-wrap;
`;

const KindListUl = styled.ul`
  padding-left: 32px;
  margin: 0;
`;

const KindListLi = styled.li`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
`;

const Guarantee = (props) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <BasicContainer metaTitle="관유정-원금보장 OEM">
      <Content>
        <PageTop
          label="영농지원"
          title="원금보장 OEM"
          ImageUrl="/assets/image/FarmHeaderImg.png"
        />
        <RowDiv>
          <TitleBox>
            <TitleLabel>영농지원</TitleLabel>
            <TitleText>{`관유정만의 특별한 제도들,
세계최초 원금보장 OEM`}</TitleText>
          </TitleBox>
        </RowDiv>
        <List>
          <ListLabel>01</ListLabel>
          <ListTitle>원금보장 AS 보험이란?</ListTitle>
          <ListContent>
            {windowSize.innerWidth <= 1439
              ? `난문화 진흥원에서 개발해서 관유정에서 실행하는 사망AS보험은 인큐베이터나 가상의 불량품들을 의심해볼 때 원금 보장 위에 난 문화 진흥원에서 개발하게 된 이유는 난초 구매시, 1대 99로 자신감 있게 AS가 가능하다고 하는 판매점은 1, 나머지 99는 사망 AS를 들어주지 못한다. 그 이유는 난초에 대한 자신감이 없기 때문에 발생하는 것이다. 교육생들에게 하는 지엽적인 색체에서 난계를 지켜낼 수 있는 3%(바닷물 소금)의 지원책이다. 또한 자산을 지켜낼 수 있는 제일 중요한 방안이다. 관유정에서도 자신이 없으면 이 제도를 실행하지 못한다.  이 제도를 만든 이유는 관유정이 생산한 관유정의 자부심과 자신감으로 만들었다.`
              : `난문화 진흥원에서 개발해서 관유정에서 실행하는 사망AS보험은 인큐베이터나 가상의 불량품들을 의심해볼 때 원금 보장 위에 난 문화 진흥원에서 개발하게 된 이유는
난초 구매시, 1대 99로 자신감 있게 AS가 가능하다고 하는 판매점은 1, 나머지 99는 사망 AS를 들어주지 못한다.
그 이유는 난초에 대한 자신감이 없기 때문에 발생하는 것이다. 교육생들에게 하는 지엽적인 색체에서 난계를 지켜낼 수 있는 3%(바닷물 소금)의 지원책이다.
또한 자산을 지켜낼 수 있는 제일 중요한 방안이다. 관유정에서도 자신이 없으면 이 제도를 실행하지 못한다. 
이 제도를 만든 이유는 관유정이 생산한 관유정의 자부심과 자신감으로 만들었다.`}
          </ListContent>
        </List>
        <RowDiv>
          <InfoBox>
            <InfoList>
              <InfoLabel>기획의도</InfoLabel>
              <Info>
                2030 초보 농의 진입장벽을 낮추기 위해 / 원금 손실을 없애 주기
                위해
              </Info>
            </InfoList>
            <InfoList>
              <InfoLabel>장점</InfoLabel>
              <Info>후불제 성공 보수제</Info>
            </InfoList>
            <InfoList>
              <InfoLabel>프로세스</InfoLabel>
              <Info>
                2급 자격 취득 -&gt; 안심 바우처 가입 -&gt; 원금 보장 AS
                보험계약서 작성
              </Info>
            </InfoList>
            <InfoList>
              <InfoLabel>기대효과</InfoLabel>
              <Info>2030 부업자들의 대거 유입</Info>
            </InfoList>
          </InfoBox>
        </RowDiv>
        <GuaranteeGraph />
        <List>
          <ListLabel>02</ListLabel>
          <ListTitle>원금보장 AS 보험 자격</ListTitle>
          <KindListUl>
            <KindListLi>
              가입하려는 인원이 많을 경우, 신청한 순서대로 가입을 진행함
            </KindListLi>
            <KindListLi>
              2030세대, 여성, 장애분의 경우 난계의 취약계층으로 1기수 정도
              어드벤티지를 주어 가입할 수 있음
            </KindListLi>
            <KindListLi>
              협력업체를 통해 제공된 난초에 한해서만 가입이 가능합니다
            </KindListLi>
          </KindListUl>
        </List>
        <List style={{ marginBottom: 130 }}>
          <ListLabel>03</ListLabel>
          <ListTitle>주의사항</ListTitle>
          <KindListUl>
            <KindListLi>
              사망하지 않는 경우에도 중증 또는 탈이 발생하여 리콜대상이 된다면,
              이에 상응하는 난초로 교체가 가능합니다.
            </KindListLi>
            <KindListLi>
              협력업체를 통해 제공된 난초에 한해서만 가입이 가능합니다.
            </KindListLi>
          </KindListUl>
        </List>
      </Content>
    </BasicContainer>
  );
};

export default Guarantee;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BasicContainer from "../../../components/BasicContainer";
import PageTop from "../../../components/PageTop";

import {
  Black,
  Gray300,
  Green100,
  Green600,
  White,
} from "../../../config/color";
import { getWindowSize } from "../../../utils";
import ScalingRepottingGraph01 from "./Graph01";
import ScalingRepottingGraph02 from "./Graph02";
import ScalingRepottingGraph03 from "./Graph03";
import ScalingRepottingGraph04 from "./Graph04";
import ScalingRepottingGraph05 from "./Graph05";

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1439px) {
    width: 100%;
    min-width: 360px;
    flex-direction: column;
  }
`;

const RowDiv = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 1328px;
  margin: 0 auto;

  @media screen and (max-width: 1439px) {
    flex-direction: column;
    width: calc(100% - 40px);
    padding: 0 20px;
    align-items: flex-start;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 72px;
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  color: ${Black};
  white-space: pre-wrap;
  line-height: 51px;

  @media screen and (max-width: 1439px) {
    font-size: 26px;
    line-height: 36px;
  }
`;

const Image = styled.img`
  width: 1328px, //100%;;
  margin-top: 40px;
`;

const List = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 16px 0;
  border-top: 1px solid ${Gray300};
`;

const ListLabel = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Green600};
`;

const ListTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Black};
  margin-bottom: 16px;
`;

const ListContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  white-space: pre-wrap;
`;

const PointBox = styled.div`
  width: 100%;
  padding: 48px 0;
  margin-top: 56px;

  @media screen and (max-width: 1439px) {
    margin-top: 0;
  }
`;

const KindListUl = styled.ul`
  padding-left: 32px;
  margin: 0;
`;

const KindListLi = styled.li`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
`;

const GraphBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 80px);
  height: calc(247px - 90px);
  padding: 45px 40px;
  background-color: ${White};
  border-radius: 10px;

  @media screen and (max-width: 1439px) {
    width: calc(100% - 48px);
    height: auto;
    padding: 24px;
  }
`;

const GraphTitle = styled.div`
  position: absolute;
  left: 40px;
  top: 45px;
  font-weight: 600;
  font-size: 24px;
  font-family: "Nanum Myeongjo";
  line-height: 34px;

  @media screen and (max-width: 1439px) {
    top: 24px;
    left: 24px;
  }
`;

const Text = styled.div`
  margin-top: 24px;
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Green600};
`;

const ScalingRepotting = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <BasicContainer metaTitle="관유정-스케일링 및 분갈이 검진">
      <Content>
        <PageTop
          label="의료지원"
          title="스케일링 및 분갈이 검진"
          ImageUrl="/assets/image/MedicalHeaderImg.png"
        />
        <RowDiv>
          <TitleBox
            style={{ marginTop: windowSize.innerWidth <= 1439 ? 32 : 56 }}
          >
            <TitleLabel>의료지원</TitleLabel>
            <TitleText>스케일링 및 분갈이 검진</TitleText>
          </TitleBox>
        </RowDiv>
        <RowDiv style={{ marginTop: 42 }}>
          <List>
            <ListLabel>01</ListLabel>
            <ListTitle>자가 검진의 필요성</ListTitle>
            <ListContent>
              <KindListUl>
                <KindListLi>
                  난초는 이상이 있을 시 전조 증상이 보인다. 이 때 관리자가 미리
                  발견하면 큰 병으로 갈 것들을 미연에 방지할 수 있다.
                </KindListLi>
                <KindListLi>
                  질병과 못지않게 생리장해가 극성을 피우는 시절이다. 생리장해는
                  약도 없다. 조기 발견으로 대응하는 길 밖에는 없다.
                </KindListLi>
                <KindListLi>
                  잎, 뿌리, 벌브, 액아, 신아, 신촉을 살펴 건강하게 자랄 수
                  있도록 자가 검진을 생활화해야 한다.
                </KindListLi>
                <KindListLi>
                  자가 검진만 철저히 해도 병에 걸리지 않는 건강한 1등급 난초로
                  키울 수 있다.
                </KindListLi>
              </KindListUl>
            </ListContent>
          </List>
        </RowDiv>
        <RowDiv
          style={
            windowSize.innerWidth <= 1439
              ? {
                  width: "100%",
                  paddingLeft: 0,
                  paddingRight: 0,
                  overflowX: "auto",
                  marginTop: 40,
                }
              : null
          }
        >
          <Image src="/assets/image/virus_img.png" />
        </RowDiv>
        <PointBox
          style={{
            backgroundColor: Green100,
            position: "relative",
          }}
        >
          <RowDiv style={{ borderTop: `1px solid ${Gray300}` }}>
            <List style={{ borderTop: 0, marginLeft: 0 }}>
              <ListLabel>02</ListLabel>
              <ListTitle>자가 검진의 종류</ListTitle>
              <ListContent>
                <GraphBox>
                  <GraphTitle>잎 검진 (6~10월)</GraphTitle>
                  <ScalingRepottingGraph01 />
                </GraphBox>
                <GraphBox style={{ marginTop: 24 }}>
                  <GraphTitle>뿌리 검진 (6~10월)</GraphTitle>
                  <ScalingRepottingGraph02 />
                </GraphBox>
                <GraphBox style={{ marginTop: 24 }}>
                  <GraphTitle>벌브 검진 (7~4월)</GraphTitle>
                  <ScalingRepottingGraph03 />
                </GraphBox>
                <GraphBox style={{ marginTop: 24 }}>
                  <GraphTitle>신아 검진 (2~4월)</GraphTitle>
                  <ScalingRepottingGraph04 />
                </GraphBox>
                <GraphBox style={{ marginTop: 24 }}>
                  <GraphTitle>{`신촉 검진 (4~6월)
스케일링`}</GraphTitle>
                  <ScalingRepottingGraph05 />
                </GraphBox>
                <Text>
                  * 이상이 감지되면 1차 진료 기관인 지역 유명 난원이나 이대발
                  난클리닉센터로 즉시 문의 바랍니다.
                </Text>
              </ListContent>
            </List>
          </RowDiv>
        </PointBox>
      </Content>
    </BasicContainer>
  );
};

export default ScalingRepotting;

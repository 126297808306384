import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BasicContainer from "../../../components/BasicContainer";
import PageTop from "../../../components/PageTop";
import {
  Black,
  Gray200,
  Gray300,
  Gray500,
  Green100,
  Green300,
  Green600,
} from "../../../config/color";

import { useNavigate } from "react-router-dom";
import { getWindowSize } from "../../../utils";

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1439px) {
    min-width: 360px;
    width: 100%;
  }
`;

const RowDiv = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  width: 1328px;
  margin: 0 auto;

  @media screen and (max-width: 1439px) {
    width: calc(100% - 40px);
    padding: 0 20px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 72px;

  @media screen and (max-width: 1439px) {
    margin-top: 32px;
  }
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  color: ${Black};
  white-space: pre-wrap;
  line-height: 51px;

  @media screen and (max-width: 1439px) {
    font-size: 26px;
    line-height: 36px;
  }
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 28.8px;
  white-space: pre-wrap;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 1328px;
  margin: 0 auto;
  margin-top: 40px;
  padding: 16px 0;
  border-top: 1px solid ${Gray300};

  @media screen and (max-width: 1439px) {
    width: calc(100% - 40px);
    margin: 0 20px;
    margin-top: 32px;
  }
`;

const ListLabel = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Green600};
`;

const ListTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Black};
  margin-bottom: 16px;
`;

const ListContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  white-space: pre-wrap;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 1328px;
  margin: 0 auto;
`;

const TableText = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Gray500};
`;

const TableCell = styled(TableText)`
  height: calc(50px - 2px);
  border: 1px solid ${Gray200};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const TableColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableLabel = styled(TableCell)`
  width: calc(265px - 2px);
  background-color: ${Green100};
`;

const TableRowCategory = styled(TableText)`
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: ${Green300};
  width: calc(100% - 2px);
  height: calc(50px - 2px);
  border: 1px solid ${Gray200};
`;

const MobileTableRow = styled.div`
  @media screen and (max-width: 1439px) {
    width: calc(100% - 40px);
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    margin-bottom: 88px;
  }
`;

const QualityGrade = (props) => {
  const navigate = useNavigate();

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <BasicContainer metaTitle="관유정-품질등급기준">
      <Content>
        <PageTop
          label="기술지원"
          title="품질 등급 기준"
          ImageUrl="/assets/image/TechnicalHeaderImg.png"
        />
        <RowDiv>
          <TitleBox>
            <TitleLabel>기술지원</TitleLabel>
            <TitleText>옵션별 계급체계</TitleText>
          </TitleBox>
        </RowDiv>
        <List>
          <ListLabel>01</ListLabel>
          <ListTitle>원예</ListTitle>
          <ListContent>잎 끝은 뾰족한 것에서 둥글수록 좋다</ListContent>
        </List>
        <MobileTableRow>
          <Table
            style={{ marginBottom: windowSize.innerWidth <= 1439 ? 0 : 88 }}
          >
            <TableRow>
              <TableLabel>잎 계급</TableLabel>
              <TableCell style={{ width: 266 - 2 }}>보통</TableCell>
              <TableCell style={{ width: 265 - 2 }}>원만</TableCell>
              <TableCell style={{ width: 266 - 2 }}>우수</TableCell>
              <TableCell style={{ width: 265 - 2 }}>예</TableCell>
            </TableRow>
            <TableRow>
              <TableLabel>잎 끝</TableLabel>
              <TableCell style={{ width: 266 - 2 }}>뾰족</TableCell>
              <TableCell style={{ width: 265 - 2 }}>원만</TableCell>
              <TableCell style={{ width: 266 - 2 }}>둥글</TableCell>
              <TableCell style={{ width: 265 - 2 }}>환엽</TableCell>
            </TableRow>
            <TableRow>
              <TableLabel>길이</TableLabel>
              <TableCell style={{ width: 266 - 2 }}>김</TableCell>
              <TableCell style={{ width: 265 - 2 }}>원만</TableCell>
              <TableCell style={{ width: 266 - 2 }}>짧음</TableCell>
              <TableCell style={{ width: 265 - 2 }}>단엽</TableCell>
            </TableRow>
            <TableRow>
              <TableLabel>폭</TableLabel>
              <TableCell style={{ width: 266 - 2 }}>좁음</TableCell>
              <TableCell style={{ width: 265 - 2 }}>원만</TableCell>
              <TableCell style={{ width: 266 - 2 }}>넓음</TableCell>
              <TableCell style={{ width: 265 - 2 }}>-</TableCell>
            </TableRow>
            <TableRow>
              <TableLabel>두께</TableLabel>
              <TableCell style={{ width: 266 - 2 }}>얇음</TableCell>
              <TableCell style={{ width: 265 - 2 }}>원만</TableCell>
              <TableCell style={{ width: 266 - 2 }}>후육</TableCell>
              <TableCell style={{ width: 265 - 2 }}>-</TableCell>
            </TableRow>
            <TableRow>
              <TableLabel>색상</TableLabel>
              <TableCell style={{ width: 266 - 2 }}>연초록</TableCell>
              <TableCell style={{ width: 265 - 2 }}>원만</TableCell>
              <TableCell style={{ width: 266 - 2 }}>진초록</TableCell>
              <TableCell style={{ width: 265 - 2 }}>-</TableCell>
            </TableRow>
            <TableRow>
              <TableLabel>휨 성</TableLabel>
              <TableCell style={{ width: 266 - 2 }}>꼬이고 늘어짐</TableCell>
              <TableCell style={{ width: 265 - 2 }}>원만</TableCell>
              <TableCell style={{ width: 266 - 2 }}>중수엽</TableCell>
              <TableCell style={{ width: 265 - 2 }}>-</TableCell>
            </TableRow>
          </Table>
        </MobileTableRow>
        <List>
          <ListLabel>02</ListLabel>
          <ListTitle>화예</ListTitle>
        </List>
        <MobileTableRow>
          <Table
            style={{ marginBottom: windowSize.innerWidth <= 1439 ? 0 : 88 }}
          >
            <TableRow>
              <TableLabel>꽃 계급</TableLabel>
              <TableCell style={{ width: 266 - 2 }}>보통</TableCell>
              <TableCell style={{ width: 265 - 2 }}>원만</TableCell>
              <TableCell style={{ width: 266 - 2 }}>우수</TableCell>
              <TableCell style={{ width: 265 - 2 }}>예</TableCell>
            </TableRow>
            <TableRowCategory>일반 외형</TableRowCategory>
            <TableRow>
              <TableLabel>화면 형태</TableLabel>
              <TableCell style={{ width: 266 - 2 }}>뾰족</TableCell>
              <TableCell style={{ width: 265 - 2 }}>원만(수선화)</TableCell>
              <TableCell style={{ width: 266 - 2 }}>둥글(매화)</TableCell>
              <TableCell style={{ width: 265 - 2 }}>원판</TableCell>
            </TableRow>
            <TableRow>
              <TableLabel>꽃 크기</TableLabel>
              <TableCell style={{ width: 266 - 2 }}>소륜</TableCell>
              <TableCell style={{ width: 265 - 2 }}>원만</TableCell>
              <TableCell style={{ width: 266 - 2 }}>대륜</TableCell>
              <TableCell style={{ width: 265 - 2 }}>-</TableCell>
            </TableRow>
            <TableRow>
              <TableLabel>휨 성</TableLabel>
              <TableCell style={{ width: 266 - 2 }}>뒤집힘</TableCell>
              <TableCell style={{ width: 265 - 2 }}>평평</TableCell>
              <TableCell style={{ width: 266 - 2 }}>안아피기</TableCell>
              <TableCell style={{ width: 265 - 2 }}>-</TableCell>
            </TableRow>
            <TableRow>
              <TableLabel>색상</TableLabel>
              <TableCell style={{ width: 266 - 2 }}>연초록</TableCell>
              <TableCell style={{ width: 265 - 2 }}>원만</TableCell>
              <TableCell style={{ width: 266 - 2 }}>진초록</TableCell>
              <TableCell style={{ width: 265 - 2 }}>-</TableCell>
            </TableRow>
            <TableRow>
              <TableLabel>두께</TableLabel>
              <TableCell style={{ width: 266 - 2 }}>얇음</TableCell>
              <TableCell style={{ width: 265 - 2 }}>원만</TableCell>
              <TableCell style={{ width: 266 - 2 }}>후육</TableCell>
              <TableCell style={{ width: 265 - 2 }}>-</TableCell>
            </TableRow>
            <TableRow>
              <TableLabel>꽃잎 끝</TableLabel>
              <TableCell style={{ width: 266 - 2 }}>뾰족</TableCell>
              <TableCell style={{ width: 265 - 2 }}>원만</TableCell>
              <TableCell style={{ width: 266 - 2 }}>둥글</TableCell>
              <TableCell style={{ width: 265 - 2 }}>-</TableCell>
            </TableRow>
            <TableRowCategory>사 가지(관상)</TableRowCategory>
            <TableRow>
              <TableLabel>봉심</TableLabel>
              <TableCell style={{ width: 266 - 2 }}>벌어집</TableCell>
              <TableCell style={{ width: 265 - 2 }}>원만</TableCell>
              <TableCell style={{ width: 266 - 2 }}>합배</TableCell>
              <TableCell style={{ width: 265 - 2 }}>-</TableCell>
            </TableRow>
            <TableRow>
              <TableLabel>꽃 화근</TableLabel>
              <TableCell style={{ width: 266 - 2 }}>많음</TableCell>
              <TableCell style={{ width: 265 - 2 }}>원만</TableCell>
              <TableCell style={{ width: 266 - 2 }}>없음</TableCell>
              <TableCell style={{ width: 265 - 2 }}>-</TableCell>
            </TableRow>
            <TableRow>
              <TableLabel>화경 화근</TableLabel>
              <TableCell style={{ width: 266 - 2 }}>많음</TableCell>
              <TableCell style={{ width: 265 - 2 }}>원만</TableCell>
              <TableCell style={{ width: 266 - 2 }}>없음</TableCell>
              <TableCell style={{ width: 265 - 2 }}>-</TableCell>
            </TableRow>
            <TableRow>
              <TableLabel>립스틱 색상</TableLabel>
              <TableCell style={{ width: 266 - 2 }}>보라</TableCell>
              <TableCell style={{ width: 265 - 2 }}>원만</TableCell>
              <TableCell style={{ width: 266 - 2 }}>빨강</TableCell>
              <TableCell style={{ width: 265 - 2 }}>-</TableCell>
            </TableRow>
            <TableRow>
              <TableLabel>립스틱 형태</TableLabel>
              <TableCell style={{ width: 266 - 2 }}>점박이</TableCell>
              <TableCell style={{ width: 265 - 2 }}>원만</TableCell>
              <TableCell style={{ width: 266 - 2 }}>VU Ⅱ</TableCell>
              <TableCell style={{ width: 265 - 2 }}>-</TableCell>
            </TableRow>
            <TableRow>
              <TableLabel>립스틱 농담</TableLabel>
              <TableCell style={{ width: 266 - 2 }}>흐릿</TableCell>
              <TableCell style={{ width: 265 - 2 }}>원만</TableCell>
              <TableCell style={{ width: 266 - 2 }}>진함</TableCell>
              <TableCell style={{ width: 265 - 2 }}>-</TableCell>
            </TableRow>
            <TableRow>
              <TableLabel>내외삼판비율</TableLabel>
              <TableCell style={{ width: 266 - 2 }}>너무 크거나 작음</TableCell>
              <TableCell style={{ width: 265 - 2 }}>원만</TableCell>
              <TableCell style={{ width: 266 - 2 }}>적합</TableCell>
              <TableCell style={{ width: 265 - 2 }}>-</TableCell>
            </TableRow>
            <TableRowCategory>기타</TableRowCategory>
            <TableRow>
              <TableLabel>어깨 각</TableLabel>
              <TableCell style={{ width: 266 - 2 }}>많이 처짐</TableCell>
              <TableCell style={{ width: 265 - 2 }}>조금 처짐</TableCell>
              <TableCell style={{ width: 266 - 2 }}>평견</TableCell>
              <TableCell style={{ width: 265 - 2 }}>-</TableCell>
            </TableRow>
            <TableRow>
              <TableLabel>머리숙임</TableLabel>
              <TableCell style={{ width: 266 - 2 }}>많이 숙임</TableCell>
              <TableCell style={{ width: 265 - 2 }}>원만</TableCell>
              <TableCell style={{ width: 266 - 2 }}>정면 피기</TableCell>
              <TableCell style={{ width: 265 - 2 }}>-</TableCell>
            </TableRow>
            <TableRow>
              <TableLabel>향기</TableLabel>
              <TableCell style={{ width: 266 - 2 }}>옅음</TableCell>
              <TableCell style={{ width: 265 - 2 }}>원만</TableCell>
              <TableCell style={{ width: 266 - 2 }}>진함</TableCell>
              <TableCell style={{ width: 265 - 2 }}>-</TableCell>
            </TableRow>
            <TableRow>
              <TableLabel>화경 굵기</TableLabel>
              <TableCell style={{ width: 266 - 2 }}>가늠</TableCell>
              <TableCell style={{ width: 265 - 2 }}>원만</TableCell>
              <TableCell style={{ width: 266 - 2 }}>튼실함</TableCell>
              <TableCell style={{ width: 265 - 2 }}>-</TableCell>
            </TableRow>
          </Table>
        </MobileTableRow>
        <List>
          <ListLabel>03</ListLabel>
          <ListTitle>무늬 색의 계급</ListTitle>
        </List>
        <MobileTableRow>
          <Table style={{ marginBottom: 40 }}>
            <TableRow>
              <TableLabel>잎 무늬 계급</TableLabel>
              <TableCell style={{ width: 266 - 2 }}>보통</TableCell>
              <TableCell style={{ width: 265 - 2 }}>원만</TableCell>
              <TableCell style={{ width: 266 - 2 }}>우수</TableCell>
              <TableCell style={{ width: 265 - 2 }}>매우 우수</TableCell>
            </TableRow>
            <TableRow>
              <TableLabel style={{ height: 100 - 2 }}>색상</TableLabel>
              <TableColumn>
                <TableRow>
                  <TableCell style={{ width: 266 - 2 }}>녹색+황색</TableCell>
                  <TableCell style={{ width: 265 - 2 }}>바나나색</TableCell>
                  <TableCell style={{ width: 266 - 2 }}>황색</TableCell>
                  <TableCell style={{ width: 265 - 2 }}>진한+황색</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: 266 - 2 }}>녹색+백색</TableCell>
                  <TableCell style={{ width: 265 - 2 }}>우유색</TableCell>
                  <TableCell style={{ width: 266 - 2 }}>백색</TableCell>
                  <TableCell style={{ width: 265 - 2 }}>흰눈색+백색</TableCell>
                </TableRow>
              </TableColumn>
            </TableRow>
            <TableRow>
              <TableLabel>색상 발현</TableLabel>
              <TableCell style={{ width: 266 - 2 }}>소멸</TableCell>
              <TableCell style={{ width: 265 - 2 }}>후발</TableCell>
              <TableCell style={{ width: 266 - 2 }}>선천</TableCell>
              <TableCell style={{ width: 265 - 2 }}>탈색 없음</TableCell>
            </TableRow>
            <TableRow>
              <TableLabel>무늬 두께</TableLabel>
              <TableCell style={{ width: 266 - 2 }}>얇음</TableCell>
              <TableCell style={{ width: 265 - 2 }}>보통</TableCell>
              <TableCell style={{ width: 266 - 2 }}>두터움</TableCell>
              <TableCell style={{ width: 265 - 2 }}>아주 두터움</TableCell>
            </TableRow>
            <TableRow>
              <TableLabel>무늬 깊이</TableLabel>
              <TableCell style={{ width: 266 - 2 }}>얕음</TableCell>
              <TableCell style={{ width: 265 - 2 }}>보통</TableCell>
              <TableCell style={{ width: 266 - 2 }}>깊음</TableCell>
              <TableCell style={{ width: 265 - 2 }}>아주 깊음</TableCell>
            </TableRow>
            <TableRow>
              <TableLabel>녹색과 대비</TableLabel>
              <TableCell style={{ width: 266 - 2 }}>보통</TableCell>
              <TableCell style={{ width: 265 - 2 }}>원만</TableCell>
              <TableCell style={{ width: 266 - 2 }}>선명</TableCell>
              <TableCell style={{ width: 265 - 2 }}>아주 선명</TableCell>
            </TableRow>
          </Table>
          <Table
            style={{ marginBottom: windowSize.innerWidth <= 1439 ? 0 : 147 }}
          >
            <TableRow>
              <TableLabel>꽃무늬 계급</TableLabel>
              <TableCell style={{ width: 266 - 2 }}>보통</TableCell>
              <TableCell style={{ width: 265 - 2 }}>원만</TableCell>
              <TableCell style={{ width: 266 - 2 }}>우수</TableCell>
              <TableCell style={{ width: 265 - 2 }}>매우 우수</TableCell>
            </TableRow>
            <TableRow>
              <TableLabel>색상 계열</TableLabel>
              <TableCell style={{ width: 266 - 2 }}>자색</TableCell>
              <TableCell style={{ width: 265 - 2 }}>주금색</TableCell>
              <TableCell style={{ width: 266 - 2 }}>황색</TableCell>
              <TableCell style={{ width: 265 - 2 }}>홍색</TableCell>
            </TableRow>
            <TableRow>
              <TableLabel>색상 농담</TableLabel>
              <TableCell style={{ width: 266 - 2 }}>묽어짐</TableCell>
              <TableCell style={{ width: 265 - 2 }}>옅음</TableCell>
              <TableCell style={{ width: 266 - 2 }}>보통</TableCell>
              <TableCell style={{ width: 265 - 2 }}>진함</TableCell>
            </TableRow>
            <TableRow>
              <TableLabel>무늬 두께 </TableLabel>
              <TableCell style={{ width: 266 - 2 }}>얇음</TableCell>
              <TableCell style={{ width: 265 - 2 }}>보통</TableCell>
              <TableCell style={{ width: 266 - 2 }}>두터움</TableCell>
              <TableCell style={{ width: 265 - 2 }}>아주 두터움</TableCell>
            </TableRow>
            <TableRow>
              <TableLabel>무늬 깊이</TableLabel>
              <TableCell style={{ width: 266 - 2 }}>얇음</TableCell>
              <TableCell style={{ width: 265 - 2 }}>보통</TableCell>
              <TableCell style={{ width: 266 - 2 }}>깊음</TableCell>
              <TableCell style={{ width: 265 - 2 }}>아주 깊음</TableCell>
            </TableRow>
            <TableRow>
              <TableLabel>녹색과 대비</TableLabel>
              <TableCell style={{ width: 266 - 2 }}>보통</TableCell>
              <TableCell style={{ width: 265 - 2 }}>원만</TableCell>
              <TableCell style={{ width: 266 - 2 }}>선명</TableCell>
              <TableCell style={{ width: 265 - 2 }}>아주 선명</TableCell>
            </TableRow>
          </Table>
        </MobileTableRow>
      </Content>
    </BasicContainer>
  );
};

export default QualityGrade;

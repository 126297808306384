import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";

import { Gray600, White } from "../config/color";

import { MobileMaxWidth, MobileMinWidth } from "../config/layout";

const Close = require("../assets/icon/Close16.png");

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

// const fadeOut = keyframes`
//   0% {
//     opacity: 1;
//   }
//   100% {
//     opacity: 0;
//   }
// `;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`;

const PopupBackground = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
`;

const PopupContainer = styled.div`
  width: 320px;
  border-radius: 10px;
  background-color: ${White};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const PopupClose = styled.img`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 16px;
  height: 16px;
  &:hover {
    cursor: pointer;
  }
`;

const PopupTitle = styled.div`
  margin-top: 40px;
  width: 256px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
`;

const PopupContent = styled.div`
  width: 256px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  white-space: pre-wrap;
`;

const PopupButton = styled.div`
  width: 272px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 24px;
  background-color: ${Gray600};
  color: ${White};
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  &:hover {
    cursor: pointer;
  }
`;

const ToastBackground = styled.div`
  position: absolute;
  bottom: 32px;
  width: 100vw;
  display: flex;
  justify-content: center;
`;

const ToastContainer = styled.div`
  width: 640px;
  border-radius: 5px;
  height: 40px;
  background-color: ${Gray600};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
  color: ${White};
  animation: ${fadeIn} 0.5s ease-out;
  @media screen and (min-width: ${MobileMinWidth}) and (max-width: ${MobileMaxWidth}) {
    width: 320px;
  }
`;

export const Popup = (props) => {
  const { title, content, buttonText, buttonEvent, closeEvent } = props;
  return (
    <Overlay>
      <PopupBackground>
        <PopupContainer>
          <PopupClose src={Close} onClick={closeEvent} />
          <PopupTitle>{title}</PopupTitle>
          <PopupContent>{content}</PopupContent>
          <PopupButton onClick={buttonEvent}>{buttonText}</PopupButton>
        </PopupContainer>
      </PopupBackground>
    </Overlay>
  );
};

export const Toast = (props) => {
  const { text, openToast, setOpenToast, timer } = props;

  useEffect(() => {
    if (openToast && timer > 0) {
      setTimeout(() => {
        setOpenToast(false);
      }, timer);
    }
  }, [openToast, setOpenToast, timer]);

  return (
    <Overlay>
      <ToastBackground>
        <ToastContainer>{text}</ToastContainer>
      </ToastBackground>
    </Overlay>
  );
};

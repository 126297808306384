import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Black, Green600 } from "../../../config/color";
import { getWindowSize } from "../../../utils";

const Certificate = require("../../../assets/icon/Certificate64.png");
const VoucherJoin = require("../../../assets/icon/VoucherJoin64.png");
const SeedContract = require("../../../assets/icon/SeedContract64.png");
const Calculate = require("../../../assets/icon/Calculate64.png");

const Container = styled.div`
  width: 561px;
  margin: 0 auto;
  margin-top: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  @media screen and (max-width: 1439px) {
    flex-direction: column;
    width: 257px;
    margin-top: 0;
  }
`;

const Box = styled.div`
  width: 104px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BoxRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 257px;
  margin-top: 32px;
`;

const BoxStep = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  text-align: center;
  color: ${Green600};
  margin-bottom: 8px;

  @media screen and (max-width: 1439px) {
    height: 29px;
  }
`;

const Circle = styled.div`
  width: 104px;
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 52px;
  background-color: ${Green600};
`;

const CircleImg = styled.img`
  width: 64px;
  height: 64px;
`;

const DashLine = styled.div`
  width: 48px;
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DashLineImg = styled.div`
  border-top: 2px dashed ${Green600};
  width: 48px;
`;

const BoxTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  margin-top: 8px;
`;

const VoucherGraph1 = (props) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Container>
      {windowSize.innerWidth <= 1439 ? (
        <BoxRow>
          <Box>
            <BoxStep>STEP 1</BoxStep>
            <Circle>
              <CircleImg src={Certificate} />
            </Circle>
            <BoxTitle>2급 자격 취득</BoxTitle>
          </Box>
          <Box style={{ width: 135 }}>
            <BoxStep></BoxStep>
            <DashLine>
              <DashLineImg />
            </DashLine>
            <BoxTitle></BoxTitle>
          </Box>
          <Box style={{ width: 105 }}>
            <BoxStep>STEP 2</BoxStep>
            <Circle>
              <CircleImg src={VoucherJoin} />
            </Circle>
            <BoxTitle>안심바우처 가입</BoxTitle>
          </Box>
        </BoxRow>
      ) : (
        <>
          <Box>
            <BoxStep>STEP 1</BoxStep>
            <Circle>
              <CircleImg src={Certificate} />
            </Circle>
            <BoxTitle>2급 자격 취득</BoxTitle>
          </Box>
          <Box style={{ width: 135 }}>
            <BoxStep></BoxStep>
            <DashLine>
              <DashLineImg />
            </DashLine>
            <BoxTitle></BoxTitle>
          </Box>
          <Box style={{ width: 105 }}>
            <BoxStep>STEP 2</BoxStep>
            <Circle>
              <CircleImg src={VoucherJoin} />
            </Circle>
            <BoxTitle>안심바우처 가입</BoxTitle>
          </Box>
        </>
      )}
      {windowSize.innerWidth <= 1439 ? null : (
        <Box style={{ width: 135 }}>
          <BoxStep></BoxStep>
          <DashLine>
            <DashLineImg />
          </DashLine>
          <BoxTitle></BoxTitle>
        </Box>
      )}
      {windowSize.innerWidth <= 1439 ? (
        <BoxRow>
          <Box>
            <BoxStep>STEP 3</BoxStep>
            <Circle>
              <CircleImg src={SeedContract} />
            </Circle>
            <BoxTitle>종자공급시 계약</BoxTitle>
          </Box>
          <Box style={{ width: 135 }}>
            <BoxStep></BoxStep>
            <DashLine>
              <DashLineImg />
            </DashLine>
            <BoxTitle></BoxTitle>
          </Box>
          <Box style={{ width: 105 }}>
            <BoxStep>STEP 4</BoxStep>
            <Circle>
              <CircleImg src={Calculate} />
            </Circle>
            <BoxTitle>계약만료시 정산</BoxTitle>
          </Box>
        </BoxRow>
      ) : (
        <>
          <Box>
            <BoxStep>STEP 3</BoxStep>
            <Circle>
              <CircleImg src={SeedContract} />
            </Circle>
            <BoxTitle>종자공급시 계약</BoxTitle>
          </Box>
          <Box style={{ width: 135 }}>
            <BoxStep></BoxStep>
            <DashLine>
              <DashLineImg />
            </DashLine>
            <BoxTitle></BoxTitle>
          </Box>
          <Box style={{ width: 105 }}>
            <BoxStep>STEP 4</BoxStep>
            <Circle>
              <CircleImg src={Calculate} />
            </Circle>
            <BoxTitle>계약만료시 정산</BoxTitle>
          </Box>
        </>
      )}
    </Container>
  );
};

export default VoucherGraph1;

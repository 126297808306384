import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import BasicContainer from "../../../components/BasicContainer";
import PageTop from "../../../components/PageTop";
import { TAB } from "../../../components/Tab";
import {
  Black,
  Gray100,
  Gray300,
  Gray400,
  Gray500,
  Green600,
  White,
} from "../../../config/color";
import ConsignmentTable from "./SpeciesCenterTable";
import ControlTable from "./ControlTable";
import LightManual from "./LightManual";
import MagampKManual from "./MagampK";
import TempManual from "./TempManual";
import WaterManual from "./WaterManual";
import SpeciesCenterGraph from "./Graph";
import { getWindowSize } from "../../../utils";

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1439px) {
    min-width: 360px;
    width: 100%;
  }
`;

const RowDiv = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  width: 1328px;
  margin: 0 auto;

  @media screen and (max-width: 1439px) {
    width: calc(100% - 40px);
    padding: 0 20px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 72px;
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  color: ${Black};
  white-space: pre-wrap;
  line-height: 51px;

  @media screen and (max-width: 1439px) {
    font-size: 26px;
    line-height: 36px;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 1328px;
  margin: 0 auto;
  margin-top: 40px;
  padding: 16px 0;
  border-top: 1px solid ${Gray300};

  @media screen and (max-width: 1439px) {
    width: calc(100% - 40px);
    margin: 0 20px;
    margin-top: 32px;
  }
`;

const ListLabel = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Green600};
`;

const ListTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Black};
  margin-bottom: 16px;
`;

const ListSubtitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  margin-left: 16px;
`;

const ListContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  white-space: pre-wrap;
`;

const PointBox = styled.div`
  width: 100%;
  padding: 48px 0;
  margin-top: 40px;
`;

const ListContentRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 16px;
`;

const ListNumber = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: ${White};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Green600};
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  margin-right: 8px;
  margin-top: 2.5px;
`;

const InfoBox = styled.div`
  background-color: ${Gray100};
  width: calc(100% - 48px);
  padding: 18px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: 700;
  line-height: 28.8px;
  color: ${Gray500};
  border-radius: 10px;
  margin-bottom: 40px;
`;

const InfoList = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${Gray400};
`;

const InfoLabel = styled.div`
  width: 60px;
  min-width: 60px;
  margin-right: 24px;
  text-align: left;
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
`;

const Info = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  white-space: pre-wrap;
  word-break: keep-all;
`;

const KindListUl = styled.ul`
  padding-left: 32px;
  margin: 0;
`;

const KindListLi = styled.li`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
`;

const TableContainer = styled.div`
  width: calc(100% - 40px);
  margin: 0 20px;
  overflow-x: auto;
`;

const TableContainer2 = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const SpeciesCenter = (props) => {
  const navigate = useNavigate();

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const [selectedTab, setSelectedTab] = useState(2);

  useEffect(() => {
    if (selectedTab === 0) {
      navigate("/farm/voucher");
    } else if (selectedTab === 1) {
      navigate("/farm/consignmentcare");
    }
  }, [selectedTab]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <BasicContainer metaTitle="관유정-관유정만의 특별한 제도">
      <Content>
        <PageTop
          label="영농지원"
          title="관유정만의 특별한 제도"
          ImageUrl="/assets/image/FarmHeaderImg.png"
        />
        <TAB
          tabList={[
            { name: "안심바우처" },
            { name: "위탁오더 대리돌봄" },
            { name: "종 보존 센터" },
          ]}
          viewStyle={{
            marginTop: 32,
            marginBottom: windowSize.innerWidth <= 1439 ? 32 : 42,
          }}
          tabStyle={{
            width:
              windowSize.innerWidth <= 1439
                ? windowSize.innerWidth / 3 - 18
                : 152,
            paddingTop: 0,
            paddingBottom: 9,
            paddingLeft: windowSize.innerWidth <= 767 ? 8 : 26,
            paddingRight: windowSize.innerWidth <= 767 ? 8 : 26,
          }}
          activeTabIdx={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <RowDiv>
          <TitleBox
            style={{ marginTop: windowSize.innerWidth <= 1439 ? 32 : 56 }}
          >
            <TitleLabel>영농지원</TitleLabel>
            <TitleText>{`관유정만의 특별한 제도들,
종 보존 센터`}</TitleText>
          </TitleBox>
        </RowDiv>
        <List>
          <ListLabel>01</ListLabel>
          <ListTitle>종 보존 센터란?</ListTitle>
          <ListContent>
            난실이 없거나 시설이 미비해 자경할 수 없는 분들과 재배실력이 부족한
            분들이 소유하고 있는 우량종자를 지켜내기 위해 온라인으로 명령을
            받아서 부탁한대로 길러주는 사업
          </ListContent>
          <ListContent>
            <KindListUl>
              <KindListLi>
                앱으로 월별 신아 성장을 사진으로 업로드합니다.
              </KindListLi>
              <KindListLi>
                매년 수익을 그래프로 남겨 관심 자 유입에 적극 활용
              </KindListLi>
              <KindListLi>재배 오더는 앱으로 간편하게 지시합니다.</KindListLi>
              <KindListLi>
                매주 한 번은 난초를 직접 돌보는 걸 원칙으로 합니다.
              </KindListLi>
              <KindListLi>
                분갈이 시에는 반드시 주인이 방문해 관유정 측과 함께하여야 함
              </KindListLi>
              <KindListLi>
                계약 해지 요청이 있을 시에는 한달 안으로 난초를 옮겨 가야 함
              </KindListLi>
              <KindListLi>가을 난 위탁 출하 시 판매가의 20% 수수료</KindListLi>
            </KindListUl>
          </ListContent>
        </List>
        <RowDiv>
          <InfoBox>
            <InfoList>
              <InfoLabel>기획의도</InfoLabel>
              <Info>아끼는(애장) 종자를 멸종시키지 않게 하기 위함</Info>
            </InfoList>
            <InfoList>
              <InfoLabel>장점</InfoLabel>
              <Info>위탁 오더 대리 돌봄으로 생산함으로 안정성이 높음</Info>
            </InfoList>
            <InfoList>
              <InfoLabel>프로세스</InfoLabel>
              <Info>
                안심바우처 &gt; 계약서 작성 &gt; 난실 거취 &gt; 오더돌봄{" "}
              </Info>
            </InfoList>
            <InfoList>
              <InfoLabel>기대효과</InfoLabel>
              <Info>명 품종이거나 유명 품종의 유실을 줄이게 됨</Info>
            </InfoList>
          </InfoBox>
        </RowDiv>
        <SpeciesCenterGraph />
        <PointBox
          style={{
            backgroundColor: Green600,
            marginTop: windowSize.innerWidth <= 1439 ? 0 : 40,
          }}
        >
          <List
            style={{
              borderTop: windowSize.innerWidth <= 1439 ? 0 : 1,
              marginTop: windowSize.innerWidth <= 1439 ? 0 : 40,
            }}
          >
            <ListLabel style={{ color: White }}>02</ListLabel>
            <ListTitle style={{ color: White }}>
              종 보존 센터의 기대효과
            </ListTitle>
            <ListContent>
              <ListContentRow style={{ color: White }}>
                <ListNumber>1</ListNumber>
                우수한 신품종의 절종 줄임
              </ListContentRow>
              <ListContentRow style={{ color: White }}>
                <ListNumber>2</ListNumber>
                난실이 없어도 부업을 할 수 있음
              </ListContentRow>
              <ListContentRow style={{ color: White }}>
                <ListNumber>3</ListNumber>
                난실 지을 돈으로 종자를 들일 수 있음
              </ListContentRow>
              <ListContentRow style={{ color: White }}>
                <ListNumber>4</ListNumber>
                초보자도 우수한 전략품종에 도전할 수 있음
              </ListContentRow>
              <ListContentRow style={{ color: White }}>
                <ListNumber>5</ListNumber>
                시설 미비의 자경보다는 더 안전함
              </ListContentRow>
            </ListContent>
          </List>
        </PointBox>
        <List>
          <ListLabel>03</ListLabel>
          <ListTitle>분쟁방지</ListTitle>
          <ListContent>
            <KindListUl>
              <KindListLi>계약서를 철저히 작성</KindListLi>
              <KindListLi>관리 이력을 철저히</KindListLi>
              <KindListLi>작황 이력 철저히</KindListLi>
              <KindListLi>수익 이력 철저히</KindListLi>
            </KindListUl>
          </ListContent>
        </List>
        <List>
          <ListLabel>04</ListLabel>
          <ListTitle>주의사항</ListTitle>
          <ListContent>
            <KindListUl>
              <KindListLi>
                본 제도는 난초 주인이 직접 길러야 할 일을 주인의 오더를 통해
                대행해 주는 시스템입니다.
              </KindListLi>
              <KindListLi>
                그러므로 난초 주인은 매주 1회씩 산채를 가듯 방문하여 난초 상태를
                체크해야 합니다.
              </KindListLi>
              <KindListLi>장기적으로 집에서 직접 길러야 합니다.</KindListLi>
              <KindListLi>
                만약 위탁 대리 돌봄 중 사망이나 재해가 우려되면 하루 속히 다른
                곳으로 옮기시면 됩니다.
              </KindListLi>
              <KindListLi style={{ color: Green600 }}>
                관유정에서는 오더에 의한 돌봄이므로 난초에 대해 책임지지
                않는다는 것을 꼭 숙지하셔야 합니다.
              </KindListLi>
            </KindListUl>
          </ListContent>
        </List>
        <List>
          <ListLabel>05</ListLabel>
          <ListTitle>비용</ListTitle>
          <ListContent>
            <KindListUl>
              <KindListLi>
                대행료 1걸이 (10화분) 계약 시 <b>선불 110만원</b> (분갈이 +
                시설물 사용료 + 대행 인건비 + 난방비 포함)
              </KindListLi>
              <KindListLi>
                대행료는 직원 인건비 및 시설 운영비의 일부분입니다.
              </KindListLi>
              <KindListLi>저온 창고 사용료는 별도</KindListLi>
              <KindListLi>위탁 판매 수수료 20%</KindListLi>
            </KindListUl>
          </ListContent>
        </List>
        {windowSize.innerWidth <= 1439 ? (
          <TableContainer>
            <ConsignmentTable />
          </TableContainer>
        ) : (
          <ConsignmentTable />
        )}
        <List style={{ marginTop: 0, border: 0 }}>
          <ListTitle style={{ display: "flex", flexDirection: "row" }}>
            메인 관리 표준 매뉴얼
            <ListSubtitle>일괄 또는 개별 체크</ListSubtitle>
          </ListTitle>
        </List>
        <List style={{ border: 0, marginTop: 0, paddingTop: 0 }}>
          {windowSize.innerWidth <= 1439 ? (
            <TableContainer2>
              <WaterManual />
            </TableContainer2>
          ) : (
            <WaterManual />
          )}
          {windowSize.innerWidth <= 1439 ? (
            <TableContainer2>
              <LightManual />
            </TableContainer2>
          ) : (
            <LightManual />
          )}
          {windowSize.innerWidth <= 1439 ? (
            <TableContainer2>
              <MagampKManual />
            </TableContainer2>
          ) : (
            <MagampKManual />
          )}
          {windowSize.innerWidth <= 1439 ? (
            <TableContainer2>
              <TempManual />
            </TableContainer2>
          ) : (
            <TempManual />
          )}
        </List>
        <List style={{ marginTop: 0, border: 0 }}>
          <ListTitle style={{ display: "flex", flexDirection: "row" }}>
            메인 예방 방제 표준 매뉴얼
          </ListTitle>
        </List>
        <List
          style={{ border: 0, marginTop: 0, paddingTop: 0, marginBottom: 56 }}
        >
          {windowSize.innerWidth <= 1439 ? (
            <TableContainer2>
              <ControlTable />
            </TableContainer2>
          ) : (
            <ControlTable />
          )}
        </List>
      </Content>
    </BasicContainer>
  );
};

export default SpeciesCenter;

import React from "react";
import styled from "styled-components";

import {
  Gray100,
  Gray200,
  Gray300,
  Gray400,
  Red700,
  TextColorBlack,
} from "../config/color.js";

const SEARCH_ICON = require("../assets/icon/Search28.png");
const RIGHT_ICON = require("../assets/icon/Right20.png");

const DefaultInput = styled.input.attrs({
  placeholderTextColor: Gray200,
})`
  width: 320px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid ${Gray200};
  padding: 0 16px;
  color: ${TextColorBlack};
  font-size: 16px;
  font-weight: 400;
  &:disabled {
    background-color: ${Gray100};
  }
  &:invalid {
    border: 1px solid ${Red700};
  }
  &:focus {
    outline: none;
  }
`;

const ExpandInput = styled.input.attrs({
  placeholderTextColor: Gray200,
})`
  width: 380px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid ${Gray200};
  padding: 0 16px;
  color: ${TextColorBlack};
  font-size: 16px;
  font-weight: 400;
  &:disabled {
    background-color: ${Gray100};
    color: ${Gray300};
  }
  &:invalid {
    border: 1px solid ${Red700};
  }
  &:focus {
    outline: none;
  }
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 700px;
  line-height: 21px;
  margin-left: 0;
  margin-right: auto;
  color: ${Gray400};
  margin-bottom: 4px;
`;

const ErrorMessage = styled.div`
  font-size: 14px;
  font-weight: 400px;
  line-height: 18px;
  margin-left: auto;
  margin-right: 0;
  color: #ff2b44;
  margin-bottom: 4px;
`;

const SearchIcon = styled.img`
  width: 28px;
  height: 28px;
  position: absolute;
  bottom: 11px;
  right: 16px;
  &:hover {
    cursor: pointer;
  }
`;

const RightIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-left: auto;
  &:hover {
    cursor: pointer;
  }
`;

const DisabledInput = styled.input.attrs({
  placeholderTextColor: Gray200,
})`
  width: 380px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid ${Gray100};
  color: ${TextColorBlack};
  font-size: 16px;
  font-weight: 400;
  &:disabled {
    background-color: ${Gray100};
  }
  &:invalid {
    border: 1px solid ${Red700};
  }
  &:focus {
    outline: none;
  }
`;

const TextArea = styled.textarea.attrs({
  placeholderTextColor: Gray200,
})`
  width: 490px;
  height: 302px;
  padding: 16px;
  border-radius: 5px;
  border: 1px solid ${Gray200};
  color: ${TextColorBlack};
  font-size: 16px;
  font-weight: 400;
  resize: none;
  &:disabled {
    background-color: ${Gray200};
  }
  &:invalid {
    border: 1px solid ${Red700};
  }
  &:focus {
    outline: none;
  }
`;

const InputA = styled(DefaultInput)``;

const InputAExpand = styled(ExpandInput)``;

const InputB = styled(DefaultInput)``;

const InputBExpand = styled(ExpandInput)``;

const SearchBox = styled(DefaultInput)`
  padding-right: 60px;
`;

const SearchBoxExpand = styled(ExpandInput)`
  padding-right: 60px;
`;

export const TF_A = (props) => {
  const {
    placeholder,
    val,
    setVal,
    required,
    errorMessage,
    pattern,
    inputType,
    inputStyle,
    viewStyle,
    disabled = false,
  } = props;
  return (
    <div style={viewStyle}>
      <div style={{ width: 352, display: "flex", flexDirection: "row" }}>
        {required ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
      </div>
      <InputA
        type={inputType}
        val={val}
        placeholder={placeholder}
        onChange={(e) => {
          setVal(e.target.value);
        }}
        required={required}
        pattern={pattern}
        style={inputStyle}
        disabled={disabled}
      />
    </div>
  );
};

export const TF_A_EXPAND = (props) => {
  const {
    placeholder,
    val,
    setVal,
    required,
    errorMessage,
    pattern,
    inputType,
    inputStyle,
    viewStyle,
  } = props;
  return (
    <div style={viewStyle}>
      <div style={{ width: 412, display: "flex", flexDirection: "row" }}>
        {required ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
      </div>
      <InputAExpand
        type={inputType}
        val={val}
        placeholder={placeholder}
        onChange={(e) => {
          setVal(e.target.value);
        }}
        required={required}
        pattern={pattern}
        style={inputStyle}
      />
    </div>
  );
};

export const TF_B = (props) => {
  const {
    placeholder,
    val,
    setVal,
    required,
    errorMessage,
    pattern,
    label,
    inputType,
    inputStyle,
    viewStyle,
  } = props;
  return (
    <div style={viewStyle}>
      <div style={{ width: 352, display: "flex", flexDirection: "row" }}>
        <Label>{label}</Label>
        {required ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
      </div>
      <InputB
        type={inputType}
        val={val}
        placeholder={placeholder}
        onChange={(e) => {
          setVal(e.target.value);
        }}
        required={required}
        pattern={pattern}
        style={inputStyle}
      />
    </div>
  );
};

export const TF_B_EXPAND = (props) => {
  const {
    placeholder,
    val,
    setVal,
    required,
    errorMessage,
    pattern,
    label,
    inputType,
    inputStyle,
    viewStyle,
    labelStyle,
    disabled,
  } = props;
  return (
    <div style={viewStyle}>
      <div
        style={{
          width: 412,
          display: "flex",
          flexDirection: "row",
          ...labelStyle,
        }}
      >
        <Label>{label}</Label>
        {required ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
      </div>
      <InputBExpand
        type={inputType}
        val={val}
        placeholder={placeholder}
        onChange={(e) => {
          setVal(e.target.value);
        }}
        required={required}
        pattern={pattern}
        style={inputStyle}
        disabled={disabled}
      />
    </div>
  );
};

export const SEARCH_BOX = (props) => {
  const { placeholder, val, setVal, onClick, viewStyle, seachBoxStyle } = props;
  return (
    <div
      style={{
        width: 352,
        display: "flex",
        position: "relative",
        ...viewStyle,
      }}
    >
      <SearchBox
        type="text"
        val={val}
        placeholder={placeholder}
        onChange={(e) => {
          setVal(e.target.value);
        }}
        style={seachBoxStyle}
      />
      <SearchIcon src={SEARCH_ICON} onClick={onClick} />
    </div>
  );
};

export const SEARCH_BOX_EXPAND = (props) => {
  const { placeholder, val, setVal, onClick, viewStyle } = props;
  return (
    <div
      style={{
        width: 412,
        display: "flex",
        position: "relative",
        ...viewStyle,
      }}
    >
      <SearchBoxExpand
        type="text"
        val={val}
        placeholder={placeholder}
        onChange={(e) => {
          setVal(e.target.value);
        }}
      />
      <SearchIcon src={SEARCH_ICON} onClick={onClick} />
    </div>
  );
};

export const ADDRESS_BOX = (props) => {
  const {
    placeholder,
    val,
    setVal,
    onClick,
    viewStyle,
    inputStyle,
    inputContainerStyle,
    inputType,
    label,
  } = props;
  return (
    <div style={viewStyle}>
      <div style={{ width: 352, display: "flex", flexDirection: "row" }}>
        <Label>{label}</Label>
      </div>
      <div
        style={{
          width: 490,
          display: "flex",
          position: "relative",
          alignItems: "center",
          borderRadius: 5,
          ...inputContainerStyle,
        }}
        onClick={onClick}
      >
        <DisabledInput
          type={inputType}
          disabled={true}
          val={val}
          placeholder={placeholder}
          onChange={(e) => {
            setVal(e.target.value);
          }}
          style={inputStyle}
        />
        <RightIcon src={RIGHT_ICON} />
      </div>
    </div>
  );
};

export const TEXTAREA = (props) => {
  const { placeholder, val, setVal, viewStyle, inputStyle, label } = props;
  return (
    <div style={viewStyle}>
      <div style={{ width: 352, display: "flex", flexDirection: "row" }}>
        <Label>{label}</Label>
      </div>
      <TextArea
        val={val}
        placeholder={placeholder}
        onChange={(e) => {
          setVal(e.target.value);
        }}
        style={inputStyle}
      />
    </div>
  );
};

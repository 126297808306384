import React, { useState, useEffect } from "react";
import styled from "styled-components";
import BasicContainer from "../../../components/BasicContainer";
import PageTop from "../../../components/PageTop";

import {
  Black,
  Gray100,
  Gray300,
  Gray500,
  Green100,
  Green600,
  White,
} from "../../../config/color";
import { getWindowSize } from "../../../utils";

const RightArrowIcon = require("../../../assets/icon/RightArrow20.png");
const LeftArrowIcon = require("../../../assets/icon/LeftArrow20.png");
const DownArrowIcon = require("../../../assets/icon/DownArrow20.png");

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1439px) {
    width: 100%;
    min-width: 360px;
    flex-direction: column;
  }
`;

const RowDiv = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 1328px;
  margin: 0 auto;

  @media screen and (max-width: 1439px) {
    width: calc(100% - 40px);
    padding: 0 20px;
    align-items: flex-start;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 72px;
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  color: ${Black};
  white-space: pre-wrap;
  line-height: 51px;

  @media screen and (max-width: 1439px) {
    font-size: 26px;
    line-height: 36px;
  }
`;

const Image = styled.img`
  width: 100%;
  margin-top: 40px;
`;

const List = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 16px 0;
  border-top: 1px solid ${Gray300};
`;

const ListLabel = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Green600};
`;

const ListTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Black};
  margin-bottom: 16px;
`;

const ListContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  white-space: pre-wrap;
`;

const PointBox = styled.div`
  width: 100%;
  padding: 48px 0;
  margin-top: 56px;
`;

const Circle = styled.div`
  width: 202px;
  height: 202px;
  border-radius: 110px;
  border: 5px solid ${Green600};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const LinkRowBox = styled.div`
  width: 68px;
  height: 202px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LinkColumnBox = styled.div`
  width: 202px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LinkImage = styled.img`
  width: 20px;
  height: 20px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Green600};
`;

const Text = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 36px;
  color: ${Black};
`;

const ContractBox = styled.div`
  width: calc(472px - 48px);
  height: calc(169px - 48px);
  padding: 24px;
  background-color: ${Green100};
  border-radius: 10px;
`;

const CareProcess = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <BasicContainer metaTitle="관유정-진료 프로세스">
      <Content>
        <PageTop
          label="의료지원"
          title="진료 프로세스"
          ImageUrl="/assets/image/MedicalHeaderImg.png"
        />
        <RowDiv>
          <TitleBox
            style={{ marginTop: windowSize.innerWidth <= 1439 ? 32 : 56 }}
          >
            <TitleLabel>의료지원</TitleLabel>
            <TitleText>진료 프로세스</TitleText>
          </TitleBox>
        </RowDiv>
        {windowSize.innerWidth >= 1440 ? (
          <>
            <RowDiv style={{ marginTop: 42, alignItems: "center" }}>
              <Circle>
                <Title>STEP 1</Title>
                <Text>자각 증상</Text>
              </Circle>
              <LinkRowBox>
                <LinkImage src={RightArrowIcon} />
              </LinkRowBox>
              <Circle>
                <Title>STEP 2</Title>
                <Text>원장님 통화</Text>
              </Circle>
              <LinkRowBox>
                <LinkImage src={RightArrowIcon} />
              </LinkRowBox>
              <Circle>
                <Title>STEP 3</Title>
                <Text>문진 카드 작성</Text>
              </Circle>
              <LinkRowBox>
                <LinkImage src={RightArrowIcon} />
              </LinkRowBox>
              <Circle>
                <Title>STEP 4</Title>
                <Text>진료 예약</Text>
              </Circle>
              <LinkRowBox>
                <LinkImage src={RightArrowIcon} />
              </LinkRowBox>
              <Circle>
                <Title>STEP 5</Title>
                <Text>개략적 진료비 안내</Text>
              </Circle>
            </RowDiv>
            <RowDiv
              style={{
                height: 68,
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <LinkColumnBox>
                <LinkImage src={DownArrowIcon} />
              </LinkColumnBox>
            </RowDiv>
            <RowDiv style={{ alignItems: "center" }}>
              <Circle>
                <Title>STEP 10</Title>
                <Text>치료 및 수술</Text>
              </Circle>
              <LinkRowBox>
                <LinkImage src={LeftArrowIcon} />
              </LinkRowBox>
              <Circle>
                <Title>STEP 9</Title>
                <Text>진단</Text>
              </Circle>
              <LinkRowBox>
                <LinkImage src={LeftArrowIcon} />
              </LinkRowBox>
              <Circle>
                <Title>STEP 8</Title>
                <Text>검진</Text>
              </Circle>
              <LinkRowBox>
                <LinkImage src={LeftArrowIcon} />
              </LinkRowBox>
              <Circle>
                <Title>STEP 7</Title>
                <Text>정밀 상담</Text>
              </Circle>
              <LinkRowBox>
                <LinkImage src={LeftArrowIcon} />
              </LinkRowBox>
              <Circle>
                <Title>STEP 6</Title>
                <Text>내원</Text>
              </Circle>
            </RowDiv>
            <RowDiv
              style={{
                height: 68,
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <LinkColumnBox>
                <LinkImage src={DownArrowIcon} />
              </LinkColumnBox>
            </RowDiv>
            <RowDiv style={{ alignItems: "center", marginBottom: 127 }}>
              <Circle>
                <Title>STEP 11</Title>
                <Text>처방전 및 치료 설계</Text>
              </Circle>
              <LinkRowBox>
                <LinkImage src={RightArrowIcon} />
              </LinkRowBox>
              <Circle>
                <Title>STEP 12</Title>
                <Text>완치 시, 기간 설명</Text>
              </Circle>
              <LinkRowBox>
                <LinkImage src={RightArrowIcon} />
              </LinkRowBox>
              <Circle>
                <Title>STEP 13</Title>
                <Text>치료 성공률 (서약서)</Text>
              </Circle>
              <LinkRowBox>
                <LinkImage src={RightArrowIcon} />
              </LinkRowBox>
              <ContractBox>
                <Title style={{ fontSize: 18 }}>서약서</Title>
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: "400",
                    lineHeight: "29px",
                    marginTop: 2,
                  }}
                >{`치료의 선택은 난초의 주인이 결정하고 그 결과는 난초의 상태에 따라 성공률이 다르므로 관유정에서 책임지지 않음을 보여주는 내용이 함된 서약서`}</Text>
              </ContractBox>
            </RowDiv>
          </>
        ) : null}

        {windowSize.innerWidth >= 768 && windowSize.innerWidth <= 1439 ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <RowDiv
              style={{
                marginTop: 42,
                alignItems: "center",
              }}
            >
              <Circle>
                <Title>STEP 1</Title>
                <Text>자각 증상</Text>
              </Circle>
              <LinkRowBox>
                <LinkImage src={RightArrowIcon} />
              </LinkRowBox>
              <Circle>
                <Title>STEP 2</Title>
                <Text>원장님 통화</Text>
              </Circle>
              <LinkRowBox>
                <LinkImage src={RightArrowIcon} />
              </LinkRowBox>
              <Circle>
                <Title>STEP 3</Title>
                <Text>문진 카드 작성</Text>
              </Circle>
            </RowDiv>
            <RowDiv
              style={{
                height: 68,
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <LinkColumnBox>
                <LinkImage src={DownArrowIcon} />
              </LinkColumnBox>
            </RowDiv>
            <RowDiv style={{ alignItems: "center" }}>
              <Circle>
                <Title>STEP 6</Title>
                <Text>내원</Text>
              </Circle>
              <LinkRowBox>
                <LinkImage src={LeftArrowIcon} />
              </LinkRowBox>
              <Circle>
                <Title>STEP 5</Title>
                <Text>개략적 진료비 안내</Text>
              </Circle>
              <LinkRowBox>
                <LinkImage src={LeftArrowIcon} />
              </LinkRowBox>
              <Circle>
                <Title>STEP 4</Title>
                <Text>진료 예약</Text>
              </Circle>
            </RowDiv>
            <RowDiv
              style={{
                height: 68,
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <LinkColumnBox>
                <LinkImage src={DownArrowIcon} />
              </LinkColumnBox>
            </RowDiv>
            <RowDiv
              style={{
                marginTop: 42,
                alignItems: "center",
              }}
            >
              <Circle>
                <Title>STEP 7</Title>
                <Text>정밀 상담</Text>
              </Circle>
              <LinkRowBox>
                <LinkImage src={RightArrowIcon} />
              </LinkRowBox>
              <Circle>
                <Title>STEP 8</Title>
                <Text>검진</Text>
              </Circle>
              <LinkRowBox>
                <LinkImage src={RightArrowIcon} />
              </LinkRowBox>
              <Circle>
                <Title>STEP 9</Title>
                <Text>진단</Text>
              </Circle>
            </RowDiv>
            <RowDiv
              style={{
                height: 68,
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <LinkColumnBox>
                <LinkImage src={DownArrowIcon} />
              </LinkColumnBox>
            </RowDiv>
            <RowDiv style={{ alignItems: "center" }}>
              <Circle>
                <Title>STEP 12</Title>
                <Text>완치 시, 기간 설명</Text>
              </Circle>
              <LinkRowBox>
                <LinkImage src={LeftArrowIcon} />
              </LinkRowBox>
              <Circle>
                <Title>STEP 11</Title>
                <Text>처방전 및 치료 설계</Text>
              </Circle>
              <LinkRowBox>
                <LinkImage src={LeftArrowIcon} />
              </LinkRowBox>
              <Circle>
                <Title>STEP 10</Title>
                <Text>치료 및 수술</Text>
              </Circle>
            </RowDiv>
            <RowDiv
              style={{
                height: 68,
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <LinkColumnBox>
                <LinkImage src={DownArrowIcon} />
              </LinkColumnBox>
            </RowDiv>
            <RowDiv style={{ alignItems: "center", marginBottom: 127 }}>
              <Circle>
                <Title>STEP 13</Title>
                <Text>치료 성공률 (서약서)</Text>
              </Circle>
              <LinkRowBox>
                <LinkImage src={RightArrowIcon} />
              </LinkRowBox>
              <ContractBox>
                <Title style={{ fontSize: 18 }}>서약서</Title>
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: "400",
                    lineHeight: "29px",
                    marginTop: 2,
                  }}
                >{`치료의 선택은 난초의 주인이 결정하고 그 결과는 난초의 상태에 따라 성공률이 다르므로 관유정에서 책임지지 않음을 보여주는 내용이 함된 서약서`}</Text>
              </ContractBox>
            </RowDiv>
          </div>
        ) : null}

        {windowSize.innerWidth <= 767 ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Circle>
              <Title>STEP 1</Title>
              <Text>자각 증상</Text>
            </Circle>
            <LinkColumnBox>
              <LinkImage src={DownArrowIcon} />
            </LinkColumnBox>
            <Circle>
              <Title>STEP 2</Title>
              <Text>원장님 통화</Text>
            </Circle>
            <LinkColumnBox>
              <LinkImage src={DownArrowIcon} />
            </LinkColumnBox>
            <Circle>
              <Title>STEP 3</Title>
              <Text>문진 카드 작성</Text>
            </Circle>
            <LinkColumnBox>
              <LinkImage src={DownArrowIcon} />
            </LinkColumnBox>
            <Circle>
              <Title>STEP 4</Title>
              <Text>진료 예약</Text>
            </Circle>
            <LinkColumnBox>
              <LinkImage src={DownArrowIcon} />
            </LinkColumnBox>
            <Circle>
              <Title>STEP 5</Title>
              <Text>개략적 진료비 안내</Text>
            </Circle>
            <LinkColumnBox>
              <LinkImage src={DownArrowIcon} />
            </LinkColumnBox>
            <Circle>
              <Title>STEP 6</Title>
              <Text>내원</Text>
            </Circle>
            <LinkColumnBox>
              <LinkImage src={DownArrowIcon} />
            </LinkColumnBox>
            <Circle>
              <Title>STEP 7</Title>
              <Text>정밀 상담</Text>
            </Circle>
            <LinkColumnBox>
              <LinkImage src={DownArrowIcon} />
            </LinkColumnBox>
            <Circle>
              <Title>STEP 8</Title>
              <Text>검진</Text>
            </Circle>
            <LinkColumnBox>
              <LinkImage src={DownArrowIcon} />
            </LinkColumnBox>
            <Circle>
              <Title>STEP 9</Title>
              <Text>진단</Text>
            </Circle>
            <LinkColumnBox>
              <LinkImage src={DownArrowIcon} />
            </LinkColumnBox>
            <Circle>
              <Title>STEP 10</Title>
              <Text>치료 및 수술</Text>
            </Circle>
            <LinkColumnBox>
              <LinkImage src={DownArrowIcon} />
            </LinkColumnBox>
            <Circle>
              <Title>STEP 11</Title>
              <Text>처방전 및 치료 설계</Text>
            </Circle>
            <LinkColumnBox>
              <LinkImage src={DownArrowIcon} />
            </LinkColumnBox>
            <Circle>
              <Title>STEP 12</Title>
              <Text>완치 시, 기간 설명</Text>
            </Circle>
            <LinkColumnBox>
              <LinkImage src={DownArrowIcon} />
            </LinkColumnBox>
            <Circle>
              <Title>STEP 13</Title>
              <Text>치료 성공률 (서약서)</Text>
            </Circle>
            <LinkColumnBox>
              <LinkImage src={DownArrowIcon} />
            </LinkColumnBox>
            <ContractBox style={{ marginBottom: 125 }}>
              <Title style={{ fontSize: 18 }}>서약서</Title>
              <Text
                style={{
                  fontSize: 16,
                  fontWeight: "400",
                  lineHeight: "29px",
                  marginTop: 2,
                }}
              >{`치료의 선택은 난초의 주인이 결정하고 그 결과는 난초의 상태에 따라 성공률이 다르므로 관유정에서 책임지지 않음을 보여주는 내용이 함된 서약서`}</Text>
            </ContractBox>
          </div>
        ) : null}
      </Content>
    </BasicContainer>
  );
};

export default CareProcess;

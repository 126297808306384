import React from "react";
import styled from "styled-components";
import { Black, White } from "../config/color";
import { ButtonB } from "./Button";

const Container = styled.div`
  flex: 1;
  border-radius: 10px;
  position: relative;
`;

const InnerContainer = styled.div`
  margin: 40px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 767px) {
    margin: 24px;
  }
`;

const Title = styled.div`
  font-family: "Nanum Myeongjo", serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  color: ${Black};
  margin-bottom: 8px;
`;

const Content = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  white-space: pre-wrap;
  z-index: 99;
`;

const IconBox = styled.div`
  position: absolute;
  top: 91px;
  right: 34px;
  width: 175px;
  height: 175px;
  border-radius: 175px;
  background-color: ${White};
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 767px) {
    top: auto;
    bottom: 24px;
    width: 108px;
    height: 108px;
    border-radius: 108px;
  }
`;

const Icon = styled.img`
  width: 99px;
  height: 99px;
  @media screen and (max-width: 767px) {
    width: 69px;
    height: 69px;
  }
`;

const Title2 = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Black};
  margin-bottom: 8px;
`;

export const CardWithIcon = (props) => {
  const {
    viewStyle,
    title,
    content,
    iconImg,
    iconStyle,
    iconBoxStyle,
    titleStyle,
    contentStyle,
  } = props;
  return (
    <Container style={viewStyle}>
      <InnerContainer>
        <Title style={titleStyle}>{title}</Title>
        <Content style={contentStyle}>{content}</Content>
      </InnerContainer>
      <IconBox style={iconBoxStyle}>
        <Icon src={iconImg} style={iconStyle} />
      </IconBox>
    </Container>
  );
};

export const CardWithIcon2 = (props) => {
  const {
    viewStyle,
    title,
    content,
    iconImg,
    iconStyle,
    iconBoxStyle,
    buttonStyle,
    onClickEvent,
  } = props;
  return (
    <Container style={viewStyle}>
      <InnerContainer>
        <Title2>{title}</Title2>
        <Content>{content}</Content>
        <ButtonB
          text="자세히보기"
          viewStyle={{ width: 235, marginTop: 24, ...buttonStyle }}
          onClickEvent={onClickEvent}
        />
      </InnerContainer>
      <IconBox style={iconBoxStyle}>
        <Icon src={iconImg} style={iconStyle} />
      </IconBox>
    </Container>
  );
};

export const CardWithIcon3 = (props) => {
  const { viewStyle, title, content, iconImg, iconStyle, iconBoxStyle } = props;
  return (
    <Container style={{ flexDirection: "column", ...viewStyle }}>
      <InnerContainer>
        <Title2>{title}</Title2>
        <Content>{content}</Content>
      </InnerContainer>
      <IconBox style={iconBoxStyle}>
        <Icon src={iconImg} style={iconStyle} />
      </IconBox>
    </Container>
  );
};

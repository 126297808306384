import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import BasicContainer from "../../../components/BasicContainer";
import {
  Black,
  Gray100,
  Gray200,
  Gray400,
  Gray500,
} from "../../../config/color";

import { getWindowSize, toStringByFormatting } from "../../../utils";
import Comment from "../../../components/Comment";
import { CheckBox } from "../../../components/Checkbox";
import { ButtonA } from "../../../components/Button";
import CommentList from "../../../components/CommentList";

const ViewIcon = require("../../../assets/icon/View24.png");
const CommentIcon = require("../../../assets/icon/Comment24.png");

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1439px) {
    min-width: 360px;
    width: calc(100% - 40px);
    padding: 0 20px;
  }
`;

const BoardContainer = styled.div`
  display: flex;
  width: calc(100% - 112px);
  flex-direction: column;
  padding: 72px 56px;
  position: relative;

  @media screen and (max-width: 1439px) {
    width: 100%;
    padding: 41px 0;
  }
`;

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 30px;
  line-height: 42px;
  color: ${Black};
  word-break: keep-all;

  @media screen and (max-width: 1439px) {
    font-size: 24px;
    line-height: 34px;
  }
`;

const EditText = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 25px;
  text-align: left;
  color: ${Gray400};
  &:hover {
    cursor: pointer;
  }
`;

const BoardTopInfoText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: ${Gray500};
`;

const CountText = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 25px;
  text-align: left;
  color: ${Gray400};
  margin-right: 16px;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 4px;
`;

const DateText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 25.2px;
  color: ${Gray400};
`;

const DataContent = styled.div`
  width: 100%;
`;

const DistinctBox = styled.div`
  width: 100%;
  height: 9px;
  background-color: ${Gray100};
`;

const BoardDetail = () => {
  const { state } = useLocation();
  const { _id } = state;

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const [data, setData] = useState({
    _id: "003",
    title: "난초 전성시대, 유전자원부터 탄탄히",
    creator: {
      nickname: "관리자",
    },
    content: `듣기만 하여도 가슴이 설레는 말이다 청춘! 너의 두손을 가슴에 대고 물방아 같은 심장의 고동을 들어 보라 청춘의 피는 끓는다 끓는 피에 뛰노는 심장은 거선의 기관과 같이 힘있다 이것이다 인류의 역사를 꾸며 내려온 동력은 바로 이것이다 이성은 투명하되 얼음과 같으며 지혜는 날카로우나 갑 듣기만 하여도 가슴이 설레는 말이다 청춘! 너의 두손을 가슴에 대고 물방아 같은 심장의 고동을 들어 보라 청춘의 피는 끓는다 끓는 피에 뛰노는 심장은 거선의 기관과 같이 힘있다 이것이다 인류의 역사를 꾸며 내려온 동력은 바로 이것이다 이성은 투명하되 얼음과 같으며 지혜는 날카로우나 갑 듣기만 하여도 가슴이 설레는 말이다 청춘! 너의 두손을 가슴에 대고 물방아 같은 심장의 고동을 들어 보라 청춘의 피는 끓는다 끓는 피에 뛰노는 심장은 거선의 기관과 같이 힘있다 이것이다 인류의 역사를 꾸며 내려온 동력은 바로 이것이다 이성은 투명하되 얼음과 같으며 지혜는 날카로우나 갑`,
    commentCnt: 13,
    viewCnt: 13,
    createdAt: new Date(),
  });
  const [comment, setComment] = useState("");
  const [isCommentSecret, setIsCommentSecret] = useState(false);
  const [commentList, setCommentList] = useState([
    {
      _id: "12345",
      creator: {
        name: "홍길동",
        profile: "/assets/tmpImg/userTmp.png",
      },
      createdAt: new Date(),
      content: `듣기만 하여도 가슴이 설레는 말이다 청춘! 너의 두손을 가슴에 대고 물방아 같은 심장의 고동을 들어 보라 청춘의 피는 끓는다 끓는 피에 뛰노는 심장은 거선의 기관과 같이 힘있다 이것이다 인류의 역사를 꾸며 내려온 동력은 바로 이것이다 이성은 투명하되 얼음과 같으며 지혜는 날카로우나 갑 듣기만 하여도 가슴이 설레는 말이다 청춘! 너의 두손을 가슴에 대고 물방아 같은 심장의 고동을 들어 보라 청춘의 피는 끓는다 끓는 피에 뛰노는 심장은 거선의 기관과 같이 힘있다 이것이다 인류의 역사를 꾸며 내려온 동력은 바로 이것이다 이성은 투명하되 얼음과 같으며 지혜는 날카로우나 갑 듣기만 하여도 가슴이 설레는 말이다 청춘! 너의 두손을 가슴에 대고 물방아 같은 심장의 고동을 들어 보라 청춘의 피는 끓는다 끓는 피에 뛰노는 심장은 거선의 기관과 같이 힘있다 이것이다 인류의 역사를 꾸며 내려온 동력은 바로 이것이다 이성은 투명하되 얼음과 같으며 지혜는 날카로우나 갑`,
    },
    {
      _id: "12445",
      creator: {
        name: "홍길동",
        profile: "/assets/tmpImg/userTmp.png",
      },
      createdAt: new Date(),
      content: `듣기만 하여도 가슴이 설레는 말이다 청춘! 너의 두손을 가슴에 대고 물방아 같은 심장의 고동을 들어 보라 청춘의 피는 끓는다 끓는 피에 뛰노는 심장은 거선의 기관과 같이 힘있다 이것이다 인류의 역사를 꾸며 내려온 동력은 바로 이것이다 이성은 투명하되 얼음과 같으며 지혜는 날카로우나 갑 듣기만 하여도 가슴이 설레는 말이다 청춘! 너의 두손을 가슴에 대고 물방아 같은 심장의 고동을 들어 보라 청춘의 피는 끓는다 끓는 피에 뛰노는 심장은 거선의 기관과 같이 힘있다 이것이다 인류의 역사를 꾸며 내려온 동력은 바로 이것이다 이성은 투명하되 얼음과 같으며 지혜는 날카로우나 갑 듣기만 하여도 가슴이 설레는 말이다 청춘! 너의 두손을 가슴에 대고 물방아 같은 심장의 고동을 들어 보라 청춘의 피는 끓는다 끓는 피에 뛰노는 심장은 거선의 기관과 같이 힘있다 이것이다 인류의 역사를 꾸며 내려온 동력은 바로 이것이다 이성은 투명하되 얼음과 같으며 지혜는 날카로우나 갑`,
    },
  ]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <BasicContainer metaTitle="관유정-자유게시판">
      <Content>
        <BoardContainer>
          {windowSize.innerWidth <= 767 ? (
            <>
              <Title>{data.title}</Title>
              <RowDiv style={{ marginTop: 28 }}>
                <BoardTopInfoText style={{ marginRight: "auto" }}>
                  {data.creator.nickname}
                </BoardTopInfoText>
                <EditText style={{ marginLeft: "auto" }}>수정</EditText>
                <EditText style={{ marginLeft: 10 }}>삭제</EditText>
              </RowDiv>
              <RowDiv
                style={{
                  paddingTop: 10,
                  paddingBottom: 12,
                  marginBottom: 24,
                  borderBottom: `1px solid ${Gray200}`,
                }}
              >
                <Icon src={CommentIcon} />
                <CountText>{data.commentCnt}</CountText>
                <Icon src={ViewIcon} />
                <CountText>{data.viewCnt}</CountText>
                <DateText>{toStringByFormatting(data.createdAt)}</DateText>
              </RowDiv>
            </>
          ) : (
            <>
              <RowDiv>
                <Title>{data.title}</Title>
                <EditText style={{ marginLeft: "auto" }}>수정</EditText>
                <EditText style={{ marginLeft: 10 }}>삭제</EditText>
              </RowDiv>
              <RowDiv
                style={{
                  marginTop: 18,
                  marginBottom: 24,
                  paddingBottom: 13,
                  borderBottom: `1px solid ${Gray200}`,
                }}
              >
                <BoardTopInfoText style={{ marginRight: "auto" }}>
                  {data.creator.nickname}
                </BoardTopInfoText>
                <Icon src={CommentIcon} />
                <CountText>{data.commentCnt}</CountText>
                <Icon src={ViewIcon} />
                <CountText>{data.viewCnt}</CountText>
                <DateText>{toStringByFormatting(data.createdAt)}</DateText>
              </RowDiv>
            </>
          )}
          <DataContent>{data.content}</DataContent>
        </BoardContainer>
        <DistinctBox />
        <Comment
          comment={comment}
          setComment={setComment}
          viewStyle={{ paddingBottom: 0, position: "relative" }}
          inputStyle={{ height: 138, width: `calc(100% - 34px)` }}
        />
        <RowDiv
          style={{
            marginTop: 16,
            marginBottom: 40,
            marginLeft: windowSize.innerWidth <= 767 ? 0 : 56,
            marginRight: windowSize.innerWidth <= 767 ? 0 : 56,
            width: windowSize.innerWidth <= 767 ? "100%" : `calc(100% - 112px)`,
          }}
        >
          <CheckBox
            text="비밀댓글"
            viewStyle={{ width: 156 }}
            isChecked={isCommentSecret}
            setIsChecked={setIsCommentSecret}
          />
          <ButtonA text="등록" viewStyle={{ width: 106, marginLeft: "auto" }} />
        </RowDiv>
        <CommentList
          list={commentList}
          viewStyle={{
            width: windowSize.innerWidth <= 767 ? "100%" : `calc(100% - 112px)`,
            paddingLeft: windowSize.innerWidth <= 767 ? 0 : 56,
            paddingRight: windowSize.innerWidth <= 767 ? 0 : 56,
          }}
        />
      </Content>
    </BasicContainer>
  );
};

export default BoardDetail;

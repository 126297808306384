import React, { useState, useEffect } from "react";
import styled from "styled-components";
import BasicContainer from "../../../components/BasicContainer";
import {
  Black,
  Gray100,
  Gray300,
  Green100,
  Green300,
  Green400,
  Green600,
} from "../../../config/color";

import { getWindowSize } from "../../../utils";

const OrchidHistoryIcon = require("../../../assets/icon/OrchidHistory196.png");

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1439px) {
    min-width: 360px;
    width: calc(100% - 40px);
    padding: 0 20px;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 112px); //calc(100% - 112px);
  padding: 0 56px;
  margin-top: 72px;
  @media screen and (max-width: 1439px) {
    width: 100%;
    padding: 0;
    margin-top: 36px;
  }
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  color: ${Black};
  white-space: pre-wrap;
  line-height: 51px;
  margin-bottom: 16px;
  @media screen and (max-width: 1439px) {
    font-size: 26px;
    line-height: 36px;
  }
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 28.8px;
  white-space: pre-wrap;
`;

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100% - 112px);
  padding: 0 56px;

  @media screen and (max-width: 1439px) {
    width: 100%;
    padding: 0;
    flex-direction: column;
  }
`;

const Icon = styled.img`
  width: 187px;
  height: 196px;
  margin-left: auto;
`;

const Card = styled.div`
  flex: 1;
  height: 107px;
  border-radius: 10px;
  margin-right: 16px;
  padding: 40px;

  @media screen and (max-width: 767px) {
    padding: 24px;
    margin-bottom: 16px;
    margin-right: 0;
  }
`;

const CardTitle = styled.div`
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 24px;
  color: ${Black};
  white-space: pre-wrap;
  line-height: 34px;
  margin-bottom: 8px;
`;

const CardText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
`;

const LongCard = styled.div`
  border-radius: 10px;
  background-color: ${Gray100};
  flex: 1;
  height: 140px;
  padding: 24px;
`;

const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

const CardLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  width: 66px;
  margin-right: 20px;
`;

const KindList = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 112px);
  margin: 0 56px;
  padding: 16px 0;
  border-top: 1px solid ${Gray300};

  @media screen and (max-width: 1439px) {
    width: 100%;
    padding: 16px 0;
  }
`;

const KindListLabel = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Green600};
`;

const KindListTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Black};
`;

const KindListUl = styled.ul`
  padding-left: 32px;
`;

const KindListLi = styled.li`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
`;

const OrchidHistory = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <BasicContainer metaTitle="관유정-이력제">
      <Content>
        <TitleBox>
          <TitleLabel>이력제</TitleLabel>
          <TitleText>{`신뢰할 수 있는
세계 최초 난초 이력제`}</TitleText>
        </TitleBox>
        <RowDiv style={{ marginBottom: 72 }}>
          <Text
            style={{ marginBottom: windowSize.innerWidth <= 1439 ? 44 : 0 }}
          >
            {windowSize.innerWidth <= 1439
              ? `난문화 진흥원에서 개발해서 관유정에서 실행 
사망AS보험은 인큐베이터나 가상의 불량품들을 의심해볼 때 원금 보장 위에 난 문화 진흥원에서 개발하게 된 이유는 난초 구매시, 1대 99로 자신감 있게 AS가 가능하다고 하는 판매점은 1, 나머지 99는 사망 AS를 들어주지 못한다. 그 이유는 난초에 대한 자신감이 없기 때문에 발생하는 것이다. 교육생들에게 하는 지엽적인 색체에서 난계를 지켜낼 수 있는 3%(바닷물 소금)의 지원책이다. 또한 자산을 지켜낼 수 있는 제일 중요한 방안이다.`
              : `난문화 진흥원에서 개발해서 관유정에서 실행
사망AS보험은 인큐베이터나 가상의 불량품들을 의심해볼 때 
원금 보장 위에 난 문화 진흥원에서 개발하게 된 이유는 난초 구매시,
1대 99로 자신감 있게 AS가 가능하다고 하는 판매점은 1, 나머지 99는 사망 AS를 들어주지 못한다.
그 이유는 난초에 대한 자신감이 없기 때문에 발생하는 것이다. 
교육생들에게 하는 지엽적인 색체에서 난계를 지켜낼 수 있는 3%(바닷물 소금)의 지원책이다.
또한 자산을 지켜낼 수 있는 제일 중요한 방안이다.`}
          </Text>
          <Icon
            src={OrchidHistoryIcon}
            style={
              windowSize.innerWidth <= 1439 ? { marginRight: "auto" } : null
            }
          />
        </RowDiv>
        <RowDiv style={{ justifyContent: "space-between", marginBottom: 40 }}>
          <Card style={{ backgroundColor: Green100 }}>
            <CardTitle>{`종자이력제
: 호에서 중투까지`}</CardTitle>
            <CardText>난초 종자에 대한 엽예, 화예 등이 기록된 이력</CardText>
          </Card>
          <Card style={{ backgroundColor: Green300 }}>
            <CardTitle>{`생산이력제
: 도입에서 판매까지`}</CardTitle>
            <CardText>
              관유정에 도입된 난초가 누구에게 판매되는지에 대한 이력
            </CardText>
          </Card>
          <Card style={{ backgroundColor: Green400, marginRight: 0 }}>
            <CardTitle>{`품질이력제
: 뿌리에서 잎까지`}</CardTitle>
            <CardText>
              뿌리, 벌브, 잎, 신아 등 병충해 유무와 등급에 관한 이력
            </CardText>
          </Card>
        </RowDiv>
        <RowDiv style={{ marginBottom: 40 }}>
          <LongCard>
            <CardRow>
              <CardLabel>기획의도</CardLabel>
              <CardText>불량품이 없는 정상적인 난 거래소를 만들고자</CardText>
            </CardRow>
            <CardRow>
              <CardLabel>장점</CardLabel>
              <CardText>
                수수료가 없고 불량품이 없다.{" "}
                {windowSize.innerWidth <= 767 ? <br /> : "/"} 가격과 모든 자료가
                투명하다.
              </CardText>
            </CardRow>
            <CardRow>
              <CardLabel>프로세스</CardLabel>
              <CardText>삼정난거래소 가입 &gt; 판매</CardText>
            </CardRow>
            <CardRow style={{ margin: 0 }}>
              <CardLabel>기대효과</CardLabel>
              <CardText>속거나 바가지 쓰는 사람이 없다</CardText>
            </CardRow>
          </LongCard>
        </RowDiv>
        <KindList>
          <KindListLabel>01</KindListLabel>
          <KindListTitle>종자 이력제</KindListTitle>
          <KindListUl>
            <KindListLi>
              난초 종자에 대한 엽예, 화예 등이 기록된 이력
            </KindListLi>
            <KindListLi>
              누가 길렀고 / 언제 어디서 공급 받았는지 알기 위한 제도
            </KindListLi>
            <KindListLi>
              앞 농가의 재배 실력과 환경과 내난실의 환경등의 차이가 얼마나
              나는지등을 알수 있어서 능동적 생산이 가능해집니다.
            </KindListLi>
          </KindListUl>
        </KindList>

        <KindList>
          <KindListLabel>02</KindListLabel>
          <KindListTitle>생산 이력제</KindListTitle>
          <KindListUl>
            <KindListLi>
              관유정에 도입된 난초가 누구에게 판매되는지에 대한 이력
            </KindListLi>
            <KindListLi>어떻게 길렀는가?</KindListLi>
            <KindListLi>
              분갈이나 스케일링 시 문제는 없었는가? 를 알기 위함
            </KindListLi>
            <KindListLi>
              물 비료 조도 조건을 어떻게 해 주었는지를 구매농가들이 알수 있어서
              환경이 바뀐 자신의 난실에서 안정적으로 품질을 유지 할수 있습니다.
            </KindListLi>
            <KindListLi>
              분갈이는 어떻게 했으며 난석의 종류와 크기 연도 조절(녹소토 함량)/
              분의 제질 크기를 알수 있어서 자신의 생산 방법과 견줄수가 있어
              리스크율을 낮출수 있습니다.
            </KindListLi>
            <KindListLi>
              그리고 이전 당시 분갈이 전 모습과 손질 후 모습을 투명하게 볼수
              있다.
            </KindListLi>
            <KindListLi>
              뿌리 위치나 개수와 전근의 사유까지도 훤히 알수 있고 뿌리 한가닥
              부터 잎 끝 하나까지 이상이 없었는지까지를 모두 알수 있습니다.
            </KindListLi>
          </KindListUl>
        </KindList>

        <KindList style={{ marginBottom: 170 }}>
          <KindListLabel>03</KindListLabel>
          <KindListTitle>전(前) 과정 품질 이력제</KindListTitle>
          <KindListUl>
            <KindListLi>
              뿌리, 벌브, 잎, 신아 등 병충해 유무와 등급에 관한 이력
            </KindListLi>
            <KindListLi>어떻게 병 예방을 했는가?</KindListLi>
            <KindListLi>큰 병은 없었는가? 를 알기 위함</KindListLi>
            <KindListLi>
              구매자가 앞 농가에서는 관행 방제는 어떻게 했는지를 훤히 알수 있게.
              스케일링 시점과 처리전과 후 사진을 통해 상습 기저질환에 의한
              피해는 줄일수 있습니다.
            </KindListLi>
            <KindListLi>
              그리고 생산 농가도 이를 통해 품질을 높여 나갈수 있습니다.
            </KindListLi>
          </KindListUl>
        </KindList>
      </Content>
    </BasicContainer>
  );
};

export default OrchidHistory;

import React from "react";
import styled from "styled-components";

import { Black, Gray400 } from "../config/color";

const GoFirstPageImg = require("../assets/icon/GoFirstPage29.png");
const GoPrevPageImg = require("../assets/icon/GoPrevPage29.png");
const GoNextPageImg = require("../assets/icon/GoNextPage29.png");
const GoLastPageImg = require("../assets/icon/GoLastPage29.png");

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 56px;
`;

const ButtonImg = styled.img`
  width: 29px;
  height: 29px;
  margin-right: 5px;
  &:hover {
    cursor: pointer;
  }
`;

const PageButton = styled.div`
  width: 29px;
  height: 29px;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  &:hover {
    cursor: pointer;
  }
`;

const ActiveText = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 14px;
  color: ${Black};
`;

const InactiveText = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 14px;
  color: ${Gray400};
`;

const Pagination = (props) => {
  const { viewStyle, totalCnt, nowPage } = props;
  return (
    <Container style={viewStyle}>
      <ButtonImg src={GoFirstPageImg} />
      <ButtonImg src={GoPrevPageImg} style={{ marginRight: 16 }} />
      <PageButton>
        <ActiveText>1</ActiveText>
      </PageButton>
      <PageButton>
        <InactiveText>2</InactiveText>
      </PageButton>
      <PageButton>
        <InactiveText>3</InactiveText>
      </PageButton>
      <ButtonImg src={GoNextPageImg} style={{ marginLeft: 11 }} />
      <ButtonImg src={GoLastPageImg} style={{ marginRight: 0 }} />
    </Container>
  );
};

export default Pagination;

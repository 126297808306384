import React from "react";
import styled from "styled-components";
import YouTube from "react-youtube";
import { Green600 } from "../config/color";

const Container = styled.div`
  width: 432px;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Green600};
`;

const Youtube = (props) => {
  const { videoId, title, viewStyle, optStyle } = props;

  const opts = {
    height: "228",
    width: "432",
    playerVars: {
      autoplay: 0,
    },
    ...optStyle,
  };
  return (
    <Container style={viewStyle}>
      <YouTube videoId={videoId} opts={opts} />
      <Title>{title}</Title>
    </Container>
  );
};

export default Youtube;

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import BasicContainer from "../../../components/BasicContainer";
import {
  Black,
  Gray100,
  Gray200,
  Gray400,
  Gray500,
} from "../../../config/color";

import { getWindowSize, toStringByFormatting } from "../../../utils";
import Comment from "../../../components/Comment";
import { ButtonA } from "../../../components/Button";
import CommentList from "../../../components/CommentList";

const CommentIcon = require("../../../assets/icon/Comment24.png");
const ViewIcon = require("../../../assets/icon/View24.png");

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1439px) {
    min-width: 320px;
    width: calc(100% - 40px);
    padding: 0 20px;
  }
`;

const BoardContainer = styled.div`
  display: flex;
  width: calc(100% - 112px);
  flex-direction: column;
  padding: 72px 56px;
  position: relative;

  @media screen and (max-width: 1439px) {
    width: 100%;
    padding: 0;
    margin-top: 36px;
  }
`;

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 30px;
  line-height: 42px;
  color: ${Black};
  white-space: pre-wrap;
  word-break: keep-all;

  @media screen and (max-width: 767px) {
    max-width: 200px;
  }
`;

const EditText = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 25px;
  text-align: left;
  color: ${Gray400};
  &:hover {
    cursor: pointer;
  }
`;

const BoardTopInfoText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: ${Gray500};
`;

const CountText = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 25px;
  text-align: left;
  color: ${Gray400};
  margin-right: 16px;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 4px;
`;

const DateText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 25.2px;
  color: ${Gray400};
`;

const DataContent = styled.div`
  width: 100%;
`;

const DistinctBox = styled.div`
  width: 100%;
  height: 9px;
  margin-top: 56px;
  background-color: ${Gray100};
`;

const AlumniDetail = () => {
  const { state } = useLocation();
  const { _id, graduation, title } = state;

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const [data, setData] = useState({
    _id: "003",
    title: `${title} ${graduation}기 총동창회`,
    creator: {
      nickname: "관리자",
    },
    content: `듣기만 하여도 가슴이 설레는 말이다 청춘! 너의 두손을 가슴에 대고 물방아 같은 심장의 고동을 들어 보라 청춘의 피는 끓는다 끓는 피에 뛰노는 심장은 거선의 기관과 같이 힘있다 이것이다 인류의 역사를 꾸며 내려온 동력은 바로 이것이다 이성은 투명하되 얼음과 같으며 지혜는 날카로우나 갑 듣기만 하여도 가슴이 설레는 말이다 청춘! 너의 두손을 가슴에 대고 물방아 같은 심장의 고동을 들어 보라 청춘의 피는 끓는다 끓는 피에 뛰노는 심장은 거선의 기관과 같이 힘있다 이것이다 인류의 역사를 꾸며 내려온 동력은 바로 이것이다 이성은 투명하되 얼음과 같으며 지혜는 날카로우나 갑 듣기만 하여도 가슴이 설레는 말이다 청춘! 너의 두손을 가슴에 대고 물방아 같은 심장의 고동을 들어 보라 청춘의 피는 끓는다 끓는 피에 뛰노는 심장은 거선의 기관과 같이 힘있다 이것이다 인류의 역사를 꾸며 내려온 동력은 바로 이것이다 이성은 투명하되 얼음과 같으며 지혜는 날카로우나 갑`,
    commentCnt: 13,
    viewCnt: 13,
    createdAt: new Date(),
  });
  const [comment, setComment] = useState("");
  const [commentList, setCommentList] = useState([
    {
      _id: "12345",
      creator: {
        name: "홍길동",
        profile: "/assets/tmpImg/userTmp.png",
      },
      createdAt: new Date(),
      content: `듣기만 하여도 가슴이 설레는 말이다 청춘! 너의 두손을 가슴에 대고 물방아 같은 심장의 고동을 들어 보라 청춘의 피는 끓는다 끓는 피에 뛰노는 심장은 거선의 기관과 같이 힘있다 이것이다 인류의 역사를 꾸며 내려온 동력은 바로 이것이다 이성은 투명하되 얼음과 같으며 지혜는 날카로우나 갑 듣기만 하여도 가슴이 설레는 말이다 청춘! 너의 두손을 가슴에 대고 물방아 같은 심장의 고동을 들어 보라 청춘의 피는 끓는다 끓는 피에 뛰노는 심장은 거선의 기관과 같이 힘있다 이것이다 인류의 역사를 꾸며 내려온 동력은 바로 이것이다 이성은 투명하되 얼음과 같으며 지혜는 날카로우나 갑 듣기만 하여도 가슴이 설레는 말이다 청춘! 너의 두손을 가슴에 대고 물방아 같은 심장의 고동을 들어 보라 청춘의 피는 끓는다 끓는 피에 뛰노는 심장은 거선의 기관과 같이 힘있다 이것이다 인류의 역사를 꾸며 내려온 동력은 바로 이것이다 이성은 투명하되 얼음과 같으며 지혜는 날카로우나 갑`,
    },
    {
      _id: "12445",
      creator: {
        name: "홍길동",
        profile: "/assets/tmpImg/userTmp.png",
      },
      createdAt: new Date(),
      content: `듣기만 하여도 가슴이 설레는 말이다 청춘! 너의 두손을 가슴에 대고 물방아 같은 심장의 고동을 들어 보라 청춘의 피는 끓는다 끓는 피에 뛰노는 심장은 거선의 기관과 같이 힘있다 이것이다 인류의 역사를 꾸며 내려온 동력은 바로 이것이다 이성은 투명하되 얼음과 같으며 지혜는 날카로우나 갑 듣기만 하여도 가슴이 설레는 말이다 청춘! 너의 두손을 가슴에 대고 물방아 같은 심장의 고동을 들어 보라 청춘의 피는 끓는다 끓는 피에 뛰노는 심장은 거선의 기관과 같이 힘있다 이것이다 인류의 역사를 꾸며 내려온 동력은 바로 이것이다 이성은 투명하되 얼음과 같으며 지혜는 날카로우나 갑 듣기만 하여도 가슴이 설레는 말이다 청춘! 너의 두손을 가슴에 대고 물방아 같은 심장의 고동을 들어 보라 청춘의 피는 끓는다 끓는 피에 뛰노는 심장은 거선의 기관과 같이 힘있다 이것이다 인류의 역사를 꾸며 내려온 동력은 바로 이것이다 이성은 투명하되 얼음과 같으며 지혜는 날카로우나 갑`,
    },
  ]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <BasicContainer metaTitle="관유정-자유게시판">
      <Content>
        <BoardContainer>
          <RowDiv>
            <Title>{data.title}</Title>
            {windowSize.innerWidth <= 767 ? null : (
              <>
                <EditText style={{ marginLeft: "auto" }}>수정</EditText>
                <EditText style={{ marginLeft: 10 }}>삭제</EditText>
              </>
            )}
          </RowDiv>
          <RowDiv
            style={{
              marginTop: 18,
              marginBottom: 24,
              paddingBottom: 13,
              borderBottom: `1px solid ${Gray200}`,
            }}
          >
            <BoardTopInfoText style={{ marginRight: "auto" }}>
              {`${title} ${graduation}기`}
            </BoardTopInfoText>
            {windowSize.innerWidth <= 767 ? (
              <>
                <EditText style={{ marginLeft: "auto" }}>수정</EditText>
                <EditText style={{ marginLeft: 10 }}>삭제</EditText>
              </>
            ) : (
              <>
                <Icon src={CommentIcon} />
                <CountText>{data.commentCnt}</CountText>
                <Icon src={ViewIcon} />
                <CountText>{data.viewCnt}</CountText>
                <DateText>{toStringByFormatting(data.createdAt)}</DateText>
              </>
            )}
          </RowDiv>
          <DataContent>{data.content}</DataContent>
        </BoardContainer>
        <DistinctBox />
        <Comment
          comment={comment}
          setComment={setComment}
          viewStyle={{ paddingBottom: 0, position: "relative" }}
          inputStyle={{ height: 138, width: `calc(100% - 34px)` }}
        />
        <RowDiv
          style={{
            marginTop: 16,
            marginBottom: 40,
            marginLeft: 56,
            marginRight: 56,
            width:
              windowSize.innerWidth <= 1439 ? "100%" : `calc(100% - 112px)`,
          }}
        >
          <ButtonA text="등록" viewStyle={{ width: 106, marginLeft: "auto" }} />
        </RowDiv>
        <CommentList
          list={commentList}
          viewStyle={{
            width:
              windowSize.innerWidth <= 1439 ? "100%" : `calc(100% - 112px)`,
            paddingLeft: 56,
            paddingRight: 56,
          }}
        />
      </Content>
    </BasicContainer>
  );
};

export default AlumniDetail;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Black,
  Gray100,
  Gray200,
  Gray300,
  Gray400,
  Gray500,
  Green600,
  White,
} from "../../../config/color";
import { getWindowSize } from "../../../utils";
import VoucherGraph1 from "./Graph";
import VoucherGraph2 from "./Graph2";

const Container = styled.div`
  width: 1328px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  margin-bottom: 106px;

  @media screen and (max-width: 767px) {
    width: 100%;
    flex-direction: column;
  }

  @media screen and (min-width: 768px) and (max-width: 1439px) {
    width: 100%;
  }
`;

const Card = styled.div`
  flex: 1;
  border: 1px solid ${Gray200};
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 10px;
`;

const CardTag = styled.div`
  width: 123px;
  padding: 4px 12px;
  color: ${White};
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  font-family: "Nanum Myeongjo";
  color: ${Black};
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: 700;
  line-height: 28.8px;
  color: ${Gray500};
  border-radius: 10px;
`;

const InfoList = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${Gray400};
`;

const InfoLabel = styled.div`
  width: 60px;
  min-width: 60px;
  margin-right: 24px;
  text-align: left;
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
`;

const Info = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  white-space: pre-wrap;
  word-break: keep-all;
`;

const VoucherCard = (props) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Container>
      <Card
        style={{
          marginRight: windowSize.innerWidth <= 767 ? 0 : 8,
          marginBottom: windowSize.innerWidth <= 767 ? 32 : 0,
        }}
      >
        <CardTag style={{ backgroundColor: Green600 }}>
          2급 안심 바우처 제도
        </CardTag>
        <Title>2급 안심 바우처 제도</Title>
        <InfoBox style={{ marginTop: 16 }}>
          <InfoList>
            <InfoLabel>기획의도</InfoLabel>
            <Info>2급 교육생들의 성공 부업 조력</Info>
          </InfoList>
        </InfoBox>
        <InfoBox style={{ marginTop: 8 }}>
          <InfoList>
            <InfoLabel>장점</InfoLabel>
            <Info>계약 기간에 원금 회수 / 100% 판로</Info>
          </InfoList>
        </InfoBox>
        <InfoBox style={{ marginTop: 8 }}>
          <InfoList>
            <InfoLabel>기대효과</InfoLabel>
            <Info>인구 유입 100만</Info>
          </InfoList>
        </InfoBox>
        <InfoBox style={{ marginTop: 8 }}>
          <InfoList>
            <InfoLabel>프로세스</InfoLabel>
            <Info>
              2급 자격 취득 &gt; 안심 바우처 가입 &gt; 종자 공급 시 계약 &gt;
              계약 만료시 정산
            </Info>
          </InfoList>
        </InfoBox>
        <VoucherGraph1 />
      </Card>
      <Card style={{ marginLeft: windowSize.innerWidth <= 767 ? 0 : 8 }}>
        <CardTag style={{ backgroundColor: Gray500 }}>
          1급 안심 바우처 제도
        </CardTag>
        <Title>1급 안심 바우처 제도</Title>
        <InfoBox style={{ marginTop: 16 }}>
          <InfoList>
            <InfoLabel>기획의도</InfoLabel>
            <Info>1급 교육생들의 난계 진출</Info>
          </InfoList>
        </InfoBox>
        <InfoBox style={{ marginTop: 8 }}>
          <InfoList>
            <InfoLabel>장점</InfoLabel>
            <Info>월 300 벌이가 되는 시스템</Info>
          </InfoList>
        </InfoBox>
        <InfoBox style={{ marginTop: 8 }}>
          <InfoList>
            <InfoLabel>기대효과</InfoLabel>
            <Info>인구 유입 100만</Info>
          </InfoList>
        </InfoBox>
        <InfoBox style={{ marginTop: 8 }}>
          <InfoList>
            <InfoLabel>프로세스</InfoLabel>
            <Info>
              1급 자격 취득 &gt; 난실 검증 &gt; 가맹점 패키지 이전 &gt; 독립
              자경 운영 &gt; 1년간 멘토링
            </Info>
          </InfoList>
        </InfoBox>
        <VoucherGraph2 />
      </Card>
    </Container>
  );
};

export default VoucherCard;

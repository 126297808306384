import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Black, Gray300, Green600 } from "../../../config/color";

import { getWindowSize } from "../../../utils";

const CIImage = require("../../../assets/image/header_logo 1@2x.png");

const TitleBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: calc(100% - 112px);
  padding: 0 56px;
  margin-top: 74px;

  @media screen and (max-width: 767px) {
    align-items: flex-start;
    padding: 0;
    margin-top: 40px;
    width: 100%;
  }
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  color: ${Black};
  white-space: pre-wrap;

  @media screen and (max-width: 1439px) {
    font-size: 26px;
    line-height: 29px;
  }
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 28.8px;
  white-space: pre-wrap;
  margin-top: 16px;
`;

const ImageContainer = styled.div`
  width: 867px;
  border-bottom: 1px solid ${Gray300};
  margin-bottom: 56px;
  display: flex;
  justify-content: center;
`;

const Image = styled.img`
  width: 390px;
  height: 221px;
`;

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const ColorContainer = styled.div`
  width: 693px;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

const ColorBox = styled.div`
  width: 139px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 767px) {
    margin-bottom: 56px;
  }
`;

const Color = styled.div`
  width: 139px;
  height: 139px;
  border-radius: 10px;
  margin-bottom: 16px;
`;

const ColorText = styled.div`
  font-size: 16px;
  line-height: 29px;
  white-space: pre-wrap;
`;

const CI = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <>
      <TitleBox>
        <TitleLabel>CI</TitleLabel>
        <TitleText>이대발 박사 난 연구소 브랜드</TitleText>
        <Text
          style={{
            textAlign: windowSize.innerWidth <= 767 ? "left" : "center",
            marginBottom: 32,
          }}
        >
          {windowSize.innerWidth <= 767
            ? "대한민국 난초 명장 이대발박사 난 연구소는 국가대표 난을 재배하기 위해 힘쓰는 연구소의 이미지를 형상화했다. 난이 뻗어 나가는 형태를 캘리체로 표현하여 이대발 박사 난 연구소의 미래와 전망을 담아 이미지를 표현했다."
            : `대한민국 난초 명장 이대발박사 난 연구소는 국가대표 난을 재배하기 위해 힘쓰는 연구소의 이미지를 형상화했다.
난이 뻗어 나가는 형태를 캘리체로 표현하여 이대발 박사 난 연구소의 미래와 전망을 담아 이미지를 표현했다.`}
        </Text>
      </TitleBox>
      <RowDiv style={{ justifyContent: "center" }}>
        <ImageContainer>
          <Image src={CIImage} />
        </ImageContainer>
      </RowDiv>
      <RowDiv
        style={{
          justifyContent: "center",
          marginBottom: windowSize.innerWidth <= 767 ? 42 : 316,
        }}
      >
        <ColorContainer>
          <ColorBox
            style={{ marginRight: windowSize.innerWidth <= 767 ? 0 : 138 }}
          >
            <Color style={{ backgroundColor: "#5c5959" }} />
            <ColorText>
              <b>{`CMYK\n`}</b>
              {`C61 M56 Y54 K28\n`}
              <b>{`RGB\n`}</b>
              {`R92 G89 B89`}
            </ColorText>
          </ColorBox>
          <ColorBox
            style={{ marginRight: windowSize.innerWidth <= 767 ? 0 : 138 }}
          >
            <Color style={{ backgroundColor: "#221F20" }} />
            <ColorText>
              <b>{`CMYK\n`}</b>
              {`C70 M68 Y64 K74\n`}
              <b>{`RGB\n`}</b>
              {`R35 G31 B32`}
            </ColorText>
          </ColorBox>
          <ColorBox>
            <Color style={{ backgroundColor: "#D9322A" }} />
            <ColorText>
              <b>{`CMYK\n`}</b>
              {`C1 M100 Y100 K0\n`}
              <b>{`RGB\n`}</b>
              {`R236 G15 B24`}
            </ColorText>
          </ColorBox>
        </ColorContainer>
      </RowDiv>
    </>
  );
};

export default CI;

import React from "react";
import styled from "styled-components";

import MainImageSlider from "../../components/MainImageSlider";
import BasicContainer from "../../components/BasicContainer";

const Content = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;

  @media screen and (max-width: 1439px) {
    width: 100%;
  }
`;

const Home = () => {
  return (
    <BasicContainer metaTitle="관유정">
      <Content>
        <MainImageSlider />
      </Content>
    </BasicContainer>
  );
};

export default Home;

import React from "react";
import styled from "styled-components";
import BasicContainer from "../../../components/BasicContainer";

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Agency = () => {
  return (
    <BasicContainer metaTitle="관유정-진흥원">
      <Content></Content>
    </BasicContainer>
  );
};

export default Agency;

import React from "react";
import styled from "styled-components";
import { Black, Gray100, Gray400 } from "../config/color";
import { toStringByFormatting } from "../utils";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 124px;
`;

const List = styled.div`
  width: calc(100% - 48px);
  padding: 24px;
  margin-bottom: 16px;
  background-color: ${Gray100};
  border-radius: 5px;
`;

const ListInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const Creator = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ProfileImg = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 8px;
  border-radius: 20px;
`;

const UserName = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Black};
  margin-right: 8px;
`;

const CreateAt = styled.div`
  font-weight: 400;
  font-size: 13px;
  color: ${Gray400};
`;

const Content = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 28.8px;
  color: #545454;
  margin-top: 12px;
`;

const EditText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: ${Gray400};
  &:hover {
    cursor: pointer;
  }
`;

const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const CommentList = (props) => {
  const { list, viewStyle } = props;
  return (
    <Container style={viewStyle}>
      {list.map((item, idx) => {
        return (
          <List key={`comment_${idx}`}>
            <ListInfo>
              <Creator>
                <ProfileImg src={item.creator.profile} />
                <ColumnBox>
                  <UserName>{item.creator.name}</UserName>
                  <CreateAt>{toStringByFormatting(item.createdAt)}</CreateAt>
                </ColumnBox>
              </Creator>
              <EditText style={{ marginLeft: "auto" }}>수정</EditText>
              <EditText style={{ marginLeft: 10 }}>삭제</EditText>
            </ListInfo>
            <Content>{item.content}</Content>
          </List>
        );
      })}
    </Container>
  );
};

export default CommentList;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { TAB } from "../../../components/Tab";
import { Black, Gray100, Gray300, Green600 } from "../../../config/color";

import {
  ADDRESS_BOX,
  TEXTAREA,
  TF_A_EXPAND,
  TF_B_EXPAND,
} from "../../../components/TextField";
import { CheckBoxExpand } from "../../../components/Checkbox";
import { ButtonB } from "../../../components/Button";
import { getWindowSize } from "../../../utils";

const RegisterImg = require("../../../assets/icon/Register44.png");

const Form = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 1095px;

  @media screen and (max-width: 1439px) {
    width: 100%;
  }
`;

const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 1095px;
  margin-top: 56px;
  padding-top: 28px;
  border-top: 1px solid ${Gray300};

  @media screen and (max-width: 1439px) {
    width: calc(100% - 40px);
    margin: 0 20px;
    margin-top: 32px;
    padding-top: 28px;
  }
`;

const FormDivTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Icon = styled.img`
  width: 44px;
  height: 44px;
`;

const FormDivTitleText = styled.div`
  font-family: "Nanum Myeongjo", serif;
  font-weight: 600;
  font-size: 26px;
  line-height: 140%;
  color: ${Black};
`;

const FullWidth = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PageTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const Title = styled.div`
  font-family: "Nanum Myeongjo", serif;
  font-weight: 600;
  font-size: 34px;
  line-height: 51px;
  color: ${Black};
  margin-top: 8px;

  @media screen and (max-width: 1439px) {
    font-size: 26px;
    line-height: 36px;
  }
`;

const MyInfo = (props) => {
  const { selectedTab, setSelectedTab } = props;

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const [userId, setUserId] = useState("");

  const [name, setName] = useState("");
  const [nickname, setNickname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [addressDetail, setAddressDetail] = useState("");

  const [selfIntro, setSelfIntro] = useState("");
  const [agreeEmail, setAgreeEmail] = useState(false);
  const [agreePhone, setAgreePhone] = useState(false);
  const [shareInfo, setShareInfo] = useState(false);

  const onUpdateUser = () => {
    console.log("signup");
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Form>
      <FullWidth>
        <TAB
          tabList={[
            { name: "내 정보" },
            { name: "장바구니" },
            { name: "주문 내역" },
          ]}
          viewStyle={{
            marginTop: windowSize.innerWidth <= 1439 ? 28 : 32,
            marginBottom: windowSize.innerWidth <= 1439 ? 32 : 32,
          }}
          tabStyle={{
            width:
              windowSize.innerWidth <= 1439
                ? windowSize.innerWidth / 3 - 18
                : "auto",
            paddingTop: 0,
            paddingBottom: 9,
            paddingLeft: windowSize.innerWidth <= 1439 ? 8 : 90,
            paddingRight: windowSize.innerWidth <= 1439 ? 8 : 90,
          }}
          activeTabIdx={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <PageTitle>마이페이지</PageTitle>
        <Title>내 정보</Title>
      </FullWidth>
      <FormDiv>
        <FormDivTitle>
          <Icon src={RegisterImg} />
          <FormDivTitleText>신청 정보</FormDivTitleText>
        </FormDivTitle>
        <TF_B_EXPAND
          val={userId}
          setVal={setUserId}
          required={false}
          label="아이디"
          labelStyle={windowSize.innerWidth <= 1439 ? { width: "100%" } : null}
          viewStyle={{
            width: windowSize.innerWidth <= 1439 ? "calc(100% - 16px)" : 490,
            marginTop: 24,
          }}
          inputStyle={{
            width: windowSize.innerWidth <= 1439 ? "calc(100% - 16px)" : 490,
          }}
          placeholder="영문 숫자 조합 3자 이상"
          inputType="text"
          disabled={true}
        />
      </FormDiv>
      <FormDiv>
        <FormDivTitle>
          <Icon src={RegisterImg} />
          <FormDivTitleText>개인정보 입력</FormDivTitleText>
        </FormDivTitle>
        <TF_B_EXPAND
          val={name}
          setVal={setName}
          required={false}
          label="이름"
          labelStyle={windowSize.innerWidth <= 1439 ? { width: "100%" } : null}
          viewStyle={{
            width: windowSize.innerWidth <= 1439 ? "calc(100% - 16px)" : 490,
            marginTop: 24,
          }}
          inputStyle={{
            width: windowSize.innerWidth <= 1439 ? "calc(100% - 16px)" : 490,
          }}
          placeholder="이름 입력"
          inputType="text"
        />
        <TF_B_EXPAND
          val={nickname}
          setVal={setNickname}
          required={false}
          label="닉네임"
          labelStyle={windowSize.innerWidth <= 1439 ? { width: "100%" } : null}
          viewStyle={{
            width: windowSize.innerWidth <= 1439 ? "calc(100% - 16px)" : 490,
            marginTop: 24,
          }}
          inputStyle={{
            width: windowSize.innerWidth <= 1439 ? "calc(100% - 16px)" : 490,
          }}
          placeholder="닉네임 입력"
          inputType="text"
        />
        <TF_B_EXPAND
          val={email}
          setVal={setEmail}
          required={false}
          label="이메일"
          labelStyle={windowSize.innerWidth <= 1439 ? { width: "100%" } : null}
          viewStyle={{
            width: windowSize.innerWidth <= 1439 ? "calc(100% - 16px)" : 490,
            marginTop: 24,
          }}
          inputStyle={{
            width: windowSize.innerWidth <= 1439 ? "calc(100% - 16px)" : 490,
          }}
          placeholder="이메일 입력"
          inputType="text"
        />
        <TF_B_EXPAND
          val={phone}
          setVal={setPhone}
          required={false}
          label="연락처"
          labelStyle={windowSize.innerWidth <= 1439 ? { width: "100%" } : null}
          viewStyle={{
            width: windowSize.innerWidth <= 1439 ? "calc(100% - 16px)" : 490,
            marginTop: 24,
          }}
          inputStyle={{
            width: windowSize.innerWidth <= 1439 ? "calc(100% - 16px)" : 490,
          }}
          placeholder="연락처 입력"
          inputType="text"
        />
        <ADDRESS_BOX
          val={address}
          setVal={setAddress}
          placeholder="주소지 선택"
          inputStyle={{
            width: windowSize.innerWidth <= 1439 ? "calc(100% - 16px)" : 440,
          }}
          inputTYpe="text"
          label="주소지"
          labelStyle={windowSize.innerWidth <= 1439 ? { width: "100%" } : null}
          viewStyle={{
            marginTop: 24,
          }}
          inputContainerStyle={{
            backgroundColor: Gray100,
            paddingLeft: 16,
            paddingRight: 16,
            width:
              windowSize.innerWidth <= 1439 ? "calc(100% - 30.5px)" : 492.5,
          }}
        />
        <TF_A_EXPAND
          val={addressDetail}
          setVal={setAddressDetail}
          placeholder="상세주소 입력"
          inputStyle={{
            width: windowSize.innerWidth <= 1439 ? "calc(100% - 16px)" : 490,
          }}
          viewStyle={{
            width: windowSize.innerWidth <= 1439 ? "calc(100% - 16px)" : 490,
            marginTop: 16,
          }}
        />
      </FormDiv>
      <FormDiv>
        <FormDivTitle>
          <Icon src={RegisterImg} />
          <FormDivTitleText>기타 입력</FormDivTitleText>
        </FormDivTitle>
        <TEXTAREA
          val={selfIntro}
          setVal={setSelfIntro}
          placeholder="자기소개 입력"
          inputStyle={{
            width: windowSize.innerWidth <= 1439 ? "calc(100% - 16px)" : 490,
          }}
          viewStyle={{
            width: windowSize.innerWidth <= 1439 ? "calc(100% - 16px)" : 490,
            marginTop: 16,
          }}
        />
        <CheckBoxExpand
          text="정보 메일을 받겠습니다."
          isChecked={agreeEmail}
          setIsChecked={setAgreeEmail}
          viewStyle={{
            width: windowSize.innerWidth <= 1439 ? "calc(100% - 32px)" : 490,
            marginTop: 32,
          }}
        />
        <CheckBoxExpand
          text="휴대폰 문자메시지를 받겠습니다."
          isChecked={agreePhone}
          setIsChecked={setAgreePhone}
          viewStyle={{
            width: windowSize.innerWidth <= 1439 ? "calc(100% - 32px)" : 490,
            marginTop: 16,
          }}
        />
        <CheckBoxExpand
          text="다른 사람에게 정보를 공개합니다."
          isChecked={shareInfo}
          setIsChecked={setShareInfo}
          viewStyle={{
            width: windowSize.innerWidth <= 1439 ? "calc(100% - 32px)" : 490,
            marginTop: 16,
          }}
        />
        <FullWidth style={{ marginTop: 60, marginBottom: 82 }}>
          <ButtonB text="수정하기" onClickEvent={onUpdateUser} />
        </FullWidth>
      </FormDiv>
    </Form>
  );
};

export default MyInfo;

import React, { useState } from "react";
import styled from "styled-components";
import BasicContainer from "../../../components/BasicContainer";
import {
  Black,
  Gray100,
  Gray200,
  Gray400,
  Gray500,
  Green100,
  Green300,
  Green500,
  Green600,
  White,
} from "../../../config/color";

import { CardWithIcon } from "../../../components/Card";

const Water = require("../../../assets/icon/Water99.png");
const Sun = require("../../../assets/icon/Sun99.png");
const Sprout = require("../../../assets/icon/Sprout99.png");
const Soil = require("../../../assets/icon/Soil99.png");
const Spring = require("../../../assets/icon/Spring99.png");
const Fall = require("../../../assets/icon/Fall99.png");
const PageTop = require("../../../components/PageTop");
const ThreeCircle = require("./Graph");
const FarmHouse = require("../../../assets/image/FarmHouse.png");

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 1440px; //calc(100% - 112px);
  margin: 0 auto;
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  color: ${Black};
`;

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 1440px;
  margin-bottom: 24px;
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 28.8px;
  white-space: pre-wrap;
  text-align: center;
`;

const PointBox = styled.div`
  width: 100%;
  padding: 48px 0;
  margin-top: 40px;
`;

const Table = styled.div`
  width: 1328px;
  display: flex;
  flex-direction: column;
`;

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const TableCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(50px - 2px);
  border: 1px solid ${Gray200};
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Gray500};
  background-color: ${White};
`;

const FarmHouseImg = styled.img`
  width: 1440px;
  height: 177px;
  margin: 0 auto;
  margin-bottom: 66px;
`;

const AnnualCareGuideline = () => {
  return (
    <BasicContainer metaTitle="관유정-연간 관리 지침">
      <Content>
        <PageTop
          label="기술지원"
          title="연간 관리 지침"
          ImageUrl="/assets/image/TechnicalHeaderImg.png"
        />
        <TitleBox
          style={{ marginTop: 64, marginBottom: 41, textAlign: "center" }}
        >
          <TitleLabel>연간 관리 지침</TitleLabel>
          <TitleText>난초가 잘 자라는 조건</TitleText>
        </TitleBox>
        <RowDiv>
          <ThreeCircle
            topText="조도"
            leftText="물"
            rightText="온도"
            viewStyle={{ backgroundColor: Green500 }}
          />
        </RowDiv>
        <RowDiv style={{ marginTop: 64 }}>
          <CardWithIcon
            viewStyle={{
              backgroundColor: Green100,
              marginRight: 8,
              height: 306,
            }}
            title="첫번째, 물관리"
            content={`수돗물(pH7)을 30초 동안 신촉을 보며 흠뻑 준다.
1년에 200번 이상 준다. (11~3월 3일 1회, 4~10월 1-2일 1회)`}
            iconImg={Water}
          />
          <CardWithIcon
            viewStyle={{
              backgroundColor: Green100,
              marginLeft: 8,
              height: 306,
            }}
            title="두번째, 햇빛 관리"
            content={`평균 조도 5,000~7,000lux로 연간 2,500시간 (일 평균 7시간)
잎 윗면에 햇빛이 닿을 수 있도록 관리
(난실 여건이 좋지 않으면 LED 식물등 활용)`}
            iconImg={Sun}
          />
        </RowDiv>
        <RowDiv>
          <CardWithIcon
            viewStyle={{
              backgroundColor: Green100,
              marginRight: 8,
              height: 306,
            }}
            title="세번째, 온도 관리"
            content={`광합성 최적 온도 범위는 22~26도
겨울철 야간 온도 : 6~7도
여름철 주간 기온 28~30도`}
            iconImg={Sprout}
          />
          <CardWithIcon
            viewStyle={{
              backgroundColor: Green100,
              marginLeft: 8,
              height: 306,
            }}
            title="네번째, 비료 관리"
            content={`난초의 생육 시기에 따라 부족한 영양분을 충족시켜
1등급으로 유지시키기 위해 주는 영양분`}
            iconImg={Soil}
          />
        </RowDiv>
        <TitleBox
          style={{ marginTop: 88, marginBottom: 41, textAlign: "center" }}
        >
          <TitleLabel>연간 관리 지침</TitleLabel>
          <TitleText>분갈이</TitleText>
          <Text style={{ marginTop: 16 }}>{`난초 분내 환경을 개선시켜 1등급
난초로 기르기 위해 시행하는 기술`}</Text>
        </TitleBox>
        <RowDiv>
          <ThreeCircle
            topText="건강검진"
            leftText={`뿌리풀
질관리`}
            rightText={`분내
환경개선`}
            viewStyle={{ backgroundColor: Gray400 }}
          />
        </RowDiv>
        <RowDiv style={{ marginTop: 64 }}>
          <CardWithIcon
            viewStyle={{
              backgroundColor: Gray100,
              marginRight: 8,
              height: 306,
            }}
            title="분갈이의 목적 - 봄"
            content={`새 뿌리가 내릴 수 있는 공간과 면적을 미리 확보해 주기 위해
경화 된 난석을 부드러운 것으로 교체해 뿌리 발달을 돕기 위해
신아가 자라는데 방해되는 요소를 미리 제거하고 확보`}
            iconImg={Spring}
          />
          <CardWithIcon
            viewStyle={{
              backgroundColor: Gray100,
              marginLeft: 8,
              height: 306,
            }}
            title="분갈이의 목적 - 가을"
            content={` • 80% 성장 시
 • 새 뿌리가 80% 쯤 자랐을 때가 분내 병목지점을 
    통과한 시점이라 뿌리 상태를 확인하기 위해
 • 분내 환경 개선과 뿌리의 질병을 점검하고 치료하기 위해`}
            iconImg={Fall}
          />
        </RowDiv>
        <PointBox style={{ backgroundColor: Green600 }}>
          <TitleBox style={{ textAlign: "center" }}>
            <TitleLabel style={{ color: White }}>연간 관리 지침</TitleLabel>
            <TitleText style={{ color: White }}>월별 연간 관리 지침</TitleText>
            <Table
              style={{
                marginTop: 24,
                marginBottom: 100,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <TableRow>
                <TableCell
                  style={{ backgroundColor: Green300, width: 110 - 2 }}
                >
                  1월
                </TableCell>
                <TableCell
                  style={{ backgroundColor: Green300, width: 110 - 2 }}
                >
                  2월
                </TableCell>
                <TableCell
                  style={{ backgroundColor: Green300, width: 110 - 2 }}
                >
                  3월
                </TableCell>
                <TableCell
                  style={{ backgroundColor: Green300, width: 110 - 2 }}
                >
                  4월
                </TableCell>
                <TableCell
                  style={{ backgroundColor: Green300, width: 110 - 2 }}
                >
                  5월
                </TableCell>
                <TableCell
                  style={{ backgroundColor: Green300, width: 110 - 2 }}
                >
                  6월
                </TableCell>
                <TableCell
                  style={{ backgroundColor: Green300, width: 110 - 2 }}
                >
                  7월
                </TableCell>
                <TableCell
                  style={{ backgroundColor: Green300, width: 110 - 2 }}
                >
                  8월
                </TableCell>
                <TableCell
                  style={{ backgroundColor: Green300, width: 110 - 2 }}
                >
                  9월
                </TableCell>
                <TableCell
                  style={{ backgroundColor: Green300, width: 110 - 2 }}
                >
                  10월
                </TableCell>
                <TableCell
                  style={{ backgroundColor: Green300, width: 110 - 2 }}
                >
                  11월
                </TableCell>
                <TableCell
                  style={{ backgroundColor: Green300, width: 110 - 2 }}
                >
                  12월
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: 110 - 2 }}>겨울광합성</TableCell>
                <TableCell style={{ width: 220 - 2 }}>겨울광합성</TableCell>
                <TableCell style={{ width: 220 - 2 }}>겨울광합성</TableCell>
                <TableCell style={{ width: 331 - 2 }}>겨울광합성</TableCell>
                <TableCell style={{ width: 110 - 2 }}>겨울광합성</TableCell>
                <TableCell style={{ width: 110 - 2 }}>겨울광합성</TableCell>
                <TableCell style={{ width: 221 - 2 }}>겨울광합성</TableCell>
              </TableRow>
            </Table>
            <FarmHouseImg src={FarmHouse} />
          </TitleBox>
        </PointBox>
      </Content>
    </BasicContainer>
  );
};

export default AnnualCareGuideline;

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import BasicContainer from "../../../components/BasicContainer";
import BoardEditor from "../../../components/BoardEditor";
import { ButtonA } from "../../../components/Button";
import { CheckBox } from "../../../components/Checkbox";

import { TF_A } from "../../../components/TextField";
import { Black, Green600 } from "../../../config/color";
import { getWindowSize } from "../../../utils";

import "./editor.css";

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1439px) {
    width: calc(100% - 40px);
    min-width: 360px;
    padding: 0 20px;
  }
`;

const EditorContainer = styled.div`
  display: flex;
  width: calc(100% - 416px);
  flex-direction: column;
  padding: 72px 208px;
  position: relative;
  @media screen and (max-width: 1439px) {
    width: 100%;
    padding: 36px 0;
  }
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  color: ${Black};
`;

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

const Writing = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [isSecret, setIsSecret] = useState(false);
  const [password, setPassword] = useState("");

  const CreateBoard = () => {
    navigate("/company/board");
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <BasicContainer metaTitle="관유정-자유게시판 글쓰기">
      <Content>
        <EditorContainer>
          <TitleLabel>자유게시판</TitleLabel>
          <TitleText>글 작성</TitleText>
          <TF_A
            viewStyle={{ marginTop: 16 }}
            inputStyle={{ width: "calc(100% - 34px)" }}
            val={title}
            setVal={setTitle}
            placeholder="제목을 입력해주세요"
          />
          <BoardEditor viewStyle={{ marginTop: 16, width: "100%" }} />
          <RowDiv style={{ marginTop: 24, marginBottom: 121 }}>
            <CheckBox
              isChecked={isSecret}
              setIsChecked={setIsSecret}
              text="비밀글 설정"
              viewStyle={{
                width: windowSize.innerWidth <= 767 ? "calc(100% - 34px)" : 214,
                marginBottom: windowSize.innerWidth <= 767 ? 12 : 0,
              }}
            />
            <TF_A
              disabled={!isSecret}
              val={password}
              setVal={setPassword}
              inputType="password"
              viewStyle={{
                marginLeft: windowSize.innerWidth <= 767 ? 0 : 8,
                marginBottom: windowSize.innerWidth <= 767 ? 12 : 0,
                width: "calc(100% - 34px)",
              }}
              inputStyle={{
                width: windowSize.innerWidth <= 767 ? "100%" : 244,
              }}
              placeholder="비밀번호 입력"
            />
            <ButtonA
              text="등록"
              viewStyle={{
                marginLeft: "auto",
                width: 106,
              }}
              onClickEvent={CreateBoard}
            />
          </RowDiv>
        </EditorContainer>
      </Content>
    </BasicContainer>
  );
};

export default Writing;

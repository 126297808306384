import React from "react";
import styled from "styled-components";
import { Black, Gray100, Gray200, Gray400, Gray500 } from "../config/color";
import { OnlyCheckBox } from "./Checkbox";
import { thousandComma } from "../utils";

const MinusImg = require("../assets/icon/Minus26.png");
const PlusImg = require("../assets/icon/Plus26.png");
const EmptyImg = require("../assets/icon/Close29.png");

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  margin: 0 20px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  padding: 14px 0;
  align-items: center;
  border-top: 1px solid ${Gray200};
  width: 100%;
`;

const ItemHalf = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ItemImg = styled.img`
  width: 81px;
  height: 81px;
`;

const ItemName = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 32.4px;
  display: flex;
  align-items: center;
  margin-top: 12px;
`;

const ItemText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Gray500};
`;

const CellBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const CalcButtonImg = styled.img`
  width: 26px;
  height: 26px;
  &:hover {
    cursor: pointer;
  }
`;

const EmptyBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${Gray100};
  border-bottom: 1px solid ${Gray200};
  height: 142px;
  width: 100%;
`;

const EmptyBoxIcon = styled.img`
  width: 29px;
  height: 29px;
`;

const EmptyBoxText = styled.div`
  margin-top: 12px;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: ${Gray400};
`;

const TotalBox = styled.div`
  background-color: ${Gray100};
  height: 60px;
  padding: 12px 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-size: 16px;
  font-weight: 700;
  line-height: 28.8px;
  color: ${Gray500};
`;

const TotalResultText = styled.div`
  margin-left: 16px;
  font-size: 20px;
  font-weight: 700;
  line-height: 36px;
  color: ${Black};
`;

const TableMobile = (props) => {
  const {
    dataList,
    toggleAllSelected,
    toggleSelected,
    selected,
    allSelected,
    viewStyle,
    minusCount,
    addCount,
  } = props;

  const calcTotal = () => {
    let result = 0;
    let selectedCount = 0;
    if (Object.keys(selected).length > 0) {
      for (let i in dataList) {
        for (let key in selected) {
          if (key === dataList[i]._id && selected[key]) {
            result += dataList[i].count * dataList[i].price;
            selectedCount++;
          }
        }
      }
    }
    return thousandComma(result) + "원 / " + selectedCount + "개";
  };

  return (
    <Container style={viewStyle}>
      {dataList.length > 0 ? (
        dataList.map((item, idx) => {
          return (
            <Item key={item._id}>
              <ItemHalf style={{ alignItems: "flex-start" }}>
                <OnlyCheckBox
                  id={item._id}
                  isAllSelect={false}
                  toggleAllSelected={toggleAllSelected}
                  toggleSelected={toggleSelected}
                  selected={selected}
                  allSelected={allSelected}
                />
                <ItemName>{item.name}</ItemName>
                <ItemText>포인트 {item.point}p</ItemText>
                <ItemText>배송비 {item.deliveryFee}</ItemText>
                <ItemText
                  style={{ fontWeight: "700", fontSize: 18, lineHiehgt: 32 }}
                >
                  {thousandComma(item.price * item.count)}원
                </ItemText>
              </ItemHalf>
              <ItemHalf
                style={{
                  alignItems: "flex-end",
                  justifyContent: "flex-start",
                  height: "100%",
                }}
              >
                <ItemImg src={item.image} />
                <CellBox style={{ marginTop: "auto" }}>
                  <CalcButtonImg
                    src={MinusImg}
                    onClick={() => minusCount(item._id)}
                    style={{ marginRight: 32 }}
                  />
                  <ItemText>{item.count}개</ItemText>
                  <CalcButtonImg
                    src={PlusImg}
                    onClick={() => addCount(item._id)}
                    style={{ marginLeft: 32 }}
                  />
                </CellBox>
              </ItemHalf>
            </Item>
          );
        })
      ) : (
        <EmptyBox>
          <EmptyBoxIcon src={EmptyImg} />
          <EmptyBoxText>내용이 존재하지 않습니다</EmptyBoxText>
        </EmptyBox>
      )}
      <TotalBox>
        총금액
        <TotalResultText>{calcTotal()}</TotalResultText>
      </TotalBox>
    </Container>
  );
};

export default TableMobile;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BasicContainer from "../../../components/BasicContainer";
import PageTop from "../../../components/PageTop";

import { Black, Gray100, Gray300 } from "../../../config/color";

import {
  ADDRESS_BOX,
  TEXTAREA,
  TF_A_EXPAND,
  TF_B_EXPAND,
} from "../../../components/TextField";
import { CheckBoxExpand, CheckBoxWithLink } from "../../../components/Checkbox";
import { ButtonB } from "../../../components/Button";
import { getWindowSize } from "../../../utils";
import { useNavigate } from "react-router-dom";

const RegisterImg = require("../../../assets/icon/Register44.png");

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 1095px;
  @media screen and (max-width: 1439px) {
    width: calc(100% - 40px);
    padding: 24px 20px;
  }
`;

const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 1095px;
  margin-top: 56px;
  padding-top: 28px;
  border-top: 1px solid ${Gray300};
  @media screen and (max-width: 1439px) {
    margin-top: 32px;
    width: 100%;
  }
`;

const FormDivTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Icon = styled.img`
  width: 44px;
  height: 44px;
`;

const FormDivTitleText = styled.div`
  font-family: "Nanum Myeongjo", serif;
  font-weight: 600;
  font-size: 26px;
  line-height: 140%;
  color: ${Black};
`;

const FullWidth = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Signup = (props) => {
  const navigate = useNavigate();

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const [userId, setUserId] = useState("");
  const [userPw, setUserPw] = useState("");
  const [userPwConfirm, setUserPwConfirm] = useState("");

  const [name, setName] = useState("");
  const [nickname, setNickname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [addressDetail, setAddressDetail] = useState("");

  const [selfIntro, setSelfIntro] = useState("");
  const [agreeEmail, setAgreeEmail] = useState(false);
  const [agreePhone, setAgreePhone] = useState(false);
  const [shareInfo, setShareInfo] = useState(false);
  const [agreeAll, setAgreeAll] = useState(false);
  const [agreeCondition, setAgreeCondition] = useState(false);
  const [agreePrivacy, setAgreePrivacy] = useState(false);

  const onSignup = () => {
    navigate("/signup/complete");
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <BasicContainer metaTitle="관유정-회원가입">
      <Content>
        <PageTop
          label="이대발난초"
          title="회원가입"
          ImageUrl="/assets/image/main_tmp.png"
        />
        <Form>
          <FormDiv>
            <FormDivTitle>
              <Icon src={RegisterImg} />
              <FormDivTitleText>신청 정보</FormDivTitleText>
            </FormDivTitle>
            <TF_B_EXPAND
              val={userId}
              setVal={setUserId}
              required={false}
              label="아이디"
              viewStyle={{
                width: windowSize.innerWidth <= 1439 ? 320 : 490,
                marginTop: 24,
              }}
              inputStyle={{ width: windowSize.innerWidth <= 1439 ? 320 : 490 }}
              labelStyle={windowSize.innerWidth <= 767 ? { width: 320 } : null}
              placeholder="영문 숫자 조합 3자 이상"
              inputType="text"
            />
            <TF_B_EXPAND
              val={userPw}
              setVal={setUserPw}
              required={false}
              label="비밀번호"
              viewStyle={{
                width: windowSize.innerWidth <= 1439 ? 320 : 490,
                marginTop: 24,
              }}
              inputStyle={{ width: windowSize.innerWidth <= 1439 ? 320 : 490 }}
              labelStyle={windowSize.innerWidth <= 767 ? { width: 320 } : null}
              placeholder="영문 숫자 조합 8자 이상"
              inputType="password"
            />
            <TF_B_EXPAND
              val={userPwConfirm}
              setVal={setUserPwConfirm}
              required={false}
              label="비밀번호 확인"
              viewStyle={{
                width: windowSize.innerWidth <= 1439 ? 320 : 490,
                marginTop: 24,
              }}
              inputStyle={{ width: windowSize.innerWidth <= 1439 ? 320 : 490 }}
              labelStyle={windowSize.innerWidth <= 767 ? { width: 320 } : null}
              placeholder="비밀번호 재 입력"
              inputType="password"
            />
          </FormDiv>
          <FormDiv>
            <FormDivTitle>
              <Icon src={RegisterImg} />
              <FormDivTitleText>개인정보 입력</FormDivTitleText>
            </FormDivTitle>
            <TF_B_EXPAND
              val={name}
              setVal={setName}
              required={false}
              label="이름"
              viewStyle={{
                width: windowSize.innerWidth <= 1439 ? 320 : 490,
                marginTop: 24,
              }}
              inputStyle={{ width: windowSize.innerWidth <= 1439 ? 320 : 490 }}
              labelStyle={windowSize.innerWidth <= 767 ? { width: 320 } : null}
              placeholder="이름 입력"
              inputType="text"
            />
            <TF_B_EXPAND
              val={nickname}
              setVal={setNickname}
              required={false}
              label="닉네임"
              viewStyle={{
                width: windowSize.innerWidth <= 1439 ? 320 : 490,
                marginTop: 24,
              }}
              inputStyle={{ width: windowSize.innerWidth <= 1439 ? 320 : 490 }}
              labelStyle={windowSize.innerWidth <= 767 ? { width: 320 } : null}
              placeholder="닉네임 입력"
              inputType="text"
            />
            <TF_B_EXPAND
              val={email}
              setVal={setEmail}
              required={false}
              label="이메일"
              viewStyle={{
                width: windowSize.innerWidth <= 1439 ? 320 : 490,
                marginTop: 24,
              }}
              inputStyle={{ width: windowSize.innerWidth <= 1439 ? 320 : 490 }}
              labelStyle={windowSize.innerWidth <= 767 ? { width: 320 } : null}
              placeholder="이메일 입력"
              inputType="text"
            />
            <TF_B_EXPAND
              val={phone}
              setVal={setPhone}
              required={false}
              label="연락처"
              viewStyle={{
                width: windowSize.innerWidth <= 1439 ? 320 : 490,
                marginTop: 24,
              }}
              inputStyle={{ width: windowSize.innerWidth <= 1439 ? 320 : 490 }}
              labelStyle={windowSize.innerWidth <= 767 ? { width: 320 } : null}
              placeholder="연락처 입력"
              inputType="text"
            />
            <ADDRESS_BOX
              val={address}
              setVal={setAddress}
              placeholder="주소지 선택"
              inputStyle={{ width: windowSize.innerWidth <= 1439 ? 320 : 440 }}
              inputTYpe="text"
              label="주소지"
              viewStyle={{
                marginTop: 24,
              }}
              inputContainerStyle={{
                backgroundColor: Gray100,
                paddingLeft: 16,
                paddingRight: 16,
                width: windowSize.innerWidth <= 1439 ? 321.5 : 492.5,
              }}
            />
            <TF_A_EXPAND
              val={addressDetail}
              setVal={setAddressDetail}
              placeholder="상세주소 입력"
              inputStyle={{ width: windowSize.innerWidth <= 1439 ? 320 : 490 }}
              labelStyle={windowSize.innerWidth <= 767 ? { width: 320 } : null}
              viewStyle={{
                width: windowSize.innerWidth <= 1439 ? 320 : 490,
                marginTop: 16,
              }}
            />
          </FormDiv>
          <FormDiv>
            <FormDivTitle>
              <Icon src={RegisterImg} />
              <FormDivTitleText>기타 입력</FormDivTitleText>
            </FormDivTitle>
            <TEXTAREA
              val={selfIntro}
              setVal={setSelfIntro}
              placeholder="자기소개 입력"
              inputStyle={{ width: windowSize.innerWidth <= 1439 ? 320 : 490 }}
              viewStyle={{
                width: windowSize.innerWidth <= 1439 ? 320 : 490,
                marginTop: 16,
              }}
            />
            <CheckBoxExpand
              text="정보 메일을 받겠습니다."
              isChecked={agreeEmail}
              setIsChecked={setAgreeEmail}
              viewStyle={{
                width: windowSize.innerWidth <= 1439 ? 320 : 490,
                marginTop: 32,
              }}
            />
            <CheckBoxExpand
              text="휴대폰 문자메시지를 받겠습니다."
              isChecked={agreePhone}
              setIsChecked={setAgreePhone}
              viewStyle={{
                width: windowSize.innerWidth <= 1439 ? 320 : 490,
                marginTop: 16,
              }}
            />
            <CheckBoxExpand
              text="다른 사람에게 정보를 공개합니다."
              isChecked={shareInfo}
              setIsChecked={setShareInfo}
              viewStyle={{
                width: windowSize.innerWidth <= 1439 ? 320 : 490,
                marginTop: 16,
              }}
            />
            <CheckBoxExpand
              text="전체 동의"
              isChecked={agreeAll}
              setIsChecked={setAgreeAll}
              viewStyle={{
                width: windowSize.innerWidth <= 1439 ? 320 : 490,
                marginTop: 32,
                backgroundColor: "#F5F6F8",
                borderColor: "#F5F6F8",
              }}
              textStyle={{ fontWeight: "700", fontSize: 14, color: "#000" }}
            />
            <CheckBoxWithLink
              text="회원가입 약관 동의 (필수)"
              linkText="전문"
              isChecked={agreeCondition}
              setIsChecked={setAgreeCondition}
              viewStyle={{
                width: windowSize.innerWidth <= 1439 ? 320 : 490,
                marginTop: 16,
                borderColor: "#fff",
              }}
              textStyle={{ fontSize: 13 }}
            />
            <CheckBoxWithLink
              text="개인정보 제공 및 이용동의 (필수)"
              linkText="전문"
              isChecked={agreePrivacy}
              setIsChecked={setAgreePrivacy}
              viewStyle={{
                width: windowSize.innerWidth <= 1439 ? 320 : 490,
                marginTop: 16,
                borderColor: "#fff",
              }}
              textStyle={{ fontSize: 13 }}
            />
            <FullWidth style={{ marginTop: 60, marginBottom: 82 }}>
              <ButtonB text="회원가입" onClickEvent={onSignup} />
            </FullWidth>
          </FormDiv>
        </Form>
      </Content>
    </BasicContainer>
  );
};

export default Signup;

// Mobile Width
export const MobileMinWidth = "360px";
export const MobileMaxWidth = "767px";
// Mobile Card
export const MobileCardWidth = "160px";
export const MobileCardHeight = "86px";

// Tablet Width
export const TabletMinWidth = "768px";
export const TabletMaxWidth = "1439px";
// Tablet Card
export const TabletCardWidth = "160px";
export const TabletCardHeight = "86px";

// PC Width
export const PCMinWidth = "1440px";
// PC Card
export const PCCardWidth = "320px";
export const PCCardHeight = "86px";

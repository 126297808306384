import React from "react";
import styled from "styled-components";

import {
  Black,
  Gray200,
  Gray300,
  Gray600,
  Green100,
  Green600,
  White,
} from "../../../config/color";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 1328px;
  margin-top: 40px;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Card = styled.div`
  width: 1328px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${Gray200};
  border-radius: 10px;
`;

const InfoBox = styled.div`
  padding: 24px;
`;

const Tag = styled.div`
  width: 100px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Gray600};
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: ${White};
  margin-bottom: 12px;
`;

const Title = styled.div`
  font-family: "Nanum Myeongjo";
  font-size: 26px;
  font-weight: 600;
  line-height: 36px;
  color: ${Black};
  margin-bottom: 24px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 8px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 8px;
`;

const Label = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  width: 28px;
  margin-right: 8px;
`;

const Content = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  white-space: pre-wrap;
  flex: 1;
`;

const Image = styled.img`
  width: 100%;
  height: 230px;
  object-fit: cover;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

const FirstGradeCard = (props) => {
  return (
    <Container>
      <InnerContainer>
        <Card>
          <InfoBox>
            <Tag>2급 자격 과정</Tag>
            <Title>프로스킬 & 프로작가</Title>
            <Row>
              <Label>장소</Label>
              <Content>대한민국난문화진흥원 (대구시 수성구 청호로 72)</Content>
            </Row>
            <Row>
              <Label>정원</Label>
              <Content>1~3명</Content>
            </Row>
            <Row>
              <Label>개강</Label>
              <Content>상시 협의 개강 1박 2일</Content>
            </Row>
            <Row>
              <Label>일정</Label>
              <Content>오전 10~ 오후 6시 하루 4과목씩</Content>
            </Row>
            <Row>
              <Label>특전</Label>
              <Content>
                한국 춘란 관리사 1급 자격증 발급 (대한민국난문화진흥원 발급)
              </Content>
            </Row>
            <Row>
              <Label>비용</Label>
              <Content>500만원 (숙식제공)</Content>
            </Row>
          </InfoBox>
          <Image src="/assets/tmpImg/lecturetmpImg.png" />
        </Card>
      </InnerContainer>
    </Container>
  );
};

export default FirstGradeCard;

import React from "react";
import styled from "styled-components";
import {
  Gray200,
  Gray500,
  Green100,
  Green600,
  White,
} from "../../../config/color";

const Container = styled.div`
  width: 1328px;
  margin: 56px auto;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1439px) {
    margin-bottom: 0;
  }
`;

const Header = styled.div`
  width: 100%;
  height: 48px;
  background-color: ${Green600};
  border: 1px solid ${Gray200};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  color: ${White};
  padding: 15px 0;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  flex: 1;
  background-color: ${Green100};
  border: 1px solid ${Gray200};
  display: flex;
  flex-direction: row;
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Gray500};
`;

const CellBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`;

const Cell = styled.div`
  flex: 1;
  border: 1px solid ${Gray200};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  color: ${Gray500};
  height: 48px;
`;

const MagampKManual = (props) => {
  return (
    <Container>
      <Header>위탁 오더 돌봄 - 비료주기(하이포넥스)</Header>
      <Content>
        <Title>
          <Cell>1월</Cell>
          <Cell>2월</Cell>
          <Cell>3월</Cell>
          <Cell>4월</Cell>
          <Cell>5월</Cell>
          <Cell>6월</Cell>
          <Cell>7월</Cell>
          <Cell>8월</Cell>
          <Cell>9월</Cell>
          <Cell>10월</Cell>
          <Cell>11월</Cell>
          <Cell>12월</Cell>
        </Title>
        <CellBox>
          <Cell>-</Cell>
          <Cell>-</Cell>
          <Cell>1</Cell>
          <Cell>-</Cell>
          <Cell>2</Cell>
          <Cell>-</Cell>
          <Cell>-</Cell>
          <Cell>3</Cell>
          <Cell>3</Cell>
          <Cell>-</Cell>
          <Cell>-</Cell>
          <Cell>-</Cell>
        </CellBox>
        <CellBox>
          <Cell>1-봄분갈이 / 2-스케일링 / 3-가을 분갈이 = 총 3회 교체</Cell>
        </CellBox>
      </Content>
    </Container>
  );
};

export default MagampKManual;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BasicContainer from "../../../components/BasicContainer";
import { getWindowSize } from "../../../utils";

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 360px;
`;

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 1440px;
  margin: 0 auto;
  margin-top: 64px;

  @media screen and (max-width: 1439px) {
    width: calc(100% - 40px);
    flex-direction: column;
    padding: 24px 20px;
    padding-top: 0;
    margin-top: 0;
  }
`;

const Image01 = styled.img`
  width: 657px;
  height: 895px;
  margin-right: 63px;
  @media screen and (max-width: 1439px) {
    width: calc(100% + 40px);
    margin-right: 0;
    margin-left: -20px;
    height: auto;
  }
`;

const Image02 = styled.img`
  width: 185px;
  height: 105px;
  margin-bottom: 32px;
  @media screen and (max-width: 1439px) {
    margin-top: 24px;
  }
`;

const Image03 = styled.img`
  width: 614px;
  height: 397px;
  margin-left: 80px;
  margin-right: 56px;
  @media screen and (max-width: 1439px) {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 32px;
  }
`;

const TextDiv = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1439px) {
    width: 100%;
  }
`;

const BigText = styled.div`
  font-family: "Nanum Myeongjo", serif;
  white-space: pre-wrap;
  font-size: 30px;
  font-weight: 600;
  line-height: 42px;
  margin-bottom: 16px;
  @media screen and (max-width: 1439px) {
    font-size: 26px;
    line-height: 34px;
  }
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 28.8px;
  white-space: pre-wrap;
`;

const Name = styled.div`
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 18px;
  line-height: 27px;
  margin-top: 32px;
`;

const Greeting = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <BasicContainer metaTitle="관유정-인사말">
      <Content>
        <RowDiv>
          <Image01
            src={
              windowSize.innerWidth <= 1439
                ? "/assets/tmpImg/greeting04.png"
                : "/assets/tmpImg/greeting03.png"
            }
          />
          <TextDiv
            style={{ marginRight: windowSize.innerWidth <= 1439 ? 0 : 88 }}
          >
            <Image02 src="/assets/image/header_logo.png" />
            <BigText>
              {windowSize.innerWidth <= 1439
                ? `국민 여러분 만나서 반갑습니다.${`\n`}저는 난초 명장 이대발${`\n`}박사입니다.`
                : `국민 여러분 만나서 반갑습니다.${`\n`}저는 난초 명장 이대발 박사입니다.`}
            </BigText>
            <Text>
              {windowSize.innerWidth <= 1439 ? (
                <>{`현재 관유정 농장과 대한민국 난 문화 진흥원을 운영하고 있으며 1988년 난초에 입문한 후부터 지금까지 난 문화 성숙, 도시농업 발전 및 일자리 창출을 위해 뛰어다니고 있습니다.난계가 태동할 때부터 우리나라는 기술 부족, 교육시스템 부재, 난초를 제대로 알 수 있는 저서 부족으로 의미 있는 성장을 이루지 못해 항상 안타깝게 생각했습니다. 저는 이 문제를 해결하기 위해 공부에 매진하며 난초로 석사, 박사, 명장이 될 수 있었습니다. 이 과정에서 국수풍이라는 한국 춘란 미학 체계를 완성하였으며, 작황을 좋게 하는 다양한 기술을 개발했습니다.

저서 7권을 집필해 난초 전반을 이해할 수 있는 토대를 마련했으며 유튜브 채널 개설로 접근 문턱을 낮추며 100만 인구 저변확대를 위해 전국을 뛰어다니고 있습니다.  이런 노력의 결실로 난초가 임산자원에서 농업자원으로 사회적 인식이 전환되었으며 정부의 적극적인 관심을 끌어내 aT센터에서 정부 주도로 난초 경매가 진행되고 있습니다. 한국 춘란을 미국에 수출할 수 있는 길을 열었으며 미국에 분점을 개설해 세계화를 시작했습니다. 연 수익률 마의 10% 벽을 무너뜨리기 위해 원금 보장 특약을 만들어 원금 손실 없이 난초로 수익을 창출할 수 있게 돕고 있습니다. 특히 모든 것을 포기해야 하는 N포 세대인 2030, 퇴직 후 삶에 고민이 많은 분께 일자리와 수익을 창출하도록 2급과 1급 자격취득 반을 개설해 보급하고 있습니다.

이제 난초에 관심을 두고 제대로 된 교육을 받으며 난초를 만난다면 난초가 희망을 선물해 주는 축복의 식물이 될 것이라 확신합니다. 그 길을 걸어갈 수 있도록 저의 삶이 다할 때까지 헌신하며 돕겠습니다. `}</>
              ) : (
                <>
                  {`현재 관유정 농장과 대한민국 난 문화 진흥원을 운영하고 있으며
1988년 난초에 입문한 후부터 지금까지
난 문화 성숙, 도시농업 발전 및 일자리 창출을 위해 뛰어다니고 있습니다.
난계가 태동할 때부터 우리나라는 기술 부족, 교육시스템 부재, 난초를 제대로 알 수 있는 저서 부족으로 의미 있는 성장을 이루지 못해 항상 안타깝게 생각했습니다.`}
                  <br />
                  <strong>{`저는 이 문제를 해결하기 위해 공부에 매진하며 난초로 석사, 박사, 명장이 될 수 있었습니다.`}</strong>
                  <br />
                  {`이 과정에서 국수풍이라는 한국 춘란 미학 체계를 완성하였으며,
작황을 좋게 하는 다양한 기술을 개발했습니다.

저서 7권을 집필해 난초 전반을 이해할 수 있는 토대를 마련했으며
유튜브 채널 개설로 접근 문턱을 낮추며 100만 인구 저변확대를 위해 전국을 뛰어다니고 있습니다. 
이런 노력의 결실로 난초가 임산자원에서 농업자원으로 사회적 인식이 전환되었으며
정부의 적극적인 관심을 끌어내 aT센터에서 정부 주도로 난초 경매가 진행되고 있습니다.
한국 춘란을 미국에 수출할 수 있는 길을 열었으며 미국에 분점을 개설해 세계화를 시작했습니다.
연 수익률 마의 10% 벽을 무너뜨리기 위해 원금 보장 특약을 만들어
원금 손실 없이 난초로 수익을 창출할 수 있게 돕고 있습니다.
특히 모든 것을 포기해야 하는 N포 세대인 2030, 퇴직 후 삶에 고민이 많은 분께
일자리와 수익을 창출하도록 2급과 1급 자격취득 반을 개설해 보급하고 있습니다.`}
                  <br />
                  <br />
                  <strong>{`이제 난초에 관심을 두고 제대로 된 교육을 받으며 난초를 만난다면
난초가 희망을 선물해 주는 축복의 식물이 될 것이라 확신합니다.
그 길을 걸어갈 수 있도록 저의 삶이 다할 때까지 헌신하며 돕겠습니다.`}</strong>
                </>
              )}
            </Text>
            <Name>난초 명장 이대발 박사</Name>
          </TextDiv>
        </RowDiv>
        <RowDiv
          style={{
            marginTop: windowSize.innerWidth <= 1439 ? 80 : 142,
            marginBottom: 180,
            alignItems: "center",
            flexDirection:
              windowSize.innerWidth <= 1439 ? "column-reverse" : "row",
          }}
        >
          <TextDiv
            style={{ marginLeft: windowSize.innerWidth <= 1439 ? 0 : 56 }}
          >
            <BigText>ESG 경영기업 관유정</BigText>
            <Text>{`관유정은 환경(Environment), 사회(Social), 지배구조(Governance)를 생각하는 ESG 기업입니다.
관유정은 봉사활동, 사회공헌활동, 환경 등 다양한 사회분야에서 활동하며 기업의 재무적인 요소 외에도 비재무적인 요소까지 함께 고려하여 사회와 함께할 수 있는 지속가능한 기업이 되고자합니다.`}</Text>
          </TextDiv>
          <Image03 src="/assets/tmpImg/greeting02.png" />
        </RowDiv>
      </Content>
    </BasicContainer>
  );
};

export default Greeting;

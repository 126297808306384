import React from "react";
import styled from "styled-components";
import { Black, Gray100, Gray200, Gray400, Gray500 } from "../config/color";
import { OnlyCheckBox } from "./Checkbox";
import { thousandComma } from "../utils";

const MinusImg = require("../assets/icon/Minus26.png");
const PlusImg = require("../assets/icon/Plus26.png");
const EmptyImg = require("../assets/icon/Close29.png");

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ListHeader = styled.div`
  height: 45px;
  border-top: 1px solid ${Gray200};
  border-bottom: 1px solid ${Gray200};
  display: flex;
  flex-direction: row;
`;

const ListHeaderCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  padding-right: 0;
  height: 60px;
  align-items: center;
  border-bottom: 1px solid ${Gray200};
`;

const ItemImgBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const ItemImg = styled.img`
  width: 60px;
  height: 60px;
`;

const ItemName = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 32.4px;
  display: flex;
  align-items: center;
  height: 60px;
  margin-left: 16px;
`;

const ItemText = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  text-align: center;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px;
  color: ${Gray500};
`;

const CellBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const CalcButtonImg = styled.img`
  width: 26px;
  height: 26px;
  &:hover {
    cursor: pointer;
  }
`;

const EmptyBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${Gray100};
  border-bottom: 1px solid ${Gray200};
  height: 142px;
  width: 100%;
`;

const EmptyBoxIcon = styled.img`
  width: 29px;
  height: 29px;
`;

const EmptyBoxText = styled.div`
  margin-top: 12px;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: ${Gray400};
`;

const TotalBox = styled.div`
  background-color: ${Gray100};
  height: 60px;
  padding: 12px 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-size: 16px;
  font-weight: 700;
  line-height: 28.8px;
  color: ${Gray500};
`;

const TotalResultText = styled.div`
  margin-left: 16px;
  font-size: 20px;
  font-weight: 700;
  line-height: 36px;
  color: ${Black};
`;

const Table = (props) => {
  const {
    headerList,
    dataList,
    cellWidth,
    toggleAllSelected,
    toggleSelected,
    selected,
    allSelected,
    viewStyle,
    minusCount,
    addCount,
  } = props;

  const calcTotal = () => {
    let result = 0;
    let selectedCount = 0;
    if (Object.keys(selected).length > 0) {
      for (let i in dataList) {
        for (let key in selected) {
          if (key === dataList[i]._id && selected[key]) {
            result += dataList[i].count * dataList[i].price;
            selectedCount++;
          }
        }
      }
    }
    return thousandComma(result) + "원 / " + selectedCount + "개";
  };

  return (
    <Container style={viewStyle}>
      <ListHeader>
        {headerList.map((item, idx) => {
          return (
            <ListHeaderCell
              key={`table_${idx}`}
              style={{ width: cellWidth[idx] }}
            >
              {item === "checkbox" ? (
                <OnlyCheckBox
                  isAllSelect={true}
                  toggleAllSelected={toggleAllSelected}
                  toggleSelected={toggleSelected}
                  selected={selected}
                  allSelected={allSelected}
                />
              ) : (
                item
              )}
            </ListHeaderCell>
          );
        })}
      </ListHeader>
      {dataList.length > 0 ? (
        dataList.map((item, idx) => {
          return (
            <Item key={item._id}>
              <ItemImgBox style={{ width: cellWidth[0] - 16 }}>
                <ItemImg src={item.image} />
                <ItemName>{item.name}</ItemName>
              </ItemImgBox>
              <CellBox style={{ width: cellWidth[1] }}>
                <CalcButtonImg
                  src={MinusImg}
                  onClick={() => minusCount(item._id)}
                />
                <ItemText>{item.count}개</ItemText>
                <CalcButtonImg
                  src={PlusImg}
                  onClick={() => addCount(item._id)}
                />
              </CellBox>
              <CellBox style={{ width: cellWidth[2] }}>
                <ItemText>{thousandComma(item.price)}</ItemText>
              </CellBox>
              <CellBox style={{ width: cellWidth[3] }}>
                <ItemText>{thousandComma(item.price * item.count)}</ItemText>
              </CellBox>
              <CellBox style={{ width: cellWidth[4] }}>
                <ItemText>{item.point}</ItemText>
              </CellBox>
              <CellBox style={{ width: cellWidth[5] }}>
                <ItemText>{item.deliveryFee}</ItemText>
              </CellBox>
              <CellBox style={{ width: cellWidth[6] }}>
                <OnlyCheckBox
                  id={item._id}
                  isAllSelect={false}
                  toggleAllSelected={toggleAllSelected}
                  toggleSelected={toggleSelected}
                  selected={selected}
                  allSelected={allSelected}
                />
              </CellBox>
            </Item>
          );
        })
      ) : (
        <EmptyBox>
          <EmptyBoxIcon src={EmptyImg} />
          <EmptyBoxText>내용이 존재하지 않습니다</EmptyBoxText>
        </EmptyBox>
      )}
      <TotalBox>
        총금액
        <TotalResultText>{calcTotal()}</TotalResultText>
      </TotalBox>
    </Container>
  );
};

export default Table;

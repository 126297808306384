import React, { useState, useEffect } from "react";
import styled from "styled-components";

import BasicContainer from "../../../components/BasicContainer";
import PageTop from "../../../components/PageTop";

import {
  Black,
  Gray300,
  Gray500,
  Green100,
  Green600,
  White,
} from "../../../config/color";
import Youtube from "../../../components/Youtube";
import InpatientTreatmentTable from "./Table";
import { getWindowSize } from "../../../utils";

const ThirtyIcon = require("../../../assets/icon/Thirty42.png");
const ChartIcon = require("../../../assets/icon/Chart42.png");
const PeriodSettingIcon = require("../../../assets/icon/PeriodSetting42.png");
const AidIcon = require("../../../assets/icon/Aid42.png");
const SpaceIcon = require("../../../assets/icon/Space42.png");
const GrowPlantIcon = require("../../../assets/icon/GrowPlant.png");

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1439px) {
    width: 100%;
    min-width: 360px;
    flex-direction: column;
  }
`;

const RowDiv = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 1328px;
  margin: 0 auto;

  @media screen and (max-width: 1439px) {
    flex-direction: column;
    width: calc(100% - 40px);
    padding: 0 20px;
    align-items: flex-start;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 72px;
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  color: ${Black};
  white-space: pre-wrap;
  line-height: 51px;

  @media screen and (max-width: 1439px) {
    font-size: 26px;
    line-height: 36px;
  }
`;

const Image = styled.img`
  width: 464px;
  height: 362px;
  border-radius: 10px;
`;

const List = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 16px 0;
  border-top: 1px solid ${Gray300};
`;

const ListLabel = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Green600};
`;

const ListTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Black};
  margin-bottom: 16px;
`;

const ListContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  white-space: pre-wrap;
`;

const PointBox = styled.div`
  width: 100%;
  padding: 48px 0;
  margin-top: 56px;
`;

const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
`;

const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Icon = styled.img`
  width: 42px;
  height: 42px;
`;

const IconCover = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${White};
  border-radius: 42px;
  width: 80px;
  height: 80px;
  margin-right: 16px;
`;

const BigIcon = styled.img`
  width: 301px;
  height: 208px;
  position: absolute;
  right: 56px;
  bottom: 76px;

  @media screen and (max-width: 767px) {
    opacity: 0.3;
  }
`;

const Text = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Gray500};
`;

const KindListUl = styled.ul`
  padding-left: 32px;
  margin: 0;
`;

const KindListLi = styled.li`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
`;

const MobileTableCover = styled.div`
  @media screen and (max-width: 767px) {
    display: flex;
    width: 100%;
    overflow-x: auto;
  }
`;

const InpatientTreatment = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <BasicContainer metaTitle="관유정-입원 치료">
      <Content>
        <PageTop
          label="의료지원"
          title="입원 치료"
          ImageUrl="/assets/image/MedicalHeaderImg.png"
        />
        <RowDiv>
          <TitleBox style={{ marginTop: 56 }}>
            <TitleLabel>의료지원</TitleLabel>
            <TitleText>{`입원 치료`}</TitleText>
          </TitleBox>
        </RowDiv>
        <RowDiv style={{ marginTop: 42 }}>
          <List>
            <ListLabel>01</ListLabel>
            <ListTitle>입원 치료란</ListTitle>
            <ListContent>
              <KindListUl>
                <KindListLi>
                  중증 및 오랫동안 치료를 요하는 난초를 클리닉센터에 입원
                  (3~12개월) 시켜 치료하는 제도.
                </KindListLi>
                <KindListLi>
                  병징에 따라 1회성 진료 및 치료가 있고 오랜 시간 관리하며
                  치료해야 할 난초가 있으므로 정확한 판단이 필요함.
                </KindListLi>
                <KindListLi>
                  장기간 입원 및 치료로 비용이 많이 나오는 단점이 있습니다
                  소중한 난초를 살려내려면 꼭 필요한 과정임.
                </KindListLi>
              </KindListUl>
            </ListContent>
          </List>
        </RowDiv>
        <RowDiv style={{ borderTop: `1px solid ${Gray300}`, marginTop: 56 }}>
          <List
            style={{
              borderTop: 0,
              marginLeft: 0,
              width: windowSize.innerWidth <= 767 ? "100%" : 448,
            }}
          >
            <ListLabel>02</ListLabel>
            <ListTitle>치료 성공 사례 - 정종성 유튜브</ListTitle>
          </List>
        </RowDiv>
        <RowDiv>
          <Youtube
            videoId="q7EBg4TMy_w"
            title="216편 - 사망 판정 난 입원"
            viewStyle={
              windowSize.innerWidth <= 1439
                ? {
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "100%",
                    marginBottom: 32,
                  }
                : { marginRight: 16 }
            }
            optStyle={windowSize.innerWidth <= 1439 ? { width: "100%" } : null}
          />
          <Youtube
            videoId="9xacynNkySE"
            title="227편 - 3주 경과 후 깨어남"
            viewStyle={
              windowSize.innerWidth <= 1439
                ? {
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "100%",
                    marginBottom: 32,
                  }
                : { marginRight: 16 }
            }
            optStyle={windowSize.innerWidth <= 1439 ? { width: "100%" } : null}
          />
          <Youtube
            videoId="nXrwaVZvVH0"
            title="299편 - 1년 후 퇴원"
            viewStyle={
              windowSize.innerWidth <= 1439
                ? { marginLeft: "auto", marginRight: "auto", width: "100%" }
                : null
            }
            optStyle={windowSize.innerWidth <= 1439 ? { width: "100%" } : null}
          />
        </RowDiv>
        <PointBox
          style={{
            backgroundColor: Green100,
            position: "relative",
          }}
        >
          <RowDiv style={{ marginTop: 0 }}>
            <List style={{ borderTop: 0, marginLeft: 0 }}>
              <ListLabel>03</ListLabel>
              <ListTitle>정기 검진 프로세스</ListTitle>
              <ListContent style={{ marginTop: 24 }}>
                <ContentRow>
                  <IconCover>
                    <Icon src={ThirtyIcon} />
                  </IconCover>
                  <ContentColumn>
                    <Text style={{ color: Green600 }}>Step 1</Text>
                    <Text
                      style={{
                        fontFamily: "Nanum Myeongjo",
                        fontSize: 18,
                        fontWeight: "600",
                        color: Black,
                      }}
                    >
                      내원
                    </Text>
                  </ContentColumn>
                </ContentRow>
                <ContentRow style={{ marginTop: 24 }}>
                  <IconCover>
                    <Icon src={ChartIcon} />
                  </IconCover>
                  <ContentColumn>
                    <Text style={{ color: Green600 }}>Step 2</Text>
                    <Text
                      style={{
                        fontFamily: "Nanum Myeongjo",
                        fontSize: 18,
                        fontWeight: "600",
                        color: Black,
                      }}
                    >
                      검진 후 진단
                    </Text>
                  </ContentColumn>
                </ContentRow>
                <ContentRow style={{ marginTop: 24 }}>
                  <IconCover>
                    <Icon src={PeriodSettingIcon} />
                  </IconCover>
                  <ContentColumn>
                    <Text style={{ color: Green600 }}>Step 3</Text>
                    <Text
                      style={{
                        fontFamily: "Nanum Myeongjo",
                        fontSize: 18,
                        fontWeight: "600",
                        color: Black,
                      }}
                    >
                      입원 기간 설정
                    </Text>
                  </ContentColumn>
                </ContentRow>
                <ContentRow style={{ marginTop: 24 }}>
                  <IconCover>
                    <Icon src={AidIcon} />
                  </IconCover>
                  <ContentColumn>
                    <Text style={{ color: Green600 }}>Step 4</Text>
                    <Text
                      style={{
                        fontFamily: "Nanum Myeongjo",
                        fontSize: 18,
                        fontWeight: "600",
                        color: Black,
                      }}
                    >
                      치료 설계 및 치료
                    </Text>
                  </ContentColumn>
                </ContentRow>
                <ContentRow style={{ marginTop: 24 }}>
                  <IconCover>
                    <Icon src={SpaceIcon} />
                  </IconCover>
                  <ContentColumn>
                    <Text style={{ color: Green600 }}>Step 5</Text>
                    <Text
                      style={{
                        fontFamily: "Nanum Myeongjo",
                        fontSize: 18,
                        fontWeight: "600",
                        color: Black,
                      }}
                    >
                      퇴원
                    </Text>
                  </ContentColumn>
                </ContentRow>
              </ListContent>
            </List>
          </RowDiv>
          <BigIcon src={GrowPlantIcon} />
        </PointBox>
        <PointBox
          style={{
            backgroundColor: Green600,
            position: "relative",
            marginTop: 0,
            paddingTop: 0,
          }}
        >
          <RowDiv style={{ borderTop: 0, marginTop: 56 }}>
            <List
              style={{
                borderTop: 0,
                marginLeft: 0,
                width: windowSize.innerWidth <= 767 ? "100%" : 448,
              }}
            >
              <ListLabel style={{ color: White }}>04</ListLabel>
              <ListTitle style={{ color: White }}>정기 검진 비용</ListTitle>
              <ListContent>
                <MobileTableCover>
                  <InpatientTreatmentTable />
                </MobileTableCover>
              </ListContent>
            </List>
          </RowDiv>
        </PointBox>
        <RowDiv style={{ marginTop: 56, marginBottom: 128 }}>
          <List>
            <ListLabel>05</ListLabel>
            <ListTitle>입원의 장점</ListTitle>
            <ListContent>
              <KindListUl>
                <KindListLi>치료의 확률이 높음</KindListLi>
                <KindListLi>의료진의 실시간 대처 가능</KindListLi>
                <KindListLi>합병증의 통제</KindListLi>
                <KindListLi>영양상태 회복</KindListLi>
              </KindListUl>
            </ListContent>
          </List>
        </RowDiv>
      </Content>
    </BasicContainer>
  );
};

export default InpatientTreatment;

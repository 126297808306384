import React, { useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useUserStore } from "../store";
import {
  Gray100,
  Gray200,
  Gray400,
  Gray500,
  Gray600,
  White,
} from "../config/color";

const CloseIcon = require("../assets/icon/Close30.png");
const CartIcon = require("../assets/icon/Cart24.png");
const DownArrowIcon = require("../assets/icon/DownArrow20.png");

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  overflow: hidden;
`;

const InnerContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 333px;
  height: 100%;
  -webkit-transition: right 1s;
  transition: right 1s;
  overflow-y: auto;
  background-color: ${White};
`;

const TopNav = styled.div`
  width: calc(100% - 32px);
  padding: 14px 16px;
  position: relative;
  background-color: #242627;
`;

const CloseButton = styled.div`
  width: 30px;
  height: 30px;
  margin-left: auto;
  margin-right: 0;
  &:hover {
    cursor: pointer;
  }
`;

const CloseImg = styled.img`
  width: 30px;
  height: 30px;
`;

const BottomNav = styled.div`
  width: 100%;
`;

const UserBox = styled.div`
  padding-top: 15px;
  padding-bottom: 14px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${Gray500};
  margin-bottom: 19px;
  align-items: center;
`;

const UserLink = styled(Link)`
  font-size: 14px;
  font-weight: 700;
  line-height: 25px;
  color: ${Gray400};
  text-decoration: none;
`;

const LanguageButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 32px;
  border-radius: 50px;
  background-color: ${Gray100};
  color: ${Gray500};
  font-size: 14px;
  font-weight: 700;
  &:hover {
    cursor: pointer;
  }
`;

const BasicButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 78px;
  height: 32px;
  border-radius: 50px;
  border: 1px solid ${Gray400};
  color: ${White};
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  margin-right: 8px;
  &:hover {
    cursor: pointer;
  }
`;

const SpecialButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${White};
  width: 86px;
  height: 32px;
  border-radius: 5px;
  color: ${Gray600};
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
`;

const CartImg = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 4px;
`;

const PageLink = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 25px;
  text-align: left;
  color: ${White};
  margin-bottom: 16px;
  &:hover {
    cursor: pointer;
  }
`;

const Menu = styled.div`
  display: flex;
  width: calc(100% - 40px);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 20px 14px 20px;
  background-color: ${White};
  border-bottom: 1px solid ${Gray200};
  &:hover {
    cursor: pointer;
  }
`;

const MenuText = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 25px;
  margin-right: auto;
  color: ${Gray500};
`;

const MenuImg = styled.img`
  width: 20px;
  height: 20px;
  margin-left: auto;
`;

const MenuLinkBox = styled.div`
  display: flex;
  padding: 12px 20px;
  background-color: ${White};
  flex-direction: column;
  border-bottom: 1px solid ${Gray200};
`;

const MenuLink = styled(Link)`
  font-weight: 700;
  font-size: 14px;
  line-height: 25px;
  color: ${Gray400};
  margin-bottom: 16px;
  text-decoration: none;
`;

const NavigationMobile = (props) => {
  const navigate = useNavigate();
  const { isLogin } = useUserStore((state) => state);
  const { hideNavMobile } = props;

  const [selectedMenu, setSelectedMenu] = useState("");

  const moveToPage = (page, tab) => {
    if (tab) {
      navigate(page, { state: { tab: tab } });
    } else {
      navigate(page);
    }
  };

  return (
    <Container>
      <InnerContainer>
        <TopNav>
          <CloseButton>
            <CloseImg src={CloseIcon} onClick={hideNavMobile} />
          </CloseButton>
          <UserBox>
            {isLogin ? (
              <>
                <UserLink to="/logout" style={{ marginRight: 24 }}>
                  로그아웃
                </UserLink>
              </>
            ) : (
              <>
                <UserLink to="/signin" style={{ marginRight: 24 }}>
                  로그인
                </UserLink>
                <UserLink to="/signup" style={{ marginRight: "auto" }}>
                  회원가입
                </UserLink>
              </>
            )}
            <LanguageButton>ENG</LanguageButton>
          </UserBox>
          <UserBox
            style={{
              paddingTop: 0,
              paddingBottom: 0,
              border: 0,
              marginBottom: 16,
            }}
          >
            <BasicButton
              onClick={() => {
                moveToPage("/mypage", "주문내역");
              }}
            >
              주문조회
            </BasicButton>
            <BasicButton
              onClick={() => {
                moveToPage("/mypage", "내 정보");
              }}
            >
              마이페이지
            </BasicButton>
            <SpecialButton
              onClick={() => {
                moveToPage("/mypage", "장바구니");
              }}
            >
              <CartImg src={CartIcon} />
              장바구니
            </SpecialButton>
          </UserBox>
          <PageLink>내 난 시세보기</PageLink>
          <PageLink>내 난 직접팔기</PageLink>
          <PageLink>바우처 시스템</PageLink>
          <PageLink>종보존 오더돌봄</PageLink>
          <PageLink>난초 품질 이력제</PageLink>
          <PageLink>난초 건강증진센터</PageLink>
        </TopNav>
        <BottomNav>
          <Menu
            onClick={() => {
              if (selectedMenu === "이대발과 관유정") {
                setSelectedMenu("");
              } else {
                setSelectedMenu("이대발과 관유정");
              }
            }}
          >
            <MenuText>이대발과 관유정</MenuText>
            <MenuImg src={DownArrowIcon} />
          </Menu>
          {selectedMenu === "이대발과 관유정" ? (
            <MenuLinkBox>
              <MenuLink to="/company/greeting">인사말</MenuLink>
              <MenuLink to="/company/master">대한민국명장</MenuLink>
              <MenuLink to="/company/precursor">선구자 이대발</MenuLink>
              <MenuLink to="/company/gyj">관유정</MenuLink>
              <MenuLink to="/company/agency">진흥원</MenuLink>
              <MenuLink to="/company/bookandpaper">저서와 논문</MenuLink>
              <MenuLink to="/company/board">게시판</MenuLink>
              <MenuLink to="/company/history">연혁</MenuLink>
              <MenuLink to="/company/orchidhistory" style={{ marginBottom: 0 }}>
                이력제
              </MenuLink>
            </MenuLinkBox>
          ) : null}
          <Menu
            onClick={() => {
              if (selectedMenu === "종자지원") {
                setSelectedMenu("");
              } else {
                setSelectedMenu("종자지원");
              }
            }}
          >
            <MenuText>종자지원</MenuText>
            <MenuImg src={DownArrowIcon} />
          </Menu>
          {selectedMenu === "종자지원" ? (
            <MenuLinkBox>
              <MenuLink to="/shop/luxary">럭셔리</MenuLink>
              <MenuLink to="/shop/collection">컬렉션</MenuLink>
              <MenuLink to="/shop/auction">옥션</MenuLink>
              <MenuLink to="/shop/beginner">입문코너</MenuLink>
              <MenuLink to="/shop/tool" style={{ marginBottom: 0 }}>
                자재 및 도구
              </MenuLink>
            </MenuLinkBox>
          ) : null}
          <Menu
            onClick={() => {
              if (selectedMenu === "영농지원") {
                setSelectedMenu("");
              } else {
                setSelectedMenu("영농지원");
              }
            }}
          >
            <MenuText>영농지원</MenuText>
            <MenuImg src={DownArrowIcon} />
          </Menu>
          {selectedMenu === "영농지원" ? (
            <MenuLinkBox>
              <MenuLink to="/farm/successroadmap">성공로드맵</MenuLink>
              <MenuLink to="/farm/cooperativefarm">
                관유정 협력농가 모집
              </MenuLink>
              <MenuLink to="/farm/guarantee">원금보장 OEM</MenuLink>
              <MenuLink to="/farm/voucher">안심바우처</MenuLink>
              <MenuLink to="/farm/consignmentcare">위탁오더 대리돌봄</MenuLink>
              <MenuLink to="/farm/speciescenter" style={{ marginBottom: 0 }}>
                종 보존센터
              </MenuLink>
            </MenuLinkBox>
          ) : null}
          <Menu
            onClick={() => {
              if (selectedMenu === "교육지원") {
                setSelectedMenu("");
              } else {
                setSelectedMenu("교육지원");
              }
            }}
          >
            <MenuText>교육지원</MenuText>
            <MenuImg src={DownArrowIcon} />
          </Menu>
          {selectedMenu === "교육지원" ? (
            <MenuLinkBox>
              <MenuLink to="/education/alumni">총동창회</MenuLink>
              <MenuLink to="/education/successcase">교육생 사례</MenuLink>
              <MenuLink to="/education/certificateinfo">
                자격증 과정 개요
              </MenuLink>
              <MenuLink to="/education/secondgrade">2급 자격 과정</MenuLink>
              <MenuLink to="/education/firstgrade">1급 자격 과정</MenuLink>
              <MenuLink to="/education/voucherfranchise">
                바우처 시스템 프랜차이즈
              </MenuLink>
              <MenuLink to="/education/recoveryfarm">개인 회생농</MenuLink>
              <MenuLink
                to="/education/outsidelecture"
                style={{ marginBottom: 0 }}
              >
                외부 강의
              </MenuLink>
            </MenuLinkBox>
          ) : null}
          <Menu
            onClick={() => {
              if (selectedMenu === "기술지원") {
                setSelectedMenu("");
              } else {
                setSelectedMenu("기술지원");
              }
            }}
          >
            <MenuText>기술지원</MenuText>
            <MenuImg src={DownArrowIcon} />
          </Menu>
          {selectedMenu === "기술지원" ? (
            <MenuLinkBox>
              <MenuLink to="/technical/qualitygrade">품질 등급 기준</MenuLink>
              <MenuLink to="/technical/upgradegrade">
                한 등급 높이는 기술
              </MenuLink>
              <MenuLink to="/technical/annualcareguideline">
                연간 관리 지침
              </MenuLink>
              <MenuLink to="/technical/iotsystem">IoT 시스템</MenuLink>
              <MenuLink to="/technical/managementtable">
                관행 관리표 7집
              </MenuLink>
              <MenuLink to="/technical/controltable">관행 방제표 7집</MenuLink>
              <MenuLink to="/technical/controltable/professional">
                관행 방제표 전문가용
              </MenuLink>
              <MenuLink to="/technical/book">책 소개 가이드북</MenuLink>
              <MenuLink to="/technical/youtube" style={{ marginBottom: 0 }}>
                유튜브 리스트
              </MenuLink>
            </MenuLinkBox>
          ) : null}
          <Menu
            onClick={() => {
              if (selectedMenu === "의료지원") {
                setSelectedMenu("");
              } else {
                setSelectedMenu("의료지원");
              }
            }}
          >
            <MenuText>의료지원</MenuText>
            <MenuImg src={DownArrowIcon} />
          </Menu>
          {selectedMenu === "의료지원" ? (
            <MenuLinkBox>
              <MenuLink to="/medical/healthexaminationcenter">
                건강검진센터
              </MenuLink>
              <MenuLink to="/medical/regularcheckup">정기건강검진</MenuLink>
              <MenuLink to="/medical/scalingrepotting">
                스케일링 및 분갈이 검진
              </MenuLink>
              <MenuLink to="/medical/careprocess">진료 프로세스</MenuLink>
              <MenuLink
                to="/medical/inpatienttreatment"
                style={{ marginBottom: 0 }}
              >
                입원 치료
              </MenuLink>
            </MenuLinkBox>
          ) : null}
        </BottomNav>
      </InnerContainer>
    </Container>
  );
};

export default NavigationMobile;

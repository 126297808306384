import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import BasicContainer from "../../../components/BasicContainer";
import { ButtonA, ButtonB } from "../../../components/Button";
import { CheckBoxExpand } from "../../../components/Checkbox";
import { TAB } from "../../../components/Tab";
import { SEARCH_BOX } from "../../../components/TextField";
import {
  Black,
  Gray100,
  Gray300,
  Gray500,
  Green600,
} from "../../../config/color";
import { thousandComma, getWindowSize } from "../../../utils";
import ToolTable from "./ToolTable";

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1328px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1439px) {
    min-width: 320px;
    width: calc(100% - 40px);
    padding: 0 20px;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  /* width: calc(100% - 112px); //calc(100% - 112px); */
  margin-top: 72px;
  @media screen and (max-width: 1439px) {
    margin-top: 32px;
  }
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  color: ${Black};
  white-space: pre-wrap;
  line-height: 51px;
  @media screen and (max-width: 1439px) {
    font-size: 26px;
    line-height: 36px;
  }
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 28.8px;
  white-space: pre-wrap;
`;

const RowDiv = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  /* width: calc(100% - 112px);
  padding: 0 56px; */
  width: 1328px;
  margin: 0 auto;

  @media screen and (max-width: 1439px) {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
`;

const MobileRowDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const KindList = styled.div`
  display: flex;
  flex-direction: column;
  /* width: calc(100% - 112px);
  margin: 0 56px; */
  width: 1328px;
  margin: 0 auto;
  padding: 16px 0;
  border-top: 1px solid ${Gray300};

  @media screen and (max-width: 1439px) {
    width: 100%;
  }
`;

const KindListLabel = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Green600};
`;

const KindListTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Black};
`;

const TotalBox = styled.div`
  width: calc(1328px - 48px);
  margin: 0 auto;
  background-color: ${Gray100};
  padding: 17px 24px;
  height: calc(101px - 34px);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-top: 1px solid ${Gray300};
  border-bottom: 1px solid ${Gray300};

  @media screen and (max-width: 1439px) {
    width: calc(100% - 48px);
  }
`;

const TotalLabel = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: ${Gray500};
  text-align: right;
`;

const TotalPrice = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Black};
  text-align: right;
`;

const TableBox = styled.div`
  width: 100%;
  margin-bottom: 76px;
  overflow-x: auto;
`;

const Tool = () => {
  const navigate = useNavigate();

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [selectedTab, setSelectedTab] = useState(4);
  const [searchText, setSearchText] = useState("");

  // 자재 및 도구
  const [toolAllSelect, setToolAllSelect] = useState(false);
  const [toolList, setToolList] = useState([
    {
      _id: "001",
      category: "일반 도구",
      name: "라벨",
      quantity: 500,
      explain: "",
      price: 10000,
    },
    {
      _id: "002",
      category: "일반 도구",
      name: "수태",
      quantity: 500,
      explain: "",
      price: 10000,
    },
    {
      _id: "003",
      category: "일반 도구",
      name: "돋보기",
      quantity: 500,
      explain: "",
      price: 10000,
    },
    {
      _id: "0041",
      category: "치료 및 방제 도구",
      name: "계량컵",
      quantity: 500,
      explain: "1000cc",
      price: 10000,
    },
    {
      _id: "0042",
      category: "치료 및 방제 도구",
      name: "계량컵",
      quantity: 500,
      explain: "2000cc",
      price: 10000,
    },
    {
      _id: "0043",
      category: "치료 및 방제 도구",
      name: "계량컵",
      quantity: 500,
      explain: "5000cc",
      price: 10000,
    },
    {
      _id: "005",
      category: "치료 및 방제 도구",
      name: "타이머",
      quantity: 500,
      explain: "",
      price: 10000,
    },
    {
      _id: "006",
      category: "치료 및 방제 도구",
      name: "회용 비닐 팩",
      quantity: 500,
      explain: "",
      price: 10000,
    },
    {
      _id: "007",
      category: "치료 및 방제 도구",
      name: "주사기",
      quantity: 500,
      explain: "",
      price: 10000,
    },
    {
      _id: "008",
      category: "치료 및 방제 도구",
      name: "전자저울",
      quantity: 500,
      explain: "",
      price: 10000,
    },
    {
      _id: "009",
      category: "치료 및 방제 도구",
      name: "다아와 고압 분무기",
      quantity: 500,
      explain: "5L",
      price: 10000,
    },
    {
      _id: "010",
      category: "치료 및 방제 도구",
      name: "다아와 고압 분무기",
      quantity: 500,
      explain: "7L",
      price: 10000,
    },
  ]);
  const [selectedTool, setSelectedTool] = useState({});
  const [selectedCntTool, setSelectedCntTool] = useState({});

  const toggleAllSelectedTool = () => {
    let newSelected = {};
    let newSelectedCnt = {};
    for (let i in toolList) {
      newSelected[toolList[i]._id] = !toolAllSelect;
      if (!toolAllSelect) {
        newSelectedCnt[toolList[i]._id] = 1;
      } else {
        newSelectedCnt[toolList[i]._id] = 0;
      }
    }
    setSelectedTool(newSelected);
    setSelectedCntTool(newSelectedCnt);
    setToolAllSelect(!toolAllSelect);
  };

  const totalPriceResultTool = () => {
    let result = 0;
    for (const key in selectedTool) {
      if (selectedTool[key]) {
        for (let i = 0; i < toolList.length; i++) {
          if (toolList[i]._id === key) {
            if (selectedCntTool[key]) {
              result += toolList[i].price * selectedCntTool[key];
            }
            break;
          }
        }
      }
    }
    return `${thousandComma(result)} 원`;
  };

  const addCartTool = () => {
    console.log(Object.keys(selectedTool));
  };

  const buyTool = () => {
    console.log("move buy page");
  };

  // 농약
  const [pesticideAllSelect, setPesticideAllSelect] = useState(false);
  const [pesticideList, setPesticideList] = useState([
    {
      _id: "0011",
      category: "일반 도구",
      name: "라벨",
      quantity: 500,
      explain: "",
      price: 10000,
    },
    {
      _id: "0021",
      category: "일반 도구",
      name: "수태",
      quantity: 500,
      explain: "",
      price: 10000,
    },
    {
      _id: "0031",
      category: "일반 도구",
      name: "돋보기",
      quantity: 500,
      explain: "",
      price: 10000,
    },
    {
      _id: "00411",
      category: "치료 및 방제 도구",
      name: "계량컵",
      quantity: 500,
      explain: "1000cc",
      price: 10000,
    },
    {
      _id: "00421",
      category: "치료 및 방제 도구",
      name: "계량컵",
      quantity: 500,
      explain: "2000cc",
      price: 10000,
    },
  ]);
  const [selectedPesticide, setSelectedPesticide] = useState({});
  const [selectedCntPesticide, setSelectedCntPesticide] = useState({});

  const toggleAllSelectedPesticide = () => {
    let newSelected = {};
    let newSelectedCnt = {};
    for (let i in pesticideList) {
      newSelected[pesticideList[i]._id] = !pesticideAllSelect;
      if (!pesticideAllSelect) {
        newSelectedCnt[pesticideList[i]._id] = 1;
      } else {
        newSelectedCnt[pesticideList[i]._id] = 0;
      }
    }
    setSelectedPesticide(newSelected);
    setSelectedCntPesticide(newSelectedCnt);
    setPesticideAllSelect(!pesticideAllSelect);
  };

  const totalPriceResultPesticide = () => {
    let result = 0;
    for (const key in selectedPesticide) {
      if (selectedPesticide[key]) {
        for (let i = 0; i < pesticideList.length; i++) {
          if (pesticideList[i]._id === key) {
            if (selectedCntPesticide[key]) {
              result += pesticideList[i].price * selectedCntPesticide[key];
            }
            break;
          }
        }
      }
    }
    return `${thousandComma(result)} 원`;
  };

  const addCartPesticide = () => {
    console.log(Object.keys(selectedPesticide));
  };

  const buyPesticide = () => {
    console.log("move buy page");
  };

  // 도서
  const [bookAllSelect, setBookAllSelect] = useState(false);
  const [bookList, setBookList] = useState([
    {
      _id: "00112",
      category: "저서",
      name: "반려식물 난초 재테크",
      quantity: 500,
      explain: "식테크 관심자",
      price: 10000,
    },
    {
      _id: "00212",
      category: "저서",
      name: "한국춘란가이드북 입문편",
      quantity: 500,
      explain: "",
      price: 10000,
    },
  ]);
  const [selectedBook, setSelectedBook] = useState({});
  const [selectedCntBook, setSelectedCntBook] = useState({});

  const toggleAllSelectedBook = () => {
    let newSelected = {};
    let newSelectedCnt = {};
    for (let i in bookList) {
      newSelected[bookList[i]._id] = !bookAllSelect;
      if (!bookAllSelect) {
        newSelectedCnt[bookList[i]._id] = 1;
      } else {
        newSelectedCnt[bookList[i]._id] = 0;
      }
    }
    setSelectedBook(newSelected);
    setSelectedCntBook(newSelectedCnt);
    setBookAllSelect(!bookAllSelect);
  };

  const totalPriceResultBook = () => {
    let result = 0;
    for (const key in selectedBook) {
      if (selectedBook[key]) {
        for (let i = 0; i < bookList.length; i++) {
          if (bookList[i]._id === key) {
            if (selectedCntBook[key]) {
              result += bookList[i].price * selectedCntBook[key];
            }
            break;
          }
        }
      }
    }
    return `${thousandComma(result)} 원`;
  };

  const addCartBook = () => {
    console.log(Object.keys(selectedBook));
  };

  const buyBook = () => {
    console.log("move buy page");
  };

  const onSearch = () => {
    console.log(searchText);
  };

  useEffect(() => {
    if (selectedTab === 0) {
      navigate("/shop/luxary");
    } else if (selectedTab === 1) {
      navigate("/shop/collection");
    } else if (selectedTab === 2) {
      navigate("/shop/auction");
    } else if (selectedTab === 3) {
      navigate("/shop/beginner");
    }
  }, [selectedTab]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <BasicContainer metaTitle="관유정-자재 및 도구">
      <Content>
        <TAB
          tabList={[
            { name: "럭셔리" },
            { name: "컬렉션" },
            { name: "옥션" },
            { name: "입문코너" },
            { name: "자재 및 도구" },
          ]}
          viewStyle={{
            marginTop: windowSize.innerWidth <= 1439 ? 28 : 32,
            marginBottom: windowSize.innerWidth <= 1439 ? 0 : 42,
          }}
          tabStyle={{
            width:
              windowSize.innerWidth <= 1439
                ? windowSize.innerWidth / 5 - 18
                : 96,
            paddingTop: 0,
            paddingBottom: 9,
            paddingLeft: windowSize.innerWidth <= 1439 ? 8 : 26,
            paddingRight: windowSize.innerWidth <= 1439 ? 8 : 26,
          }}
          activeTabIdx={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        {windowSize.innerWidth <= 1439 ? (
          <RowDiv>
            <TitleBox>
              <TitleLabel>종자지원</TitleLabel>
              <TitleText>자재 및 도구</TitleText>
              <Text style={{ marginBottom: 24 }}>
                처음 시작하는 입문자용 코너입니다
              </Text>
            </TitleBox>
            <SEARCH_BOX
              placeholder="검색하기"
              val={searchText}
              setVal={setSearchText}
              onClick={onSearch}
              viewStyle={{ width: "100%", marginBottom: 24 }}
              seachBoxStyle={{ width: "calc(100% - 61px)" }}
            />
          </RowDiv>
        ) : (
          <RowDiv>
            <TitleBox>
              <TitleLabel>종자지원</TitleLabel>
              <TitleText>자재 및 도구</TitleText>
            </TitleBox>
            <SEARCH_BOX
              placeholder="검색하기"
              val={searchText}
              setVal={setSearchText}
              onClick={onSearch}
            />
          </RowDiv>
        )}
        {windowSize.innerWidth <= 1439 ? null : (
          <RowDiv style={{ marginTop: 2, marginBottom: 56 }}>
            <Text>처음 시작하는 입문자용 코너입니다</Text>
          </RowDiv>
        )}
        <KindList>
          <KindListLabel>01</KindListLabel>
          <KindListTitle>기본 자재 및 도구</KindListTitle>
          <CheckBoxExpand
            isChecked={toolAllSelect}
            setIsChecked={toggleAllSelectedTool}
            text="모든 자재 및 도구를 선택합니다"
            viewStyle={{
              marginTop: 32,
              marginBottom: windowSize.innerWidth <= 1439 ? 40 : 32,
            }}
          />
        </KindList>

        {windowSize.innerWidth <= 1439 ? (
          <TableBox>
            <ToolTable
              list={toolList}
              selectedItem={selectedTool}
              setSelectedItem={setSelectedTool}
              selectedCnt={selectedCntTool}
              setSelectedCnt={setSelectedCntTool}
              typeofdata="도구"
            />
          </TableBox>
        ) : (
          <ToolTable
            list={toolList}
            selectedItem={selectedTool}
            setSelectedItem={setSelectedTool}
            selectedCnt={selectedCntTool}
            setSelectedCnt={setSelectedCntTool}
            typeofdata="도구"
          />
        )}
        <TotalBox>
          <TotalLabel>총 금액</TotalLabel>
          <TotalPrice>{totalPriceResultTool()}</TotalPrice>
        </TotalBox>
        <RowDiv style={{ justifyContent: "flex-end", marginTop: 24 }}>
          {windowSize.innerWidth <= 1439 ? (
            <MobileRowDiv>
              <ButtonA
                text="장바구니"
                viewStyle={{ width: 152, marginRight: 16, marginLeft: "auto" }}
                onClickEvent={addCartTool}
              />
              <ButtonB
                text="바로 구매하기"
                onClickEvent={buyTool}
                viewStyle={{ width: 152 }}
              />
            </MobileRowDiv>
          ) : (
            <>
              <ButtonA
                text="장바구니"
                viewStyle={{ width: 208, marginRight: 16 }}
                onClickEvent={addCartTool}
              />
              <ButtonB text="바로 구매하기" onClickEvent={buyTool} />
            </>
          )}
        </RowDiv>
        <KindList style={{ marginTop: 104 }}>
          <KindListLabel>02</KindListLabel>
          <KindListTitle>농약</KindListTitle>
          <Text
            style={{ marginTop: 16 }}
          >{`농약은 4-3 관행 예방 방재표에 해당되는 필수 작물보호제입니다. 
구하지 못하시면 연락하시고 저희들이 농약상에 가서 대신 보내드립니다. 이 때 운임 및 수수료가 발생합니다.`}</Text>
          <CheckBoxExpand
            isChecked={pesticideAllSelect}
            setIsChecked={toggleAllSelectedPesticide}
            text="모든 농약을 선택합니다"
            viewStyle={{ marginTop: 32, marginBottom: 32 }}
          />
        </KindList>

        {windowSize.innerWidth <= 1439 ? (
          <TableBox>
            <ToolTable
              list={pesticideList}
              selectedItem={selectedPesticide}
              setSelectedItem={setSelectedPesticide}
              selectedCnt={selectedCntPesticide}
              setSelectedCnt={setSelectedCntPesticide}
              typeofdata="농약"
            />
          </TableBox>
        ) : (
          <ToolTable
            list={pesticideList}
            selectedItem={selectedPesticide}
            setSelectedItem={setSelectedPesticide}
            selectedCnt={selectedCntPesticide}
            setSelectedCnt={setSelectedCntPesticide}
            typeofdata="농약"
          />
        )}
        <TotalBox>
          <TotalLabel>총 금액</TotalLabel>
          <TotalPrice>{totalPriceResultPesticide()}</TotalPrice>
        </TotalBox>
        <RowDiv style={{ justifyContent: "flex-end", marginTop: 24 }}>
          {windowSize.innerWidth <= 1439 ? (
            <MobileRowDiv>
              <ButtonA
                text="장바구니"
                viewStyle={{ width: 152, marginLeft: "auto", marginRight: 16 }}
                onClickEvent={addCartPesticide}
              />
              <ButtonB
                text="바로 구매하기"
                onClickEvent={buyPesticide}
                viewStyle={{ width: 152 }}
              />
            </MobileRowDiv>
          ) : (
            <>
              <ButtonA
                text="장바구니"
                viewStyle={{ width: 208, marginRight: 16 }}
                onClickEvent={addCartPesticide}
              />
              <ButtonB text="바로 구매하기" onClickEvent={buyPesticide} />
            </>
          )}
        </RowDiv>
        <KindList style={{ marginTop: 104 }}>
          <KindListLabel>03</KindListLabel>
          <KindListTitle>도서</KindListTitle>
          <Text
            style={{ marginTop: 16 }}
          >{`책은 교보문고 YES24에서 구하시면 됩니다. 저자의 사인이 필요하면 저희에게 주문하시면 사인을 해서 보내 드립니다`}</Text>
          <CheckBoxExpand
            isChecked={bookAllSelect}
            setIsChecked={toggleAllSelectedBook}
            text="모든 도서를 선택합니다"
            viewStyle={{ marginTop: 32, marginBottom: 32 }}
          />
        </KindList>
        {windowSize.innerWidth <= 1439 ? (
          <TableBox>
            <ToolTable
              list={bookList}
              selectedItem={selectedBook}
              setSelectedItem={setSelectedBook}
              selectedCnt={selectedCntBook}
              setSelectedCnt={setSelectedCntBook}
              typeofdata="도서"
            />
          </TableBox>
        ) : (
          <ToolTable
            list={bookList}
            selectedItem={selectedBook}
            setSelectedItem={setSelectedBook}
            selectedCnt={selectedCntBook}
            setSelectedCnt={setSelectedCntBook}
            typeofdata="도서"
          />
        )}
        <TotalBox>
          <TotalLabel>총 금액</TotalLabel>
          <TotalPrice>{totalPriceResultBook()}</TotalPrice>
        </TotalBox>
        <RowDiv
          style={{
            justifyContent: "flex-end",
            marginTop: 24,
            marginBottom: 313,
          }}
        >
          {windowSize.innerWidth <= 1439 ? (
            <MobileRowDiv>
              <ButtonA
                text="장바구니"
                viewStyle={{ width: 152, marginLeft: "auto", marginRight: 16 }}
                onClickEvent={addCartBook}
              />
              <ButtonB
                text="바로 구매하기"
                onClickEvent={buyBook}
                viewStyle={{ width: 152 }}
              />
            </MobileRowDiv>
          ) : (
            <>
              <ButtonA
                text="장바구니"
                viewStyle={{ width: 208, marginRight: 16 }}
                onClickEvent={addCartBook}
              />
              <ButtonB text="바로 구매하기" onClickEvent={buyBook} />
            </>
          )}
        </RowDiv>
      </Content>
    </BasicContainer>
  );
};

export default Tool;

import React, { useState } from "react";
import styled from "styled-components";
import { getWindowSize } from "../../../utils";
import BasicContainer from "../../../components/BasicContainer";
import {
  Black,
  Gray100,
  Gray400,
  Green600,
  White,
} from "../../../config/color";

import BookBox from "./BookBox";
import { useEffect } from "react";

const OrchidBookImg = require("../../../assets/image/OrchidBook.png");
const GuideBook01Img = require("../../../assets/image/GuideBook01.png");
const GuideBook02Img = require("../../../assets/image/GuideBook02.png");

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1439px) {
    min-width: 360px;
    width: calc(100% - 40px);
    padding: 0 20px;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 112px);
  padding: 0 56px;
  margin-top: 74px;

  @media screen and (max-width: 1439px) {
    width: 100%;
    padding: 0;
    margin-top: 36px;
  }
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  line-height: 51px;
  color: ${Black};
  white-space: pre-wrap;

  @media screen and (max-width: 1439px) {
    font-size: 26px;
    line-height: 36px;
  }
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 28.8px;
  white-space: pre-wrap;
  margin-top: 16px;
`;

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100% - 112px);
  padding: 56px 0;
  margin: 0 56px;
  border-top: 1px solid ${Gray400};

  @media screen and (max-width: 767px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 32px 0;
    margin: 0;
  }

  @media screen and (min-width: 768px) and (max-width: 1439px) {
    width: 100%;
    padding: 32px 0;
    margin: 0;
  }
`;

const LinkButton = styled.a`
  width: 288px;
  height: 50px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Green600};
  border-radius: 5px;
  text-decoration: none;
  margin-top: 26px;
  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 767px) {
    width: calc(100% - 32px);
  }
`;

const LinkButtonText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 28.8px;
  margin-right: auto;
  color: ${White};
`;

const LinkButtonTextBold = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-left: auto;
  color: ${White};
`;

const PointBox = styled.div`
  width: 100%;
  padding: 48px 0;
  background-color: ${Gray100};

  @media screen and (max-width: 1439px) {
    padding: 73px 20px;
  }
`;

const BookAndPaper = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <BasicContainer metaTitle="관유정-저서와 논문">
      <Content>
        <TitleBox>
          <TitleLabel>저서와 논문</TitleLabel>
          <TitleText>{`명장의 손에서 탄생한 단단한 노하우
저서와 논문`}</TitleText>
        </TitleBox>
        <RowDiv style={{ marginTop: 56 }}>
          <BookBox
            Image={OrchidBookImg}
            publish="2022년 02월"
            hit={true}
            population={true}
            title="반려식물 난초 재테크"
            viewStyle={
              windowSize.innerWidth <= 767 ? { marginBottom: 32 } : null
            }
          />
          <TitleBox style={{ marginTop: 0 }}>
            <TitleLabel>미리보기</TitleLabel>
            <TitleText>반려식물 난초 재테크</TitleText>
            <Text>{`불확실성의 시대다. 마음도 어지럽고 경제적 자유도 쉽지 않은 막막함이 모두의 삶에 깃들어 있는 때, ‘치유’와 ‘재테크’를 한 번에 성취하는 길을 안내하는 책이 『반려식물 난초 재테크』다. 인간의 마음을 편하게 만드는 녹색 식물에 대한 관심이 높아지고 있는 지금, 식물을 집안으로 들여와 함께하는 사람들이 많아지고 있는데, 재테크도 가능한 작물이 바로 난초다. 난초는 정서적인 부분과 경제적인 측면 두 마리 토끼를 잡는 데 매우 효과적이다. 현대인의 지친 마음을 위로해줄 뿐만 아니라 고수익을 올릴 수 있게 해준다. 실제 많은 사람들이 난초로 자산가가 되기도 하고 월급을 받는 것처럼 돈을 벌고 있다. 난초로 석사, 박사, 신지식인, 대한민국 명장이 된 필자가 알려주는 반려식물 재테크 전략은 그야말로 애프터코로나 시대의 최고 전략 지침서라 할 수 있다. 삶을 치유하고 경제적 안정성까지 보장하는 것이다.

『반려식물 난초 재테크』 1장에서는 정부에서 주도하는 도시농업의 작물인 난초의 의미와 가치에 대해 이야기하고, 2장에서는 난초가 반려식물로 어떤 이점이 있는지를 이야기한다. 특히 아파트 베란다에서도 난초를 길러 어떻게 수익을 창출할 수 있는지를 소개하는 데 초점을 맞춘다. 3장에서는 난초에 대한 전반적인 배경지식과 정보를 이야기하고, 4장과 5장에서는 베란다에서 어떻게 난초를 길러서 수익을 창출할 수 있을 것인지, 기본적인 배양정보와 기술을 이야기한다. 6장과 7장에서는 베란다 농사로 어떻게 하면 월 100만 원을 벌 수 있을지 다양한 매뉴얼을 제시한다. 필자의 지침을 따라 충분한 공부와 교육을 이수한다면, 반려식물 난초는 분명 불확실성의 시대를 이기는 최고의 전략이 될 것이다.`}</Text>
            <LinkButton
              href="http://www.yes24.com/Product/Goods/107054715"
              target="_blank"
            >
              <LinkButtonText>다음 내용이 궁금하다면?</LinkButtonText>
              <LinkButtonTextBold>구매하기</LinkButtonTextBold>
            </LinkButton>
          </TitleBox>
        </RowDiv>
        <RowDiv style={{ marginTop: 88 }}>
          <BookBox
            Image={GuideBook01Img}
            publish="2020년 05월"
            hit={true}
            population={true}
            title="한국춘란 가이드 북 - 입문편"
            viewStyle={
              windowSize.innerWidth <= 767 ? { marginBottom: 32 } : null
            }
          />
          <TitleBox style={{ marginTop: 0 }}>
            <TitleLabel>미리보기</TitleLabel>
            <TitleText>한국춘란 가이드 북 - 입문편</TitleText>
            <Text>{`한국춘란은 40여 년의 역사를 지닌 부가가치가 높은 농작물이다. 영전한 사람들에게 대통령이 고급 난초를 선물하는 장면을 TV로 보게 되는데 그게 한국춘란이다.

한국춘란의 연간 매출이 4천억 원 정도이고, 정부에서도 적극적으로 권장하는 농작물 중 하나이다. 춘란을 취미와 업으로 삼고 있는 애란인도 20만여 명이 넘는다. 이에 반해 동양란의 선물 시장은 1조 원에 육박한다. 동양란은 대부분이 수입에 의존하는데 한국춘란은 순수 국산이다. 이러한 한국춘란의 현황에도 불구하고 아직 제대로 된 한국춘란 관련 입문서와 전문 기술서 한 권 없는 게 현실이다. 이에 저자는 한국춘란을 더 부흥시키고 한국춘란으로 난 시장을 대체시키기 위한 사명감으로 이 책을 쓰게 되었다.

이 책은 관유정을 운영하며 현장 경험과 학문적 이론이 풍부한 이대건 명장이 한국춘란을 쉽게 공부하고 현장에서 유용하게 활용할 수 있도록 하고자 두 권으로 기획된 한국춘란 가이드 북(입문편, 전문가편)중에서 첫 번째 책인 입문편이다.

입문편에서는 아주 기초적인 지식을 습득해 춘란을 기르고 부수입도 올릴 수 있도록 친절히 안내했다. 입문자들이 알기 쉽도록 한국춘란의 역사와 가계도를 안내하고 춘란 재배의 기초이며 핵심이 되는 배양의 노하우를 설명한다. 또한, 한국춘란을 단순한 취미를 넘어 재태크에 성공하는 비결도 친절하게 안내하고 있으며 초보자와 입문자들이 꼭 알아야 할 춘란 상식을 공유해주고 있어서 정말 값지고 귀한 책이다. 부록에는 입문자와 초보자를 위한 한국춘란 용어 사전을 곁들였다.`}</Text>
            <LinkButton
              href="http://www.yes24.com/Product/Goods/90114965"
              target="_blank"
            >
              <LinkButtonText>다음 내용이 궁금하다면?</LinkButtonText>
              <LinkButtonTextBold>구매하기</LinkButtonTextBold>
            </LinkButton>
          </TitleBox>
        </RowDiv>
        <RowDiv style={{ marginTop: 88 }}>
          <BookBox
            Image={GuideBook02Img}
            publish="2020년 07월"
            hit={true}
            population={true}
            title="한국춘란 가이드 북 - 전문가편"
            viewStyle={
              windowSize.innerWidth <= 767 ? { marginBottom: 32 } : null
            }
          />
          <TitleBox style={{ marginTop: 0 }}>
            <TitleLabel>미리보기</TitleLabel>
            <TitleText>한국춘란 가이드 북 - 전문가편</TitleText>
            <Text>{`한국춘란은 40여 년의 역사를 지닌 부가가치가 높은 농작물이다. 영전한 사람들에게 대통령이 고급 난초를 선물하는 장면을 TV로 보게 되는데 그게 한국춘란이다.

한국춘란은 연간 매출이 4천억 원 정도이고, 정부에서도 적극적으로 권장하는 농작물 중 하나이다. 춘란을 취미와 업으로 삼고 있는 애란인도 20만여 명이 넘는다. 이에 반해 동양란의 선물 시장은 1조 원에 육박한다. 동양란은 대부분이 수입에 의존하는데 한국춘란은 순수 국산이다. 이러한 한국춘란의 현황에도 불구하고 아직 제대로 된 한국춘란 관련 입문서와 전문 기술서 한 권 없는 게 현실이다. 이에 저자는 한국춘란을 더 부흥시키고 한국춘란으로 난 시장을 대체시키기 위한 사명감으로 이 책을 쓰게 되었다.

이 책은 관유정을 운영하며 현장 경험과 학문적 이론이 풍부한 이대건 명장이 한국춘란을 쉽게 공부하고 현장에서 유용하게 활용할 수 있도록 하고자 두 권으로 기획한 한국춘란 가이드 북(입문편, 전문가편)중에서 두 번째 책인 전문가편이다.

입문편에서는 한국춘란의 역사와 문화, 배양 기술 등 기본 지식이 주요 골자를 이뤘다면 이번 전문가편에서는 단순히 취미를 목적으로 한 난 재배를 넘어 한층 전문적이고 정교한 난 재배 기술을 담고 있다. 총 7장으로 구성된 이 책은 1장 시장 분석, 2장 전략 수립, 3장 재배 생리, 4장 생산기술, 5장 품질 관리, 6장 위기 대처, 7장 예술 세계라는 큰 틀을 잡고 세부 내용을 풀어냈다. 30년 경력의 농업 명장인 저자의 전문 지식과 기술을 꼼꼼히 망라한 본서의 전문가편은 춘란 전문가의 길로 발돋움하려는 이들에게 든든한 길잡이가 되어줄 것이다.`}</Text>
            <LinkButton
              href="http://www.yes24.com/Product/Goods/90578203"
              target="_blank"
            >
              <LinkButtonText>다음 내용이 궁금하다면?</LinkButtonText>
              <LinkButtonTextBold>구매하기</LinkButtonTextBold>
            </LinkButton>
          </TitleBox>
        </RowDiv>
        <RowDiv style={{ marginTop: 88 }}>
          <BookBox
            Image={GuideBook02Img}
            publish=""
            hit={false}
            population={false}
            title=""
            viewStyle={
              windowSize.innerWidth <= 767 ? { marginBottom: 32 } : null
            }
          />
          <TitleBox style={{ marginTop: 0 }}>
            <TitleLabel>미리보기</TitleLabel>
            <TitleText>석사 논문</TitleText>
            <Text>{`한국춘란은 40여 년의 역사를 지닌 부가가치가 높은 농작물이다. 영전한 사람들에게 대통령이 고급 난초를 선물하는 장면을 TV로 보게 되는데 그게 한국춘란이다.

한국춘란은 연간 매출이 4천억 원 정도이고, 정부에서도 적극적으로 권장하는 농작물 중 하나이다. 춘란을 취미와 업으로 삼고 있는 애란인도 20만여 명이 넘는다. 이에 반해 동양란의 선물 시장은 1조 원에 육박한다. 동양란은 대부분이 수입에 의존하는데 한국춘란은 순수 국산이다. 이러한 한국춘란의 현황에도 불구하고 아직 제대로 된 한국춘란 관련 입문서와 전문 기술서 한 권 없는 게 현실이다. 이에 저자는 한국춘란을 더 부흥시키고 한국춘란으로 난 시장을 대체시키기 위한 사명감으로 이 책을 쓰게 되었다.

이 책은 관유정을 운영하며 현장 경험과 학문적 이론이 풍부한 이대건 명장이 한국춘란을 쉽게 공부하고 현장에서 유용하게 활용할 수 있도록 하고자 두 권으로 기획한 한국춘란 가이드 북(입문편, 전문가편)중에서 두 번째 책인 전문가편이다.

입문편에서는 한국춘란의 역사와 문화, 배양 기술 등 기본 지식이 주요 골자를 이뤘다면 이번 전문가편에서는 단순히 취미를 목적으로 한 난 재배를 넘어 한층 전문적이고 정교한 난 재배 기술을 담고 있다. 총 7장으로 구성된 이 책은 1장 시장 분석, 2장 전략 수립, 3장 재배 생리, 4장 생산기술, 5장 품질 관리, 6장 위기 대처, 7장 예술 세계라는 큰 틀을 잡고 세부 내용을 풀어냈다. 30년 경력의 농업 명장인 저자의 전문 지식과 기술을 꼼꼼히 망라한 본서의 전문가편은 춘란 전문가의 길로 발돋움하려는 이들에게 든든한 길잡이가 되어줄 것이다.`}</Text>
          </TitleBox>
        </RowDiv>
        <RowDiv style={{ marginTop: 88, marginBottom: 104 }}>
          <BookBox
            Image={GuideBook02Img}
            publish=""
            hit={false}
            population={false}
            title=""
            viewStyle={
              windowSize.innerWidth <= 767 ? { marginBottom: 32 } : null
            }
          />
          <TitleBox style={{ marginTop: 0 }}>
            <TitleLabel>미리보기</TitleLabel>
            <TitleText>박사 논문</TitleText>
            <Text>{`한국춘란은 40여 년의 역사를 지닌 부가가치가 높은 농작물이다. 영전한 사람들에게 대통령이 고급 난초를 선물하는 장면을 TV로 보게 되는데 그게 한국춘란이다.

한국춘란은 연간 매출이 4천억 원 정도이고, 정부에서도 적극적으로 권장하는 농작물 중 하나이다. 춘란을 취미와 업으로 삼고 있는 애란인도 20만여 명이 넘는다. 이에 반해 동양란의 선물 시장은 1조 원에 육박한다. 동양란은 대부분이 수입에 의존하는데 한국춘란은 순수 국산이다. 이러한 한국춘란의 현황에도 불구하고 아직 제대로 된 한국춘란 관련 입문서와 전문 기술서 한 권 없는 게 현실이다. 이에 저자는 한국춘란을 더 부흥시키고 한국춘란으로 난 시장을 대체시키기 위한 사명감으로 이 책을 쓰게 되었다.

이 책은 관유정을 운영하며 현장 경험과 학문적 이론이 풍부한 이대건 명장이 한국춘란을 쉽게 공부하고 현장에서 유용하게 활용할 수 있도록 하고자 두 권으로 기획한 한국춘란 가이드 북(입문편, 전문가편)중에서 두 번째 책인 전문가편이다.

입문편에서는 한국춘란의 역사와 문화, 배양 기술 등 기본 지식이 주요 골자를 이뤘다면 이번 전문가편에서는 단순히 취미를 목적으로 한 난 재배를 넘어 한층 전문적이고 정교한 난 재배 기술을 담고 있다. 총 7장으로 구성된 이 책은 1장 시장 분석, 2장 전략 수립, 3장 재배 생리, 4장 생산기술, 5장 품질 관리, 6장 위기 대처, 7장 예술 세계라는 큰 틀을 잡고 세부 내용을 풀어냈다. 30년 경력의 농업 명장인 저자의 전문 지식과 기술을 꼼꼼히 망라한 본서의 전문가편은 춘란 전문가의 길로 발돋움하려는 이들에게 든든한 길잡이가 되어줄 것이다.`}</Text>
          </TitleBox>
        </RowDiv>
        <PointBox>
          <TitleBox style={{ marginTop: 0 }}>
            <TitleLabel>저서와 논문</TitleLabel>
            <TitleText>연구 논문</TitleText>
            <Text>
              <ul style={{ margin: 0, paddingLeft: 26 }}>
                <li>學士 論文 (한국 춘란에 발생하는 병충해의 현황)</li>
                <li>
                  碩士 論文 (한국춘란의 삭과 형성 및 종자 발아에 미치는 NaOCl의
                  영향)
                </li>
                <li>
                  博士 論文 (Cymbidium-goeringii 의 형태학적 특성 및
                  Microsatellite DNA 좌위에 의한 다양성 분석)포스트 발표[
                  한국춘란(Cymbidium-goeringii )종자 발아에 미치는NaOCl의 영향 ]
                  원예학회
                </li>
                <li>
                  FLOWER RESEARDH JOURNAN(화훼연구지) 춘란(Cymbidium-goeringii
                  )의 개화정도별 삭과 형성 및 종자 발아에 미치는 NaOCl의 영향
                  Vol. 18 No. 4 (December 31, 2010)
                </li>
                <li>
                  춘란(Cymbidium-goeringii ) 품종의 SSRDNA의 복합 유전자형
                  결정과 적용 원예 과학 기술지(SCI -E 논문 2012.06)
                </li>
                <li>
                  A.R.P.C 관유정. 대가대. 공주대. 제주대 협동 연구[ 과제명:
                  춘란(Cymbidium-goeringii )의 초다형성
                </li>
                <li>SSR DNA ID와 돌연변이 유도를 통한 우수 신품종의 개발 ]</li>
                <li>네팔 난 연구(2013.11.25~12.05)</li>
                <li>대만 불리(2014.07.19~21 다이중-짜이-포리)</li>
                <li>
                  심비디움 대량 재배 기법 및 호접란의 기내배양심비디움 대량생산
                  체계 및 시장 동향
                </li>
                <li>
                  해외 연구(중국/호북성 대오, 하남성 동백. 대만/양매, 가우슝,
                  불리. 베트남/하노이 난 시장, 최북단 정글, 힉스씨 난 농장.
                  네팔/카투만두 북쪽 정글)
                </li>
              </ul>
            </Text>
          </TitleBox>
        </PointBox>
      </Content>
    </BasicContainer>
  );
};

export default BookAndPaper;

import React from "react";
import styled from "styled-components";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import "@kimnagui/ckeditor5-translation-ko";

const Conatiner = styled.div`
  display: flex;
`;

const BoardEditor = (props) => {
  const { viewStyle } = props;
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("boardImage", file);
            fetch(`/board/image`, {
              method: "post",
              body: body,
            })
              .then((res) => res.json())
              .then((res) => {
                console.log(res);
                resolve({
                  default: `/upload/board/${res.filename}`,
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }
  return (
    <Conatiner style={viewStyle}>
      <CKEditor
        config={{
          extraPlugins: [uploadPlugin],
          language: "ko",
        }}
        data="<p>게시물 작성!</p>"
        editor={Editor}
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
          console.log("Editor is ready to use!", editor);
        }}
        onChange={(event, editor) => {
          // const data = editor.getData();
          // console.log( { event, editor, data } );
          console.log(editor.getData());
        }}
        onBlur={(event, editor) => {
          console.log("Blur.", editor);
        }}
        onFocus={(event, editor) => {
          console.log("Focus.", editor);
        }}
        // onError={(editor) => {
        //   console.log("Error", editor);
        // }}
      />
    </Conatiner>
  );
};

export default BoardEditor;

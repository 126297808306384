import React from "react";
import styled from "styled-components";
import { White } from "../config/color";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 222px;
  @media screen and (max-width: 1439px) {
    height: 144px;
  }
`;

const TextBox = styled.div`
  flex: 1;
  padding: 67px 56px;

  @media screen and (max-width: 1439px) {
    padding: 35px 20px;
  }
`;

const Label = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 180%;
  color: ${White};
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 34px;
  line-height: 150%;
  color: ${White};
  margin-top: 8px;
  font-family: "Nanum Myeongjo";

  @media screen and (max-width: 1439px) {
    font-size: 26px;
    line-height: 36px;
  }
`;

const PageTop = (props) => {
  const { label, title, ImageUrl } = props;
  return (
    <Container style={{ backgroundImage: `url(${ImageUrl})` }}>
      <TextBox>
        <Label>{label}</Label>
        <Title>{title}</Title>
      </TextBox>
    </Container>
  );
};

export default PageTop;

import React from "react";
import styled from "styled-components";
import { Gray300, Gray500, Green100, White } from "../../../config/color";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Label = styled.div`
  width: 122px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Gray500};
  border: 1px solid ${Gray300};
  background-color: ${Green100};
`;

const Content = styled.div`
  width: 424px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Gray300};
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Gray500};
  background-color: ${White};
`;

const InpatientTreatmentTable = () => {
  return (
    <Container>
      <Row>
        <Label>1년</Label>
        <Content>150만원</Content>
      </Row>
      <Row>
        <Label>6개월</Label>
        <Content>80만원</Content>
      </Row>
      <Row>
        <Label>3개월</Label>
        <Content>40만원</Content>
      </Row>
      <Row>
        <Label>2개월</Label>
        <Content>30만원</Content>
      </Row>
    </Container>
  );
};

export default InpatientTreatmentTable;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BasicContainer from "../../../components/BasicContainer";
import { TAB } from "../../../components/Tab";
import {
  Black,
  Gray100,
  Green100,
  Green600,
  White,
} from "../../../config/color";

import { CardWithIcon } from "../../../components/Card";
import { getWindowSize } from "../../../utils";
import TopGraph from "./TopGraph";

const Medal = require("../../../assets/icon/Medal99.png");
const Plant = require("../../../assets/icon/Plant99.png");
const Chart = require("../../../assets/icon/Chart99.png");
const Valance = require("../../../assets/icon/Valance99.png");
const Setting = require("../../../assets/icon/Setting99.png");
const Farm = require("../../../assets/icon/Farm99.png");
const Vector = require("../../../assets/icon/Vector.png");

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1439px) {
    min-width: 360px;
    width: 100%;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 1440px; //calc(100% - 112px);
  margin: 0 auto;
  @media screen and (max-width: 1439px) {
    width: calc(100% - 40px);
    padding: 0 20px;
  }
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  color: ${Black};
  @media screen and (max-width: 1439px) {
    font-size: 26px;
    line-height: 36px;
  }
`;

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 1440px;
  margin-bottom: 24px;

  @media screen and (max-width: 1439px) {
    flex-direction: column;
    width: calc(100% - 40px);
    height: auto;
    padding: 0 20px;
  }
`;

const Introduce = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${Green100};
  position: relative;
`;

const ImageBox = styled.div`
  flex: 1;
`;

const Image01 = styled.img`
  width: 100%;
  height: 100%;
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 28.8px;
  white-space: pre-wrap;
`;

const TagContainer = styled.div`
  width: 1440px;
  height: auto;
  margin: 0 auto;
  margin-top: 32px;

  @media screen and (max-width: 1439px) {
    width: calc(100% - 40px);
    padding: 0 20px;
  }
`;

const TagRowBox = styled.div`
  max-width: 1100px;
`;

const TagBox = styled.div`
  float: left;
  padding: 16px 24px;
  border-radius: 50px;
  background-color: ${Green100};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Nanum Myeongjo", serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: ${Black};
  margin-right: 16px;
  margin-bottom: 16px;
  word-break: keep-all;
`;

const Precursor = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <BasicContainer metaTitle="관유정-선구자 이대발">
      <Content>
        <TAB
          tabList={[{ name: "선구자 이대발" }, { name: "주요 업적" }]}
          viewStyle={{
            marginTop: 32,
            marginBottom: windowSize.innerWidth < 1439 ? 32 : 42,
          }}
          tabStyle={{
            paddingTop: 0,
            paddingBottom: 9,
            paddingLeft: 26,
            paddingRight: 26,
          }}
          activeTabIdx={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        {selectedTab === 0 ? (
          <>
            <TitleBox>
              <TitleLabel>선구자 이대발</TitleLabel>
              <TitleText>
                이대발을 왜{windowSize.innerWidth < 1439 ? <br /> : null}
                선구자라고 하는가?
              </TitleText>
              <Text style={{ marginTop: 16 }}>
                {windowSize.innerWidth < 1439 ? (
                  <>
                    <b>{`그가 가면 길이 되었고 그가 하면 기술체계가 정립되었기 때문이다. `}</b>
                    {`내 기술은 특이하게도 기존 난계에서 시행하고 있는 배양기술의 98% 이상이 정반대이다. 난계가 하고 있는 방식과 완전히 다르다. 그러다 보니 돌아이, 미친 녀석이라는 수식어가 끊이지 않았다. `}
                    <b>{`하지만 지금은 나를 바라보는 시각이 완전히 다르다.`}</b>
                    {`내가 제시한 이론이 담긴 《한국 춘란 가이드북》은 난초 동의보감이라는 평가를 받게 되었다. 난계의 폭발적인 공감을 끌어내게 된 것이다.《한국 춘란 가이드북》을 토대로 제작한 유튜브는 난초 초보자의 진입장벽을 허물었다. 책과 유튜브, 2급 자격 과정을 이수하면 누구나 난초로 의미 있는 결과를 만들어낼 수 있는 시대를 만든 것이다. 이것은 번지르르하게 말을 잘하고 글을 잘 쓴 결과가 아니다. 누가 봐도 인정할 만한 기술과 품질, 세계적인 품종개발에서 비롯된 것이다. 그래서 이대발 박사를 난초 계의 선구자라고 부르는 것이다.`}
                  </>
                ) : (
                  <>
                    <b>{`그가 가면 길이 되었고 그가 하면 기술체계가 정립되었기 때문이다.`}</b>
                    <br />
                    {`내 기술은 특이하게도 기존 난계에서 시행하고 있는 배양기술의 98% 이상이 정반대이다.
난계가 하고 있는 방식과 완전히 다르다. 그러다 보니 돌아이, 미친 녀석이라는 수식어가 끊이지 않았다.`}
                    <br />
                    <b>{`하지만 지금은 나를 바라보는 시각이 완전히 다르다.`}</b>
                    <br />
                    {`내가 제시한 이론이 담긴 《한국 춘란 가이드북》은 난초 동의보감이라는 평가를 받게 되었다.
난계의 폭발적인 공감을 끌어내게 된 것이다. 
《한국 춘란 가이드북》을 토대로 제작한 유튜브는 난초 초보자의 진입장벽을 허물었다.
책과 유튜브, 2급 자격 과정을 이수하면 누구나 난초로 의미 있는 결과를 만들어낼 수 있는 시대를 만든 것이다.
이것은 번지르르하게 말을 잘하고 글을 잘 쓴 결과가 아니다.
누가 봐도 인정할 만한 기술과 품질, 세계적인 품종개발에서 비롯된 것이다.
그래서 이대발 박사를 난초 계의 선구자라고 부르는 것이다.`}
                  </>
                )}
              </Text>
            </TitleBox>
            <TagContainer>
              <TagRowBox>
                <TagBox>저변확대의 선구자</TagBox>
                <TagBox>생산기술 개발의 선구자</TagBox>
                <TagBox>한국춘란 자원화의 선구자</TagBox>
                <TagBox>해외수출의 선구자</TagBox>
                <TagBox>농가교육의 선구자</TagBox>
                <TagBox>일자리창출의 선구자</TagBox>
                <TagBox>국책승화의 선구자</TagBox>
                <TagBox>난초 병원의 선구자</TagBox>
                <TagBox>국내 전체 농가 생산 품질 향상의 선구자</TagBox>
                <TagBox>한국춘란 선발육종의 선구자</TagBox>
              </TagRowBox>
            </TagContainer>
            <RowDiv
              style={{
                marginTop: windowSize.innerWidth <= 1439 ? 69 : 149,
                height: windowSize.innerWidth <= 1439 ? "auto" : 735,
                marginBottom: 0,
              }}
            >
              <Introduce style={{ backgroundColor: Green600 }}>
                <TitleLabel
                  style={{
                    color: White,
                    marginLeft: windowSize.innerWidth <= 1439 ? 20 : 56,
                    marginTop: windowSize.innerWidth <= 1439 ? 50 : 0,
                  }}
                >
                  선구자 이대발
                </TitleLabel>
                <TitleText
                  style={{
                    color: White,
                    marginLeft: windowSize.innerWidth <= 1439 ? 20 : 56,
                  }}
                >
                  교육사업
                </TitleText>
                <Text
                  style={{
                    marginTop: 16,
                    color: White,
                    fontWeight: 400,
                    marginLeft: windowSize.innerWidth <= 1439 ? 20 : 56,
                    marginRight: windowSize.innerWidth <= 1439 ? 20 : 0,
                    marginBottom: windowSize.innerWidth <= 1439 ? 50 : 0,
                    whiteSpace:
                      windowSize.innerWidth <= 1439 ? "normal" : "pre-wrap",
                  }}
                >{`젊은 시절 난계에 들어와 제대로 된 스승 다섯분을 모신 것이 계기가 돼
오늘의 영광을 누리게 되었다.
당시 스승님을 만나기 전에 접한 정보나 자료들은 부족함이 많았다.
‘옵션 체계를 갖춘 품종을 보는 안목, 안 죽고 잘 기를 수 있는 기술을
체계적으로 배울 수 있다면 얼마나 좋을까?’라며 당시에 볼멘소리를 자주 했다.
내가 만약 기술과 능력이 된다면 후진을 양성해 좋은 품종으로 잘 길러
시합에서 성적을 거둘 수 있도록 돕겠다는 마음이 있었다. 
이런 마음이 공부에 매진하는 계기가 되었고 1999년 첫 유료강의를 시작할 수 있었다.
최초의 교육생인 김OO을 시작으로 오늘에 이르렀다.
2021년부터 자격증 제도를 신설해 2급과 1급 자격자를 양성 배출하고 있다.
2급은 부업농 지도 과정이며 1급은 프로 작가 및 심판을 양성하는 과정이다.
1999년 10만 원으로 시작한 강의는 2022년 현재 300만 원이 되었다.
이제는 경력으로 목소리를 높이는 시대는 지났다.
체계적인 기술과 품질로 자신의 능력을 알려야 한다. 그 길의 시작은 교육이 답이다. `}</Text>
              </Introduce>
              <ImageBox>
                <Image01
                  src="/assets/tmpImg/precursor03.png"
                  style={{
                    height: windowSize.innerWidth <= 1439 ? 735 : "auto",
                    objectFit:
                      windowSize.innerWidth <= 1439 ? "cover" : "contain",
                  }}
                />
              </ImageBox>
            </RowDiv>
            <RowDiv
              style={{
                marginBottom: 0,
                flexDirection:
                  windowSize.innerWidth <= 1439 ? "column-reverse" : "row",
              }}
            >
              <ImageBox>
                <Image01
                  src="/assets/tmpImg/precursor02.png"
                  style={{
                    height: windowSize.innerWidth <= 1439 ? 735 : "auto",
                    objectFit:
                      windowSize.innerWidth <= 1439 ? "cover" : "contain",
                  }}
                />
              </ImageBox>
              <Introduce style={{ backgroundColor: White }}>
                <TitleLabel
                  style={{
                    marginLeft: windowSize.innerWidth <= 1439 ? 20 : 56,
                    marginTop: windowSize.innerWidth <= 1439 ? 76 : 0,
                  }}
                >
                  선구자 이대발
                </TitleLabel>
                <TitleText
                  style={{
                    marginLeft: windowSize.innerWidth <= 1439 ? 20 : 56,
                  }}
                >
                  복륜 전문점 5년이
                  {windowSize.innerWidth <= 1439 ? <br /> : null} 동방불패
                  개발로
                </TitleText>
                <Text
                  style={{
                    marginTop: 16,
                    fontWeight: 400,
                    marginLeft: windowSize.innerWidth <= 1439 ? 20 : 56,
                    marginRight: windowSize.innerWidth <= 1439 ? 20 : 0,
                    marginBottom: windowSize.innerWidth <= 1439 ? 75 : 0,
                  }}
                >
                  {windowSize.innerWidth <= 1439
                    ? "1997년 네 번째 차린 비닐하우스 간판에는 복륜 전문점이란 표제가 걸렸다. 그때 수백 화분의 복륜을 기르며 복륜의 깊이와 넓이와 높이를 쌓았다. 복륜의 무늬와 잎의 생김새로 꽃을 유추하려고 데이터를 확보하며 익혀갔다. 5년의 세월이 흐르자 복륜이 제대로 보이기 시작했다. 복륜이 이해되니 중투와 산반의 세계도 눈을 뜰 수 있었다. 그때 익힌 기술을 토대로 산채가 붙어 있는 미개화 포기에서 세계기록의 원판 금계산반 심대복륜화인 동방불패를 개발하는 신기원을 이루게 되었다."
                    : `1997년 네 번째 차린 비닐하우스 간판에는 복륜 전문점이란 표제가 걸렸다.
그때 수백 화분의 복륜을 기르며 복륜의 깊이와 넓이와 높이를 쌓았다.
복륜의 무늬와 잎의 생김새로 꽃을 유추하려고 데이터를 확보하며 익혀갔다.
5년의 세월이 흐르자 복륜이 제대로 보이기 시작했다.
복륜이 이해되니 중투와 산반의 세계도 눈을 뜰 수 있었다.
그때 익힌 기술을 토대로 산채가 붙어 있는 미개화 포기에서
세계기록의 원판 금계산반 심대복륜화인 동방불패를 개발하는 신기원을 이루게 되었다.`}
                </Text>
              </Introduce>
            </RowDiv>
            <RowDiv style={{ marginBottom: 46 }}>
              <Introduce style={{ backgroundColor: White }}>
                <TitleLabel
                  style={{
                    marginLeft: windowSize.innerWidth <= 1439 ? 20 : 56,
                    marginTop: windowSize.innerWidth <= 1439 ? 76 : 0,
                  }}
                >
                  선구자 이대발
                </TitleLabel>
                <TitleText
                  style={{
                    marginLeft: windowSize.innerWidth <= 1439 ? 20 : 56,
                  }}
                >
                  한국 춘란의 수출 사업
                </TitleText>
                <Text
                  style={{
                    marginTop: 16,
                    fontWeight: 400,
                    marginLeft: windowSize.innerWidth <= 1439 ? 20 : 56,
                    marginRight: windowSize.innerWidth <= 1439 ? 20 : 56,
                    marginBottom: windowSize.innerWidth <= 1439 ? 75 : 0,
                  }}
                >
                  {`10년의 시도 끝에 2022년 `}
                  <b>{`국내 최초 한국춘란 미국 공식 수출업체`}</b>
                  {windowSize.innerWidth <= 1439
                    ? "10년의 시도 끝에 2022년 국내 최초 한국춘란 미국 공식 수출업체가 되었다. 정상적인 검역을 거쳐 수출을 하게 된 것이다. 미국에는 이미 부귀란이 자리를 잡았는데 한국 춘란 붐도 함께 일어나고 있다. 오래전부터 한국춘란의 문화적 콘텐츠를 수출 기획하고 있다가 2022년 5월 12일 관유정 CI가 새겨진 박스에 담아 수출의 길을 열었다. 미국 홈페이지도 개설하였다. 미국 한국춘란 애호가들이 자유롭게 쇼핑하고 정보를 공유할 수 있게 한 것이다. 이미 많은 수의 명품들이 진출했다. 미국에서 선호하는 품종의 2등급 이내의 정상품은 수가 매우 달린다. 2급 교육으로 잘 기르기만 한다면 판로는 걱정이 없는 시절이 되었다."
                    : `가 되었다.
정상적인 검역을 거쳐 수출을 하게 된 것이다. 미국에는 이미 부귀란이 자리를 잡았는데 한국 춘란 붐도 함께 일어나고 있다. 오래전부터 한국춘란의 문화적 콘텐츠를
수출 기획하고 있다가 2022년 5월 12일 관유정 CI가 새겨진 박스에 담아 수출의 길을 열었다.
미국 홈페이지도 개설하였다. 미국 한국춘란 애호가들이 자유롭게 쇼핑하고
정보를 공유할 수 있게 한 것이다. 이미 많은 수의 명품들이 진출했다.
미국에서 선호하는 품종의 2등급 이내의 정상품은 수가 매우 달린다.
2급 교육으로 잘 기르기만 한다면 판로는 걱정이 없는 시절이 되었다. `}
                </Text>
              </Introduce>
              <ImageBox>
                <Image01
                  src="/assets/tmpImg/precursor01.png"
                  style={{
                    height: windowSize.innerWidth <= 1439 ? 735 : "auto",
                    objectFit:
                      windowSize.innerWidth <= 1439 ? "cover" : "contain",
                  }}
                />
              </ImageBox>
            </RowDiv>
          </>
        ) : (
          <>
            <TitleBox
              style={{
                marginBottom: 41,
                textAlign: windowSize.innerWidth <= 1439 ? "left" : "center",
              }}
            >
              <TitleLabel>선구자 이대발</TitleLabel>
              <TitleText>
                난초에 대한{windowSize.innerWidth <= 1439 ? <br /> : null} 5대
                장벽을 허물어버리다
              </TitleText>
            </TitleBox>
            <RowDiv>
              <TopGraph
                viewStyle={{
                  marginBottom: windowSize.innerWidth <= 1439 ? 80 : 97,
                }}
              />
            </RowDiv>
            <RowDiv>
              <CardWithIcon
                viewStyle={{
                  backgroundColor: Gray100,
                  marginRight: windowSize.innerWidth < 1439 ? 0 : 8,
                  marginBottom: windowSize.innerWidth < 1439 ? 24 : 0,
                  height: 306,
                  minHeight: windowSize.innerWidth < 768 ? 360 : 306,
                }}
                title="국내 최초 대외 인식 변화"
                content={`대한민국농업명장
대구가톨릭대학/대구대학 겸임교수역임
우장춘 박사 기념관 등재
CF 출연 및 100여 건의 언론 활동
국내 최초 미국 진출
`}
                iconImg={Medal}
              />
              <CardWithIcon
                viewStyle={{
                  backgroundColor: Green100,
                  marginLeft: windowSize.innerWidth < 1439 ? 0 : 8,
                  height: 306,
                  minHeight: windowSize.innerWidth < 768 ? 380 : 306,
                }}
                title="국내 최초 난계 시스템 확립"
                content={`생산 기술 체계
농가 교육 체계
미술 체계와 작품 체계
대회 경기 심판 채점 체계
부업 농 성공 체계
                `}
                iconImg={Plant}
              />
            </RowDiv>
            <RowDiv
              style={{
                flexDirection:
                  windowSize.innerWidth < 1439 ? "column-reverse" : "row",
              }}
            >
              <CardWithIcon
                viewStyle={{
                  backgroundColor: Green100,
                  marginRight: windowSize.innerWidth < 1439 ? 0 : 8,
                  height: 306,
                  minHeight: windowSize.innerWidth < 768 ? 360 : 306,
                }}
                title="일자리 창출"
                content={`유튜브 운영
《반려식물 난초 재테크》,저술
 1-2급 자격 과정 운영
원금 보장 A/S 보험 제도 개발
바우처시스템 프랜차이즈 개발                
`}
                iconImg={Chart}
              />
              <CardWithIcon
                viewStyle={{
                  backgroundColor: Gray100,
                  marginLeft: windowSize.innerWidth < 1439 ? 0 : 8,
                  marginBottom: windowSize.innerWidth < 1439 ? 24 : 0,
                  height: 306,
                  minHeight: windowSize.innerWidth < 768 ? 360 : 306,
                }}
                title="시장안정"
                content={`유전자 검사
품질 등급 기준체계 개발
aT 경매 제안(김재수 1차관에게)
삼정난거래소 설립
뿌리를 보여주고 판매 국내 시장 완전 정착                
                `}
                iconImg={Valance}
              />
            </RowDiv>
            <RowDiv>
              <CardWithIcon
                viewStyle={{
                  backgroundColor: Gray100,
                  marginRight: windowSize.innerWidth < 1439 ? 0 : 8,
                  marginBottom: windowSize.innerWidth < 1439 ? 24 : 0,
                  height: 306,
                  minHeight: windowSize.innerWidth < 768 ? 430 : 306,
                }}
                title="생산기술"
                content={`일본에 10년 뒤처진 걸 동등하게 만들었음
저압 습식법 
저촉 분주 다산법
저부가 아조변이를 고부가 중투로 만드는 체계 확립
(농림축산식품과학기술대상)
T/R율이 등급 향상에 미치는 영향 규명 전국 교육
`}
                iconImg={Setting}
              />
              <CardWithIcon
                viewStyle={{
                  backgroundColor: Green100,
                  marginLeft: windowSize.innerWidth < 1439 ? 0 : 8,
                  height: 306,
                  minHeight: windowSize.innerWidth < 768 ? 360 : 306,
                }}
                title="농가지원"
                content={`《한국춘란 가이드북》,저술
2009 난 클리닉센터 설립
2022 종 보존센터 운영
위기농 재건 컬설팅 운영
내 난 시세 알아보기 프로그램 개발
                `}
                iconImg={Farm}
              />
            </RowDiv>
            <RowDiv>
              <CardWithIcon
                viewStyle={{
                  backgroundColor: Green100,
                  marginBottom: 0,
                  minHeight: windowSize.innerWidth < 768 ? 430 : 306,
                }}
                title="시장안정"
                content={`인터넷 강좌 인클 입점
법률 분쟁조정위원 역임(울산광역시)
대한민국난등록협회 전문위원
대한민국 원예학회 정회원 
연간 관행 예방 방제 표준 매뉴얼 개발
방사능 처리 돌연변이 유기에 관한 연구
대한민국 농업(난초) 명장 상 재정(상훈 대통령)
한중 원산지 판별법 연구
`}
                iconImg={Vector}
                iconStyle={{
                  width: windowSize.innerWidth < 768 ? 253 : 411,
                  height: windowSize.innerWidth < 768 ? 81 : 131,
                  position: "absolute",
                  top: windowSize.innerWidth < 768 ? 40 : 66,
                  right: 20,
                }}
                titleStyle={{
                  fontSize: windowSize.innerWidth < 768 ? 24 : 20,
                }}
                contentStyle={{
                  fontSize: windowSize.innerWidth < 768 ? 16 : 14,
                }}
              />
            </RowDiv>
          </>
        )}
      </Content>
    </BasicContainer>
  );
};

export default Precursor;

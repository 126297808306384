import React from "react";
import styled from "styled-components";
import {
  Black,
  Gray300,
  Green400,
  Green600,
  White,
} from "../../../config/color";

const Container = styled.div`
  width: 1440px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 767px) {
    width: 320px;
    margin: 0 auto;
  }
  @media screen and (min-width: 768px) and (max-width: 1439px) {
    width: 729px;
    margin: 0 auto;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
`;

const GraphBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 208px;
  position: relative;
  margin-right: -29px;
  @media screen and (max-width: 767px) {
    width: 72px;
    margin-right: -10px;
  }
  @media screen and (min-width: 768px) and (max-width: 1439px) {
    width: 164px;
    margin-right: -23px;
  }
`;

const GraphTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: #8f8f8f;
  text-align: center;
  margin-bottom: 7px;
  @media screen and (max-width: 767px) {
    font-size: 5.5px;
    line-height: 10px;
    display: none;
  }
  @media screen and (min-width: 768px) and (max-width: 1439px) {
    font-size: 12.6px;
    line-height: 22.7px;
  }
`;

const Graph = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 208px;
  height: 208px;
  opacity: 0.2;
  border-radius: 104px;

  @media screen and (max-width: 767px) {
    width: 72px;
    height: 72px;
  }
  @media screen and (min-width: 768px) and (max-width: 1439px) {
    width: 164px;
    height: 164px;
  }
`;

const GraphText = styled.div`
  position: absolute;
  top: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 208px;
  height: 208px;
  opacity: 1;
  font-weight: 700;
  font-size: 24px;
  z-index: 99;
  @media screen and (max-width: 767px) {
    width: 72px;
    height: 72px;
    font-size: 8.3px;
    top: 0;
  }
  @media screen and (min-width: 768px) and (max-width: 1439px) {
    width: 164px;
    height: 164px;
    font-size: 19px;
    top: 31.9px;
  }
`;

const DashBox1 = styled.div`
  width: 538px;
  margin-left: 104px;
  margin-right: 75px;
  display: flex;
  justify-content: center;
  position: relative;
  @media screen and (max-width: 767px) {
    width: 188px;
    margin-left: 36px;
    margin-right: 75px;
  }
  @media screen and (min-width: 768px) and (max-width: 1439px) {
    width: 424px;
    margin-left: 82px;
    margin-right: 82px;
  }
`;

const DashBox2 = styled.div`
  width: 208px;
  display: flex;
  justify-content: center;
  position: relative;
  @media screen and (max-width: 767px) {
    width: 186px;
    margin-left: -50px;
  }
  @media screen and (min-width: 768px) and (max-width: 1439px) {
    width: 424px;
    margin-left: -18px;
  }
`;

const Dash1 = styled.div`
  width: 538px;
  height: 70px;
  border: 3px dashed ${Green600};
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  border-top: 0;

  @media screen and (max-width: 767px) {
    width: 186px;
    height: 24px;
    border-width: 1px;
  }
  @media screen and (min-width: 768px) and (max-width: 1439px) {
    width: 424px;
    height: 55px;
    border-width: 2px;
  }
`;

const Dash2 = styled.div`
  width: 0;
  height: 70px;
  border-right: 3px dashed ${Green600};
  @media screen and (max-width: 767px) {
    width: 0;
    height: 24px;
    border-width: 1px;
  }
  @media screen and (min-width: 768px) and (max-width: 1439px) {
    width: 0;
    height: 55px;
    border-width: 2px;
  }
`;

const WayText = styled.div`
  position: absolute;
  top: 40px;
  width: 191px;
  height: 59px;
  background-color: ${Green600};
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 18px;
  line-height: 27px;
  color: ${White};
  @media screen and (max-width: 767px) {
    top: 8px;
    width: 100px;
    height: 30px;
    font-size: 8px;
    font-weight: 400;
  }
  @media screen and (min-width: 768px) and (max-width: 1439px) {
    top: 31px;
    width: 150px;
    height: 46px;
    font-size: 14px;
    font-weight: 400;
  }
`;

const TopGraph = (props) => {
  const { viewStyle } = props;
  return (
    <Container style={viewStyle}>
      <InnerContainer>
        <GraphBox>
          <GraphTitle>난초는 잘 죽는다</GraphTitle>
          <Graph style={{ backgroundColor: Gray300 }}></Graph>
          <GraphText style={{ color: Black }}>죽는다</GraphText>
        </GraphBox>
        <GraphBox>
          <GraphTitle>키우기가 어렵다</GraphTitle>
          <Graph style={{ backgroundColor: Green400 }}></Graph>
          <GraphText style={{ color: Green600 }}>어렵다</GraphText>
        </GraphBox>
        <GraphBox>
          <GraphTitle>생계와는 관련이 없다</GraphTitle>
          <Graph style={{ backgroundColor: Gray300 }}></Graph>
          <GraphText style={{ color: Black }}>돈이 안된다</GraphText>
        </GraphBox>
        <GraphBox>
          <GraphTitle>애물단지다</GraphTitle>
          <Graph style={{ backgroundColor: Green400 }}></Graph>
          <GraphText style={{ color: Green600 }}>안팔린다</GraphText>
        </GraphBox>
        <GraphBox style={{ marginRight: 0 }}>
          <GraphTitle>신뢰가 없다</GraphTitle>
          <Graph style={{ backgroundColor: Gray300 }}></Graph>
          <GraphText style={{ color: Black }}>속인다</GraphText>
        </GraphBox>
      </InnerContainer>
      <InnerContainer>
        <DashBox1>
          <Dash1 />
          <WayText>체계적인 교육으로</WayText>
        </DashBox1>
        <DashBox2>
          <Dash2 />
          <WayText>투명한 시세 공개로</WayText>
        </DashBox2>
      </InnerContainer>
    </Container>
  );
};

export default TopGraph;

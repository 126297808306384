import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Black,
  Gray200,
  Gray500,
  Green,
  Green600,
  White,
} from "../../../config/color";
import { getWindowSize } from "../../../utils";

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 112px);
  padding: 0 56px;
  margin-top: 74px;

  @media screen and (max-width: 1439px) {
    width: 100%;
    padding: 0;
    margin-top: 40px;
  }
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  color: ${Black};
  white-space: pre-wrap;

  @media screen and (max-width: 1439px) {
    font-size: 26px;
    line-height: 29px;
  }
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 28.8px;
  white-space: pre-wrap;
  margin-top: 16px;
`;

const TableBox = styled.div`
  width: 100%;
  margin-bottom: 76px;
  overflow-x: auto;
`;

const Table = styled.div`
  width: calc(1440px - 112px);
  margin: 56px;
  margin-bottom: 112px;
  position: relative;
  @media screen and (max-width: 1439px) {
    margin: 40px 0;
    margin-bottom: 0;
  }
`;

const TableRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 50px;
`;

const TableCell = styled.div`
  border: 1px solid ${Gray200};
  display: flex;
`;

const TableHeaderCell = styled(TableCell)`
  font-weight: 700;
  font-size: 16px;
  color: ${White};
  background-color: ${Green600};
  align-items: center;
  justify-content: center;
`;

const TableContentCell = styled(TableCell)`
  font-weight: 700;
  font-size: 16px;
  color: ${Gray500};
  background-color: ${White};
  align-items: center;
  justify-content: flex-start;
  padding: 11px 16px;
`;

const Volunteer = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [list, setList] = useState([
    {
      date: "2005.01",
      event: "인도양 해일 참사",
      amount: 300,
      destination: "대한적십사",
      total: 300,
    },
    {
      date: "2005.01",
      event: "인도양 해일 참사",
      amount: 300,
      destination: "대한적십사",
      total: 300,
    },
    {
      date: "2005.01",
      event: "인도양 해일 참사",
      amount: 300,
      destination: "대한적십사",
      total: 300,
    },
    {
      date: "2005.01",
      event: "인도양 해일 참사",
      amount: 300,
      destination: "대한적십사",
      total: 300,
    },
    {
      date: "2005.01",
      event: "인도양 해일 참사",
      amount: 300,
      destination: "대한적십사",
      total: 300,
    },
  ]);

  const calcTotal = (idx) => {
    let result = 0;
    for (let i = 0; i <= idx; i++) {
      result += list[i].amount;
    }
    return result;
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <>
      <TitleBox>
        <TitleLabel>관유정</TitleLabel>
        <TitleText>사회봉사</TitleText>
        <Text>{`2002년 9월 태풍 루사의 무차별 공격에 따른 김천지역주민의 아픔과 고통을 함께 하고자 처음 시작을 했던 것이시발이 되어 용천폭파, 쓰나미
등에 이어 국·내외의 가슴 아픈현장을 볼 때 마다 작은 마음이라도 함께 나누고자 했던시간들이 벌써 15년을 바라보는군요!`}</Text>
      </TitleBox>
      {windowSize.innerWidth <= 1439 ? (
        <TableBox>
          <Table>
            <TableRow>
              <TableHeaderCell style={{ width: 87 }}>번호</TableHeaderCell>
              <TableHeaderCell style={{ width: 188 }}>기간</TableHeaderCell>
              <TableHeaderCell style={{ width: 361 }}>행사명</TableHeaderCell>
              <TableHeaderCell style={{ width: 188 }}>
                모금액 (천원)
              </TableHeaderCell>
              <TableHeaderCell style={{ width: 285 }}>전달처</TableHeaderCell>
              <TableHeaderCell style={{ width: 219 }}>
                누계 (천원)
              </TableHeaderCell>
            </TableRow>
            {list.map((item, idx) => {
              return (
                <TableRow key={`table_${idx}`}>
                  <TableContentCell
                    style={{ width: 55, justifyContent: "center" }}
                  >
                    {idx + 1}
                  </TableContentCell>
                  <TableContentCell style={{ width: 156 }}>
                    {item.date}
                  </TableContentCell>
                  <TableContentCell style={{ width: 329 }}>
                    {item.event}
                  </TableContentCell>
                  <TableContentCell style={{ width: 156 }}>
                    {item.amount}
                  </TableContentCell>
                  <TableContentCell style={{ width: 253 }}>
                    {item.destination}
                  </TableContentCell>
                  <TableContentCell style={{ width: 187 }}>
                    {calcTotal(idx)}
                  </TableContentCell>
                </TableRow>
              );
            })}
          </Table>
        </TableBox>
      ) : (
        <Table>
          <TableRow>
            <TableHeaderCell style={{ width: 87 }}>번호</TableHeaderCell>
            <TableHeaderCell style={{ width: 188 }}>기간</TableHeaderCell>
            <TableHeaderCell style={{ width: 361 }}>행사명</TableHeaderCell>
            <TableHeaderCell style={{ width: 188 }}>
              모금액 (천원)
            </TableHeaderCell>
            <TableHeaderCell style={{ width: 285 }}>전달처</TableHeaderCell>
            <TableHeaderCell style={{ width: 219 }}>
              누계 (천원)
            </TableHeaderCell>
          </TableRow>
          {list.map((item, idx) => {
            return (
              <TableRow key={`table_${idx}`}>
                <TableContentCell
                  style={{ width: 55, justifyContent: "center" }}
                >
                  {idx + 1}
                </TableContentCell>
                <TableContentCell style={{ width: 156 }}>
                  {item.date}
                </TableContentCell>
                <TableContentCell style={{ width: 329 }}>
                  {item.event}
                </TableContentCell>
                <TableContentCell style={{ width: 156 }}>
                  {item.amount}
                </TableContentCell>
                <TableContentCell style={{ width: 253 }}>
                  {item.destination}
                </TableContentCell>
                <TableContentCell style={{ width: 187 }}>
                  {calcTotal(idx)}
                </TableContentCell>
              </TableRow>
            );
          })}
        </Table>
      )}
    </>
  );
};

export default Volunteer;

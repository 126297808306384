import React from "react";
import styled from "styled-components";
import {
  Gray200,
  Gray500,
  Green100,
  Green600,
  White,
} from "../../../config/color";

const Container = styled.div`
  width: 1328px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
`;

const Label = styled.div`
  width: 164px;
  background-color: ${Green600};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  color: ${White};
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  flex: 1;
  background-color: ${Green100};
  border: 1px solid ${Gray200};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Gray500};
  padding-left: 16px;
`;

const CellBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`;

const Cell = styled.div`
  flex: 1;
  border: 1px solid ${Gray200};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  padding: 10px 0;
  color: ${Gray500};
`;

const ControlTable = (props) => {
  return (
    <Container>
      <Label>
        <Cell style={{ width: "100%", color: White }}>1월</Cell>
        <Cell style={{ width: "100%", color: White }}>2월</Cell>
        <Cell style={{ width: "100%", color: White }}>3월</Cell>
        <Cell style={{ width: "100%", color: White }}>4월</Cell>
        <Cell style={{ width: "100%", color: White }}>5월</Cell>
        <Cell style={{ width: "100%", color: White }}>6월</Cell>
        <Cell style={{ width: "100%", color: White }}>7월</Cell>
        <Cell style={{ width: "100%", color: White }}>8월</Cell>
        <Cell style={{ width: "100%", color: White }}>9월</Cell>
        <Cell style={{ width: "100%", color: White }}>10월</Cell>
        <Cell style={{ width: "100%", color: White }}>11월</Cell>
        <Cell style={{ width: "100%", color: White }}>12월</Cell>
      </Label>
      <Content>
        <CellBox>
          <Cell style={{ paddingLeft: 16, justifyContent: "flex-start" }}>
            모촉 곰팡이 병 예방 방제
          </Cell>
          <Cell style={{ paddingLeft: 16, justifyContent: "flex-start" }}>
            델란 1000배 잎과 분내
          </Cell>
        </CellBox>
        <CellBox>
          <Cell style={{ paddingLeft: 16, justifyContent: "flex-start" }}>
            세균성 검은색 그을음 잎마름 병
          </Cell>
          <Cell style={{ paddingLeft: 16, justifyContent: "flex-start" }}>
            일품 1000배
          </Cell>
        </CellBox>
        <CellBox>
          <Cell style={{ paddingLeft: 16, justifyContent: "flex-start" }}>
            탄저-엽고병-검은색 뿌리 썩음병
          </Cell>
          <Cell style={{ paddingLeft: 16, justifyContent: "flex-start" }}>
            오티바 1000배 잎과 분내
          </Cell>
        </CellBox>
        <CellBox>
          <Cell style={{ paddingLeft: 16, justifyContent: "flex-start" }}>
            탄저-엽고병-검은색 뿌리 썩음병
          </Cell>
          <Cell style={{ paddingLeft: 16, justifyContent: "flex-start" }}>
            코리스 1000배 잎과 분내
          </Cell>
        </CellBox>
        <CellBox>
          <Cell style={{ paddingLeft: 16, justifyContent: "flex-start" }}>
            역병 - 작은 뿌리파리 & 총채벌레
          </Cell>
          <Cell style={{ paddingLeft: 16, justifyContent: "flex-start" }}>
            프리엔 1000배 리도밀 1500배 / 타르보 2000배 신촉 벌브 주변
          </Cell>
        </CellBox>
        <CellBox>
          <Cell style={{ paddingLeft: 16, justifyContent: "flex-start" }}>
            연부병
          </Cell>
          <Cell style={{ paddingLeft: 16, justifyContent: "flex-start" }}>
            일품 1000배 아그리마이신 1000배
          </Cell>
        </CellBox>
        <CellBox>
          <Cell style={{ paddingLeft: 16, justifyContent: "flex-start" }}>
            신촉과 신촉 뿌리 갈색 부패병
          </Cell>
          <Cell style={{ paddingLeft: 16, justifyContent: "flex-start" }}>
            스포탁 1000배 몬카트 1000배
          </Cell>
        </CellBox>
        <CellBox>
          <Cell style={{ paddingLeft: 16, justifyContent: "flex-start" }}>
            검은색 뿌리 썩음병 - 작은 뿌리파리 & 총채벌레
          </Cell>
          <Cell style={{ paddingLeft: 16, justifyContent: "flex-start" }}>
            오티바 1000배 잎과 분내 / 타르보 2000배 신촉 벌브 주변
          </Cell>
        </CellBox>
        <CellBox>
          <Cell style={{ paddingLeft: 16, justifyContent: "flex-start" }}>
            탄저-엽고병-검은색 뿌리 썩음병
          </Cell>
          <Cell style={{ paddingLeft: 16, justifyContent: "flex-start" }}>
            코리스 1000배 잎과 분내
          </Cell>
        </CellBox>
        <Title style={{ flex: 2, justifyContent: "flex-start" }}>
          수확 추수기
        </Title>
        <Title style={{ justifyContent: "flex-start" }}>휴식기</Title>
      </Content>
    </Container>
  );
};

export default ControlTable;

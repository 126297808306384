import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import BasicContainer from "../../../components/BasicContainer";
import PageTop from "../../../components/PageTop";
import { TAB } from "../../../components/Tab";
import {
  Black,
  Gray100,
  Gray300,
  Gray400,
  Gray500,
  Green100,
  Green600,
  White,
} from "../../../config/color";
import { getWindowSize } from "../../../utils";
import VoucherCard from "./VoucherCard";
import VoucherTable from "./VoucherTable";

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1439px) {
    min-width: 360px;
    width: 100%;
  }
`;

const RowDiv = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  width: 1328px;
  margin: 0 auto;

  @media screen and (max-width: 1439px) {
    width: calc(100% - 40px);
    padding: 0 20px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 72px;
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  color: ${Black};
  white-space: pre-wrap;
  line-height: 51px;

  @media screen and (max-width: 1439px) {
    font-size: 26px;
    line-height: 36px;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 1328px;
  margin: 0 auto;
  margin-top: 40px;
  padding: 16px 0;
  border-top: 1px solid ${Gray300};

  @media screen and (max-width: 1439px) {
    width: calc(100% - 40px);
    margin: 0 20px;
    margin-top: 32px;
  }
`;

const ListLabel = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Green600};
`;

const ListTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Black};
  margin-bottom: 16px;
  white-space: pre-wrap;
`;

const ListContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  white-space: pre-wrap;
`;

const PointBox = styled.div`
  width: 100%;
  padding: 48px 0;
  margin-top: 40px;
`;

const ListContentRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 16px;
`;

const ListNumber = styled.div`
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: ${Green600};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${White};
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  margin-right: 8px;
  margin-top: 2.5px;
`;

const VoucherTableContainer = styled.div`
  width: calc(100% - 40px);
  margin: 0 20px;
  overflow-x: auto;
`;

const Voucher = (props) => {
  const navigate = useNavigate();

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (selectedTab === 1) {
      navigate("/farm/consignmentcare");
    } else if (selectedTab === 2) {
      navigate("/farm/speciescenter");
    }
  }, [selectedTab]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <BasicContainer metaTitle="관유정-관유정만의 특별한 제도">
      <Content>
        <PageTop
          label="영농지원"
          title="관유정만의 특별한 제도"
          ImageUrl="/assets/image/FarmHeaderImg.png"
        />
        <TAB
          tabList={[
            { name: "안심바우처" },
            { name: "위탁오더 대리돌봄" },
            { name: "종 보존 센터" },
          ]}
          viewStyle={{
            marginTop: 32,
            marginBottom: windowSize.innerWidth <= 1439 ? 32 : 42,
          }}
          tabStyle={{
            width:
              windowSize.innerWidth <= 1439
                ? windowSize.innerWidth / 3 - 18
                : 152,
            paddingTop: 0,
            paddingBottom: 9,
            paddingLeft: windowSize.innerWidth <= 767 ? 8 : 26,
            paddingRight: windowSize.innerWidth <= 767 ? 8 : 26,
          }}
          activeTabIdx={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <RowDiv>
          <TitleBox
            style={{ marginTop: windowSize.innerWidth <= 1439 ? 32 : 56 }}
          >
            <TitleLabel>영농지원</TitleLabel>
            <TitleText>{`관유정만의 특별한 제도들,
안심 바우처`}</TitleText>
          </TitleBox>
        </RowDiv>
        <List>
          <ListLabel>01</ListLabel>
          <ListTitle>관유정 안심 바우처란?</ListTitle>
          <ListContent>{`바우처란 흔히 특정 서비스에 대한 보조 지원 제도를 말한다. 2급 자격 취득자들의 부업 진업 문턱을 낮추기 위해 실시합니다.
관유정 바우처 제도는 이런 분들에게 적합합니다`}</ListContent>
        </List>
        <PointBox style={{ backgroundColor: Green100 }}>
          <List
            style={{
              borderTopWidth: windowSize.innerWidth <= 1439 ? 0 : 1,
              marginTop: windowSize.innerWidth <= 1439 ? 16 : 40,
            }}
          >
            <ListLabel>02</ListLabel>
            <ListTitle>
              {windowSize.innerWidth <= 1439
                ? `안심 바우처
이런 분들께 추천합니다`
                : `안심 바우처 이런 분들께 추천합니다`}
            </ListTitle>
            <ListContent>
              <ListContentRow>
                <ListNumber>1</ListNumber>
                부업 시 사망에 따른 원금 손실을 우려하는 분들
              </ListContentRow>
              <ListContentRow>
                <ListNumber>2</ListNumber>
                계약 기간 만료 후 2촉을 판매해 원금 정도를 회수할 자신이 없는
                분들
              </ListContentRow>
              <ListContentRow>
                <ListNumber>3</ListNumber>
                기술이 정립되지 않아 재배에 자신이 없는 분들
              </ListContentRow>
              <ListContentRow>
                <ListNumber>4</ListNumber>난 실이 부실해 작황에 자신이 없는 분들
              </ListContentRow>
            </ListContent>
          </List>
        </PointBox>
        <List style={{ marginBottom: 56, marginTop: 56 }}>
          <ListLabel>03</ListLabel>
          <ListTitle>관유정에서는</ListTitle>
          <ListContent>
            <ListContentRow>
              <ListNumber>1</ListNumber>
              분쟁 방지용 계약서를 철저히 작성합니다
            </ListContentRow>
            <ListContentRow>
              <ListNumber>2</ListNumber>
              분쟁 방지차 관리 이력을 철저히 기록합니다
            </ListContentRow>
            <ListContentRow>
              <ListNumber>3</ListNumber>
              작황 이력을 철저히 기록합니다
            </ListContentRow>
            <ListContentRow>
              <ListNumber>4</ListNumber>
              수익 이력 기록을 철저히 하여 성실히 임합니다.
            </ListContentRow>
          </ListContent>
        </List>
        {windowSize.innerWidth <= 1439 ? (
          <VoucherTableContainer>
            <VoucherTable />
          </VoucherTableContainer>
        ) : (
          <VoucherTable />
        )}
        <List style={{ marginTop: 56 }}>
          <ListLabel>04</ListLabel>
          <ListTitle>프리랜서로 넘어가면</ListTitle>
          <ListContent>{`계약 기간 후 본전과 1촉을 남기면 프리랜서로 넘어가며 프리랜서로 넘어가면
각자의 집으로 찾아가거나 관유정 오더 돌봄으로 위탁 생산하거나 두가지 중 한가지를 결정해야한다`}</ListContent>
        </List>
        <PointBox style={{ backgroundColor: Gray100 }}>
          <List
            style={{
              borderTopWidth: windowSize.innerWidth <= 1439 ? 0 : 1,
              marginTop: windowSize.innerWidth <= 1439 ? 16 : 40,
            }}
          >
            <ListLabel>05</ListLabel>
            <ListTitle>안심 바우처 가맹점 조건</ListTitle>
            <ListContent>
              <ListContentRow>
                <ListNumber>1</ListNumber>
                1급 자격증 취득자
              </ListContentRow>
              <ListContentRow>
                <ListNumber>2</ListNumber>
                시.군.구별 한 업체
              </ListContentRow>
              <ListContentRow>
                <ListNumber>3</ListNumber>
                브랜드 프랜차이즈
              </ListContentRow>
              <ListContentRow>
                <ListNumber>4</ListNumber>하우스 시설 (심사.컨설팅)
              </ListContentRow>
              <ListContentRow>
                <ListNumber>5</ListNumber>핵심 노하우 전수
              </ListContentRow>
              <ListContentRow>
                <ListNumber>6</ListNumber>지속적인 모니터링
              </ListContentRow>
              <ListContentRow>
                <ListNumber>7</ListNumber>OEM 공동체 특화 과정 이수자
              </ListContentRow>
              <ListContentRow>
                <ListNumber>8</ListNumber>신규참여 우대
              </ListContentRow>
              <ListContentRow>
                <ListNumber>9</ListNumber>지역별 생산물을 관유정에서 납품을
                받기도 하는 시스템
              </ListContentRow>
              <ListContentRow>
                <ListNumber>10</ListNumber>시설물은 반드시 컨설팅 비용을
                지불하고 합격 수준을 통과해야 협력업체가 됨
              </ListContentRow>
              <ListContentRow>
                <ListNumber>11</ListNumber>3년간 모니터링(품종관리 기간) 후,
                독립적으로 활동도 가능
              </ListContentRow>
            </ListContent>
          </List>
        </PointBox>
        <List style={{ marginBottom: 56, marginTop: 56 }}>
          <ListLabel>06</ListLabel>
          <ListTitle>안심 바우처 시스템 멤버 자격 취득</ListTitle>
          <ListContent>
            <VoucherCard />
          </ListContent>
        </List>
      </Content>
    </BasicContainer>
  );
};

export default Voucher;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import BasicContainer from "../../../components/BasicContainer";
import { SEARCH_BOX } from "../../../components/TextField";
import {
  Black,
  Gray100,
  Gray200,
  Gray400,
  Gray500,
  Green600,
} from "../../../config/color";

import { getWindowSize, toStringByFormatting } from "../../../utils";
import Pagination from "../../../components/Pagination";
import { DROPDOWN_A } from "../../../components/Dropdown";
import PageTop from "../../../components/PageTop";
import { useEffect } from "react";

const EmptyIcon = require("../../../assets/icon/Close29.png");

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1439px) {
    min-width: 360px;
    width: 100%;
    flex-direction: column;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 72px;

  @media screen and (max-width: 1439px) {
    margin-top: 32px;
    width: 100%;
  }
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  line-height: 51px;
  color: ${Black};

  @media screen and (max-width: 1439px) {
    font-size: 26px;
    line-height: 36px;
  }
`;

const RowDiv = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 1328px;
  margin: 0 auto;

  @media screen and (max-width: 1439px) {
    min-width: 360px;
    width: calc(100% - 40px);
    margin: 0 20px;
    flex-direction: column;
  }
`;

const EmptyBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${Gray100};
  height: 142px;
  width: 1328px;

  @media screen and (max-width: 1439) {
    width: calc(100% - 20px);
    padding: 0 20px;
  }
`;

const EmptyBoxIcon = styled.img`
  width: 29px;
  height: 29px;
`;

const EmptyBoxText = styled.div`
  margin-top: 12px;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: ${Gray400};
`;

const ListBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 1328px;

  @media screen and (max-width: 1439) {
    width: calc(100% - 40px);
    padding: 0 20px;
  }
`;

const List = styled.div`
  flex: 1;
  height: 32px;
  border: 1px solid ${Gray200};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  border-radius: 8px;
  margin-bottom: 16px;
  &:hover {
    cursor: pointer;
  }
`;

const ListLabel = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 25px;
  color: ${Gray400};
  margin-right: 16px;
  margin-left: 0;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Number = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Gray400};
  margin-right: 16px;
  margin-left: 0;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1439px) {
    justify-content: flex-start;
  }
`;

const ListTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 32.4px;
  color: ${Black};
  margin-right: 16px;
  width: calc(439px - 16px);
`;

const ListText = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Gray500};
  margin-right: 16px;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 4px;
`;

const CountText = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 25.2px;
  margin-right: 16px;
`;

const DateText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 25.2px;
  color: ${Gray400};
`;

const SearchBoxRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;
  width: 100%;
  position: relative;
`;

const MobileListBox = styled.div`
  width: calc(100% - 72px);
  margin: 0 20px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${Gray200};
  margin-bottom: 16px;
`;

const MobileListLabel = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: ${Gray400};
`;

const MobileListRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const OutsideLecture = () => {
  const navigate = useNavigate();

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const [searchText, setSearchText] = useState("");
  const [selectType, setSelectType] = useState("");
  const [list, setList] = useState([
    {
      _id: "003",
      title: "3강. 100% 판로 확보와 판매 시스템",
      lectureStartDate: new Date(),
      location:
        "대구카톨릭대학교 평생교육원 (대구시 달서구 용산로 24길 17 교육원)",
      writer: {
        role: "Admin",
        name: "이대건",
      },
      createdAt: new Date(),
    },
    {
      _id: "002",
      title: "2강. 성공 전략 품종 선택 방법",
      lectureStartDate: new Date(),
      location:
        "대구카톨릭대학교 평생교육원 (대구시 달서구 용산로 24길 17 교육원)",
      writer: {
        role: "Admin",
        name: "이대건",
      },
      createdAt: new Date(),
    },
    {
      _id: "001",
      title: "1강. 월 200 난초 연금 이게 가능한가?",
      lectureStartDate: new Date(),
      location:
        "대구카톨릭대학교 평생교육원 (대구시 달서구 용산로 24길 17 교육원)",
      writer: {
        role: "Admin",
        name: "이대건",
      },
      createdAt: new Date(),
    },
  ]);

  const onSearch = () => {
    console.log(searchText);
  };

  const onMoveWriting = () => {
    navigate("/company/board/writing", { state: "id값" });
  };

  const onMoveDetail = (data) => {
    navigate("/education/outsidelecture/detail", { state: { data } });
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <BasicContainer metaTitle="관유정-외부강의">
      <Content>
        <PageTop
          label="교육지원"
          title="외부강의"
          ImageUrl="/assets/image/EduHeaderImg.png"
        />

        {windowSize.innerWidth <= 1439 ? (
          <RowDiv
            style={{
              marginTop: windowSize.innerWidth <= 1439 ? 32 : 64,
              marginBottom: windowSize.innerWidth <= 1439 ? 0 : 35,
            }}
          >
            <TitleBox style={{ marginTop: 0 }}>
              <TitleLabel>교육지원</TitleLabel>
              <TitleText>외부 강의 커리큘럼</TitleText>
              <RowDiv
                style={{
                  flexDirection: "row",
                  marginTop: 32,
                  marginLeft: 0,
                  marginRight: 0,
                  width: "100%",
                }}
              >
                <SearchBoxRow>
                  <DROPDOWN_A
                    list={["제목", "지역명"]}
                    defaultText="검색분류"
                    dropdownSelected={selectType}
                    setDropdownSelected={setSelectType}
                    viewStyle={{
                      width: 143,
                      marginRight: 8,
                      height: 18,
                    }}
                    optionStyle={{ width: 147 - 22 }}
                  />
                  <SEARCH_BOX
                    val={searchText}
                    setVal={setSearchText}
                    onClick={onSearch}
                    placeholder="검색하기"
                    viewStyle={{ marginLeft: 16, width: "calc(100% - 161px)" }}
                    seachBoxStyle={{ width: "calc(100% - 81px)" }}
                  />
                </SearchBoxRow>
              </RowDiv>
            </TitleBox>
          </RowDiv>
        ) : (
          <RowDiv style={{ marginTop: 64, marginBottom: 35 }}>
            <TitleBox style={{ marginTop: 0 }}>
              <TitleLabel>교육지원</TitleLabel>
              <RowDiv>
                <TitleText>외부 강의 커리큘럼</TitleText>
                <DROPDOWN_A
                  list={["제목", "지역명"]}
                  defaultText="검색분류"
                  dropdownSelected={selectType}
                  setDropdownSelected={setSelectType}
                  viewStyle={{ width: 133, marginLeft: "auto" }}
                  optionStyle={{ width: 133 }}
                />
                <SEARCH_BOX
                  viewStyle={{ marginLeft: 16 }}
                  val={searchText}
                  setVal={setSearchText}
                  onClick={onSearch}
                  placeholder="검색하기"
                />
              </RowDiv>
            </TitleBox>
          </RowDiv>
        )}
        {list.length <= 0 ? (
          <EmptyBox>
            <EmptyBoxIcon src={EmptyIcon} />
            <EmptyBoxText>내용이 존재하지 않습니다</EmptyBoxText>
          </EmptyBox>
        ) : windowSize.innerWidth <= 1439 ? (
          <>
            {list.map((item, idx) => {
              return (
                <MobileListBox
                  key={item._id}
                  onClick={() => {
                    onMoveDetail(item);
                  }}
                >
                  <Number>{list.length - idx}</Number>
                  <ListTitle>{item.title}</ListTitle>
                  <MobileListLabel style={{ marginTop: 8 }}>
                    출강일
                  </MobileListLabel>
                  <ListText style={{ width: 158 - 16 }}>
                    {toStringByFormatting(item.lectureStartDate)}
                  </ListText>
                  <MobileListLabel style={{ marginTop: 10 }}>
                    출강 지역
                  </MobileListLabel>
                  <ListText
                    style={{
                      color: Gray400,
                      width: "100%",
                      marginBottom: 26,
                      wordBreak: "keep-all",
                    }}
                  >
                    {item.location}
                  </ListText>
                  <MobileListRow>
                    <ListText
                      style={{
                        color: Gray400,
                        width: 86 - 16,
                        marginRight: "auto",
                      }}
                    >
                      {item.writer.role === "Admin"
                        ? "관리자"
                        : item.writer.name}
                    </ListText>
                    <DateText style={{ width: 79, marginLeft: "auto" }}>
                      {toStringByFormatting(item.createdAt)}
                    </DateText>
                  </MobileListRow>
                </MobileListBox>
              );
            })}

            <Pagination viewStyle={{ marginTop: 40, marginBottom: 83 }} />
          </>
        ) : (
          <ListBox>
            <List
              style={{
                border: 0,
                paddingBottom: 0,
                marginBottom: 8,
                justifyContent: "flex-start",
              }}
            >
              <ListLabel>순번</ListLabel>
              <ListLabel style={{ width: 439 - 16 }}>제목</ListLabel>
              <ListLabel style={{ width: 158 - 16 }}>출강일</ListLabel>
              <ListLabel style={{ width: 476 - 16 }}>출강 지역</ListLabel>
              <ListLabel style={{ width: 86 - 16 }}>작성자</ListLabel>
              <DateText></DateText>
            </List>
            {list.map((item, idx) => {
              return (
                <List
                  key={item._id}
                  onClick={() => {
                    onMoveDetail(item);
                  }}
                >
                  <Number>{list.length - idx}</Number>
                  <ListTitle>{item.title}</ListTitle>
                  <ListText style={{ width: 158 - 16 }}>
                    {toStringByFormatting(item.lectureStartDate)}
                  </ListText>
                  <ListText style={{ color: Gray400, width: 476 - 16 }}>
                    {item.location}
                  </ListText>
                  <ListText style={{ color: Gray400, width: 86 - 16 }}>
                    {item.writer.role === "Admin" ? "관리자" : item.writer.name}
                  </ListText>
                  <DateText style={{ width: 79 }}>
                    {toStringByFormatting(item.createdAt)}
                  </DateText>
                </List>
              );
            })}
            <Pagination viewStyle={{ marginTop: 40, marginBottom: 83 }} />
          </ListBox>
        )}
      </Content>
    </BasicContainer>
  );
};

export default OutsideLecture;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BasicContainer from "../../../components/BasicContainer";
import PageTop from "../../../components/PageTop";
import {
  Black,
  Gray200,
  Gray300,
  Gray500,
  Green100,
  Green300,
  Green600,
} from "../../../config/color";

import { useNavigate } from "react-router-dom";
import Youtube from "../../../components/Youtube";
import { getWindowSize } from "../../../utils";

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1439px) {
    min-width: 360px;
    width: 100%;
  }
`;

const RowDiv = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  width: 1328px;
  margin: 0 auto;

  @media screen and (max-width: 1439px) {
    width: calc(100% - 40px);
    padding: 0 20px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 72px;

  @media screen and (max-width: 1439px) {
    margin-top: 32px;
  }
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  color: ${Black};
  white-space: pre-wrap;
  line-height: 51px;

  @media screen and (max-width: 1439px) {
    font-size: 26px;
    line-height: 36px;
  }
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 28.8px;
  white-space: pre-wrap;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 1328px;
  margin: 0 auto;
  margin-top: 40px;
  padding: 16px 0;
  border-top: 1px solid ${Gray300};

  @media screen and (max-width: 1439px) {
    width: calc(100% - 40px);
    margin: 0 20px;
    margin-top: 32px;
  }
`;

const ListLabel = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Green600};
`;

const ListTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Black};
  margin-bottom: 16px;
`;

const ListContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  white-space: pre-wrap;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 1328px;
  margin: 0 auto;
`;

const TableText = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Gray500};
`;

const TableCell = styled(TableText)`
  height: calc(50px - 2px);
  border: 1px solid ${Gray200};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const TableColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableLabel = styled(TableCell)`
  width: calc(265px - 2px);
  background-color: ${Green100};
`;

const TableRowCategory = styled(TableText)`
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: ${Green300};
  width: calc(100% - 2px);
  height: calc(50px - 2px);
  border: 1px solid ${Gray200};
`;

const UpgradeGrade = (props) => {
  const navigate = useNavigate();

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <BasicContainer metaTitle="관유정-한 등급 높이는 기술">
      <Content>
        <PageTop
          label="기술지원"
          title="한 등급 높이는 기술"
          ImageUrl="/assets/image/TechnicalHeaderImg.png"
        />
        <RowDiv>
          <TitleBox>
            <TitleLabel>영농지원</TitleLabel>
            <TitleText>한 등급 높이는 기술</TitleText>
          </TitleBox>
        </RowDiv>
        <List
          style={{
            marginBottom:
              windowSize.innerWidth <= 767
                ? 32
                : windowSize.innerWidth > 767 && windowSize.innerWidth <= 1439
                ? 56
                : 88,
          }}
        >
          <ListLabel>01</ListLabel>
          <ListTitle>물주기</ListTitle>
          <ListContent>
            <Youtube
              videoId="kr-jHcJegP8"
              viewStyle={{ width: "100%" }}
              optStyle={{
                width: "100%",
                height:
                  windowSize.innerWidth <= 767
                    ? 300
                    : windowSize.innerWidth > 767 &&
                      windowSize.innerWidth <= 1439
                    ? 500
                    : 706,
              }}
            />
          </ListContent>
        </List>
        <List
          style={{
            marginBottom:
              windowSize.innerWidth <= 767
                ? 32
                : windowSize.innerWidth > 767 && windowSize.innerWidth <= 1439
                ? 56
                : 88,
          }}
        >
          <ListLabel>02</ListLabel>
          <ListTitle>빛 주기</ListTitle>
          <ListContent>
            <Youtube
              videoId="yoJCtGXXTC4"
              viewStyle={{ width: "100%" }}
              optStyle={{
                width: "100%",
                height:
                  windowSize.innerWidth <= 767
                    ? 300
                    : windowSize.innerWidth > 767 &&
                      windowSize.innerWidth <= 1439
                    ? 500
                    : 706,
              }}
            />
          </ListContent>
        </List>
        <List
          style={{
            marginBottom:
              windowSize.innerWidth <= 767
                ? 32
                : windowSize.innerWidth > 767 && windowSize.innerWidth <= 1439
                ? 56
                : 117,
          }}
        >
          <ListLabel>03</ListLabel>
          <ListTitle>분갈이의 목적과 시기 결정하기</ListTitle>
          <ListContent>
            <Youtube
              videoId="jtyNUlmT8Eg"
              viewStyle={{ width: "100%" }}
              optStyle={{
                width: "100%",
                height:
                  windowSize.innerWidth <= 767
                    ? 300
                    : windowSize.innerWidth > 767 &&
                      windowSize.innerWidth <= 1439
                    ? 500
                    : 706,
              }}
            />
          </ListContent>
        </List>
      </Content>
    </BasicContainer>
  );
};

export default UpgradeGrade;

import React, { useState } from "react";
import styled from "styled-components";

import {
  Gray200,
  Gray300,
  Green600,
  TextColorBlack,
  White,
} from "../config/color";

const DownArrow = require("../assets/icon/DownArrow20.png");
const UpArrow = require("../assets/icon/UpArrow20.png");
const Close = require("../assets/icon/Close30.png");

const DropDownA = styled.div`
  position: relative;
  border: 1px solid ${Gray200};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 320px;
  height: 19px;
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }

  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

const DropDownAExpand = styled.div`
  position: relative;
  border: 1px solid ${Gray200};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 380px;
  height: 19px;
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
  color: #696969;
  line-height: 19px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }

  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

const DropDownB = styled.div`
  position: relative;
  border: 1px solid ${Gray200};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100px;
  height: 19px;
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }

  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

const DropDownBExpand = styled.div`
  position: relative;
  border: 1px solid ${Gray200};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 150px;
  height: 19px;
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
  color: #696969;
  line-height: 19px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }

  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

const DropDownMobile = styled.div`
  position: relative;
  border: 1px solid ${Gray200};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 320px;
  height: 19px;
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Option = styled.div`
  border: 1px solid ${Gray200};
  height: 19px;
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
  color: #696969;
  background-color: #fff;
  line-height: 19px;
  position: absolute;
  left: -1;
  z-index: 999;
  &:active {
    color: ${Green600};
  }

  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

const MobileBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.6);
`;

const MobileOptionBox = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100vw - 44px);
  height: 215px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 15px 15px 0px 0px;
  padding: 22px 20px;
`;

const MobileOptionTitleBox = styled.div`
  display: flex;
  flex-direction: row;
  height: 30px;
  align-items: center;
  margin-bottom: 6px;
`;

const MobileOptionTitle = styled.div`
  color: ${TextColorBlack};
  font-size: 20px;
  font-weight: 700;
  margin-left: 0;
  margin-right: auto;
`;

const MobileOptionClose = styled.img`
  width: 30px;
  height: 30px;
  margin-left: auto;
  margin-right: 0;
  &:hover {
    cursor: pointer;
  }
`;

const MobileOption = styled.div`
  height: 19px;
  font-size: 16px;
  font-weight: 500;
  color: #696969;
  background-color: #fff;
  position: absolute;
  left: -1;
  z-index: 999;
  margin-bottom: 19px;
  &:active {
    color: ${Green600};
  }
  &:hover {
    cursor: pointer;
  }
`;

const SelectedVal = styled.div`
  padding-right: 36px;
  margin-left: 0;
  margin-right: auto;
`;

const DropDownIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const DROPDOWN_A = (props) => {
  const {
    list,
    disabled,
    defaultText,
    dropdownSelected,
    setDropdownSelected,
    viewStyle,
    optionStyle,
  } = props;

  const [openOptions, setOpenOptions] = useState(false);

  return (
    <DropDownA
      style={{
        borderBottomLeftRadius: openOptions ? 0 : 5,
        borderBottomRightRadius: openOptions ? 0 : 5,
        backgroundColor: disabled ? Gray200 : White,
        color: disabled ? Gray300 : "#696969",
        ...viewStyle,
      }}
      onClick={() => {
        if (!disabled) {
          setOpenOptions(!openOptions);
        }
      }}
    >
      <SelectedVal>
        {dropdownSelected ? dropdownSelected : defaultText}
      </SelectedVal>
      <DropDownIcon src={openOptions ? UpArrow : DownArrow} />
      {openOptions
        ? list.map((item, idx) => {
            return (
              <Option
                style={{
                  width: 320,
                  top: 51 * (idx + 1) + idx * 1,
                  borderBottomLeftRadius: idx !== list.length - 1 ? 0 : 5,
                  borderBottomRightRadius: idx !== list.length - 1 ? 0 : 5,
                  ...optionStyle,
                }}
                key={`dropdown_${idx}`}
                onClick={() => {
                  setDropdownSelected(item);
                }}
              >
                {item}
              </Option>
            );
          })
        : null}
    </DropDownA>
  );
};

export const DROPDOWN_A_EXPAND = (props) => {
  const {
    list,
    disabled,
    defaultText,
    dropdownSelected,
    setDropdownSelected,
    viewStyle,
    optionStyle,
  } = props;

  const [openOptions, setOpenOptions] = useState(false);

  return (
    <DropDownAExpand
      style={{
        borderBottomLeftRadius: openOptions ? 0 : 5,
        borderBottomRightRadius: openOptions ? 0 : 5,
        backgroundColor: disabled ? Gray200 : White,
        color: disabled ? Gray300 : "#696969",
        ...viewStyle,
      }}
      onClick={() => {
        if (!disabled) {
          setOpenOptions(!openOptions);
        }
      }}
    >
      <SelectedVal>
        {dropdownSelected ? dropdownSelected : defaultText}
      </SelectedVal>
      <DropDownIcon src={openOptions ? UpArrow : DownArrow} />
      {openOptions
        ? list.map((item, idx) => {
            return (
              <Option
                style={{
                  width: 380,
                  top: 51 * (idx + 1) + idx * 1,
                  borderBottomLeftRadius: idx !== list.length - 1 ? 0 : 5,
                  borderBottomRightRadius: idx !== list.length - 1 ? 0 : 5,
                  ...optionStyle,
                }}
                key={`dropdown_${idx}`}
                onClick={() => {
                  setDropdownSelected(item);
                }}
              >
                {item}
              </Option>
            );
          })
        : null}
    </DropDownAExpand>
  );
};

export const DROPDOWN_B = (props) => {
  const {
    list,
    disabled = false,
    defaultText,
    dropdownSelected,
    setDropdownSelected,
  } = props;

  const [openOptions, setOpenOptions] = useState(false);

  return (
    <DropDownB
      style={{
        borderBottomLeftRadius: openOptions ? 0 : 5,
        borderBottomRightRadius: openOptions ? 0 : 5,
        backgroundColor: disabled ? Gray200 : "#F5F6F8",
        color: disabled ? Gray300 : "#696969",
      }}
      onClick={() => {
        if (!disabled) {
          setOpenOptions(!openOptions);
        }
      }}
    >
      <SelectedVal>
        {dropdownSelected ? dropdownSelected : defaultText}
      </SelectedVal>
      <DropDownIcon src={openOptions ? UpArrow : DownArrow} />
      {openOptions
        ? list.map((item, idx) => {
            return (
              <Option
                style={{
                  width: 100,
                  top: 51 * (idx + 1) + idx * 1,
                  borderBottomLeftRadius: idx !== list.length - 1 ? 0 : 5,
                  borderBottomRightRadius: idx !== list.length - 1 ? 0 : 5,
                  backgroundColor: "#F5F6F8",
                }}
                key={`dropdown_${idx}`}
                onClick={() => {
                  setDropdownSelected(item);
                }}
              >
                {item}
              </Option>
            );
          })
        : null}
    </DropDownB>
  );
};

export const DROPDOWN_B_EXPAND = (props) => {
  const { list, disabled, defaultText, dropdownSelected, setDropdownSelected } =
    props;

  const [openOptions, setOpenOptions] = useState(false);

  return (
    <DropDownBExpand
      style={{
        borderBottomLeftRadius: openOptions ? 0 : 5,
        borderBottomRightRadius: openOptions ? 0 : 5,
        backgroundColor: disabled ? Gray200 : "#F5F6F8",
        color: disabled ? Gray300 : "#696969",
      }}
      onClick={() => {
        if (!disabled) {
          setOpenOptions(!openOptions);
        }
      }}
    >
      <SelectedVal>
        {dropdownSelected ? dropdownSelected : defaultText}
      </SelectedVal>
      <DropDownIcon src={openOptions ? UpArrow : DownArrow} />
      {openOptions
        ? list.map((item, idx) => {
            return (
              <Option
                style={{
                  width: 150,
                  top: 51 * (idx + 1) + idx * 1,
                  borderBottomLeftRadius: idx !== list.length - 1 ? 0 : 5,
                  borderBottomRightRadius: idx !== list.length - 1 ? 0 : 5,
                  backgroundColor: "#F5F6F8",
                }}
                key={`dropdown_${idx}`}
                onClick={() => {
                  setDropdownSelected(item);
                }}
              >
                {item}
              </Option>
            );
          })
        : null}
    </DropDownBExpand>
  );
};

export const DROPDOWN_MOBILE = (props) => {
  const { list, disabled, defaultText, dropdownSelected, setDropdownSelected } =
    props;

  const [openOptions, setOpenOptions] = useState(false);

  return (
    <DropDownMobile
      style={{
        borderBottomLeftRadius: openOptions ? 0 : 5,
        borderBottomRightRadius: openOptions ? 0 : 5,
        backgroundColor: disabled ? Gray200 : White,
        color: disabled ? Gray300 : "#696969",
      }}
      onClick={() => {
        if (!disabled) {
          setOpenOptions(true);
        }
      }}
    >
      <SelectedVal>
        {dropdownSelected ? dropdownSelected : defaultText}
      </SelectedVal>
      <DropDownIcon src={openOptions ? UpArrow : DownArrow} />
      {openOptions ? (
        <MobileBackground>
          <MobileOptionBox>
            <MobileOptionTitleBox>
              <MobileOptionTitle>{dropdownSelected}</MobileOptionTitle>
              <MobileOptionClose
                src={Close}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  setOpenOptions(false);
                }}
              />
            </MobileOptionTitleBox>
            {list.map((item, idx) => {
              return (
                <MobileOption
                  style={{
                    width: "100vw",
                    top: 52 + 26 * (idx + 1) + idx * 19,
                  }}
                  key={`dropdown_${idx}`}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    setDropdownSelected(item);
                    setOpenOptions(false);
                  }}
                >
                  {item}
                </MobileOption>
              );
            })}
          </MobileOptionBox>
        </MobileBackground>
      ) : null}
    </DropDownMobile>
  );
};

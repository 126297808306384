import React, { useEffect, useState } from "react";
import styled from "styled-components";

import BasicContainer from "../../../components/BasicContainer";
import PageTop from "../../../components/PageTop";

import {
  Black,
  Gray100,
  Gray300,
  Gray500,
  Green100,
  Green600,
  White,
} from "../../../config/color";
import { useNavigate } from "react-router-dom";
import FirstGradeCard from "./Card";

const PhoneIcon = require("../../../assets/icon/Phone32.png");
const AccountIcon = require("../../../assets/icon/Account32.png");

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const RowDiv = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 1328px;
  margin: 0 auto;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 72px;
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  color: ${Black};
  white-space: pre-wrap;
  line-height: 51px;
`;

const List = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 1328px;
  margin: 0 auto;
  margin-top: 40px;
  padding: 16px 0;
  border-top: 1px solid ${Gray300};
`;

const ListSubInfo = styled.div`
  display: flex;
  position: absolute;
  top: -37px;
  right: 0;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Green600};
  z-index: 999;
`;

const ListLabel = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Green600};
`;

const ListTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Black};
  margin-bottom: 16px;
`;

const ListContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  white-space: pre-wrap;
`;
const KindListUl = styled.ul`
  padding-left: 32px;
  margin: 0;
`;

const KindListLi = styled.li`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
`;

const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 56px;
  align-items: center;
`;

const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Icon = styled.img`
  width: 32px;
  height: 32px;
`;

const IconCover = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Green100};
  border-radius: 28px;
  width: 56px;
  height: 56px;
  margin-right: 16px;
`;

const Text = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Gray500};
`;

const Box = styled.div`
  width: calc(100% - 32px);
  padding: 16px;
  border-radius: 10px;
  background-color: ${Green100};
`;

const BoxTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: ${Green600};
`;

const BoxSubtitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  margin-top: 2px;
  margin-bottom: 2px;
`;

const BoxContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  white-space: pre-wrap;
`;

const FirstGrade = () => {
  const navigate = useNavigate();

  return (
    <BasicContainer metaTitle="관유정-1급 자격 과정">
      <Content>
        <PageTop
          label="교육지원"
          title="자격증 과정"
          ImageUrl="/assets/image/EduHeaderImg.png"
        />
        <RowDiv style={{ marginTop: 64 }}>
          <TitleBox style={{ marginTop: 0 }}>
            <TitleLabel>교육지원</TitleLabel>
            <TitleText>{`1급 한국 춘란 관리사 자격 과정
1박 2일 상시 모집`}</TitleText>
          </TitleBox>
        </RowDiv>
        <List>
          <ListSubInfo>*2급 자격자에 한함</ListSubInfo>
          <ListLabel>01</ListLabel>
          <ListTitle>1급을 받으면 발생하는 좋은 이유</ListTitle>
          <ListContent>
            <KindListUl>
              <KindListLi>
                최신 고급 기술 체계 확립으로 영농성과 향상
              </KindListLi>
              <KindListLi>작품 체계와 경기운영 능력 확립</KindListLi>
              <KindListLi>안심 바우처 프랜차이즈 자격부여</KindListLi>
              <KindListLi>지역 선후배 애란인 부업 기술지도 </KindListLi>
            </KindListUl>
          </ListContent>
        </List>
        <RowDiv>
          <FirstGradeCard />
        </RowDiv>
        <RowDiv style={{ marginTop: 0 }}>
          <List style={{ marginTop: 56, marginLeft: 0 }}>
            <ListLabel>02</ListLabel>
            <ListTitle>문의 및 계좌</ListTitle>
            <ListContent>
              <ContentRow>
                <IconCover>
                  <Icon src={PhoneIcon} />
                </IconCover>
                <ContentColumn>
                  <ContentRow style={{ height: "auto" }}>
                    <Text>대한민국난문화진흥원</Text>
                    <Text style={{ color: Green600, marginLeft: 8 }}>
                      053-766-5935
                    </Text>
                  </ContentRow>
                  <ContentRow style={{ height: "auto" }}>
                    <Text>강사</Text>
                    <Text style={{ color: Green600, marginLeft: 8 }}>
                      010-3505-5577
                    </Text>
                  </ContentRow>
                </ContentColumn>
              </ContentRow>
              <ContentRow style={{ marginTop: 24 }}>
                <IconCover>
                  <Icon src={AccountIcon} />
                </IconCover>
                <ContentColumn>
                  <Text>대구은행</Text>
                  <Text>026-08-223767 이대건</Text>
                </ContentColumn>
              </ContentRow>
            </ListContent>
          </List>
        </RowDiv>
        <RowDiv style={{ marginTop: 0 }}>
          <List style={{ marginTop: 56, marginLeft: 0 }}>
            <ListLabel>03</ListLabel>
            <ListTitle>커리큘럼</ListTitle>
            <ListContent>
              <Box style={{ backgroundColor: Gray100 }}>
                <BoxTitle>1강</BoxTitle>
                <BoxSubtitle>무엇이든 물어보세요 : 2급 복습</BoxSubtitle>
                <BoxContent>{`  1. 영농설계 / 전략품종
  2. 재배생리 / 광합성
  3. 품질관리 / 병충해
  4. 분 갈이 / 기타`}</BoxContent>
              </Box>
              <Box style={{ marginTop: 16 }}>
                <BoxTitle>2강</BoxTitle>
                <BoxSubtitle>최신 기술 : 생리장해</BoxSubtitle>
                <BoxContent>{`  1. 과노화 촉진
  2. 과화아 분화
  3. 신 촉의 키가 짧아지고 2모작 신아가 다 발생
  4. 과출아
  5. 쫑(?) 침 현상
  6. 화아의 과유산율
  7. 신아가 말라버림
  8. 조기 개화
  9. 신아의 성장 멈춤
  10. 발근 부족
  11. 잎 마름
  12. 난초가 살이 안찐다.
  13. 봄 잎 마름
  14. 꽃이 안 붙는다.
  15. 뿌리 경화 및 탄화
  16. 뿌리가 잘 썩는다.
`}</BoxContent>
              </Box>
              <Box style={{ backgroundColor: Gray100, marginTop: 16 }}>
                <BoxTitle>3강</BoxTitle>
                <BoxSubtitle>최신기술 1~4 : 최신기술 1</BoxSubtitle>
                <BoxContent>{`  1. 줌탁동시하라
  2. 중투 만들기 기술의 이해
  3. 무발근 촉 재활기술
  4. 초기 차광술`}</BoxContent>
              </Box>
              <Box style={{ marginTop: 16 }}>
                <BoxTitle>4강</BoxTitle>
                <BoxSubtitle>최신기술 5~8 : 최신기술 2</BoxSubtitle>
                <BoxContent>{`  1. 가짜 백화, 황화, 두화의 색화 구분 원리 이해
  2. 특수품종 혈당관리
  3. 진단기술-잎의 팽압과 액아의 팽압
  4. 단면 색상 검증법`}</BoxContent>
              </Box>
              <Box style={{ backgroundColor: Gray100, marginTop: 16 }}>
                <BoxTitle>5강</BoxTitle>
                <BoxSubtitle>최신기술 9~12 : 최신기술 3</BoxSubtitle>
                <BoxContent>{`  1. ??의 이해와 감소 방안
  2. 기타 질환과 과노대 ??? 기술
  3. 속 장이 변색되면 빨리 병원으로 가라
  4. 벌브와 ??`}</BoxContent>
              </Box>
              <Box style={{ marginTop: 16 }}>
                <BoxTitle>6강</BoxTitle>
                <BoxSubtitle>최신기술 13~16 : 최신기술 4</BoxSubtitle>
                <BoxContent>{`  1. 이럴 때 신속히 병원으로 가 검진하라
  2. 약물치료와 재활치료를 이해하라
  3. 치료는 침지가 최고다
  4. 완치는 어디까지인가?`}</BoxContent>
              </Box>
              <Box
                style={{
                  backgroundColor: Gray100,
                  marginTop: 16,
                  marginBottom: 186,
                }}
              >
                <BoxTitle>7강</BoxTitle>
                <BoxSubtitle>최신기술 17~20 : 작품세계</BoxSubtitle>
                <BoxContent>{`  1. 시합과 경기
  2. 작품공정
  3. 실격과 채점
  4. 출전과 기록도전`}</BoxContent>
              </Box>
            </ListContent>
          </List>
        </RowDiv>
      </Content>
    </BasicContainer>
  );
};

export default FirstGrade;

import React, { useEffect, useState } from "react";
import styled from "styled-components";

import BasicContainer from "../../../components/BasicContainer";
import PageTop from "../../../components/PageTop";

import {
  Black,
  Gray100,
  Gray300,
  Gray500,
  Green100,
  Green600,
  White,
} from "../../../config/color";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../components/Pagination";
import SuccessCaseListItem from "../../../components/SuccessCaseListItem";

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const RowDiv = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 1328px;
  margin: 0 auto;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 72px;
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  color: ${Black};
  white-space: pre-wrap;
  line-height: 51px;
`;

const ListGrid = styled.div`
  display: grid;
  width: 1328px;
  margin: 32px 112px;
  gap: 16px;
  row-gap: 56px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 435px);
`;

const SuccessCase = () => {
  const navigate = useNavigate();

  const [selectType, setSelectType] = useState("");
  const [searchText, setSearchText] = useState("");

  const [list, setList] = useState([
    {
      _id: "001",
      title: "난초 교육으로 부업까지",
      content:
        "다육식물을 기르다 시민대학 난초 무료교육을 통해 아내가 입문하고 난초의 매력에 빠져서 남편까지 교육을 받은 후 부업에 뛰어들었다.",
      studentName: "박정윤.이조일 부부",
      graduation: "1",
      thumbnail: "/assets/tmpImg/successcasetmpImg.png",
    },
    {
      _id: "002",
      title: "난초 교육으로 부업까지",
      content:
        "다육식물을 기르다 시민대학 난초 무료교육을 통해 아내가 입문하고 난초의 매력에 빠져서 남편까지 교육을 받은 후 부업에 뛰어들었다.",
      studentName: "박정윤.이조일 부부",
      graduation: "1",
      thumbnail: "/assets/tmpImg/successcasetmpImg.png",
    },
    {
      _id: "003",
      title: "난초 교육으로 부업까지",
      content:
        "다육식물을 기르다 시민대학 난초 무료교육을 통해 아내가 입문하고 난초의 매력에 빠져서 남편까지 교육을 받은 후 부업에 뛰어들었다.",
      studentName: "박정윤.이조일 부부",
      graduation: "1",
      thumbnail: "/assets/tmpImg/successcasetmpImg.png",
    },
    {
      _id: "004",
      title: "난초 교육으로 부업까지",
      content:
        "다육식물을 기르다 시민대학 난초 무료교육을 통해 아내가 입문하고 난초의 매력에 빠져서 남편까지 교육을 받은 후 부업에 뛰어들었다.",
      studentName: "박정윤.이조일 부부",
      graduation: "1",
      thumbnail: "/assets/tmpImg/successcasetmpImg.png",
    },
    {
      _id: "005",
      title: "난초 교육으로 부업까지",
      content:
        "다육식물을 기르다 시민대학 난초 무료교육을 통해 아내가 입문하고 난초의 매력에 빠져서 남편까지 교육을 받은 후 부업에 뛰어들었다.",
      studentName: "박정윤.이조일 부부",
      graduation: "1",
      thumbnail: "/assets/tmpImg/successcasetmpImg.png",
    },
    {
      _id: "006",
      title: "난초 교육으로 부업까지",
      content:
        "다육식물을 기르다 시민대학 난초 무료교육을 통해 아내가 입문하고 난초의 매력에 빠져서 남편까지 교육을 받은 후 부업에 뛰어들었다.",
      studentName: "박정윤.이조일 부부",
      graduation: "1",
      thumbnail: "/assets/tmpImg/successcasetmpImg.png",
    },
    {
      _id: "007",
      title: "난초 교육으로 부업까지",
      content:
        "다육식물을 기르다 시민대학 난초 무료교육을 통해 아내가 입문하고 난초의 매력에 빠져서 남편까지 교육을 받은 후 부업에 뛰어들었다.",
      studentName: "박정윤.이조일 부부",
      graduation: "1",
      thumbnail: "/assets/tmpImg/successcasetmpImg.png",
    },
    {
      _id: "008",
      title: "난초 교육으로 부업까지",
      content:
        "다육식물을 기르다 시민대학 난초 무료교육을 통해 아내가 입문하고 난초의 매력에 빠져서 남편까지 교육을 받은 후 부업에 뛰어들었다.",
      studentName: "박정윤.이조일 부부",
      graduation: "1",
      thumbnail: "/assets/tmpImg/successcasetmpImg.png",
    },
  ]);

  const onSearch = () => {
    console.log(searchText);
  };

  const onClickSuccessCase = (_id, graduation) => {
    navigate("/education/successcase/detail", {
      state: { _id, graduation },
    });
  };

  return (
    <BasicContainer metaTitle="관유정-교육생 사례">
      <Content>
        <PageTop
          label="교육지원"
          title="교육생 사례"
          ImageUrl="/assets/image/EduHeaderImg.png"
        />
        <RowDiv style={{ marginTop: 64 }}>
          <TitleBox style={{ marginTop: 0 }}>
            <TitleLabel>교육지원</TitleLabel>
            <TitleText>난초로 월급처럼 버는 사람들의 모범 사례</TitleText>
          </TitleBox>
        </RowDiv>
        <ListGrid>
          {list.map((item, idx) => {
            return (
              <SuccessCaseListItem
                key={item._id}
                thumbnail={item.thumbnail}
                graduation={item.graduation}
                title={item.title}
                content={item.content}
                studentName={item.studentName}
                onClickEvent={() => {
                  onClickSuccessCase(item._id, item.graduation);
                }}
              />
            );
          })}
        </ListGrid>
        <Pagination viewStyle={{ marginTop: 56, marginBottom: 174 }} />
      </Content>
    </BasicContainer>
  );
};

export default SuccessCase;

import React, { useState } from "react";
import styled from "styled-components";
import BasicContainer from "../../../components/BasicContainer";
import { DROPDOWN_A } from "../../../components/Dropdown";
import { Green600 } from "../../../config/color";
import { agreementContent } from "./content";

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const InnerContainer = styled.div`
  width: 1024px;
  padding-top: 56px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media screen and (max-width: 1439px) {
    width: calc(100% - 40px);
    padding: 0 20px;
    padding-top: 12px;
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 35px;
  margin-top: 34px;
`;

const AgreementUpdateDate = styled.div`
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  color: ${Green600};
  margin-top: 7px;
`;

const AgreementContent = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #545454;
  margin-top: 27px;
  margin-bottom: 82px;
  white-space: pre-wrap;
`;

const AGREEMENT_LIST = ["회원가입 약관 동의", "개인정보 제공 및 이용동의"];

const Agreement = (props) => {
  const [selected, setSelected] = useState("");

  return (
    <BasicContainer metaTitle="관유정-약관">
      <Content>
        <InnerContainer>
          <DROPDOWN_A
            list={AGREEMENT_LIST}
            defaultText="선택하세요"
            dropdownSelected={selected}
            setDropdownSelected={setSelected}
          />
          {selected ? (
            <>
              <Title>{selected}</Title>
              <AgreementUpdateDate>
                {agreementContent[selected].date}
              </AgreementUpdateDate>
              <AgreementContent>
                {agreementContent[selected].content}
              </AgreementContent>
            </>
          ) : null}
        </InnerContainer>
      </Content>
    </BasicContainer>
  );
};

export default Agreement;

import React from "react";
import styled from "styled-components";
import { Gray400, Green100, Green600, White } from "../../../config/color";

const BookImageBox = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
`;

const BookImage = styled.img`
  width: 300px;
  height: 399px;
`;

const BookInfo = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 19px;
`;

const BookTitleBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const BookPublish = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 25.2px;
  color: ${Gray400};
`;

const BookTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 36px;
  margin-left: 0;
  margin-right: auto;
  margin-top: 2px;
`;

const BookTag1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 26px;
  padding: 4px 12px;
  background-color: ${Green100};
  color: ${Green600};
  line-height: 12px;
  font-weight: 700;
  font-size: 12px;
  margin-left: auto;
`;

const BookTag2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 26px;
  padding: 4px 12px;
  background-color: ${Green600};
  color: ${White};
  line-height: 12px;
  font-weight: 700;
  font-size: 12px;
  margin-left: 8px;
`;

const BookBox = (props) => {
  const { viewStyle, publish, hit, population, title, Image } = props;

  return (
    <BookImageBox style={viewStyle}>
      <BookImage src={Image} />
      <BookInfo>
        <BookTitleBox style={{ paddingTop: 12 }}>
          <BookPublish>{publish}</BookPublish>
        </BookTitleBox>
        {population ? <BookTag1>인기</BookTag1> : null}
        {hit ? <BookTag2>HIT</BookTag2> : null}
      </BookInfo>
      <BookTitle>{title}</BookTitle>
    </BookImageBox>
  );
};

export default BookBox;

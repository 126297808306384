import React, { useEffect, useState } from "react";
import styled from "styled-components";

import BasicContainer from "../../../components/BasicContainer";
import PageTop from "../../../components/PageTop";

import {
  Black,
  Gray100,
  Gray200,
  Gray300,
  Gray500,
  Green100,
  Green600,
  White,
} from "../../../config/color";
import { useNavigate } from "react-router-dom";
import { CardWithIcon } from "../../../components/Card";

const Chart = require("../../../assets/icon/Chart99.png");
const Space = require("../../../assets/icon/Space99.png");
const Farm = require("../../../assets/icon/Farm99.png");
const Person = require("../../../assets/icon/Person99.png");

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const RowDiv = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 1328px;
  margin: 0 auto;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 72px;
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  color: ${Black};
  white-space: pre-wrap;
  line-height: 51px;
`;

const List = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 1328px;
  margin: 0 auto;
  margin-top: 40px;
  padding: 16px 0;
  border-top: 1px solid ${Gray300};
`;

const ListLabel = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Green600};
`;

const ListTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Black};
  margin-bottom: 16px;
`;

const ListContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  white-space: pre-wrap;
`;
const KindListUl = styled.ul`
  padding-left: 32px;
  margin: 0;
`;

const KindListLi = styled.li`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 8px;
  position: relative;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 8px;
`;

const Label = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  width: 28px;
  margin-right: 8px;
`;

const Value = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  white-space: pre-wrap;
  flex: 1;
`;

// Table
const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 1328px;
`;

const TableHeader = styled.div`
  width: calc(100% - 2px);
  height: 50px;
  border: 1px solid ${Gray200};
  background-color: ${Green600};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 29px;
  text-align: center;
  color: ${White};
`;

const TableFullRow = styled.div`
  width: calc(100% - 34px);
  height: 50px;
  padding: 0 16px;
  border: 1px solid ${Gray200};
  background-color: ${Green100};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: 700;
  line-height: 29px;
  text-align: center;
  color: ${Gray500};
`;

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const TableRowLabel = styled.div`
  width: calc(250px - 34px);
  height: 400px;
  padding: 0 16px;
  background-color: ${Green100};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid ${Gray200};
  font-size: 16px;
  font-weight: 700;
  line-height: 29px;
  text-align: center;
  color: ${Gray500};
`;

const TableRowLabelSmall = styled.div`
  width: calc(122px - 2px);
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Gray200};
  font-size: 16px;
  font-weight: 700;
  line-height: 29px;
  text-align: center;
  color: ${Gray500};
`;

const TableRowColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TableRowContent = styled.div`
  flex: 1;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid ${Gray200};
  font-size: 16px;
  font-weight: 700;
  line-height: 29px;
  text-align: center;
  color: ${Gray500};
`;

const TableRowEtc = styled.div`
  width: calc(321px - 34px);
  height: 400px;
  padding: 0 16px;
  background-color: ${White};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid ${Gray200};
  font-size: 16px;
  font-weight: 700;
  line-height: 29px;
  text-align: center;
  color: ${Gray500};
`;

const RecoveryFarm = () => {
  const navigate = useNavigate();

  return (
    <BasicContainer metaTitle="관유정-개인회생농">
      <Content>
        <PageTop
          label="교육지원"
          title="개인회생농"
          ImageUrl="/assets/image/EduHeaderImg.png"
        />
        <RowDiv style={{ marginTop: 64 }}>
          <TitleBox style={{ marginTop: 0 }}>
            <TitleLabel>교육지원</TitleLabel>
            <TitleText>개인회생농 프로그램</TitleText>
          </TitleBox>
        </RowDiv>
        <List style={{ marginTop: 24 }}>
          <ListLabel>01</ListLabel>
          <ListTitle>개인회생농 프로그램을 받으면 발생하는 좋은 이유</ListTitle>
          <ListContent>
            <KindListUl>
              <KindListLi>
                최신 고급 기술 체계 확립으로 영농성과 향상
              </KindListLi>
              <KindListLi>작품 체계와 경기운영 능력 확립</KindListLi>
              <KindListLi>안심 바우처 프랜차이즈 자격부여</KindListLi>
              <KindListLi>지역 선후배 애란인 부업 기술지도</KindListLi>
            </KindListUl>
          </ListContent>
        </List>
        <RowDiv style={{ marginTop: 16 }}>
          <CardWithIcon
            viewStyle={{
              backgroundColor: Gray100,
              marginRight: 8,
              height: 306,
            }}
            title="피폐농 회생-심각한 병 중해 구재 작업"
            content={`1. 난초 분석ㅡ품종과 품질
2. 원인 분석과 개선 방안 제시
3. 종합 대응 솔루션 방안 제시
4. 약물 치료 및 재활치료
5. 난실 구조 개선과 분내 환경 개선 `}
            iconImg={Chart}
          />
          <CardWithIcon
            viewStyle={{
              backgroundColor: Green100,
              marginLeft: 8,
              height: 306,
            }}
            title="승계농 승계ㅡ자녀들 대물림 구조 효율 작업"
            content={`1. 난초 분석ㅡ품종과 품질
2. 품종 구조 조정의 방안 제시
3. 월 200 연금 발생 마스트 플랜
4. 자녀 자경 또는 위탁 오더 대리돌봄 제시
5. 자녀 난실 구조 방안 제시 `}
            iconImg={Space}
          />
        </RowDiv>
        <RowDiv style={{ marginTop: 24 }}>
          <CardWithIcon
            viewStyle={{
              backgroundColor: Green100,
              marginRight: 8,
              height: 306,
            }}
            title="난실 신설-입지. 설계. 시설지도"
            content={`1. 규모와 방향 설정(취미. 또는 부업)
2. 사계절 환경 조성의 원리
3. 입지 적합도
4. 설계 및 필수 장치들 
5. 시공시 업자에게 지도 `}
            iconImg={Farm}
          />
          <CardWithIcon
            viewStyle={{
              backgroundColor: Gray100,
              marginLeft: 8,
              height: 306,
            }}
            title="부업농 전환"
            content={`1. 마스트 플랜
2. 전략품종 설계 방법
3. 생산 시설 구조 개선
4. 광합성 조건 0점 잡기
5. 연간 관리 체계 0점 잡기`}
            iconImg={Person}
          />
        </RowDiv>
        <List style={{ marginTop: 40 }}>
          <ListLabel>02</ListLabel>
          <ListTitle>경영 악화 농 재기 희망(회생) 컨설팅</ListTitle>
          <ListContent>
            <Row>
              <Label>목적</Label>
              <Value>
                비용 대비 본전 길이 요원한 농가, 배양장 세도 못만드는 농가의
                경영 점검을 통해 개선점을 알려주고 재기를 안내
              </Value>
            </Row>
            <Row>
              <Label>시간</Label>
              <Value>1일 6시간 이내</Value>
            </Row>
            <Row>
              <Label>비용</Label>
              <Value>상담료 300만원</Value>
            </Row>
            <Row>
              <Label>진행</Label>
              <Value>방문 또는 내원</Value>
            </Row>
          </ListContent>
        </List>
        <Table>
          <TableHeader>경영 악화 농 재기 회생 컨설팅</TableHeader>
          <TableFullRow>현재 가장 애로점은?</TableFullRow>
          <TableFullRow>수익 구조와 소득은?</TableFullRow>
          <TableFullRow style={{ backgroundColor: White }}>
            눈에 띄는 문제점 체크 및 해결책
          </TableFullRow>
          <TableFullRow style={{ backgroundColor: White }}>
            작황 체크 - 상당급 / 중등급 / 하등급 / 평균 작황 체크 - 문제점 및
            원인 분석 및 해결책
          </TableFullRow>
          <TableFullRow style={{ backgroundColor: White }}>
            옷자람도 체크
          </TableFullRow>
          <TableFullRow style={{ backgroundColor: White }}>
            뿌리 품질 및 기저 질환도 체크
          </TableFullRow>
          <TableFullRow style={{ backgroundColor: White }}>
            시설물의 적합도 - 피복재 상태와 수준, 청결도, 수질, 바닥 청결도
          </TableFullRow>
          <TableFullRow style={{ backgroundColor: White }}>
            원하는 목적에 일 맞는 분수의 적합도 체크, 분간 거리 체크
          </TableFullRow>
          <TableRow>
            <TableRowLabel>기술 숙련도</TableRowLabel>
            <TableRowColumn>
              <TableRowContent>분갈이 개념</TableRowContent>
              <TableRowContent>스케일링</TableRowContent>
              <TableRowContent>조기 관제 숙련도</TableRowContent>
              <TableRowContent>관행 예방 방제의 이행도</TableRowContent>
              <TableRowContent>약 치는 기술 숙련도</TableRowContent>
              <TableRowContent>관수 방법과 횟수</TableRowContent>
              <TableRowContent>시비 방법과 연간 공급양</TableRowContent>
              <TableRowContent>여름과 겨울 온도 조절 수준</TableRowContent>
            </TableRowColumn>
            <TableRowEtc></TableRowEtc>
          </TableRow>
          <TableRow>
            <TableRowLabel style={{ height: 300 }}>
              환경 제공 적합도
            </TableRowLabel>
            <TableRowColumn>
              <TableRowContent>기준 조도</TableRowContent>
              <TableRowContent>일 평균 조사 시간</TableRowContent>
              <TableRowContent>연 누적 조사 시간</TableRowContent>
              <TableRowContent>광합성 온도 제공 조건</TableRowContent>
              <TableRowContent>순 광합성 양</TableRowContent>
              <TableRowContent>보광 수준</TableRowContent>
            </TableRowColumn>
            <TableRowEtc style={{ height: 300 }}></TableRowEtc>
          </TableRow>
          <TableRow>
            <TableRowLabel style={{ height: 250 }}>
              경영 수익 구조의 건전도
            </TableRowLabel>
            <TableRowColumn>
              <TableRowContent>희망 수익 금액과 실제 금액</TableRowContent>
              <TableRowContent>품종 전략과 전략 품종</TableRowContent>
              <TableRowContent>출하 방식과 출하 시 등급</TableRowContent>
              <TableRowContent>기대 품의 비중</TableRowContent>
              <TableRowContent>국대 품의 비중</TableRowContent>
            </TableRowColumn>
            <TableRowEtc style={{ height: 250 }}></TableRowEtc>
          </TableRow>
        </Table>
        <List style={{ marginTop: 88 }}>
          <ListLabel>03</ListLabel>
          <ListTitle>부실 농 반/반 회생 지원 프로그램</ListTitle>
          <ListContent>
            <KindListUl>
              <KindListLi>{`본전 길이 없는 농가 회생을 위해 관유정과 애로 농가의 반반 참여로 전략품종을 들여 개월에 원금의 2배 짜리 전략품종을 확보시키고
이듬해 1촉을 시세에 50% 선으로 매입해주어 본전하고 원금의 200% 종자를 확보시켜 드려 월 200의 꿈을 실현해 드리기 위함`}</KindListLi>
              <KindListLi>2급 자격 취득 후 종자 공급</KindListLi>
            </KindListUl>
          </ListContent>
        </List>
        <List style={{ marginTop: 40 }}>
          <ListLabel>04</ListLabel>
          <ListTitle>상속 승계 장려 컨설팅</ListTitle>
          <ListContent>
            <Row>
              <Label>목적</Label>
              <Value>
                자녀들에게 물려주려 하는데 자녀들이 안 받으려 할 때 메리트를
                제공하고 실수익 평생 월 200만원 발생의 방법을 안내
              </Value>
            </Row>
            <Row>
              <Label>시간</Label>
              <Value>1일 6시간 이내</Value>
            </Row>
            <Row>
              <Label>비용</Label>
              <Value>상담료 300만원</Value>
            </Row>
            <Row>
              <Label>진행</Label>
              <Value>방문 또는 내원</Value>
            </Row>
          </ListContent>
        </List>
        <Table>
          <TableHeader>상속 승계 장려 컨설팅</TableHeader>
          <TableFullRow>현재 가장 애로점은?</TableFullRow>
          <TableFullRow>왜 안 받으려 하는가?</TableFullRow>
          <TableFullRow style={{ backgroundColor: White }}>
            눈에 띄는 문제점 체크 및 해결책
          </TableFullRow>
          <TableFullRow style={{ backgroundColor: White }}>
            철저한 구조 조정
          </TableFullRow>
          <TableFullRow style={{ backgroundColor: White }}>
            반드시 2급 자격 취득하게 함
          </TableFullRow>
          <TableFullRow style={{ backgroundColor: White }}>
            부업의 경우 반드시 20여 분 이내로 압축하고 월 100만 이상이 되게
            체제를 전환
          </TableFullRow>
          <TableRow>
            <TableRowLabel style={{ height: 150 }}>
              돈이 되게 구조 조정
            </TableRowLabel>
            <TableRowColumn>
              <TableRowContent>기대품</TableRowContent>
              <TableRowContent>신아 얼룩이</TableRowContent>
              <TableRowContent>저 사양 옵션 품</TableRowContent>
            </TableRowColumn>
            <TableRowEtc style={{ height: 150 }}>방출 후 1촉으로</TableRowEtc>
          </TableRow>
          <TableRow>
            <TableRowLabel style={{ height: 50 }}>
              특별한 미개화 기대품
            </TableRowLabel>
            <TableRowColumn>
              <TableRowContent>승계해 기름</TableRowContent>
            </TableRowColumn>
            <TableRowEtc style={{ height: 50 }}>웬만하면 구조 조정</TableRowEtc>
          </TableRow>
        </Table>
        <List style={{ marginTop: 88 }}>
          <ListLabel>05</ListLabel>
          <ListTitle>난실 설비 사전 컨설팅</ListTitle>
          <ListContent>
            <Row>
              <Label>목적</Label>
              <Value>
                최대한 농사가 잘 될 난실 설계 상담 - 수질, 광, 질, 건축법, 온도
                조절, 광도 조절, 병충해 밀도 조절, 피복제 선택 등의 50여 가지의
                필수 조건을 자세히 알려 드림.
              </Value>
            </Row>
            <Row>
              <Label>시간</Label>
              <Value>1일 6시간 이내</Value>
            </Row>
            <Row>
              <Label>비용</Label>
              <Value>상담료 300만원</Value>
            </Row>
            <Row>
              <Label>진행</Label>
              <Value>방문 또는 내원</Value>
            </Row>
          </ListContent>
        </List>
        <Table>
          <TableHeader>난실 설비 사전 컨설팅</TableHeader>
          <TableFullRow>예산은?</TableFullRow>
          <TableFullRow>부지는?</TableFullRow>
          <TableFullRow>수도는? </TableFullRow>
          <TableFullRow>기준 조도 설정은?</TableFullRow>
          <TableFullRow>예상 수익 금액은?</TableFullRow>
          <TableFullRow>예상 분수는? </TableFullRow>
          <TableFullRow>중점 전략 품종은?</TableFullRow>
          <TableFullRow>
            난 실의 용도는? - 부업/전업/랜탈/취미/병함
          </TableFullRow>
          <TableRow>
            <TableRowLabelSmall>1</TableRowLabelSmall>
            <TableRowContent>농사용 전기는?</TableRowContent>
          </TableRow>
          <TableRow>
            <TableRowLabelSmall>2</TableRowLabelSmall>
            <TableRowContent>건축법상 문제는?</TableRowContent>
          </TableRow>
          <TableRow>
            <TableRowLabelSmall>3</TableRowLabelSmall>
            <TableRowContent>CCTV와 보안 회사는?</TableRowContent>
          </TableRow>
          <TableRow>
            <TableRowLabelSmall>4</TableRowLabelSmall>
            <TableRowContent>연간 채광량은?</TableRowContent>
          </TableRow>
          <TableRow>
            <TableRowLabelSmall>5</TableRowLabelSmall>
            <TableRowContent>차양 방식은?</TableRowContent>
          </TableRow>
          <TableRow>
            <TableRowLabelSmall>6</TableRowLabelSmall>
            <TableRowContent>지붕의 구조는?</TableRowContent>
          </TableRow>
          <TableRow>
            <TableRowLabelSmall>7</TableRowLabelSmall>
            <TableRowContent>
              천장의 구조와 측 창의 구조는? 그리고 자동 수동
            </TableRowContent>
          </TableRow>
          <TableRow>
            <TableRowLabelSmall>8</TableRowLabelSmall>
            <TableRowContent>피복제와 뼈대와 기초의 방향은?</TableRowContent>
          </TableRow>
          <TableRow>
            <TableRowLabelSmall>9</TableRowLabelSmall>
            <TableRowContent>난방과 냉방은?</TableRowContent>
          </TableRow>
          <TableRow>
            <TableRowLabelSmall>10</TableRowLabelSmall>
            <TableRowContent>보온은?</TableRowContent>
          </TableRow>
          <TableRow>
            <TableRowLabelSmall>11</TableRowLabelSmall>
            <TableRowContent>정전 시 비상 가온 설비는?</TableRowContent>
          </TableRow>
          <TableRow>
            <TableRowLabelSmall>12</TableRowLabelSmall>
            <TableRowContent>저온실은?</TableRowContent>
          </TableRow>
          <TableRow>
            <TableRowLabelSmall>13</TableRowLabelSmall>
            <TableRowContent>방충망은?</TableRowContent>
          </TableRow>
          <TableRow>
            <TableRowLabelSmall>14</TableRowLabelSmall>
            <TableRowContent>급수 장치와 방식은?</TableRowContent>
          </TableRow>
          <TableRow>
            <TableRowLabelSmall>15</TableRowLabelSmall>
            <TableRowContent>바닥과 옥외 침출수 배출은?</TableRowContent>
          </TableRow>
          <TableRow>
            <TableRowLabelSmall>16</TableRowLabelSmall>
            <TableRowContent>
              손질하는 시스템은? 싱크대 난실 내부 또는 외부?
            </TableRowContent>
          </TableRow>
          <TableRow>
            <TableRowLabelSmall>17</TableRowLabelSmall>
            <TableRowContent>일 평균 조사 시간은?</TableRowContent>
          </TableRow>
          <TableRow>
            <TableRowLabelSmall>18</TableRowLabelSmall>
            <TableRowContent>연 누적 조사 시간은?</TableRowContent>
          </TableRow>
          <TableRow>
            <TableRowLabelSmall>19</TableRowLabelSmall>
            <TableRowContent>순 광합성 양은?</TableRowContent>
          </TableRow>
          <TableRow>
            <TableRowLabelSmall>20</TableRowLabelSmall>
            <TableRowContent>보광 설비는?</TableRowContent>
          </TableRow>
          <TableRow>
            <TableRowLabelSmall>21</TableRowLabelSmall>
            <TableRowContent>화제시 대비책은?</TableRowContent>
          </TableRow>
          <TableRow>
            <TableRowLabelSmall>22</TableRowLabelSmall>
            <TableRowContent>태풍과 적설 시 견고함</TableRowContent>
          </TableRow>
        </Table>
        <List style={{ marginTop: 88 }}>
          <ListLabel>06</ListLabel>
          <ListTitle>부업 희망 멘토링 상담</ListTitle>
          <ListContent>
            <Row>
              <Label style={{ width: 105 }}>무료 전화 상담</Label>
              <Value>10분 미만</Value>
            </Row>
            <Row>
              <Label style={{ width: 105 }}>유료 전화 상담</Label>
              <Value>
                010-3505-5577 / 053-766-5935 / 30분 미만 20만 원 선납
              </Value>
            </Row>
            <Row>
              <Label style={{ width: 105 }}>유료 방문 컨설팅</Label>
              <Value>
                60분 미만 100만원 선납 (진흥원 PPT 상담 및 질의응답)
              </Value>
            </Row>
          </ListContent>
        </List>
        <Table style={{ marginBottom: 42 }}>
          <TableHeader>난실 설비 사전 컨설팅</TableHeader>
          <TableRow>
            <TableRowLabelSmall style={{ backgroundColor: Green100 }}>
              Q1
            </TableRowLabelSmall>
            <TableRowContent>
              당신은 왜 난초 부업을 하려 합니까?
            </TableRowContent>
          </TableRow>
          <TableRow>
            <TableRowLabelSmall style={{ backgroundColor: Green100 }}>
              Q2
            </TableRowLabelSmall>
            <TableRowContent>명장에게 꼭 묻고 싶은 것은?</TableRowContent>
          </TableRow>
          <TableRow>
            <TableRowLabelSmall style={{ backgroundColor: Green100 }}>
              Q3
            </TableRowLabelSmall>
            <TableRowContent>
              모든 농업은 폭락이 있다 - 위험성 강조
            </TableRowContent>
          </TableRow>
          <TableRow>
            <TableRowLabelSmall style={{ backgroundColor: Green100 }}>
              Q4
            </TableRowLabelSmall>
            <TableRowContent>
              들일 때 보다 상 등급을 만들지 못하면 실패한다
            </TableRowContent>
          </TableRow>
          <TableRow>
            <TableRowLabelSmall style={{ backgroundColor: Green100 }}>
              Q5
            </TableRowLabelSmall>
            <TableRowContent>
              부업의 프로세스와 난 부업의 기본 공식
            </TableRowContent>
          </TableRow>
          <TableRow>
            <TableRowLabelSmall style={{ backgroundColor: Green100 }}>
              Q6
            </TableRowLabelSmall>
            <TableRowContent>당신이 월 200만원을 벌려면?</TableRowContent>
          </TableRow>
          <TableRow>
            <TableRowLabelSmall style={{ backgroundColor: Green100 }}>
              Q7
            </TableRowLabelSmall>
            <TableRowContent>본전 후 프리랜서의 길</TableRowContent>
          </TableRow>
          <TableRow>
            <TableRowLabelSmall style={{ backgroundColor: Green100 }}>
              Q8
            </TableRowLabelSmall>
            <TableRowContent>
              관심과 자신과 맞는다면 2급 교육을 받아라
            </TableRowContent>
          </TableRow>
          <TableRow>
            <TableRowLabelSmall style={{ backgroundColor: Green100 }}>
              Q9
            </TableRowLabelSmall>
            <TableRowContent>
              여러분의 성공 도우미 관유정 공동체란?
            </TableRowContent>
          </TableRow>
          <TableRow>
            <TableRowLabelSmall style={{ backgroundColor: Green100 }}>
              Q10
            </TableRowLabelSmall>
            <TableRowContent>Q&A</TableRowContent>
          </TableRow>
        </Table>
      </Content>
    </BasicContainer>
  );
};

export default RecoveryFarm;

import React, { useEffect, useState } from "react";
import styled from "styled-components";

import BasicContainer from "../../../components/BasicContainer";
import PageTop from "../../../components/PageTop";

import {
  Black,
  Gray100,
  Gray300,
  Gray500,
  Green100,
  Green600,
  White,
} from "../../../config/color";
import { useNavigate } from "react-router-dom";
import CertificateInfoCard from "./Card";
import { getWindowSize } from "../../../utils";

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1439px) {
    min-width: 360px;
    width: 100%;
  }
`;

const RowDiv = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 1328px;
  margin: 0 auto;

  @media screen and (max-width: 1439px) {
    width: calc(100% - 40px);
    padding: 0 20px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 72px;

  @media screen and (max-width: 1439px) {
    margin-top: 32px;
  }
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  color: ${Black};
  white-space: pre-wrap;
  line-height: 51px;

  @media screen and (max-width: 1439px) {
    font-size: 26px;
    line-height: 36px;
  }
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  white-space: pre-wrap;
`;

const CertificateInfo = () => {
  const navigate = useNavigate();

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <BasicContainer metaTitle="관유정-자격증 과정">
      <Content>
        <PageTop
          label="교육지원"
          title="자격증 과정"
          ImageUrl="/assets/image/EduHeaderImg.png"
        />
        <RowDiv style={{ marginTop: windowSize.innerWidth <= 1439 ? 32 : 64 }}>
          <TitleBox style={{ marginTop: 0 }}>
            <TitleLabel>교육지원</TitleLabel>
            <TitleText>난초 자격증 과정 개요</TitleText>
          </TitleBox>
        </RowDiv>
        <RowDiv
          style={{
            paddingTop: 16,
            borderTop: `1px solid ${Gray300}`,
            marginTop: 32,
          }}
        >
          <Text>
            {`자격 과정은 2급과정과 1급과정으로 나뉘어집니다.
1급 과정은 2급자격을 취득한 분들에게만 해당이 있습니다.
2급 자격과정은 2개로 나뉘어지며, 취미반과 부업자 양성반으로 나뉘어집니다.  
2급 과정은 주말반과 평일반으로 나뉘어집니다.
주말반은 대구카톨릭대학 평생교육원에서 실시하며 10주간 매주 토요일 실시하며 연 4회(3,6,9,12) 실시합니다.
평일반은 대한민국난문화진흥원에서 실시하며 2박3일간 실시하며, 연 12회 매월 첫 째 주 월,화,수에 실시합니다. 
1급은 1박 2일로 진행하며, 첫째날은 최신 고급기술편이며 둘째날은 작품과 예술편입니다.
2급 주말반은 교육비 200만원으로 정원 20~25명, 평일반은 교육비 300만원으로 정원 3~7명
1급 과정은 교육비 500만원으로 정원 1~3명이며, 대한민국 난문화진흥원에서 실시합니다.`}
          </Text>
        </RowDiv>
        <RowDiv>
          <CertificateInfoCard />
        </RowDiv>
      </Content>
    </BasicContainer>
  );
};

export default CertificateInfo;

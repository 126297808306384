import React from "react";
import styled from "styled-components";
import { Gray400, Green100, Green600, White } from "../config/color";

const Container = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  &:hover {
    cursor: pointer;
  }
`;

const Image = styled.img`
  width: 320px;
  height: 320px;
  border-radius: 10px;
`;

const ItemInfo = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
`;

const ItemTitleBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemPublish = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 25.2px;
  color: ${Gray400};
`;

const ItemTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 36px;
  margin-left: 0;
  margin-right: auto;
  margin-top: 2px;
`;

const ItemTag1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 26px;
  padding: 4px 12px;
  background-color: ${Green100};
  color: ${Green600};
  line-height: 12px;
  font-weight: 700;
  font-size: 12px;
  margin-left: auto;
`;

const ItemTag2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 26px;
  padding: 4px 12px;
  background-color: ${Green600};
  color: ${White};
  line-height: 12px;
  font-weight: 700;
  font-size: 12px;
  margin-left: 8px;
`;

const Price = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: ${Gray400};
`;

const PriceInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Timer = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${Green600};
  margin-left: auto;
`;

const SalesListItem = (props) => {
  const {
    uri,
    createdAt,
    hit,
    population,
    name,
    price,
    onClickEvent,
    isAuction,
  } = props;
  return (
    <Container onClick={onClickEvent}>
      <Image src={uri} />
      <ItemInfo>
        <ItemTitleBox style={{ paddingTop: 12 }}>
          <ItemPublish>{createdAt}</ItemPublish>
        </ItemTitleBox>
        {population ? <ItemTag1>인기</ItemTag1> : null}
        {hit ? <ItemTag2>HIT</ItemTag2> : null}
      </ItemInfo>
      <ItemTitle>{name}</ItemTitle>
      {isAuction ? (
        <PriceInfo>
          <Price>{price}</Price>
          <Timer>마감까지 12:59:00</Timer>
        </PriceInfo>
      ) : (
        <Price>{price}</Price>
      )}
    </Container>
  );
};

export default SalesListItem;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import BasicContainer from "../../../components/BasicContainer";
import { SEARCH_BOX } from "../../../components/TextField";
import {
  Black,
  Gray100,
  Gray200,
  Gray400,
  Gray500,
  Green600,
} from "../../../config/color";

import { getWindowSize, toStringByFormatting } from "../../../utils";
import Pagination from "../../../components/Pagination";
import { DROPDOWN_A } from "../../../components/Dropdown";
import PageTop from "../../../components/PageTop";

const EmptyIcon = require("../../../assets/icon/Close29.png");

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1439px) {
    min-width: 360px;
    width: 100%;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 72px;

  @media screen and (max-width: 1439px) {
    margin-top: 32px;
    width: 100%;
  }
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  line-height: 51px;
  color: ${Black};

  @media screen and (max-width: 1439px) {
    font-size: 26px;
    line-height: 36px;
  }
`;

const RowDiv = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 1328px;
  margin: 0 auto;

  @media screen and (max-width: 1439px) {
    width: calc(100% - 40px);
    padding: 0 20px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const EmptyBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${Gray100};
  height: 142px;
  width: 1328px;
`;

const EmptyBoxIcon = styled.img`
  width: 29px;
  height: 29px;
`;

const EmptyBoxText = styled.div`
  margin-top: 12px;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: ${Gray400};
`;

const ListBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 1328px;
`;

const List = styled.div`
  flex: 1;
  height: 32px;
  border: 1px solid ${Gray200};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  border-radius: 8px;
  margin-bottom: 16px;
  &:hover {
    cursor: pointer;
  }
`;

const ListLabel = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 25px;
  color: ${Gray400};
  margin-right: 16px;
  margin-left: 0;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Number = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Gray400};
  margin-right: 16px;
  margin-left: 0;
  width: calc(51px - 16px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ListTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 32.4px;
  color: ${Black};
  margin-right: 16px;
  width: calc(439px - 16px);
`;

const ListText = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Gray500};
  margin-right: 16px;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 4px;
`;

const CountText = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 25.2px;
  margin-right: 16px;
`;

const DateText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 25.2px;
  color: ${Gray400};
`;

const SearchBoxRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  @media screen and (max-width: 1439px) {
    margin-left: 0;
    margin-bottom: 0;
    width: 100%;
    position: relative;
  }
`;

const MobileList = styled.div`
  display: flex;
  width: calc(100% - 72px);
  margin: 0 20px;
  margin-bottom: 20px;
  padding: 16px;
  flex-direction: column;
  border: 1px solid ${Gray200};
  border-radius: 8px;
`;

const MobileListRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 16px;
`;

const YoutubePage = () => {
  const navigate = useNavigate();

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const [searchText, setSearchText] = useState("");
  const [selectType, setSelectType] = useState("");
  const [list, setList] = useState([
    {
      _id: "003",
      title: "3강. 100% 판로 확보와 판매 시스템",
      category: "교육",
      createdAt: new Date(),
    },
    {
      _id: "002",
      title: "2강. 성공 전략 품종 선택 방법",
      category: "시사진단",
      createdAt: new Date(),
    },
    {
      _id: "001",
      title: "1강. 월 200 난초 연금 이게 가능한가?",
      category: "종합병원",
      createdAt: new Date(),
    },
  ]);

  const onSearch = () => {
    console.log(searchText);
  };

  const onMoveWriting = () => {
    navigate("/company/board/writing", { state: "id값" });
  };

  const onMoveDetail = (data) => {
    navigate("/education/outsidelecture/detail", { state: { data } });
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <BasicContainer metaTitle="관유정-유튜브 리스트">
      <Content>
        <PageTop
          label="기술지원"
          title="유튜브 리스트"
          ImageUrl="/assets/image/EduHeaderImg.png"
        />
        <RowDiv
          style={{
            marginTop: windowSize.innerWidth <= 1439 ? 32 : 64,
            marginBottom: windowSize.innerWidth <= 1439 ? 32 : 35,
          }}
        >
          <TitleBox style={{ marginTop: 0 }}>
            <TitleLabel>교육지원</TitleLabel>
            <RowDiv
              style={
                windowSize.innerWidth <= 1439
                  ? { margin: 0, padding: 0, width: "100%" }
                  : null
              }
            >
              <TitleText
                style={
                  windowSize.innerWidth <= 1439 ? { marginBottom: 24 } : null
                }
              >
                유튜브 리스트
              </TitleText>
              <SearchBoxRow>
                <DROPDOWN_A
                  list={["제목", "카테고리"]}
                  defaultText="검색분류"
                  dropdownSelected={selectType}
                  setDropdownSelected={setSelectType}
                  viewStyle={{
                    width: 133,
                    marginLeft: "auto",
                    marginRight: windowSize.innerWidth <= 1439 ? 8 : 16,
                  }}
                  optionStyle={{ width: 133 }}
                />
                <SEARCH_BOX
                  viewStyle={
                    windowSize.innerWidth <= 1439
                      ? { width: "calc(100% - 161px)" }
                      : null
                  }
                  seachBoxStyle={
                    windowSize.innerWidth <= 1439
                      ? { width: "calc(100% - 81px)" }
                      : null
                  }
                  val={searchText}
                  setVal={setSearchText}
                  onClick={onSearch}
                  placeholder="검색하기"
                />
              </SearchBoxRow>
            </RowDiv>
          </TitleBox>
        </RowDiv>
        {list.length <= 0 ? (
          <EmptyBox>
            <EmptyBoxIcon src={EmptyIcon} />
            <EmptyBoxText>내용이 존재하지 않습니다</EmptyBoxText>
          </EmptyBox>
        ) : windowSize.innerWidth <= 1439 ? (
          list.map((item, idx) => {
            return (
              <MobileList
                key={item._id}
                onClick={() => {
                  onMoveDetail(item);
                }}
              >
                <Number style={{ justifyContent: "flex-start" }}>
                  {list.length - idx}
                </Number>
                <ListTitle style={{ width: 1055 - 16 }}>{item.title}</ListTitle>
                <MobileListRow>
                  <ListText
                    style={{ width: 95 - 16, fontSize: 18, color: Green600 }}
                  >
                    {item.category}
                  </ListText>
                  <DateText style={{ width: 79, marginLeft: "auto" }}>
                    {toStringByFormatting(item.createdAt)}
                  </DateText>
                </MobileListRow>
              </MobileList>
            );
          })
        ) : (
          <ListBox>
            <List
              style={{
                border: 0,
                paddingBottom: 0,
                marginBottom: 8,
                justifyContent: "flex-start",
              }}
            >
              <ListLabel style={{ width: 51 - 16 }}>순번</ListLabel>
              <ListLabel style={{ width: 95 - 16 }}>카테고리</ListLabel>
              <ListLabel style={{ width: 1055 - 16 }}>제목</ListLabel>
              <DateText></DateText>
            </List>
            {list.map((item, idx) => {
              return (
                <List
                  key={item._id}
                  onClick={() => {
                    onMoveDetail(item);
                  }}
                >
                  <Number>{list.length - idx}</Number>
                  <ListText
                    style={{ width: 95 - 16, fontSize: 18, color: Green600 }}
                  >
                    {item.category}
                  </ListText>
                  <ListTitle style={{ width: 1055 - 16 }}>
                    {item.title}
                  </ListTitle>
                  <DateText style={{ width: 79 }}>
                    {toStringByFormatting(item.createdAt)}
                  </DateText>
                </List>
              );
            })}
            <Pagination viewStyle={{ marginTop: 40, marginBottom: 83 }} />
          </ListBox>
        )}
      </Content>
    </BasicContainer>
  );
};

export default YoutubePage;

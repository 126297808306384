import React, { useEffect, useState } from "react";
import styled from "styled-components";

import BasicContainer from "../../../components/BasicContainer";
import PageTop from "../../../components/PageTop";

import {
  Black,
  Gray100,
  Gray300,
  Gray500,
  Green100,
  Green600,
  White,
} from "../../../config/color";
import { TAB } from "../../../components/Tab";
import { SEARCH_BOX } from "../../../components/TextField";
import { DROPDOWN_A } from "../../../components/Dropdown";
import { useNavigate } from "react-router-dom";
import EduListItem from "../../../components/EduListItem";
import Pagination from "../../../components/Pagination";
import { getWindowSize } from "../../../utils";

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1439px) {
    min-width: 360px;
    width: 100%;
  }
`;

const RowDiv = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 1328px;
  margin: 0 auto;

  @media screen and (max-width: 1439px) {
    width: calc(100% - 40px);
    padding: 0 20px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 72px;

  @media screen and (max-width: 1439px) {
    margin-top: 32px;
  }
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  color: ${Black};
  white-space: pre-wrap;
  line-height: 51px;

  @media screen and (max-width: 1439px) {
    font-size: 26px;
    line-height: 36px;
  }
`;
const SearchBoxRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;
  width: 100%;
  position: relative;
`;

const ListGrid = styled.div`
  display: grid;
  width: 1328px;
  margin: 32px 112px;
  gap: 16px;
  row-gap: 56px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 435px);
  justify-content: center;
  align-content: center;

  @media screen and (max-width: 767px) {
    width: calc(100% - 40px);
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, auto);
    row-gap: 28px;
    margin: 0 auto;
  }

  @media screen and (min-width: 768px) and (max-width: 1439px) {
    width: calc(100% - 40px);
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, auto);
    row-gap: 28px;
    margin: 0 auto;
  }
`;

const Alumni = () => {
  const navigate = useNavigate();

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const [selectedTab, setSelectedTab] = useState(0);
  const [selectType, setSelectType] = useState("");
  const [searchText, setSearchText] = useState("");

  const [list, setList] = useState([
    {
      _id: "001",
      graduation: "1",
      thumbnail: "/assets/tmpImg/lecturetmpImg.png",
    },
    {
      _id: "002",
      graduation: "2",
      thumbnail: "/assets/tmpImg/lecturetmpImg.png",
    },
    {
      _id: "003",
      graduation: "3",
      thumbnail: "/assets/tmpImg/lecturetmpImg.png",
    },
    {
      _id: "004",
      graduation: "4",
      thumbnail: "/assets/tmpImg/lecturetmpImg.png",
    },
    {
      _id: "005",
      graduation: "5",
      thumbnail: "/assets/tmpImg/lecturetmpImg.png",
    },
    {
      _id: "006",
      graduation: "6",
      thumbnail: "/assets/tmpImg/lecturetmpImg.png",
    },
    {
      _id: "007",
      graduation: "7",
      thumbnail: "/assets/tmpImg/lecturetmpImg.png",
    },
    {
      _id: "008",
      graduation: "8",
      thumbnail: "/assets/tmpImg/lecturetmpImg.png",
    },
  ]);

  const onSearch = () => {
    console.log(searchText);
  };

  const onClickAlumni = (_id, graduation) => {
    navigate("/education/alumni/detail", {
      state: { _id, graduation, title: "대구카톨릭평생교육원" },
    });
  };

  useEffect(() => {
    if (selectedTab === 1) {
      navigate("/education/alumni/1");
    } else if (selectedTab === 2) {
      navigate("/education/alumni/2");
    }
  }, [selectedTab]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <BasicContainer metaTitle="관유정-총동창회">
      <Content>
        <PageTop
          label="교육지원"
          title="총동창회"
          ImageUrl="/assets/image/EduHeaderImg.png"
        />
        <TAB
          tabList={[
            { name: "카톨릭대학교" },
            { name: "난문화진흥원" },
            // { name: "1:1" },
          ]}
          viewStyle={{
            marginTop: windowSize.innerWidth <= 1439 ? 28 : 56,
            marginBottom: windowSize.innerWidth <= 1439 ? 0 : 32,
          }}
          tabStyle={{
            width:
              windowSize.innerWidth <= 1439
                ? windowSize.innerWidth / 2 - 20
                : 152,
            paddingTop: 0,
            paddingBottom: 9,
            paddingLeft: windowSize.innerWidth <= 767 ? 8 : 26,
            paddingRight: windowSize.innerWidth <= 767 ? 8 : 26,
          }}
          activeTabIdx={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        {windowSize.innerWidth <= 1439 ? (
          <RowDiv>
            <TitleBox style={{ marginTop: 32 }}>
              <TitleLabel>교육지원</TitleLabel>
              <RowDiv style={{ padding: 0, width: "100%" }}>
                <TitleText style={{ marginBottom: 24 }}>카톨릭대학교</TitleText>
              </RowDiv>
            </TitleBox>
            <SearchBoxRow>
              <DROPDOWN_A
                list={["제목", "기수"]}
                defaultText="검색분류"
                dropdownSelected={selectType}
                setDropdownSelected={setSelectType}
                viewStyle={{
                  width: 143,
                  marginRight: 8,
                  height: 18,
                }}
                optionStyle={{ width: 147 - 22 }}
              />
              <SEARCH_BOX
                val={searchText}
                setVal={setSearchText}
                onClick={onSearch}
                placeholder="검색하기"
                viewStyle={{ width: "calc(100% - 161px)" }}
                seachBoxStyle={{ width: "calc(100% - 81px)" }}
              />
            </SearchBoxRow>
          </RowDiv>
        ) : (
          <RowDiv>
            <TitleBox style={{ marginTop: 0 }}>
              <TitleLabel>교육지원</TitleLabel>
              <RowDiv>
                <TitleText>카톨릭대학교</TitleText>
                <DROPDOWN_A
                  list={["제목", "기수"]}
                  defaultText="검색분류"
                  dropdownSelected={selectType}
                  setDropdownSelected={setSelectType}
                  viewStyle={{ width: 133, marginLeft: "auto" }}
                  optionStyle={{ width: 133 }}
                />
                <SEARCH_BOX
                  viewStyle={{ marginLeft: 16 }}
                  val={searchText}
                  setVal={setSearchText}
                  onClick={onSearch}
                  placeholder="검색하기"
                />
              </RowDiv>
            </TitleBox>
          </RowDiv>
        )}
        <ListGrid>
          {list.map((item, idx) => {
            return (
              <EduListItem
                key={item._id}
                thumbnail={item.thumbnail}
                graduation={item.graduation}
                title="카톨릭대학교"
                onClickEvent={() => {
                  onClickAlumni(item._id, item.graduation);
                }}
              />
            );
          })}
        </ListGrid>
        <Pagination viewStyle={{ marginTop: 56, marginBottom: 174 }} />
      </Content>
    </BasicContainer>
  );
};

export default Alumni;

import React, { useState } from "react";
import styled from "styled-components";
import BasicContainer from "../../../components/BasicContainer";

import { Black, Green600 } from "../../../config/color";
import { ButtonB } from "../../../components/Button";
import { useNavigate } from "react-router-dom";

const PERSON_ICON = require("../../../assets/icon/Person71.png");

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 360px;
`;

const Icon = styled.img`
  width: 71px;
  height: 71px;
  margin-top: 87px;
`;

const IconLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  margin-top: 19px;
  color: ${Green600};
`;

const Title = styled.div`
  font-family: "Nanum Myeongjo", serif;
  font-weight: 600;
  font-size: 26px;
  line-height: 36.4px;
  color: ${Black};
  margin-top: 2px;
`;

const Comment = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 28.8px;
  text-align: center;
  color: ${Black};
  margin-top: 24px;
  white-space: pre-wrap;
`;

const SignupComplete = (props) => {
  const navigate = useNavigate();

  const moveToSignin = () => {
    navigate("/signin");
  };

  return (
    <BasicContainer metaTitle="관유정-회원가입완료">
      <Content>
        <Icon src={PERSON_ICON} />
        <IconLabel>회원가입</IconLabel>
        <Title>회원가입이 완료되었습니다</Title>
        <Comment>
          회원가입이 완료되었습니다.
          <br />
          로그인 후 이용바랍니다.
        </Comment>
        <ButtonB
          viewStyle={{ width: 262, height: 50, marginTop: 24 }}
          text="로그인하기"
          onClickEvent={moveToSignin}
        />
      </Content>
    </BasicContainer>
  );
};

export default SignupComplete;

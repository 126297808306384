import React from "react";
import styled from "styled-components";
import { White } from "../../../config/color";

const Container = styled.div`
  width: 345px;
  height: 337px;
  position: relative;
  margin: 0 auto;
`;

const CircleText = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${White};
  white-space: pre-wrap;
  text-align: center;
`;

const Circle = styled(CircleText)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  border-radius: 100px;
  mix-blend-mode: multiply;
`;

const ThreeCircle = (props) => {
  const { topText, leftText, rightText, viewStyle } = props;
  return (
    <Container>
      <Circle style={{ top: 0, left: 72, ...viewStyle }}>{topText}</Circle>
      <Circle style={{ bottom: 0, left: 0, ...viewStyle }}>{leftText}</Circle>
      <Circle style={{ bottom: 0, right: 0, ...viewStyle }}>{rightText}</Circle>
    </Container>
  );
};

export default ThreeCircle;

import React, { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import BasicContainer from "../../../../components/BasicContainer";
import { CardWithIcon3 } from "../../../../components/Card";
import PageTop from "../../../../components/PageTop";
import {
  Black,
  Gray100,
  Gray300,
  Green100,
  Green600,
  White,
} from "../../../../config/color";
import { getWindowSize } from "../../../../utils";

const Valance = require("../../../../assets/icon/Valance99.png");
const Plant = require("../../../../assets/icon/Plant99.png");

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1439px) {
    min-width: 360px;
    width: 100%;
  }
`;

const RowDiv = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  width: 1328px;
  margin: 0 auto;

  @media screen and (max-width: 1439px) {
    flex-direction: column;
    width: calc(100% - 40px);
    padding: 0 20px;
    align-items: flex-start;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 72px;

  @media screen and (max-width: 1439px) {
    margin-top: 32px;
  }
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  color: ${Black};
  white-space: pre-wrap;
  line-height: 51px;

  @media screen and (max-width: 1439px) {
    font-size: 26px;
    line-height: 36px;
    word-break: keep-all;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 1328px;
  margin: 0 auto;
  margin-top: 40px;
  padding: 16px 0;
  border-top: 1px solid ${Gray300};

  @media screen and (max-width: 1439px) {
    width: calc(100% - 40px);
    margin: 0 20px;
    margin-top: 32px;
  }
`;

const ListLabel = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Green600};
`;

const ListTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Black};
  margin-bottom: 16px;
`;

const ListContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  white-space: pre-wrap;
`;

const ListContentRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 16px;
`;

const ListNumber = styled.div`
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: ${Green600};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${White};
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  margin-right: 8px;
  margin-top: 2.5px;
`;

const PointBox = styled.div`
  width: 100%;
  padding: 48px 0;
  margin-top: 40px;

  @media screen and (max-width: 1439px) {
    padding: 0;
    margin-top: 0;
  }
`;

const HalfToHalf = (props) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <BasicContainer metaTitle="관유정-성공로드맵">
      <Content>
        <PageTop
          label="영농지원"
          title="성공로드맵"
          ImageUrl="/assets/image/FarmHeaderImg.png"
        />
        <RowDiv>
          <TitleBox>
            <TitleLabel>영농지원</TitleLabel>
            <TitleText>{`관유정만의 특별한 제도들,
반반 지렛대 프로그램과 단독프로그램`}</TitleText>
          </TitleBox>
        </RowDiv>
        <List>
          <ListLabel>01</ListLabel>
          <ListTitle>반반 지렛대 프로그램이란?</ListTitle>
          <ListContent>{`2급 자격 취득생들을 위한 우대 시스템으로 과거에 실시하던 1.5년 시, 4촉 때 2촉을 모기업(관유정 등)에서 종자돈을 회수시켜주기 위해 책임매입을 실시한 2촉 책임 매입 제도를 업그레이드한 시스템입니다. 
시장상황과 모기업(관유정 등)의 비축, 재고량의 유동에 따라 변수로 인한 신뢰추락과 모기업(관유정 등)의 과도한 의존도에 따른 자립경영 의지의 위축을 방지
하기 위해 신설한(2022년 12월) 시스템입니다. 반반이란 참여 농가가 2급 교육을 수료하고 품종 전략을 짤 때, 50%의 자본으로 2배짜리를 4~5개월(봄에 사서 가을)
만에 확보할 수 있게 하여 성공 확률을 획기적으로 높여주는 대한민국 난문화 진흥원이 세계 최초로 개발한 시스템입니다.`}</ListContent>
        </List>
        <List>
          <ListLabel>02</ListLabel>
          <ListTitle>반반 지렛대 프로그램의 장점</ListTitle>
          <ListContent>{` - 절반의 종잣돈으로 4~5개월만에 2배의 전략품종을 확보해 2년 후, 4촉을 만듬
 - 4촉은 투자금을 800% 이므로 절반인 2촉을 출하했을 때, 손쉽게 본전을 할 수 있음
 - 남은 2촉은 투자금의 약 400~600%(세력이 20% 증가 시)가 잉여되어 원하는 연금 수령을 누구나 손 쉽게 가능하게 됨
 - 4월에 사서 10월이면 2촉이 되어 지분분할을 거쳐 젊고 튼실한 촉을 베이스로 확보할 수 있음(필요성: 품종전략에 선택된 품종들은 구입하려하여도 1년생의 
    뿌리가 좋고 깨끗한 젊은 촉은 구하기가 매우 어려운데 이 인큐베이팅OEM 과정을 거치므로 참여 농가 모두는 1년생의 젊은 촉을 확보할 수 있다. `}</ListContent>
        </List>
        <PointBox style={{ backgroundColor: Green100 }}>
          <List style={{ borderTop: 0 }}>
            <ListLabel>03</ListLabel>
            <ListTitle>반반 지렛대 프로그램 프로세스</ListTitle>
            <ListContent>
              <ListContentRow>
                <ListNumber>1</ListNumber>
                2급자격 취득
              </ListContentRow>
              <ListContentRow>
                <ListNumber>2</ListNumber>
                품종 전략을 짠다.
              </ListContentRow>
              <ListContentRow>
                <ListNumber>3</ListNumber>
                종잣돈을 확보한다.
              </ListContentRow>
              <ListContentRow>
                <ListNumber>4</ListNumber>
                반반 인큐베이팅 OEM을 실시해 줄 모기업(관유정 등)과 계약을
                한다.(계약서 작성)
              </ListContentRow>
              <ListContentRow>
                <ListNumber>5</ListNumber>
                봄에 사서 가을이면 지분 분할을 한다.
              </ListContentRow>
              <ListContentRow>
                <ListNumber>6</ListNumber>
                {`지분분할한 전진촉을 자신의 난실로 가져간다.(기술에 자신이 없거나 난실 여건이 좋지 않을 시 모기업(관유정 등) 1년간 위탁 오더 대리돌봄으로 기른 후
이듬해 한 촉은 모기업에 그대로 기르고 한 촉은 열악하지만 자신의 난실로 가져간다.)`}
              </ListContentRow>
              <ListContentRow>
                <ListNumber>7</ListNumber>
                교육에서 배운대로 철저히 관리한다.
              </ListContentRow>
              <ListContentRow>
                <ListNumber>8</ListNumber>
                이듬해 가을이면 2촉이 되고, 이를 분주해 2개로 나누어 심는다.
              </ListContentRow>
              <ListContentRow>
                <ListNumber>9</ListNumber>
                3년 차 가을이면 4~5촉이 되고, 1~3촉을 직접 aT 경매,
                모기업(관유정 등), 유통상 위탁판매 등으로 출하해 본전을
                회수한다.
              </ListContentRow>
              <ListContentRow>
                <ListNumber>10</ListNumber>
                대부분 2촉이상이 잉여되며, 1촉은 출하해 보름달 등 국가대표
                20품종으로 포트폴리오 하여 작품활동과 컬렉션 활동을 한다.
              </ListContentRow>
              <ListContentRow>
                <ListNumber>11</ListNumber>한 촉을 매년 가을 2촉이 되면, 한 촉을
                출하해 연금수령을 한다.
              </ListContentRow>
              <ListContentRow>
                <ListNumber>12</ListNumber>
                본전 후, 만약 시장 급변수에 의해 가격이 급락하면 한 해를
                출하하지 않고 나누어 2트랙으로 운영하면 연금확보가 된다.
              </ListContentRow>
            </ListContent>
          </List>
        </PointBox>
        <PointBox
          style={{
            backgroundColor: Green600,
          }}
        >
          <List
            style={{
              borderTop: "1px solid White",
              marginTop: windowSize.innerWidth <= 1439 ? 56 : 32,
              marginBottom: windowSize.innerWidth <= 1439 ? 50 : 0,
            }}
          >
            <ListLabel style={{ color: White }}>04</ListLabel>
            <ListTitle style={{ color: White }}>
              반반 지렛대 프로그램의 기대효과
            </ListTitle>
            <ListContent
              style={{ color: White }}
            >{`1억짜리 종자라 하여도 모기업(관유정 등) 50% 지분과 참여농가 지분인 50%는 교육 동기생들 세 분이 협업하면 각 1666만원이면
지분 분할 후 1.5년이면 모두가 1억짜리 1촉 씩을 확보할 수 있게되어 자본금이 열악한 농가들도 쉽게 부업에 성공할 수 있게 된다.  `}</ListContent>
          </List>
        </PointBox>
        <PointBox style={{ backgroundColor: Gray100, marginTop: 0 }}>
          <List
            style={{
              marginTop: windowSize.innerWidth <= 1439 ? 56 : 32,
              marginBottom: windowSize.innerWidth <= 1439 ? 50 : 0,
            }}
          >
            <ListLabel>05</ListLabel>
            <ListTitle>반반 지렛대와 단독의 차이점</ListTitle>
            <ListContent
              style={{
                flexDirection: windowSize.innerWidth <= 767 ? "column" : "row",
                display: "flex",
                marginTop: 32,
              }}
            >
              <CardWithIcon3
                viewStyle={{
                  backgroundColor: White,
                  marginRight: windowSize.innerWidth <= 767 ? 0 : 8,
                  marginBottom: windowSize.innerWidth <= 767 ? 16 : 0,
                  minHeight: windowSize.innerWidth <= 767 ? 440 : 428,
                  paddingTop: 1,
                }}
                title="반반 지렛대 시스템"
                content={`반반은 모기업(관유정 등)에서 한 해를 생산하므로 섬세하게 관리할 수 있으며,
농가가 직접 난초를 매일같이 볼 수 없다.  
그러나 절 반의 비용으로 시작할 수 있는 장점과 모기업(관유정 등)의 기술력 여하에 따라
안전도(들여올 때 보다 20%이상 상품성 증가)가 훨씬 더 높아질 수 있다.               
`}
                iconBoxStyle={{
                  backgroundColor: Green100,
                  top: "auto",
                  right: 24,
                  bottom: 24,
                  width: 175,
                  height: 175,
                }}
                iconStyle={{
                  width: 99,
                  height: 99,
                }}
                iconImg={Valance}
              />
              <CardWithIcon3
                viewStyle={{
                  backgroundColor: White,
                  marginLeft: windowSize.innerWidth <= 767 ? 0 : 8,
                  minHeight: windowSize.innerWidth <= 767 ? 440 : 428,
                  paddingTop: 1,
                }}
                title="반반 지렛대 시스템"
                content={`단독은 참여농가가 직접 개인의 난실에서 기를 수 있으므로 섬세하게 관리할 수 있으며,
직접 난초를 매일 같이 볼 수 있다. 그리고 가을 2촉이 되었을 때, 한 촉을 출하해 보름달 등
국가대표 20품종으로 포트폴리오하여 작품활동과 컬렉션 활동을 할 수 있다.
또한 예컨대 천종을 계약해 5개월 후 2촉이 되었을 때 한촉을 출하해 천종에 버금가는
또다른 천종으로 포트폴리오 하여 2트랙으로 운영하면 안전도가 200%로 증가하게 된다. 
`}
                iconBoxStyle={{
                  backgroundColor: Green100,
                  top: "auto",
                  right: 24,
                  bottom: 24,
                  width: 175,
                  height: 175,
                }}
                iconStyle={{
                  width: 99,
                  height: 99,
                }}
                iconImg={Plant}
              />
            </ListContent>
          </List>
        </PointBox>
        <PointBox style={{ backgroundColor: Gray100, marginTop: 0 }}>
          <List>
            <ListLabel>06</ListLabel>
            <ListTitle>본전회수를 위한 최선의 판로 프로세스</ListTitle>
            <ListContent>{` - 연 20% 이상의 수익을 원하는 주변 지인들 난초 영농에 참여할 수 있게 권장
 - 대한민국 난문화 진흥원에서 실시하는 부업 양성과정 2급 자격 과정 참여
 - 교육을 통해 난초의 재배 생리 및 여러 기술을 숙지하여 지역으로 돌아간다. 
 - 본인이 기르던 전략품종을 추천한다.
 - 이 때는 모기업(관유정 등)에서 판매하는 시세의 50~60%로 판매의뢰 한다.(이 때는 품질이 매우 좋아야 한다.)
 - 이렇게 되면 자신의 지분으로 잉여된 투자금액의 2배인 4촉 중, 한 촉만 출하하여도 본전을 쉽게 회수할 수 있게 된다. 
 * 이렇게 참여농가의 권유로 참여한 또다른 참여농가는 투자금 자체가 모기업(관유정 등) 시세의 50% 수준이므로 시작 자체가 반반으로 하게 되므로 이분 또한
    수월하게 본전을 조기에 회수하고 3촉을 남길 수 있어 매우 좋다.
 * 이렇게 되면 시장점유율 99%에 해당되는 관유정 출하가의 50%선인 불량품들과의 경쟁에서도 유리해져 늘 고수익을 발생시킬 수 있다. 
 * 만약 전략품종의 가격 유지에 불안요소가 감지되면 다른 품종으로 전환하면 더 안전하게 연금을 확보할 수 있다. 
 - aT.센터 경매장에 가서 판매를 하면 1.5~2촉이면 품질만 좋다면 어떠한 경우라도 본전을 쉽게 회수할 수 있게 된다.
 - 마지막으로 2년 전, 품종을 들여온 모기업(관유정 등)에 수수료 20%의 위탁 판매를 의뢰하면 쉽게 본전을 회수할 수 있게 된다. 
 * 만에 하나, 판매되지 않는다면 성촉을 대주로 길러 꽃을 2송이 달아 큰 대회에 출전하거나 유통상에 위탁판매하면 훨씬 더 큰 수익을 거둘 수 있다. `}</ListContent>
          </List>
        </PointBox>
      </Content>
    </BasicContainer>
  );
};

export default HalfToHalf;

import React, { useState } from "react";
import styled from "styled-components";
import BasicContainer from "../../../components/BasicContainer";
import { CardWithIcon2 } from "../../../components/Card";
import PageTop from "../../../components/PageTop";
import { Black, Green100, Green600 } from "../../../config/color";

import { useNavigate } from "react-router-dom";
import { getWindowSize } from "../../../utils";
import { useEffect } from "react";

const Valance = require("../../../assets/icon/Valance99.png");
const Plant = require("../../../assets/icon/Plant99.png");

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1439px) {
    min-width: 320px;
    width: 100%;
  }
`;

const RowDiv = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  width: 1328px;
  margin: 0 auto;

  @media screen and (max-width: 1439px) {
    flex-direction: column;
    width: calc(100% - 40px);
    padding: 0 20px;
    align-items: center;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 72px;

  @media screen and (max-width: 1439px) {
    align-items: center;
    width: 100%;
  }
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  color: ${Black};
  white-space: pre-wrap;
  line-height: 51px;
  text-align: center;
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 28.8px;
  white-space: pre-wrap;
`;

const SuccessRoadMap = (props) => {
  const navigate = useNavigate();

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <BasicContainer metaTitle="관유정-성공로드맵">
      <Content>
        <PageTop
          label="영농지원"
          title="성공로드맵"
          ImageUrl="/assets/image/FarmHeaderImg.png"
        />
        <RowDiv>
          <TitleBox>
            <TitleLabel>영농지원</TitleLabel>
            <TitleText>
              {windowSize.innerWidth <= 1439
                ? `관유정만의 특별한 제도,
성공로드맵`
                : `관유정만의 특별한 제도, 성공로드맵`}
            </TitleText>
          </TitleBox>
        </RowDiv>
        <RowDiv style={{ marginTop: 40, marginBottom: 131 }}>
          <CardWithIcon2
            viewStyle={{
              backgroundColor: Green100,
              marginRight: windowSize.innerWidth <= 1439 ? 0 : 8,
              width: windowSize.innerWidth <= 767 ? 320 : 656,
              minHeight: windowSize.innerWidth <= 767 ? 502 : 306,
              marginBottom:
                windowSize.innerWidth <= 767
                  ? 32
                  : windowSize.innerWidth > 767 && windowSize.innerWidth <= 1439
                  ? 16
                  : 0,
            }}
            iconBoxStyle={{
              top: windowSize.innerWidth > 767 ? 70 : "auto",
              right: windowSize.innerWidth > 767 ? 34 : 72.5,
              bottom: windowSize.innerWidth > 767 ? "auto" : 98,
              width: 175,
              height: 175,
            }}
            iconStyle={{
              width: 99,
              height: 99,
            }}
            buttonStyle={{
              width: windowSize.innerWidth <= 767 ? "calc(100% - 48px)" : 235,
              position: windowSize.innerWidth <= 767 ? "absolute" : "relative",
              bottom: windowSize.innerWidth <= 767 ? 24 : "auto",
            }}
            title="반반 지렛대 시스템"
            content={
              windowSize.innerWidth <= 767
                ? `2급 자격 취득생들을 위한 우대 시스템으로 취약계층, 자신감 결여, 원금 손해, 판로가 걱정되는 분, 3040 등 젊은 분들을 위한 맞춤 시스템`
                : `2급 자격 취득생들을 위한 우대 시스템으로
취약계층, 자신감 결여, 원금 손해, 판로가 걱정되는 분,
3040 등 젊은 분들을 위한 맞춤 시스템                
`
            }
            iconImg={Valance}
            onClickEvent={() => {
              navigate("/farm/successroadmap/halftohalf");
            }}
          />
          <CardWithIcon2
            viewStyle={{
              backgroundColor: Green100,
              marginLeft: windowSize.innerWidth <= 1439 ? 0 : 8,
              width: windowSize.innerWidth <= 767 ? 320 : 656,
              minHeight: windowSize.innerWidth <= 767 ? 502 : 306,
            }}
            iconBoxStyle={{
              top: windowSize.innerWidth > 767 ? 70 : "auto",
              right: windowSize.innerWidth > 767 ? 34 : 72.5,
              bottom: windowSize.innerWidth > 767 ? "auto" : 98,
              width: 175,
              height: 175,
            }}
            iconStyle={{
              width: 99,
              height: 99,
            }}
            buttonStyle={{
              width: windowSize.innerWidth <= 767 ? "calc(100% - 48px)" : 235,
              position: windowSize.innerWidth <= 767 ? "absolute" : "relative",
              bottom: windowSize.innerWidth <= 767 ? 24 : "auto",
            }}
            title="단독 시스템"
            content={
              windowSize.innerWidth <= 767
                ? `단독으로 구매하여 본인의 난실(집 베란다 등)로 가져감 1촉을 구매하여 2촉이 되면, 다른 품종으로 전환하여 포트폴리오 변경으로 안전도를 높일 수 있음`
                : `단독으로 구매하여 본인의 난실(집 베란다 등)로 가져감
1촉을 구매하여 2촉이 되면, 다른 품종으로 전환하여
포트폴리오 변경으로 안전도를 높일 수 있음`
            }
            iconImg={Plant}
            onClickEvent={() => {
              navigate("/farm/successroadmap/halftohalf");
            }}
          />
        </RowDiv>
      </Content>
    </BasicContainer>
  );
};

export default SuccessRoadMap;

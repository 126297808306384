import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

import BasicContainer from "../../../components/BasicContainer";
import { ButtonA, ButtonB } from "../../../components/Button";
import { TF_B_EXPAND } from "../../../components/TextField";
import { Black, Gray500, Green600 } from "../../../config/color";
import { getWindowSize } from "../../../utils";

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 56px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: ${Green600};
`;

const Intro = styled.div`
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  font-weight: 600;
  line-height: 51px;
  text-align: center;
  color: ${Black};
  margin-top: 8px;
  margin-bottom: 56px;
  white-space: pre-wrap;
  @media screen and (max-width: 767px) {
    font-size: 26px;
    line-height: 36px;
  }
`;

const ForgetBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const LinkPage = styled(Link)`
  font-weight: 700;
  font-size: 14px;
  line-height: 180%;
  color: ${Gray500};
  text-decoration: none;
`;

const Signin = (props) => {
  const navigate = useNavigate();

  const [userId, setUserId] = useState("");
  const [userPw, setUserPw] = useState("");

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const moveToSignup = () => {
    navigate("/signup");
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <BasicContainer metaTitle="관유정-로그인">
      <Content>
        <Title>로그인</Title>
        <Intro>{`어서오세요
이대발난초입니다`}</Intro>
        <TF_B_EXPAND
          val={userId}
          setVal={setUserId}
          required={false}
          label="아이디"
          viewStyle={{
            width: windowSize.innerWidth <= 767 ? 320 : 490,
            marginBottom: 24,
          }}
          inputStyle={{
            width: windowSize.innerWidth <= 767 ? 320 - 34 : 490,
          }}
          labelStyle={windowSize.innerWidth <= 767 ? { width: 320 } : null}
          placeholder="아이디 입력"
          inputType="text"
        />
        <TF_B_EXPAND
          val={userPw}
          setVal={setUserPw}
          required={false}
          label="비밀번호"
          viewStyle={{
            width: windowSize.innerWidth <= 767 ? 320 : 490,
            marginBottom: 56,
          }}
          inputStyle={{ width: windowSize.innerWidth <= 767 ? 320 - 34 : 490 }}
          labelStyle={windowSize.innerWidth <= 767 ? { width: 320 } : null}
          placeholder="비밀번호 입력"
          inputType="password"
        />
        <ButtonB text="로그인" viewStyle={{ marginBottom: 16 }} />
        <ButtonA
          text="회원가입"
          viewStyle={{ marginBottom: 40 }}
          onClickEvent={moveToSignup}
        />
        <ForgetBox>
          <LinkPage to="/" style={{ marginRight: 32 }}>
            아이디 찾기
          </LinkPage>
          {"|"}
          <LinkPage to="/" style={{ marginLeft: 32 }}>
            비밀번호 찾기
          </LinkPage>
        </ForgetBox>
      </Content>
    </BasicContainer>
  );
};

export default Signin;

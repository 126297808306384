import React, { useEffect, useState } from "react";
import styled from "styled-components";

import BasicContainer from "../../../components/BasicContainer";

import {
  Black,
  Gray100,
  Gray200,
  Gray300,
  Gray400,
  Gray500,
  Green600,
  White,
} from "../../../config/color";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getWindowSize, toStringByFormatting } from "../../../utils";

const MasterImg = require("../../../assets/image/MasterImg283.png");

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1439px) {
    min-width: 360px;
    width: calc(100% - 40px);
    padding: 0 20px;
  }
`;

const RowDiv = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 1328px;
  margin: 0 auto;

  @media screen and (max-width: 1439px) {
    width: 100%;
    flex-direction: column;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 72px;
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  color: ${Black};
  white-space: pre-wrap;
  line-height: 51px;
  word-break: keep-all;

  @media screen and (max-width: 1439px) {
    font-size: 24px;
    line-height: 34px;
  }
`;

const AdminEventBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 0;
  margin-left: auto;
`;

const AdminEvent = styled(Link)`
  font-weight: 700;
  font-size: 14px;
  line-height: 25px;
  color: ${Gray400};
  margin-left: 10px;
  text-decoration: none;
`;

const List = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 1328px;
  margin: 0 auto;
  margin-top: 40px;
  border-top: 1px solid ${Gray300};

  @media screen and (max-width: 1439px) {
    width: 100%;
  }
`;

const ListSubInfo = styled.div`
  display: flex;
  position: absolute;
  top: -38px;
  right: 0;
  align-items: flex-end;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: ${Gray400};
  z-index: 999;

  @media screen and (max-width: 1439px) {
    position: relative;
    top: auto;
    right: auto;
  }
`;

const ListTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Black};
  margin-bottom: 16px;
`;

const ListContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  white-space: pre-wrap;
`;

const Box = styled.div`
  flex: 1;
  height: calc(188px - 48px);
  padding: 24px;
  border-radius: 10px;
  background-color: ${Gray100};
  display: flex;
  flex-direction: column;
  position: relative;

  @media screen and (max-width: 1439px) {
    height: auto;
  }
`;

const BoxRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
`;

const BoxLabel = styled.div`
  width: 60px;
  min-width: 60px;
  margin-right: 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
`;

const BoxText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  white-space: pre-wrap;
`;

const MobileTableBox = styled.div`
  @media screen and (max-width: 1439px) {
    width: 100%;
    overflow-x: auto;
    margin-bottom: 42px;
  }
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1439px) {
    width: 1328px;
  }
`;

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const TableNumber = styled.div`
  width: calc(71px - 2px);
  height: calc(50px - 2px);
  border: 1px solid ${Gray200};
  background-color: ${Green600};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${White};
`;

const TableTime = styled.div`
  width: calc(181px - 2px);
  height: calc(50px - 2px);
  border: 1px solid ${Gray200};
  background-color: ${Gray100};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Gray500};
`;

const TableContent = styled.div`
  width: calc(1076px - 34px);
  height: calc(50px - 2px);
  border: 1px solid ${Gray200};
  background-color: ${White};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Gray500};
`;

const Lecturer = styled.img`
  width: 283px;
  height: 283px;
  position: absolute;
  top: 47px;
  right: 24px;

  @media screen and (max-width: 1439px) {
    position: relative;
    top: auto;
    right: auto;
    width: 251px;
    height: 251px;
    margin: 40px auto;
  }
`;

const MobileListRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const curriculumList = [
  {
    time: "09:00~09:00",
    content: "강사 소개 및 친교의 장",
  },
  {
    time: "09:00~09:00",
    content: "촬영",
  },
  {
    time: "09:00~09:00",
    content: "강좌 개설의 의미와 시대적 환경과 100만 도시농업 일자리의 신호탄",
  },
  {
    time: "09:00~09:00",
    content:
      "유튜브 - 강창희 - 예금 2억보다 월급 50만원 일자리를 찾아라 (편집본)",
  },
  {
    time: "09:00~09:00",
    content: "유튜브 - 인플레이션으로 이득을 얻는 사람은 어떤 사람들일까? ",
  },
  {
    time: "09:00~09:00",
    content: "유튜브 - 국민연금 30년 비켜라! 10배짜리 난이 납신다",
  },
  {
    time: "09:00~09:00",
    content: "휴식 20분",
  },
  {
    time: "09:00~09:00",
    content:
      "여러분들이 가장 우려하는 4대 고민은? - 죽는다, 내린다, 안 팔린다, 어렵다",
  },
  {
    time: "09:00~09:00",
    content:
      "각종 지원 시스템 - 사망시 원금 보장 보험, OEM 안심 바우처, 위탁 대리 돌봄",
  },
  {
    time: "09:00~09:00",
    content: "이젠 아무도 손실이 나지 않는 시절이다",
  },
  {
    time: "09:00~09:00",
    content:
      "그런데 과거는 왜 대부분 부업에 실패했나? 취미와 부업의 구분이 없었다",
  },
  {
    time: "09:00~09:00",
    content:
      "불량품, 기대 품, 기술 부족, 교육 부족, 시스템 지원 부족, 병원과 학교가 무",
  },
  {
    time: "09:00~09:00",
    content:
      "한국의 교육지원 시스템 한국의 의료지원 시스템 인클 무료 강좌 소개",
  },
];

const OutsideLectureDetail = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { data } = state;

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <BasicContainer metaTitle="관유정-외부강의">
      <Content>
        <RowDiv style={{ marginTop: windowSize.innerWidth <= 1439 ? 36 : 72 }}>
          <TitleBox
            style={{
              marginTop: 0,
              marginBottom: windowSize.innerWidth <= 1439 ? 26 : 0,
            }}
          >
            <TitleText style={{ marginTop: 0 }}>{data.title}</TitleText>
          </TitleBox>
          {/* 사용자가 관리자일 경우에만 보이기 */}
          {windowSize.innerWidth <= 1439 ? null : (
            <AdminEventBox>
              <AdminEvent to="/admin">수정</AdminEvent>
              <AdminEvent to="/admin">삭제</AdminEvent>
            </AdminEventBox>
          )}
          {windowSize.innerWidth <= 1439 ? (
            <>
              <MobileListRow>
                <ListSubInfo style={{ marginRight: "auto" }}>
                  {data.writer.role === "Admin" ? "관리자" : data.writer.name}
                </ListSubInfo>
                <AdminEventBox>
                  <AdminEvent to="/admin">수정</AdminEvent>
                  <AdminEvent to="/admin">삭제</AdminEvent>
                </AdminEventBox>
              </MobileListRow>
              <ListSubInfo style={{ marginTop: 10 }}>
                {toStringByFormatting(data.createdAt)}
              </ListSubInfo>
            </>
          ) : null}
        </RowDiv>
        <List style={{ marginTop: windowSize.innerWidth <= 1439 ? 12 : 56 }}>
          {windowSize.innerWidth <= 1439 ? null : (
            <>
              <ListSubInfo style={{ left: 0 }}>
                {data.writer.role === "Admin" ? "관리자" : data.writer.name}
              </ListSubInfo>
              <ListSubInfo>{toStringByFormatting(data.createdAt)}</ListSubInfo>
            </>
          )}
          <ListTitle style={{ marginTop: 35 }}>개요</ListTitle>
          <ListContent>
            <Box>
              <BoxRow>
                <BoxLabel>강의명</BoxLabel>
                <BoxText>월 200 난초 연금 이게 가능한가?</BoxText>
              </BoxRow>
              <BoxRow>
                <BoxLabel>출강일</BoxLabel>
                <BoxText>2022-05-20 오전 8시 ~ 오후 3시</BoxText>
              </BoxRow>
              <BoxRow>
                <BoxLabel>참여인원</BoxLabel>
                <BoxText>15~20명</BoxText>
              </BoxRow>
              <BoxRow>
                <BoxLabel>출강지역</BoxLabel>
                <BoxText>
                  대구카톨릭대학교 평생교육원 (대구시 달서구 용산로 24길 17
                  교육원)
                </BoxText>
              </BoxRow>
            </Box>
          </ListContent>
        </List>
        <List style={{ marginTop: 56, borderTop: 0 }}>
          <ListTitle>강의 커리큘럼</ListTitle>
          <ListContent>
            <MobileTableBox>
              <Table>
                {curriculumList.map((item, idx) => {
                  return (
                    <TableRow>
                      <TableNumber>{idx + 1}</TableNumber>
                      <TableTime>{item.time}</TableTime>
                      <TableContent>{item.content}</TableContent>
                    </TableRow>
                  );
                })}
              </Table>
            </MobileTableBox>
          </ListContent>
        </List>
        <List style={{ marginTop: 56, marginBottom: 78, borderTop: 0 }}>
          <ListTitle>강사 소개</ListTitle>
          <ListContent>
            <Box style={{ height: "auto" }}>
              <BoxRow>
                <BoxLabel>강사명</BoxLabel>
                <BoxText>이대발</BoxText>
              </BoxRow>
              <BoxRow>
                <BoxLabel>소속</BoxLabel>
                <BoxText>대한민국난문화진흥원</BoxText>
              </BoxRow>
              <BoxRow>
                <BoxLabel>직위</BoxLabel>
                <BoxText>원장</BoxText>
              </BoxRow>
              <BoxRow>
                <BoxLabel>학력</BoxLabel>
                <BoxText>대구가톨릭대학 농학박사</BoxText>
              </BoxRow>
              <BoxRow>
                <BoxLabel>경력</BoxLabel>
                <BoxText>{`고용노동부 - 대한민국 농업 난초 명장 
농림축산식품부 - 농림축산식품과학기술대상
대한민국난문화진흥원 - 유튜브 난테크 TV 운영
대한민국난문화진흥원 - 1-2급 자격 과정 운영`}</BoxText>
              </BoxRow>
              <BoxRow>
                <BoxLabel>연구실적</BoxLabel>
                <BoxText>{`한국 춘란 가이드북 입문편 / 전문가 편
반려식물 난초 재테크`}</BoxText>
              </BoxRow>
              <Lecturer src={MasterImg} />
            </Box>
          </ListContent>
        </List>
      </Content>
    </BasicContainer>
  );
};

export default OutsideLectureDetail;

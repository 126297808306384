import React, { useState } from "react";
import styled from "styled-components";
import BasicContainer from "../../components/BasicContainer";

import {
  Black,
  Gray100,
  Gray300,
  Gray400,
  Gray500,
  Green100,
  Green600,
} from "../../config/color";
import { ButtonB } from "../../components/Button";
import { useLocation, useNavigate } from "react-router-dom";

const SHOP_ICON = require("../../assets/icon/Shop71.png");

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 767px) {
    min-width: 360px;
    width: calc(100% - 40px);
    padding: 0 20px;
  }
`;

const Icon = styled.img`
  width: 71px;
  height: 71px;
  margin-top: 87px;
`;

const IconLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  margin-top: 19px;
  color: ${Green600};
`;

const Title = styled.div`
  font-family: "Nanum Myeongjo", serif;
  font-weight: 600;
  font-size: 26px;
  line-height: 36.4px;
  color: ${Black};
  margin-top: 2px;
`;

const TotalBox = styled.div`
  background-color: ${Gray100};
  height: calc(182px - 24px);
  width: calc(456px - 48px);
  padding: 18px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: 700;
  line-height: 28.8px;
  color: ${Gray500};
  border-radius: 5px;

  @media screen and (max-width: 767px) {
    width: calc(100% - 48px);
    max-width: calc(456px - 48px);
  }
`;

const TotalResultText = styled.div`
  margin-left: 16px;
  font-size: 20px;
  font-weight: 700;
  line-height: 36px;
  color: ${Black};
`;

const TotalPriceList = styled.div`
  margin-left: 0;
  margin-right: auto;
  display: flex;
  flex-direction: column;
`;

const PriceList = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${Gray400};
`;

const PriceLabel = styled.div`
  width: 60px;
  margin-right: 24px;
  text-align: left;
`;

const Price = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${Black};
`;

const TotalPrice = styled.div`
  width: 100%;
  padding: 12px 0;
  margin-top: 24px;
  border-top: 1px solid ${Gray300};
  display: flex;
  flex-direction: row;
`;

const AccountBox = styled.div`
  width: calc(456px - 48px);
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: ${Green100};
  margin-top: 16px;
  border-radius: 5px;

  @media screen and (max-width: 767px) {
    width: calc(100% - 48px);
    max-width: calc(456px - 48px);
  }
`;

const AccountText = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 28.8px;
  color: ${Black};
  margin-bottom: 4px;
`;

const AccountInfo = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
  word-break: keep-all;
`;

const OrderComplete = (props) => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const done = () => {
    navigate("/mypage/orderdetail");
  };

  return (
    <BasicContainer metaTitle="관유정-주문완료">
      <Content>
        <Icon src={SHOP_ICON} />
        <IconLabel>결제완료</IconLabel>
        <Title>상품 구매가 완료되었습니다</Title>
        <TotalBox style={{ marginTop: 20 }}>
          <TotalPriceList>
            <PriceList style={{ marginBottom: 12 }}>
              <PriceLabel>주문금액</PriceLabel>
              <Price>{state.orderPrice}</Price>
            </PriceList>
            <PriceList style={{ marginBottom: 12 }}>
              <PriceLabel>배송비</PriceLabel>
              <Price>0원</Price>
            </PriceList>
            <PriceList>
              <PriceLabel>포인트</PriceLabel>
              <Price>0점</Price>
            </PriceList>
          </TotalPriceList>
          <TotalPrice>
            총금액
            <TotalResultText>{state.total}</TotalResultText>
          </TotalPrice>
        </TotalBox>
        <AccountBox>
          <AccountText>농협 011-5555-5555-08 예금주 이대건</AccountText>
          <AccountInfo>
            3일 이내에 입금이 이루어지지 않으면 주문이 취소됩니다.
          </AccountInfo>
        </AccountBox>
        <ButtonB
          viewStyle={{ width: 262, height: 50, marginTop: 24 }}
          text="주문 내역 확인"
          onClickEvent={done}
        />
      </Content>
    </BasicContainer>
  );
};

export default OrderComplete;

import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import { White } from "../../../config/color";
import { getWindowSize } from "../../../utils";

const slideImages = [
  {
    url: "/assets/tmpImg/gyj01.png",
    title: "2022년 겨울의 관유정",
    size: "cover",
  },
  {
    url: "/assets/tmpImg/gyjslide01.png",
    title: "달서구 진천동 1989-1990",
    size: "contain",
  },
  {
    url: "/assets/tmpImg/gyjslide02.png",
    title: "달서구 도원동 100번지 1997-",
    size: "contain",
  },
  {
    url: "/assets/tmpImg/gyjslide03.png",
    title: "수성구 지산동 881-1번지",
    size: "contain",
  },
];

const Container = styled.div`
  width: 100%;
  height: 338px;
  display: flex;
`;

const SliderBox = styled.div`
  width: calc(100% - 112px);
  height: 338px;
  padding: 0 56px;
  position: relative;

  @media screen and (max-width: 1439px) {
    width: 100%;
    padding: 0;
  }
`;

const SliderImage = styled.div`
  width: 100%;
  height: 338px;
  position: relative;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-position: center right;

  @media screen and (max-width: 1439px) {
    width: auto;
    padding: 0;
  }
`;

const SlideImageCover = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 338px;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.65) 0%,
    rgba(0, 0, 0, 0) 50%
  );
  border-radius: 20px;
`;
const SlideImageCover2 = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 1120px;
  height: 338px;
  background: linear-gradient(90deg, #30312e 77.23%, rgba(48, 49, 46, 0) 100%);
  border-radius: 20px;

  @media screen and (max-width: 1439px) {
    width: 100%;
    background: linear-gradient(90deg, #30312e 0%, rgba(48, 49, 46, 0) 20%);
  }
`;
const SlideImageCover3 = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 1200px;
  height: 338px;
  background: linear-gradient(
    90deg,
    #d9d9d9 26.13%,
    rgba(217, 217, 217, 0) 93.84%
  );
  border-radius: 20px;

  @media screen and (max-width: 1439px) {
    width: 100%;
    background: linear-gradient(90deg, #30312e 0%, rgba(48, 49, 46, 0) 20%);
  }
`;
const SlideImageCover4 = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 1100px;
  height: 338px;
  background: linear-gradient(
    90deg,
    #d9d9d9 26.13%,
    rgba(217, 217, 217, 0) 93.84%
  );
  border-radius: 20px;

  @media screen and (max-width: 1439px) {
    width: 100%;
    background: linear-gradient(90deg, #30312e 0%, rgba(48, 49, 46, 0) 20%);
  }
`;

const SliderContent = styled.div`
  position: absolute;
  bottom: 23px;
  left: 43px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
  color: ${White};
  white-space: pre-line;
`;

const SliderImageBox = styled.div`
  border-radius: 20px;
`;

const GyjSlider = (props) => {
  const properties = useRef({
    duration: 5000,
    autoplay: true,
    transitionDuration: 500,
    arrows: false,
    infinite: true,
    easing: "ease",
  });
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <Container>
      <SliderBox className="slide-container">
        <Slide {...properties.current}>
          {slideImages.map((slideImage, index) => (
            <SliderImageBox className="each-slide" key={index}>
              <SliderImage
                style={{
                  backgroundImage: `url(${slideImage.url})`,
                  backgroundSize:
                    windowSize.innerWidth <= 1439
                      ? `auto 100%`
                      : `${slideImage.size}`,
                }}
              >
                {index === 0 ? <SlideImageCover></SlideImageCover> : null}
                {index === 1 ? <SlideImageCover2></SlideImageCover2> : null}
                {index === 2 ? <SlideImageCover3></SlideImageCover3> : null}
                {index === 3 ? <SlideImageCover4></SlideImageCover4> : null}
                <SliderContent>
                  <Title>{slideImage.title}</Title>
                </SliderContent>
              </SliderImage>
            </SliderImageBox>
          ))}
        </Slide>
      </SliderBox>
    </Container>
  );
};

export default GyjSlider;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BasicContainer from "../../../components/BasicContainer";
import { TAB } from "../../../components/Tab";
import { Black, Green600 } from "../../../config/color";
import GyjSlider from "./GyjSlider";

import CI from "./CI";
import Certificate from "./Certificate";
import Volunteer from "./Volunteer";
import { getWindowSize } from "../../../utils";

const GYJIcon = require("../../../assets/icon/GyjIcon.png");

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1439px) {
    min-width: 360px;
    width: calc(100% - 40px);
    padding: 0 20px;
  }
`;

const TabContent = styled.div`
  display: flex;
  width: 100%;
  min-width: 1440px;
  position: relative;
  flex-direction: column;

  @media screen and (max-width: 1439px) {
    min-width: 0;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 112px);
  padding: 0 56px;
  margin-top: 74px;

  @media screen and (max-width: 1439px) {
    margin-top: 0;
    width: 100%;
    padding: 0;
  }
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};

  @media screen and (max-width: 1439px) {
    margin-top: 40px;
    width: 100%;
  }
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  color: ${Black};
  white-space: pre-wrap;

  @media screen and (max-width: 1439px) {
    font-size: 26px;
    line-height: 29px;
  }
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 28.8px;
  white-space: pre-wrap;
  margin-top: 16px;
`;

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media screen and (max-width: 1439px) {
    flex-direction: column;
  }
`;

const ImageBox = styled.div`
  width: 584px;
  height: 414px;
  margin-right: 40px;
  margin-left: 56px;
  border-radius: 10px;
  overflow: hidden;

  @media screen and (max-width: 1439px) {
    margin: 0;
    width: 100%;
    height: auto;
  }
`;

const Image01 = styled.img`
  width: 488px;
  height: 414px;

  @media screen and (max-width: 1439px) {
    width: 100%;
    height: auto;
  }
`;

const Image02 = styled.img`
  width: 719px;
  height: 340px;

  @media screen and (max-width: 1439px) {
    width: 100%;
    height: auto;
  }
`;

const Icon01 = styled.img`
  width: 100%;
  height: 177px;
  margin: 176px 0;

  @media screen and (max-width: 1439px) {
    width: calc(100% + 40px);
    height: auto;
    margin-top: 56px;
    margin-bottom: 40px;
    margin-left: -20px;
  }
`;

const GYJ = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <BasicContainer metaTitle="관유정-소개">
      <Content>
        <TAB
          tabList={[
            { name: "관유정" },
            { name: "CI" },
            { name: "특허와 자격증" },
            { name: "사회봉사" },
          ]}
          viewStyle={{
            marginTop: windowSize.innerWidth <= 1439 ? 28 : 32,
            marginBottom: windowSize.innerWidth <= 1439 ? 24 : 42,
          }}
          tabStyle={{
            width:
              windowSize.innerWidth <= 1439
                ? windowSize.innerWidth / 4 - 18
                : 152,
            paddingTop: 0,
            paddingBottom: 9,
            paddingLeft: windowSize.innerWidth <= 767 ? 8 : 26,
            paddingRight: windowSize.innerWidth <= 767 ? 8 : 26,
          }}
          activeTabIdx={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <TabContent>
          <GyjSlider />
          {selectedTab === 0 ? (
            <>
              <TitleBox style={{ marginBottom: 88 }}>
                <TitleLabel>관유정</TitleLabel>
                <TitleText>
                  한국을 대표하는 춘란농장,
                  {windowSize.innerWidth <= 1439 ? <br /> : null}관유정
                </TitleText>
                <Text>{`고객 여러분 반갑습니다. 관유정 대표 이대건입니다. 저는 어릴 때부터 농업에 관심이 많아 오늘 이 자리에까지 오게 되었습니다. 원예학도의 길을 평생 걸어오며 한 사람의 농업인으로써의높은 긍지와 자부심으로 살아왔습니다. 중학교 농업 과목이 너무 좋아서 4H 활동을 하였고, 농고 원예과에 진학해 영농 후계자의 길을 선택해 고등학교 시절에 농업 경영을 했습니다.특히, 제가 밴치마킹을 한 일본 동경의 흥화원은 그림 같은 유리온실이었었는데,나도 언젠가는 유리온실을 짓고 말 거야 라는 큰 포부를 가졌었습니다.시설 원예 인들은 누구나 네덜란드에 등장하는 자동화된 첨단 유리온실을 꿈꾸게 됩니다.2009년 길랑-바레 증후군에 걸려 약 3분간 사망 상태를 거쳐 집중 중환자실에서 두 번의 사선을 넘나들어 가며,고통스러운 제활 과정을 이겨내고 재발하면 죽는다는 주치의의 말을 듣고서 목발에 의지해 병원을 나서던 날,저는 곧바로 집 근처 부동산으로 가 연구소 부지를 구해 달라고 부탁을 했습니다.보름쯤 후 지금의 부지와 인연을 맺었고 저는 사채 2억과 대출 6억을 받아 2009년 8월 15일 착공을 해 10.15일 준공식을 치렀고 2010. 1월 이대발 난 연구소 겸 관유정을 개소하게 되었습니다.전국의 저명한 대가 분들이 대거 참석을 해 많은 격려를 해 주셨습니다.저는 1989년 70만 원으로 출발해 지금에 와서는 5000배로 성장해 한국을 대표하는 춘란 농장이 되었습니다.`}</Text>
              </TitleBox>
              <RowDiv>
                <ImageBox>
                  <Image01 src="/assets/tmpImg/gyj02.png" />
                </ImageBox>
                <TitleBox style={{ margin: 0, padding: 0 }}>
                  <TitleLabel>사훈</TitleLabel>
                  <TitleText>{`신용은 지키는 것이 아니라
만들어지는 것이다.`}</TitleText>
                  <Text>
                    {`한국 춘란은 변이가 일어난 표준(민춘란)을 벗어난 유전체를 다루는 작물이다 보니
미래 예측이 순탄치 않고, 더러는 예상을 빗나가 소비자만족도가 낮아지기도 합니다.
이러한 이유에 따라 공급자가 진심과 양심을 다해 공급 했다 하더라도
공산 공산품이나 일반 농산물과는 달리 사고의 위험이 존재합니다.

다시 말 하자면 소비자 만족도를 높이려면 하늘의 도움과 깊은 경지의 과학적 기술과
아름다운 신념이 만나야만 가능해 집니다. 이른 이유의 염원을 담아
신용은 지키는 것이 아니라 만들어 지는 것이다 라는 사훈을 만들게 되었고
2012년『주간조선』에 소개가 되기도 했습니다.`}
                  </Text>
                </TitleBox>
              </RowDiv>
              <Icon01 src={GYJIcon} />
              <TitleBox
                style={{
                  textAlign: windowSize.innerWidth <= 1439 ? "left" : "center",
                  marginBottom: 55,
                }}
              >
                <TitleLabel>관유정</TitleLabel>
                <TitleText>관유정 이름의 유래</TitleText>
              </TitleBox>
              <RowDiv style={{ justifyContent: "center", marginBottom: 16 }}>
                <Image02 src="/assets/tmpImg/gyj03.png" />
              </RowDiv>
              <Text
                style={{
                  margin: windowSize.innerWidth <= 1439 ? 0 : 56,
                  marginTop: 0,
                  marginBottom: 294,
                }}
              >{`89년 6월 7일 진천원예라는 이름으로 창업하였는데 주먹구구식으로 하다보니 기술력 부재로 큰 좌절을 직면하게 되었습니다. 그래서 저는 난 상점을접고 한국의 제대로 된 전문가가 되기 위해 공부하려고 이리저리로 뛰어다니던 시절이었습니다. 당시 하나의 일환으로 『난초인식표(라벨)에 쓰는한자를 예쁘게 써 보려고』 대구시 달서구 성당동에 있는 천곡 서예원에서 먹을 갈며 시간을 보내던 시절이 있었습니다.1991년 초여름 원장님께 나의 꿈과 포부를 말씀드리며평생 내 이름처럼 사용할 수 있는 난 농장의 이름을 부탁했었습니다.1991년 여름 어느 날 아침원장님에게서 “이군 서실로 와보게 하고 전화가 왔었는데, 도착해 와보니 밤을새워 글을 쓰셨습니다. 탁자 아래는 수북이 쌓인 구겨서 버려버린 종이무덤이 있었고 탁자 위에 두 장의 글(당 호)이 있었습니다.하나는 지금의 관유정(寬裕亭) 다른 하나는 만화원(萬花園) 이었습니다.저는 아직도 그때를 잊지 못합니다. 저의 눈에 들어온 건 바로 관유정.....『너그럽고 넉넉한 사람들이 모이는 곳』 이라는 뜻의 관유정은 한눈에 맘에 들었습니다.한 번에 관유정을 찍었더니 원장님께서 이렇게 말씀 하셨습니다.이군 언젠가 성공할걸세 이름에 걸맞게 말이야 라고 말씀해 주던 때가 기억납니다.그리하여 89년 6월 7일 창업한 진천원예라는 이름을 뒤로하고 91년 6월 관유정으로 개칭해 1995년 3월 1일제가 결혼을 함과 동시에 2번째 창업을 한 난 상점의 간판에 사용하면서 2015년 현재까지 사용하고 있습니다.`}</Text>
            </>
          ) : null}
          {selectedTab === 1 ? <CI /> : null}
          {selectedTab === 2 ? <Certificate /> : null}
          {selectedTab === 3 ? <Volunteer /> : null}
        </TabContent>
      </Content>
    </BasicContainer>
  );
};

export default GYJ;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  GoogleMap,
  useJsApiLoader,
  MarkerClustererF,
  MarkerF,
  MarkerWithLabel,
  MarkerWithLabelF,
  DirectionsRenderer,
  OverlayView,
  OverlayViewF,
} from "@react-google-maps/api";

import BasicContainer from "../../../components/BasicContainer";
import PageTop from "../../../components/PageTop";

import {
  Black,
  Gray100,
  Gray300,
  Gray500,
  Green100,
  Green600,
  White,
} from "../../../config/color";
import { useNavigate } from "react-router-dom";
import VoucherFranchiseTable from "./VoucherFranchiseTable";
import { getWindowSize } from "../../../utils";

const Content = styled.div`
  display: flex;
  flex: 1;
  min-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1439px) {
    min-width: 360px;
    width: 100%;
    flex-direction: column;
  }
`;

const RowDiv = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 1328px;
  margin: 0 auto;

  @media screen and (max-width: 1439px) {
    width: calc(100% - 40px);
    padding: 0 20px;
    align-items: flex-start;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 72px;

  @media screen and (max-width: 1439px) {
    margin-top: 32px;
  }
`;

const TitleLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28.8px;
  color: ${Green600};
`;

const TitleText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-family: "Nanum Myeongjo", serif;
  font-size: 34px;
  color: ${Black};
  white-space: pre-wrap;
  line-height: 51px;

  @media screen and (max-width: 767px) {
    font-size: 26px;
    line-height: 36px;
  }
`;

const List = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 1328px;
  margin: 0 auto;
  margin-top: 40px;
  padding: 16px 0;
  border-top: 1px solid ${Gray300};

  @media screen and (max-width: 1439px) {
    width: calc(100% - 40px);
    padding: 16px 0;
    margin: 0 20px;
    align-items: flex-start;
  }
`;

const ListLabel = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Green600};
`;

const ListTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${Black};
  margin-bottom: 16px;
`;

const ListContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  white-space: pre-wrap;
  width: 100%;
`;
const KindListUl = styled.ul`
  padding-left: 32px;
  margin: 0;
`;

const KindListLi = styled.li`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
`;

const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 56px;
  align-items: center;
`;

const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  white-space: pre-wrap;
`;

const TableBox = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const center = { lat: 35.824440232895995, lng: 128.64589113376041 };
const options = {
  imagePath:
    "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m", // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
};

function createKey(location) {
  return location.lat + location.lng;
}
const getPixelPositionOffset = (offsetWidth, offsetHeight, labelAnchor) => {
  console.log();
  return {
    x: (offsetWidth / 2) * -1, // + labelAnchor.x,
    y: -70, //offsetHeight + labelAnchor.y,
  };
};

const labelSize = { width: 220 };
const labelPadding = 8;

const VoucherFranchise = () => {
  const navigate = useNavigate();

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAp19zNdXLbv_jvS3JPUEVT2DxNKTzk9lA",
  });

  const [map, setMap] = useState(null);
  const [locations, setLocations] = useState([
    {
      _id: "001",
      lat: 35.824440232895995,
      lng: 128.64589113376041,
      name: "관유정",
    },
    {
      _id: "002",
      lat: 35.888178,
      lng: 128.640323,
      name: "금마루",
    },
  ]);
  const [markerLabels, setMarkerLabels] = useState([]);

  const markerLabelsHandler = (clusterer) => {
    const markerLabelsList = [];
    let allClusters = clusterer.clusters,
      allMarkers;
    allClusters.forEach((cluster, clusterIndex) => {
      allMarkers = cluster.getMarkers();
      allMarkers.forEach((marker, MarkerIndex) => {
        if (allMarkers.length < 2) {
          // console.log(marker._id);
          markerLabelsList.push(
            <OverlayViewF
              key={marker.title}
              position={marker.position}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              getPixelPositionOffset={(x, y) =>
                getPixelPositionOffset(x, y, { x: 0, y: 0 })
              }
            >
              <div
                style={{
                  background: `#203254`,
                  padding: `7px 12px`,
                  fontSize: "11px",
                  color: `white`,
                  borderRadius: "4px",
                }}
              >
                {marker.title}
              </div>
            </OverlayViewF>
          );
        }
      });
    });
    setMarkerLabels(markerLabelsList);
  };

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    // map.

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  // const onLoad = (marker) => {
  //   console.log("marker: ", marker);
  // };
  // useEffect(() => {
  //   if (map) console.log(map);
  // }, [map]);
  return (
    <BasicContainer metaTitle="관유정-바우처 시스템 프랜차이즈">
      <Content>
        <PageTop
          label="교육지원"
          title="바우처 시스템 프랜차이즈"
          ImageUrl="/assets/image/EduHeaderImg.png"
        />
        <RowDiv style={{ marginTop: 64 }}>
          <TitleBox style={{ marginTop: 0 }}>
            <TitleLabel>교육지원</TitleLabel>
            <TitleText>바우처 (voucher) 시스템 프랜차이즈</TitleText>
            <Text
              style={{ marginTop: 16 }}
            >{`1급 자격자들의 난 계 진출과 기술자로서의 역할을 통해 일자리 창출과 난 계 발전을 위한 역할의 발판을 제공하고자
이대발 창업보육센터에서 실시하는 바우처(voucher) 양성 시스템입니다. 바우처 센터를 운영코자 하는 분들은 반드시 운영 시스템과
프로그램 및 운영 기술이 필요합니다. 이 때 필요한 컨설팅, 교육, 프로그램 지원 난실 시공까지 완벽하게 패키지로 임차해 주는 제도입니다.
이 때 가맹점은 중앙회의 협력 업체로 등록되며 완전한 독립 운영체제입니다.`}</Text>
            <Text style={{ color: Green600 }}>
              기존 공동 재배장과는 차원이 다른 실수익형 서비스 지원
              시스템입니다.
            </Text>
          </TitleBox>
        </RowDiv>
        <RowDiv style={{ marginTop: 40 }}>
          <ListTitle>국내 가맹점 현황</ListTitle>
          <ListTitle style={{ color: Green600, marginLeft: 8 }}>40</ListTitle>
        </RowDiv>
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={{
              width: windowSize.innerWidth <= 1439 ? `calc(100% - 40px)` : 1328,
              height:
                windowSize.innerWidth <= 1439 && windowSize.innerWidth > 767
                  ? 622
                  : windowSize.innerWidth <= 767
                  ? 273
                  : 646,
            }}
            center={center}
            zoom={18}
            options={{
              mapTypeControl: false,
              fullscreenControl: false,
              streetViewControl: false,
              gestureHandling: "cooperative",
              styles: [
                {
                  featureType: "road",
                  elementType: "labels",
                  stylers: [{ visibility: "off" }],
                },
                {
                  featureType: "water",
                  elementType: "labels",
                  stylers: [{ visibility: "off" }],
                },
                {
                  featureType: "poi.business",
                  stylers: [{ visibility: "off" }],
                },
                {
                  featureType: "transit",
                  elementType: "labels.icon",
                  stylers: [{ visibility: "off" }],
                },
                {
                  featureType: "poi",
                  elementType: "labels",
                  stylers: [{ visibility: "off" }],
                },
              ],
            }}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            <MarkerClustererF
              options={options}
              onClusteringEnd={markerLabelsHandler}
              gridSize={50}
              ignoreHidden={true}
            >
              {(clusterer) =>
                locations.map((location) => {
                  return (
                    <MarkerF
                      key={createKey(location)}
                      position={location}
                      // onLoad={onLoad}
                      clusterer={clusterer}
                      scaledSize={new window.google.maps.Size(50, 50)}
                      title={location.name}
                    >
                      {/* <OverlayView
                        key={location._id}
                        position={location}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        getPixelPositionOffset={(x, y) =>
                          getPixelPositionOffset(x, y, { x: 0, y: 0 })
                        }
                      >
                        <div
                          style={{
                            background: `#203254`,
                            padding: `7px 12px`,
                            fontSize: "11px",
                            color: `white`,
                            borderRadius: "4px",
                          }}
                        >
                          {location.name}
                        </div>
                      </OverlayView> */}
                    </MarkerF>
                    // <MarkerWithLabel
                    //   labelStyle={{
                    //     textAlign: "center",
                    //     width: labelSize.width + "px",
                    //     backgroundColor: "#7fffd4",
                    //     fontSize: "14px",
                    //     padding: labelPadding + "px",
                    //   }}
                    //   labelAnchor={{
                    //     x: labelSize.width / 2 + labelPadding,
                    //     y: 80,
                    //   }}
                    //   key={createKey(location)}
                    //   position={location}
                    //   clusterer={clusterer}
                    // >
                    //   <span>{location.name}</span>
                    // </MarkerWithLabel>
                  );
                })
              }
            </MarkerClustererF>
            {markerLabels}
            {/* Child components, such as markers, info windows, etc. */}
            <></>
          </GoogleMap>
        ) : (
          <></>
        )}
        <List style={{ marginTop: 56 }}>
          <ListLabel>01</ListLabel>
          <ListTitle>기대효과</ListTitle>
          <ListContent>
            {`한 분마다 센터는 오더 돌봄을 통해 참여자 수익의 30%를 수수료로 발생하게 되는데 3,000만원 투자를 3분만 받으면, 각종 비용을 제하고 월 100만원의 소득이 발생 됩니다.
덧붙여 본인의 영농을 통해 월 100만원을 벌면 안정적으로 노후를 보낼 수 있으며, 매년 1~2명 씩 인원을 늘리면 월 300까지도 가능합니다.`}
          </ListContent>
        </List>
        <List style={{ marginTop: 40 }}>
          <ListLabel>02</ListLabel>
          <ListTitle>바우처 시스템 프랜차이즈 참여 대상</ListTitle>
          <ListContent>
            <KindListUl>
              <KindListLi>1급 자격이 되어야 함</KindListLi>
              <KindListLi>
                난 실을 지으려고 예산이 확보된 분이어야 함
              </KindListLi>
              <KindListLi>
                3,000만원 정도의 종잣돈으로 난에 입문할 분을 3분 이상 확보한 분
              </KindListLi>
            </KindListUl>
          </ListContent>
        </List>
        <List style={{ marginTop: 40 }}>
          <ListLabel>03</ListLabel>
          <ListTitle>바우처 시스템 프랜차이즈 전체 구성</ListTitle>
          <ListContent>
            <KindListUl>
              <KindListLi>가맹 본부</KindListLi>
              <KindListLi>가맹점</KindListLi>
              <KindListLi>가맹점 참여 농가</KindListLi>
            </KindListUl>
          </ListContent>
        </List>
        <List style={{ marginTop: 40, marginBottom: 135 }}>
          <ListLabel>04</ListLabel>
          <ListTitle>
            가맹 본부 가맹점을 성공 할 수 있게 관리 운영하는 공동체
          </ListTitle>
          <ListContent>
            {windowSize.innerWidth <= 1439 ? (
              <TableBox>
                <VoucherFranchiseTable />
              </TableBox>
            ) : (
              <VoucherFranchiseTable />
            )}
          </ListContent>
        </List>
      </Content>
    </BasicContainer>
  );
};

export default VoucherFranchise;

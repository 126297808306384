import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ButtonB } from "../../../components/Button";
import {
  Black,
  Gray200,
  Gray600,
  Green600,
  White,
} from "../../../config/color";
import { getWindowSize } from "../../../utils";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 1328px;
  margin-top: 56px;
  margin-bottom: 112px;

  @media screen and (max-width: 1439px) {
    min-width: 360px;
    width: 100%;
    flex-direction: column;
  }
`;

const Card = styled.div`
  width: 432px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${Gray200};
  border-radius: 10px;

  @media screen and (max-width: 1439px) {
    width: 100%;
  }
`;

const InfoBox = styled.div`
  height: calc(428px - 48px);
  padding: 24px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  @media screen and (max-width: 1439px) {
    height: auto;
  }
`;

const Tag = styled.div`
  width: 100px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Green600};
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: ${White};
  margin-bottom: 12px;
`;

const Title = styled.div`
  font-family: "Nanum Myeongjo";
  font-size: 26px;
  font-weight: 600;
  line-height: 36px;
  color: ${Black};
  margin-bottom: 24px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 8px;
`;

const Label = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  width: 28px;
  margin-right: 8px;
`;

const Content = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${Black};
  white-space: pre-wrap;
  flex: 1;
`;

const Image = styled.img`
  width: 434px;
  height: 230px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  @media screen and (max-width: 1439px) {
    width: 100%;
    object-fit: cover;
  }
`;

const CertificateInfoCard = (props) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Container>
      <Card>
        <InfoBox>
          <Tag>2급 자격증 과정</Tag>
          <Title>2급 주말반</Title>
          <Row>
            <Label>장소</Label>
            <Content>대구카톨릭대학 평생교육원</Content>
          </Row>
          <Row>
            <Label>정원</Label>
            <Content>20~25명</Content>
          </Row>
          <Row>
            <Label>일정</Label>
            <Content>10주간 매주 토요일 / 연 4회 (3, 6, 9, 12) 실시</Content>
          </Row>
          <Row>
            <Label>특전</Label>
            <Content>한국 춘란 관리사 2급 자격증 발급</Content>
          </Row>
          <Row>
            <Label>비용</Label>
            <Content style={{ fontWeight: "700" }}>200만원</Content>
          </Row>
          <ButtonB
            viewStyle={{
              width: windowSize.innerWidth <= 1439 ? "100%" : 384,
              marginTop: 48,
            }}
            text="자세히보기"
            onClickEvent={() => {}}
          />
        </InfoBox>
        <Image src="/assets/tmpImg/lecturetmpImg.png" />
      </Card>
      <Card
        style={{
          marginLeft: windowSize.innerWidth <= 1439 ? 0 : 16,
          marginRight: windowSize.innerWidth <= 1439 ? 0 : 16,
          marginTop: windowSize.innerWidth <= 1439 ? 24 : 0,
          marginBottom: windowSize.innerWidth <= 1439 ? 24 : 0,
        }}
      >
        <InfoBox>
          <Tag>2급 자격증 과정</Tag>
          <Title>2급 평일반</Title>
          <Row>
            <Label>장소</Label>
            <Content>대한민국난문화진흥원</Content>
          </Row>
          <Row>
            <Label>정원</Label>
            <Content>5명 이상</Content>
          </Row>
          <Row>
            <Label>일정</Label>
            <Content>
              매월 첫 주 월~수 / 2박3일 (오전 10시 ~ 오후 5시) <br />
              하루 3과목씩 실시
            </Content>
          </Row>
          <Row>
            <Label>특전</Label>
            <Content>한국 춘란 관리사 2급 자격증 발급</Content>
          </Row>
          <Row>
            <Label>비용</Label>
            <Content style={{ fontWeight: "700" }}>300만원 (숙식 제공)</Content>
          </Row>
          <ButtonB
            viewStyle={{
              width: windowSize.innerWidth <= 1439 ? "100%" : 384,
              marginTop: 18,
            }}
            text="자세히보기"
            onClickEvent={() => {}}
          />
        </InfoBox>
        <Image src="/assets/tmpImg/lecturetmpImg.png" />
      </Card>
      <Card>
        <InfoBox>
          <Tag style={{ backgroundColor: Gray600 }}>1급 자격증 과정</Tag>
          <Title>1급반</Title>
          <Row>
            <Label>장소</Label>
            <Content>대한민국난문화진흥원</Content>
          </Row>
          <Row>
            <Label>정원</Label>
            <Content>1~3명</Content>
          </Row>
          <Row>
            <Label>일정</Label>
            <Content>
              상시 협의 개강 1박 2일 (오전 10시 ~ 오후6시)
              <br />
              하루 4과목씩
            </Content>
          </Row>
          <Row>
            <Label>특전</Label>
            <Content>한국 춘란 관리사 1급 자격증 발급</Content>
          </Row>
          <Row>
            <Label>비용</Label>
            <Content style={{ fontWeight: "700" }}>500만원 (숙식 제공)</Content>
          </Row>
          <ButtonB
            viewStyle={{
              width: windowSize.innerWidth <= 1439 ? "100%" : 384,
              marginTop: 18,
            }}
            text="자세히보기"
            onClickEvent={() => {}}
          />
        </InfoBox>
        <Image src="/assets/tmpImg/lecturetmpImg.png" />
      </Card>
    </Container>
  );
};

export default CertificateInfoCard;

import React from "react";
import styled from "styled-components";

const Container = styled.div`
  flex: 1;
`;

const Education = () => {
  return <Container></Container>;
};

export default Education;

import React from "react";
import styled from "styled-components";
import { Black, Gray200, Gray500, Green600, White } from "../config/color";

const Container = styled.div`
  width: calc(320px - 2px);
  display: flex;
  flex-direction: column;
  border: 1px solid ${Gray200};
  border-radius: 10px;
  margin: 0 auto;
  &:hover {
    cursor: pointer;
  }
`;

const InfoBox = styled.div`
  width: calc(100% - 48px);
  height: clac(182px - 44px);
  padding: 22px 24px;
  display: flex;
  flex-direction: column;
`;

const Tag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Green600};
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  width: 40px;
  height: 26px;
  color: ${White};
`;

const Image = styled.img`
  width: 320px;
  height: 256px;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

const ItemTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin-top: 12px;
  color: ${Black};
  white-space: pre-wrap;
`;

const ItemSubTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 25.2px;
  color: ${Gray500};
  margin-top: 12px;
`;

const EduListItem = (props) => {
  const { thumbnail, graduation, title, onClickEvent } = props;
  return (
    <Container onClick={onClickEvent}>
      <InfoBox>
        <Tag>{graduation}기</Tag>
        <ItemTitle>{`${title}
${graduation}기 총동창회`}</ItemTitle>
        <ItemSubTitle>대구 | 대구카톨릭평생교육원 {graduation}기</ItemSubTitle>
      </InfoBox>
      <Image src={thumbnail} />
    </Container>
  );
};

export default EduListItem;

import React from "react";
import styled from "styled-components";
import Footer from "./Footer";
import Header from "./Header";
import HelmetInfo from "./HelmetInfo";
import Navigation from "./Navigation";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

const BasicContainer = (props) => {
  const { metaTitle, children } = props;
  return (
    <Container>
      <HelmetInfo title={metaTitle} />
      <Header />
      <Navigation />
      {children}
      <Footer />
    </Container>
  );
};

export default BasicContainer;
